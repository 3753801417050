/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import Layout from "Layouts/PageLayout";
import { connect } from "react-redux";
import { appRoutesConst } from "../../app/navigation";
import { bindActionCreators } from "redux";
import {
  onRequestSetComponent,
  onSetDynamicHealthInput,
  onSetDynamicInput,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { actions as patientDetailActions } from "../../reducers/patientDetail";
import {
  setOfflineCallStatus,
  setDocumentViewedFlag,
} from "../../reducers/onlineOfflineCall";
import { onRequestSetVideoCallStatus } from "../../reducers/videoCall";
import { navigateTo } from "../../app/navigation";
import { Beforeunload } from "react-beforeunload";
import PatientDetailContainer from "../../containers/PatientDetailContainer/PatientDetailContainer";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";

import MedicalHistoryContainer from "../../containers/PatientDetailContainer/MedicalHistoryContainer/MedicalHistoryContainer";
import AllergiesContainer from "../../containers/PatientDetailContainer/AllergiesContainer/AllergiesContainer";
import AcuteMedicationsContainer from "../../containers/PatientDetailContainer/AcuteMedicationsContainer/AcuteMedicationsContainer";
import NHSContainer from "../../containers/PatientDetailContainer/NHSContainer/NHSContainer";
import FamilyRecordsContainer from "../../containers/PatientDetailContainer/FamilyRecordsContainer/FamilyRecordsContainer";
import TestInvestigationContainer from "../../containers/PatientDetailContainer/TestInvestigationContainer/TestInvestigationContainer";
import PreQuestionnaireContainer from "../../containers/PatientDetailContainer/PreQuestionnaireContainer/PreQuestionnaireContainer";
import ReportsContainer from "../../containers/PatientDetailContainer/ReportsContainer/ReportsContainer";
import SpecialNotesContainer from "../../containers/PatientDetailContainer/SpecialNotesContainer/SpecialNotesContainer";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../../containers/PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../../containers/PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../../containers/PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import WalkinAppointmentContainer from "../../containers/WalkinAppointment/WalkinAppointmentContainer";
import ExaminationContainer from "../../containers/WalkinAppointment/ExaminationContainer";
import ManagementPlanContainer from "../../containers/WalkinAppointment/ManagementPlanContainer";
import DignosisContainer from "../../containers/WalkinAppointment/DignosisContainer";
import BarcodeContainer from "../../containers/WalkinAppointment/BarcodeContainer";

import "assets/css/pages/profile-detail.css";
import topbarback from "assets/images/profile_header/topbar-back.svg";
// import location from "assets/images/common/location.png";
import { Prompt, withRouter } from "react-router-dom";
import { startEndOfflineCall } from "../../services/CallingServices";
import ImageSliderModal from "./ImageSliderModal";
import CallScreenShotContainer from "../../containers/WalkinAppointment/CallScreenShotContainer";
// import AppointmentReasonContainer from "../../containers/WalkinAppointment/AppointmentReasonContainer";
import store from "../../app/store";
import { actions as actionCableActions } from "reducers/actionCableData";
import {
  DATE_FORMAT,
  ROLE_CS_EXECUTIVE,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
  APPT_TYPE_WALKIN,
} from "../../constants/common";
import CollapsiableSubHeader from "../../components/common/CollapsiableSubHeader/CollapsiableSubHeader";
import moment from "moment";
import patientdetails from "assets/images/sidebar/ic-patient-details.svg";
import history from "assets/images/sidebar/ic-history.svg";
// import examination from "assets/images/sidebar/ic-examination.svg";
// import diagnosis from "assets/images/sidebar/ic-diagnosis.svg";
// import managmentplan from "assets/images/sidebar/ic-managmentplan.svg";
import referral from "assets/images/sidebar/ic-referral.svg";
import testinvestigation from "assets/images/sidebar/ic-test.svg";
import allergies from "assets/images/sidebar/ic-allergies-sensitivities.svg";
import currentmedication from "assets/images/sidebar/ic-medication-history.svg";
// import reports from "assets/images/sidebar/ic-reports.svg";
import specialnotes from "assets/images/sidebar/ic-specialnotes.svg";
import familyhistory from "assets/images/sidebar/ic-family-history.svg";
import previousencounters from "assets/images/sidebar/ic-previous-encounters.svg";
// import nhs from "assets/images/sidebar/ic-nhs.svg";
import prescription from "assets/images/sidebar/ic-prescription.svg";
import sicknote from "assets/images/sidebar/ic-sicknote.svg";
// import prescreeningquestionnaire from "assets/images/sidebar/ic-prescreeningquestionnaire.svg";
import medicalhistory from "assets/images/sidebar/ic-medical-history.svg";
// import screenshots from "assets/images/sidebar/ic-screenshots-uploads.svg";
import barcode from "assets/images/sidebar/ic-barcode.svg";
import {
  endPrescriberAppointment,
  getPatientHealthAssessmentDetailApi,
} from "services/patientConsultation";
// import PresriberEndCallModal from "./PresriberEndCallModal";
import HealthAssessmentContainer from "containers/PatientDetailContainer/HealthAssessmentContainer/HealthAssessmentContainer";
import healthassessment from "../../assets/images/sidebar/ic_Health Assessments_blue.svg";
import Timer from "react-compound-timer";
import CommonLoader from "components/common/CommonLoader";
import {
  FamilyhistorymenuIcon,
  HealthassessmentIcon,
  PatientdetailsIcon,
} from "./VideoAppointmentPage";
import { HistorymenuIcon } from "pages/QuestionnaireReview/QuestionnaireReviewDetailPage";
import photo_id from "assets/images/profile_header/id-card.svg";
import { addCallNotesAPI, verifyPatientAPI } from "services/patients";
import BookAppointmentForPatientContainer from "containers/AppointmentsContainers/BookAppointmentForPatientContainer";
import { Button } from "antd";
import { baseWebSocketURL } from "services/index";
import actionCable from "actioncable";
// const { Panel } = Collapse;

// function callback(key) {}
let OfflineAppointmentChnl;

class PatientDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName:
        verifyObject(this.props.appointmentObj, "appointment_type", null) ===
        APPT_TYPE_WALKIN
          ? "WalkinAppointmentContainer"
          : "PatientDetailContainer",
      visible: false,
      showWaitingModal: false,
      showPatientDetailModal: false,
      patientDetails: null,
      waitingSocketData: null,
      startCall: false,
      prescriberCallEnded: false,
      isCallEnding: false,
      reason_text: "",
      showInformGP: false,
      reviewDuration: 0,
      isOfflineAppointmentSocketConnected: false,
      timerKey: 0,
      connectedSocket: false,
    };
    [
      "_getIndexRoute",
      "_renderDynamicComponent",
      "_getPatientDetails",
      "_handleNavigation",
      "_handleBack",
      // "_handleTextChange",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.OfflineAppointmentRoom = actionCable.createConsumer(
      `${baseWebSocketURL}/cable`
    );
  }
  connectOfflineAppointmentChannel = async (params) => {
    let callParams = {};
    if (params) {
      callParams = {
        auth_token: store.getState().localStore.token,
        ...params,
      };
    }
    console.log(
      "------------Function called to connect offline appointment channel----------"
    );

    OfflineAppointmentChnl = this.OfflineAppointmentRoom.subscriptions.create(
      {
        channel: "OfflineAppointmentChannel",
        ...callParams,
      },
      {
        connected: (data) => {
          console.log(
            "------------Connected offline appointment channel----------",
            data
          );
          this.setState({
            isOfflineAppointmentSocketConnected: true,
          });
        },
        disconnected: () => {
          console.log(
            "--------Disconnected offline appointment channel----------"
          );
          this.OfflineAppointmentChnl = {};
          this.setState({
            isOfflineAppointmentSocketConnected: false,
            connectedSocket: false,
          });
        },
        received: (data) => {
          console.log(
            "-----------Received data from offline appointment channel",
            data
          );
          if (data?.code === 705) {
            this.setState(
              {
                reviewDuration: data?.data?.active_duration || 0,
                connectedSocket: true,
              },
              () => {
                console.log(
                  "------STATE updated offline appointment channel",
                  this.state.reviewDuration
                );
              }
            );
          }
          if (data?.code === 502) {
            this.setState(
              {
                isShowNHSPathFinder: data?.data?.nhs_pathfinder ,
              },
              () => {
                console.log(
                  "------STATE isShowNHSPathFinder",
                  this.state.isShowNHSPathFinder
                );
              }
            );
          }
        },
      }
    );
    this.setState({
      offlineAppointmentSubscribedChannel: OfflineAppointmentChnl,
    });
  };
  // _handleTextChange(e) {
  //   if (e)
  //     return this.setState({[e.target.name]: e.target.value}, () => {
  //       let condition =
  //         this.state.reason_text !== "" &&
  //         this.state.reason_text !== undefined &&
  //         this.state.reason_text.trim().length !== 0;
  //       if (condition) {
  //         let {onSetDynamicInput} = this.props.dynamicTabAction;
  //         let {dynamicInputs} = this.props;

  //         if (typeof onSetDynamicInput === "function") {
  //           let payload = {
  //             ...dynamicInputs,
  //             reason_text: this.state.reason_text,
  //           };
  //           onSetDynamicInput(payload);
  //         }
  //       }
  //     });
  // }
  // onUnload = () => {
  // };

  // componentDidMount() {
  //   // this._getPatientConultationDetails();

  // }

  componentDidMount() {
    store.dispatch({
      type: "ON_PRESCRIPTION_ADDED",
      payload: false,
    });
    let { dynamicInputs, appointmentObj, offlineCallStatus } = this.props;
    this.setState({
      ...dynamicInputs,
    });
    if (verifyObject(appointmentObj, "id", null)) {
      this.connectOfflineAppointmentChannel({
        appointment_id: verifyObject(appointmentObj, "id", null),
      });
    }
    this._getIndexRoute();
    this._getPatientDetails();
    let { componentName } = this.props;
    let onCallComponent =
      verifyObject(this.props.appointmentObj, "appointment_type", null) ===
      APPT_TYPE_WALKIN
        ? "WalkinAppointmentContainer"
        : componentName;
    this.setState({ componentName: onCallComponent });

    let isConsentGiven = verifyObject(dynamicInputs, "isConsentGiven", false);

    if (
      !isConsentGiven &&
      window.location.pathname === "/doctor/walkin-appointment" &&
      ((this.props.offlineCallStatus === "start" &&
        !verifyObject(
          this.props.appointmentObj,
          "inform_gp_request.inform_gp_request",
          null
        ) &&
        verifyObject(this.props.appointmentObj, "appointment_type", null) ===
          APPT_TYPE_WALKIN) ||
        this.props.is_from_patient_search_listing === true)
    ) {
      this.setState({ showInformGP: true }, () => {});
    }

    if (window.performance) {
    }

    if (
      performance.navigation.type === performance.navigation.TYPE_RELOAD
      //  &&
      // verifyObject(this.props.appointmentObj, "appointment_type", null) ===
      //   APPT_TYPE_WALKIN
    ) {
      // debugger;
      // store.dispatch({
      //   type: "SET_OFFLINE_CALL_STATUS",
      //   payload: null,
      // });
    } else {
    }
  }

  _SaveComponentStateToRedux2 = () => {
    let { onSetDynamicInput } = this.props.dynamicTabAction;
    let { dynamicInputs } = this.props;

    if (typeof onSetDynamicInput === "function") {
      let Inputs = {
        ...dynamicInputs,
        history: dynamicInputs.history,
        examination: dynamicInputs.examination,
        managementPlan: dynamicInputs.managementPlan,
        barcodeNumber: dynamicInputs.barcodeNumber,
        selectedSnomedCodesArray: dynamicInputs.selectedSnomedCodesArray,
        isShowSubmit: true,
        isConsentGiven: dynamicInputs.isConsentGiven,
      };
      onSetDynamicInput(Inputs);
    }
  };

  _getIndexRoute() {
    let { onRequestSetComponent, onSetSidebarKey } =
      this.props.dynamicTabAction;
    let onCallComponent =
      verifyObject(this.props.appointmentObj, "appointment_type", null) ===
      APPT_TYPE_WALKIN
        ? "WalkinAppointmentContainer"
        : "PatientDetailContainer";
    let onCallKey =
      verifyObject(this.props.appointmentObj, "appointment_type", null) ===
      APPT_TYPE_WALKIN
        ? "90"
        : "1";
    if (typeof onRequestSetComponent === "function") {
      onSetSidebarKey([onCallKey]);

      onRequestSetComponent(onCallComponent);
    }
  }

  _getPatientDetails(patient_id) {
    let {
      patientId,
      patientDetailActions: { onRequest },
    } = this.props;
    if (typeof onRequest === "function") {
      onRequest({ id: patient_id ? patient_id : patientId });
    }
  }

  componentWillReceiveProps(nextProps) {
    let { componentName } = nextProps;
    this.setState({
      componentName: componentName,
    });
    // let { isClosed } = this.state;
  }

  _renderDynamicComponent() {
    let { componentName } = this.state;
    let { componentId } = this.props;
    let state = JSON.parse(localStorage.getItem("state"));

    switch (componentName) {
      case "PatientDetailContainer":
        // let {isLoadingDetail} = this.props;
        return (
          <PatientDetailContainer
            isLoadingDetail={
              this.props &&
              this.props.isLoadingDetail &&
              this.props.isLoadingDetail
            }
            patientDetail={verifyObject(
              state,
              "localStore.patient_detail_obj",
              null
            )}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
      case "HealthAssessmentContainer":
        return <HealthAssessmentContainer />;
      case "MedicalHistoryContainer":
        return <MedicalHistoryContainer />;
      case "WalkinAppointmentContainer":
        return (
          <div>
            {/* <AppointmentReasonContainer
              _handleTextChange={this._handleTextChange}
              reason_text={this.state.reason_text}
              fromOngoingConsultation={true}
            /> */}
            <WalkinAppointmentContainer
              componentId={componentId}
              fromOngoingConsultation={true}
              handlePrescriberCallEnd={this.handlePrescriberCallEnd}
              isCallEnding={this.state.isCallEnding}
              callStartEnd={this.callStartEnd}
            />
          </div>
        );
      case "ExaminationContainer":
        return (
          <div>
            {/* <AppointmentReasonContainer
              _handleTextChange={this._handleTextChange}
              reason_text={this.state.reason_text}
              fromOngoingConsultation={true}
            /> */}
            <ExaminationContainer />
          </div>
        );

      case "ManagementPlanContainer":
        return (
          <div>
            {/* <AppointmentReasonContainer
              _handleTextChange={this._handleTextChange}
              reason_text={this.state.reason_text}
              fromOngoingConsultation={true}
            /> */}
            <ManagementPlanContainer />
          </div>
        );

      case "DignosisContainer":
        return (
          <div>
            {/* <AppointmentReasonContainer
              _handleTextChange={this._handleTextChange}
              reason_text={this.state.reason_text}
              fromOngoingConsultation={true}
            /> */}
            <DignosisContainer />
          </div>
        );
      case "BarcodeContainer":
        return (
          <div>
            {/* <AppointmentReasonContainer
              _handleTextChange={this._handleTextChange}
              reason_text={this.state.reason_text}
              fromOngoingConsultation={true}
            /> */}
            <BarcodeContainer />
          </div>
        );
      case "CallScreenShotContainer":
        return <CallScreenShotContainer />;
      // case "AppointmentReasonContainer":
      //   return <AppointmentReasonContainer />;
      // case "AddCallPrescription":
      //   return <AddCallPrescription />;
      case "AllergiesContainer":
        return <AllergiesContainer />;
      case "AcuteMedicationsContainer":
        return <AcuteMedicationsContainer />;

      case "NHSContainer":
        return <NHSContainer />;

      case "FamilyRecordsContainer":
        return <FamilyRecordsContainer />;

      case "TestInvestigationContainer":
        return <TestInvestigationContainer />;

      case "PreQuestionnaireContainer":
        return <PreQuestionnaireContainer fromVideoAppointment={true} />;

      case "ReportsContainer":
        return <ReportsContainer />;

      case "SpecialNotesContainer":
        return <SpecialNotesContainer />;

      case "PreEncountersContainer":
        return <PreEncountersContainer />;

      case "PrescriptionsContainer":
        return <PrescriptionsContainer />;

      case "SickNotesContainer":
        return <SickNotesContainer />;

      case "ReferralNotesContainer":
        return <ReferralNotesContainer />;

      default:
        return (
          <PatientDetailContainer
            isLoadingDetail={
              this.props &&
              this.props.isLoadingDetail &&
              this.props.isLoadingDetail
            }
            patientDetail={verifyObject(
              state,
              "localStore.patient_detail_obj",
              null
            )}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
    }
  }

  _handleNavigation() {
    let { navigateTo } = this.props.navigateTo;
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientSearch);
    }
  }

  _SaveComponentStateToRedux = () => {
    let { onSetDynamicInput } = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let { dynamicInputs } = this.props;
      let HistoryParams = {
        history:
          dynamicInputs && dynamicInputs.history
            ? `${dynamicInputs.history} \nPatient identity has been checked`
            : `Patient identity has been checked`,
      };
      let payload = {
        ...dynamicInputs,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history: dynamicInputs ? dynamicInputs.history : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        managementPlan: dynamicInputs ? dynamicInputs.managementPlan : "",
        reason_text: dynamicInputs ? dynamicInputs.reason_text : [],

        ...HistoryParams,
      };

      let { setDocumentViewedFlag } = this.props.offlineCallActions;

      onSetDynamicInput(payload);
      if (typeof setDocumentViewedFlag === "function") {
        setDocumentViewedFlag(true);
      }
    }
  };

  _handleBack() {
    let {
      history: { goBack },
    } = this.props;
    if (typeof goBack === "function") {
      // goBack();
    }

    this._clearData();
  }

  callStartEnd = async (status, isSubmitForReview, reviewerParams) => {
    if (status === "start") {
      this.getPatientHealthAssessmentDetail();
      store.dispatch({
        type: "IS_CLICKED_START_CALL",
        payload: true,
      });
    }
    let { appointmentId } = this.props;
    let reviewerParams1 = {};
    if (reviewerParams) {
      reviewerParams1 = {
        review_by_id: reviewerParams?.review_by_id,
      };
      if (reviewerParams.reviewer === "healthya_corp") {
        reviewerParams1 = {
          review_by_healthya_team: true,
          // review_by_id: reviewerParams.review_by_id,
        };
      }
    }

    let payload = {
      appointment_id: appointmentId,
      call_token: "",
      status: status,
      time: new Date().toISOString(),
      is_submitted_for_review: isSubmitForReview,
      ...reviewerParams1,
    };
    let { setOfflineCallStatus } = this.props.offlineCallActions;
    if (typeof setOfflineCallStatus === "function") {
      setOfflineCallStatus(status);
    }
    store.dispatch({
      type: "SET_SYMPTOMP_FETCHED_LINKS",
      payload: null,
    });
    await setOfflineCallStatus(status);
    let { onSetSidebarKey, onRequestSetComponent } =
      this.props.dynamicTabAction;
    let key = 90;
    let page = "WalkinAppointmentContainer";
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];
        console.log("PAYLOAD", payload);

        onSetSidebarKey(payload);
      }
    }
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }

    try {
      let response = await startEndOfflineCall(payload);

      if (response.data && response.data.message) {
        // let { setOfflineCallStatus } = this.props.offlineCallActions;
        // if (typeof setOfflineCallStatus === "function") {
        // }
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      this.setState({ updatingDetail: false });
      errorToast({ content: message });
    }
  };
  getPatientHealthAssessmentDetail = async () => {
    let { patientId } = this.props;
    try {
      this.setState({ isLoadingHealthAssessment: true });
      let response = await getPatientHealthAssessmentDetailApi(patientId);
      let data = verifyObject(response, "data.data", []);
      // verifyObject(response, "data.data", []).length > 0 &&
      //   verifyObject(response, "data.data", []).map((item, index) => {
      //     return
      this.setState(
        {
          pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
          heightObject: this.getObjectFromData(data, "height"),
          weightObject: this.getObjectFromData(data, "weight"),
          body_temperatureObject: this.getObjectFromData(
            data,
            "body_temperature"
          ),
          blood_oxygen_saturationObject: this.getObjectFromData(
            data,
            "blood_oxygen_saturation"
          ),
          systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
          diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
          bmiObject: this.getObjectFromData(data, "bmi"),
        },
        () => {
          let {
            pulseObject,
            heightObject,
            weightObject,
            blood_oxygen_saturationObject,
            body_temperatureObject,
            bmiObject,
            systolic_bpObject,
            diastolic_bpObject,
          } = this.state;
          let { onSetDynamicHealthInput } = this.props.dynamicTabAction;
          if (typeof onSetDynamicHealthInput === "function") {
            let Inputs = {
              ...this.props.dynamicHealthInputs,
              pulseObject,
              heightObject,
              weightObject,
              blood_oxygen_saturationObject,
              body_temperatureObject,
              bmiObject,
              systolic_bpObject,
              diastolic_bpObject,
            };
            onSetDynamicHealthInput(Inputs);
          }
        }
      );

      await this.setState({ isLoadingHealthAssessment: false });
    } catch (error) {
      // const {message} = getErrorObject(error);
      await this.setState({ isLoadingHealthAssessment: false });
      // errorToast({content: message});
    }
  };
  getObjectFromData = (data, value) => {
    let found;
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.find((item, index) => {
        // console.log("checked=========>", item.name, value, item.name === value);
        if (item.name === value) return (found = item);
      });
    }
    console.log("found--------------------", found);
    return found;
  };
  handlePrescriberCallEnd = async (
    status,
    isSubmitForReview,
    reviewerParams
  ) => {
    let { appointmentId } = this.props;
    let submittedReviewParamas = {};
    let reviewerParams1 = {
      review_by_id: reviewerParams.review_by_id,
    };
    if (reviewerParams.reviewer === "healthya_corp") {
      reviewerParams1 = {
        review_by_healthya_team: true,
        // review_by_id: reviewerParams.review_by_id,
      };
    }
    if (isSubmitForReview) {
      submittedReviewParamas = {
        status: "submitted_to_review", //or finished
        ...reviewerParams1,
      };
    }
    let payload = {
      id: appointmentId,
      ...submittedReviewParamas,
    };
    let { setOfflineCallStatus } = this.props.offlineCallActions;
    if (typeof setOfflineCallStatus === "function") {
      setOfflineCallStatus(status);
    }
    await setOfflineCallStatus(status);
    try {
      await this.setState({
        isCallEnding: true,
      });
      let response = await endPrescriberAppointment(payload);
      if (response.data && response.data.message) {
        // let { setOfflineCallStatus } = this.props.offlineCallActions;
        // if (typeof setOfflineCallStatus === "function") {
        // }
        store.dispatch({
          type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
          payload: null,
        });
        store.dispatch({ type: "SHOW_SUBMIT_FROM_NOTES", payload: false });
        store.dispatch({
          type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
          payload: null,
        });
        store.dispatch({
          type: "IS_CLICKED_START_CALL",
          payload: false,
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let { onSetDynamicHealthInput } = this.props.dynamicTabAction;
        if (typeof onSetDynamicHealthInput === "function") {
          onSetDynamicHealthInput(null);
        }
        // console.log(
        //   "INNNNNNNNN.... iswon pharmacy",
        //   process.env.REACT_APP_ENV,
        //   this.props.isOwnPharamcy,
        //   this.props.prescriptionCode
        // );
        let { navigateTo } = this.props.navigateTo;

        if (!this.props.isOwnPharamcy && this.props.prescriptionCode) {
          // let corporate_name = verifyObject(user, "pharmacy.name", "");
          // let pharmacistName = `${verifyObject(
          //   user,
          //   "first_name",
          //   ""
          // )} ${verifyObject(user, "last_name", "")}`;
          // let gphcNumber = verifyObject(user, "pharmacy.unique_number", "");
          // if (process.env.REACT_APP_ENV === "prod") {
          //   window.open(
          //     `https://pharmacy.alldaydr.com/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}&&corporate_name=${corporate_name}&&pharmacist_name=${pharmacistName}&&gphc=${gphcNumber}`,
          //     "_blank"
          //   );
          //   // window.location.href = `https://pharmacy.alldaydr.com/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}`;
          // } else if (process.env.REACT_APP_ENV === "stage") {
          //   window.open(
          //     `https://d14niqine55k61.cloudfront.net/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}&&corporate_name=${corporate_name}&&pharmacist_name=${pharmacistName}&&gphc=${gphcNumber}`,
          //     "_blank"
          //   );
          //   // window.location.href = `https://d14niqine55k61.cloudfront.net/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}`;
          // } else {
          // window.open(
          //   `https://d2sd2yiqffqkcy.cloudfront.net/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}&&corporate_name=${corporate_name}&&pharmacist_name=${pharmacistName}&&gphc=${gphcNumber}`,
          //   "_blank"
          // );
          // window.location.href = `https://d2sd2yiqffqkcy.cloudfront.net/e_prescription?dob=${this.props.patientDOB}&&perscripton_code=${this.props.prescriptionCode}`;
          // }
          if (typeof navigateTo === "function") {
            await navigateTo(appRoutesConst.dashboard);
          }
        } else {
          if (typeof navigateTo === "function") {
            await navigateTo(appRoutesConst.dashboard);
          }
        }
        this.setState({ prescriberCallEnded: true, isCallEnding: false });

        store.dispatch({
          type: "ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER",
          payload: false,
        });
        // store.dispatch({     //already set null above for show poup for next call
        //   type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
        //   payload: false,
        // });
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      this.setState({ updatingDetail: false });
      errorToast({ content: message });
    }
  };

  _clearData = () => {
    let { navigateTo } = this.props.navigateTo;
    var r = window.confirm("Are you sure want to leave this page");
    if (r === true) {
      store.dispatch({
        type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
        payload: null,
      });
      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: null,
      });
      store.dispatch({
        type: "IS_CLICKED_START_CALL",
        payload: false,
      });
      store.dispatch({
        type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
        payload: null,
      });
      store.dispatch({ type: "SHOW_SUBMIT_FROM_NOTES", payload: false });
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHED_LINKS",
        payload: null,
      });
      let { onSetDynamicHealthInput } = this.props.dynamicTabAction;
      if (typeof onSetDynamicHealthInput === "function") {
        onSetDynamicHealthInput(null);
      }
      navigateTo(appRoutesConst.dashboard);
    } else {
      if (typeof navigateTo === "function") {
        navigateTo(
          this.props.prev_appt_tab_key === "unfinished"
            ? appRoutesConst.patientConsultationDetail
            : appRoutesConst.walkinAppointment
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.reviewDuration !== this.state.reviewDuration) {
      // Increment the key to remount the Timer with the updated initialTime
      this.setState((prevState) => ({
        timerKey: prevState.timerKey + 1,
      }));
    }
    // window.onpopstate = (e) => {
    //   this._clearData();
    // };
    let { dynamicInputs, appointmentObj, is_gp_informed } = this.props;

    let isConsentGiven = verifyObject(dynamicInputs, "isConsentGiven", false);
    if (
      this.props.offlineCallStatus === "start" &&
      verifyObject(this.props.appointmentObj, "appointment_type", null) ===
        APPT_TYPE_WALKIN &&
      // !verifyObject(
      //   this.props.appointmentObj,
      //   "inform_gp_request.inform_gp_request",
      //   null
      // ) &&
      this.state.isOfflineAppointmentSocketConnected
    ) {
      console.log(
        "-------offline socket connected and called call log API------"
      );
      this.callStartEnd("start");
      this.setState({ isOfflineAppointmentSocketConnected: false });
    }
    if (
      !isConsentGiven &&
      !this.state.showInformGP &&
      window.location.pathname === "/doctor/walkin-appointment" &&
      ((this.props.offlineCallStatus === "start" &&
        !verifyObject(
          this.props.appointmentObj,
          "inform_gp_request.inform_gp_request",
          null
        ) &&
        verifyObject(this.props.appointmentObj, "appointment_type", null) ===
          APPT_TYPE_WALKIN) ||
        this.props.is_from_patient_search_listing === true) &&
      !is_gp_informed
    ) {
      this.setState({ showInformGP: true }, () => {
        // store.dispatch({
        //   type: "IS_CONSULTATION_API_STARTED",
        //   payload: false,
        // });
      });
    }
    if (
      this.props.offlineCallStatus !== "start" &&
      verifyObject(
        this.props.appointmentObj,
        "inform_gp_request.inform_gp_request",
        null
      )
    ) {
      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
    }
  }

  componentWillUnmount() {
    let { onSetSidebarKey, onSetDynamicInput } = this.props.dynamicTabAction;
    let { setOfflineCallStatus, setDocumentViewedFlag } =
      this.props.offlineCallActions;
    // store.dispatch({
    //   type: "IS_CLICKED_START_CALL",
    //   payload: false,
    // });
    if (
      this.state.offlineAppointmentSubscribedChannel &&
      typeof this.state.offlineAppointmentSubscribedChannel.unsubscribe ===
        "function"
    ) {
      this.state.offlineAppointmentSubscribedChannel.unsubscribe();
      this.OfflineAppointmentRoom = null;
      OfflineAppointmentChnl = null;
    }
    if (typeof onSetSidebarKey === "function") {
      let payload = [`1`];
      onSetSidebarKey(payload);
    }

    if (typeof onSetDynamicInput === "function") {
      onSetDynamicInput(null);
    }
    if (typeof setOfflineCallStatus === "function") {
      setOfflineCallStatus(null);
    }
    if (typeof setDocumentViewedFlag === "function") {
      setDocumentViewedFlag(false);
    }
    // window.onpopstate = null;
  }
  renderStatus = (patientDetail) => {
    console.log("RENDER FOR STATUS----", patientDetail);
    const status = verifyObject(patientDetail, "user.status");

    switch (status) {
      case "active":
        return "Verified";
      case "unverified":
        return "Unverified";
      case "rejected":
        return "Rejected";
      default:
        return "";
    }
  };
  verifyPatientFunction = async (patient_id) => {
    try {
      this.setState({ verifyingPatient: true });
      let verifyParam = { user: { status: "active" } };
      let callNoteParam = {
        patient_id,
        note: "Verification",
        note_type: "Verification",
        end_call: true,
        call_id: patient_id,
        call_type: "User",
        call_flag: 1,
      };
      const responsePatient = await verifyPatientAPI(verifyParam, patient_id);
      const responseNote = await addCallNotesAPI(callNoteParam);
      this._getPatientDetails(patient_id);

      successToast({
        content: verifyObject(
          responsePatient,
          "data.message",
          "Verified Succsess"
        ),
      });
      successToast({
        content: verifyObject(responseNote, "data.message", "Call notes saved"),
      });
      console.log("BOTH REPONSE---", responsePatient, responseNote);
      this.setState({ verifyingPatient: false, visible: false });
    } catch (error) {
      this.setState({ verifyingPatient: false });
      const { message } = getErrorObject(error);

      errorToast({ content: message });
    }
  };
  render() {
    let { patientDetail, offlineCallStatus, isDocumentViewed, userRole } =
      this.props;
    let { visible } = this.state;
    let sideBarArray = [
      {
        label: "Patient Details",
        path: appRoutesConst.walkinAppointment,
        icon: (
          // <img
          //   alt="Patient Details"
          //   title="Patient Details"
          //   src={patientdetails}
          // ></img>
          <PatientdetailsIcon width="12px" fill="#757575" />
        ),
        key: 1,
        page: "PatientDetailContainer",
        component_id: "patient_detail",
        useDynamicComponent: true,
      },
      {
        label: "Summary",
        path: appRoutesConst.dynamicQuestionnaireReviewDetail,
        icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

        key: 6,
        page: "FamilyRecordsContainer",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Medication History",
            path: appRoutesConst.walkinAppointment,
            icon: (
              <img alt="Medication History  " src={currentmedication}></img>
            ),
            key: 202,
            page: "AcuteMedicationsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Problems / Conditions",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Problems / Conditions" src={medicalhistory}></img>,
            key: 2,
            page: "MedicalHistoryContainer",
            useDynamicComponent: true,
          },
          {
            label: "Family Records",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Family Records" src={familyhistory}></img>,
            key: 6,
            page: "FamilyRecordsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Allergies and Sensitivities",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Allergies and Sensitivities" src={allergies}></img>,
            page: "AllergiesContainer",
            key: 3,
            component_id: "AllergiesContainer",
            useDynamicComponent: true,
          },
          {
            label: "Test/Investigation",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Test/Investigation" src={testinvestigation}></img>,
            page: "TestInvestigationContainer",
            key: 7,
            useDynamicComponent: true,
          },
        ],
      },
      {
        label: "Health Assessments",
        path: appRoutesConst.patientConsultationDetail,
        icon: <HealthassessmentIcon width="14px" fill="#757575" />,
        page: "HealthAssessmentContainer",
        key: 112,
        useDynamicComponent: true,
      },
      {
        label: "Consultations",
        path: appRoutesConst.walkinAppointment,
        icon: <HistorymenuIcon width="12px" fill="#757575" />,
        key: 201,
        page: "WalkinAppointmentContainer",
        component_id: "patient_detail",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Consultation Detail",
            component_id: "history",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="History" title="History" src={history}></img>,
            key: 90,
            page: "WalkinAppointmentContainer",
            useDynamicComponent: true,
          },

          {
            label: "Add Referral",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Referral" title="Referral" src={referral}></img>,
            key: 95,
            page: "AddCallReferral",
            useDynamicComponent: true,
          },
          {
            label: "Add Sick Note",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Sick Notes" src={sicknote}></img>,
            key: 96,
            page: "AddCallSickNote",
            useDynamicComponent: true,
            isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
          },
          {
            label: "Add Prescription",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="Prescriptions" src={prescription}></img>,
            key: 97,
            page: "AddCallPrescription",
            useDynamicComponent: true,
            isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
          },
        ],
      },
      // {
      //   label: "BARCODE Number",
      //   component_id: "barcode_number",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: (
      //     <img alt="BARCODE Number" title="BARCODE Number" src={barcode}></img>
      //   ),
      //   key: 94,
      //   page: "BarcodeContainer",
      //   useDynamicComponent: true,
      // },

      // {
      //   label: "Previous Consultations",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: <img alt="Medication History  " src={currentmedication}></img>,
      //   key: 392,
      //   page: "MedicalHistoryContainer",
      //   useDynamicComponent: true,
      //   hasSubmenu: true,
      //   submenu: [
      //     {
      //       label: "Pre. Encounters",
      //       path: appRoutesConst.walkinAppointment,
      //       icon: <img alt="Pre. Encounters" src={previousencounters}></img>,
      //       page: "PreEncountersContainer",
      //       key: 11,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Prescriptions",
      //       path: appRoutesConst.walkinAppointment,
      //       icon: <img alt="Prescriptions" src={prescription}></img>,
      //       page: "PrescriptionsContainer",
      //       key: 12,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Sick Notes",
      //       path: appRoutesConst.walkinAppointment,
      //       icon: <img alt="Sick Notes" src={sicknote}></img>,
      //       page: "SickNotesContainer",
      //       key: 13,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Referral Notes",
      //       path: appRoutesConst.walkinAppointment,
      //       icon: <img alt="Referral Notes" src={referral}></img>,
      //       page: "ReferralNotesContainer",
      //       key: 14,
      //       useDynamicComponent: true,
      //     },
      //   ],
      // },

      // {
      //   label: "Pre-Screening Questionnaire",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: (
      //     <img
      //       alt="Pre-Screening Questionnaire"
      //       src={prescreeningquestionnaire}
      //     ></img>
      //   ),
      //   page: "PreQuestionnaireContainer",
      //   key: 8,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Reports",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: <img alt="Reports" src={reports}></img>,
      //   page: "ReportsContainer",
      //   key: 9,
      //   useDynamicComponent: true,
      // },
      {
        label: (
          <span>
            Special Notes
            <span className="sidebar-notification-badge">
              {patientDetail && patientDetail.special_notes_count}
            </span>
          </span>
        ),
        path: appRoutesConst.walkinAppointment,
        icon: <img alt="Special Notes" src={specialnotes}></img>,
        page: "SpecialNotesContainer",
        key: 10,
        useDynamicComponent: true,
      },
    ];
    if (this.props.is_from_patient_search_listing) {
      sideBarArray = [
        {
          label: "Patient Details",
          path: appRoutesConst.walkinAppointment,
          icon: (
            // <img
            //   alt="Patient Details"
            //   title="Patient Details"
            //   src={patientdetails}
            // ></img>
            <PatientdetailsIcon width="12px" fill="#757575" />
          ),
          key: 1,
          page: "PatientDetailContainer",
          component_id: "patient_detail",
          useDynamicComponent: true,
        },
        {
          label: "Summary",
          path: appRoutesConst.dynamicQuestionnaireReviewDetail,
          icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

          key: 6,
          page: "FamilyRecordsContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Medication History",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Medication History  " src={currentmedication}></img>
              ),
              key: 202,
              page: "AcuteMedicationsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Problems / Conditions",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Problems / Conditions" src={medicalhistory}></img>
              ),
              key: 2,
              page: "MedicalHistoryContainer",
              useDynamicComponent: true,
            },
            {
              label: "Family Records",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="Family Records" src={familyhistory}></img>,
              key: 6,
              page: "FamilyRecordsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Allergies and Sensitivities",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Allergies and Sensitivities" src={allergies}></img>
              ),
              page: "AllergiesContainer",
              key: 3,
              component_id: "AllergiesContainer",
              useDynamicComponent: true,
            },
            {
              label: "Test/Investigation",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Test/Investigation" src={testinvestigation}></img>
              ),
              page: "TestInvestigationContainer",
              key: 7,
              useDynamicComponent: true,
            },
          ],
        },
        {
          label: "Health Assessments",
          path: appRoutesConst.patientConsultationDetail,
          icon: <HealthassessmentIcon width="14px" fill="#757575" />,
          page: "HealthAssessmentContainer",
          key: 112,
          useDynamicComponent: true,
        },
        {
          label: "Consultations",
          path: appRoutesConst.walkinAppointment,
          icon: <HistorymenuIcon width="12px" fill="#757575" />,
          key: 201,
          page: "WalkinAppointmentContainer",
          component_id: "patient_detail",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Consultation Detail",
              component_id: "history",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="History" title="History" src={history}></img>,
              key: 90,
              page: "WalkinAppointmentContainer",
              useDynamicComponent: true,
            },
            // {
            //   label: "Examination",
            //   component_id: "examination",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Examination"
            //       title="Examination"
            //       src={examination}
            //     ></img>
            //   ),
            //   key: 91,
            //   page: "ExaminationContainer",
            //   useDynamicComponent: true,
            // },
            // {
            //   label: "Dignosis / Clinical Impression",
            //   component_id: "dignosis_clinical_impression",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Dignosis / Clinical Impression"
            //       title="Dignosis / Clinical Impression"
            //       src={diagnosis}
            //     ></img>
            //   ),
            //   key: 92,
            //   page: "DignosisContainer",
            //   useDynamicComponent: true,
            // },
            // {
            //   label: "Management Plan",
            //   component_id: "management_plan",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Management Plan"
            //       title="Management Plan"
            //       src={managmentplan}
            //     ></img>
            //   ),
            //   key: 93,
            //   page: "ManagementPlanContainer",
            //   useDynamicComponent: true,
            // },
            {
              label: "Add Referral",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="Referral" title="Referral" src={referral}></img>,
              key: 95,
              page: "AddCallReferral",
              useDynamicComponent: true,
            },
            {
              label: "Add Sick Note",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="Sick Notes" src={sicknote}></img>,
              key: 96,
              page: "AddCallSickNote",
              useDynamicComponent: true,
              isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
            },
            {
              label: "Add Prescription",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="Prescriptions" src={prescription}></img>,
              key: 97,
              page: "AddCallPrescription",
              useDynamicComponent: true,
              isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
            },
          ],
        },

        {
          label: (
            <span>
              Special Notes
              <span className="sidebar-notification-badge">
                {patientDetail && patientDetail.special_notes_count}
              </span>
            </span>
          ),
          path: appRoutesConst.walkinAppointment,
          icon: <img alt="Special Notes" src={specialnotes}></img>,
          page: "SpecialNotesContainer",
          key: 10,
          useDynamicComponent: true,
        },
      ];
    }
    if (
      userRole === ROLE_HCSW ||
      userRole === ROLE_NURSE ||
      userRole === ROLE_CS_EXECUTIVE
    ) {
      sideBarArray = [
        {
          label: "Patient Details",
          path: appRoutesConst.walkinAppointment,
          icon: (
            <img
              alt="Patient Details"
              title="Patient Details"
              src={patientdetails}
            ></img>
          ),
          key: 1,
          page: "PatientDetailContainer",
          component_id: "patient_detail",
          useDynamicComponent: true,
        },
        {
          label: "Summary",
          path: appRoutesConst.dynamicQuestionnaireReviewDetail,
          icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

          key: 6,
          page: "FamilyRecordsContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Family Records",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="Family Records" src={familyhistory}></img>,
              key: 6,
              page: "FamilyRecordsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Allergies and Sensitivities",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Allergies and Sensitivities" src={allergies}></img>
              ),
              page: "AllergiesContainer",
              key: 3,
              component_id: "AllergiesContainer",
              useDynamicComponent: true,
            },
            {
              label: "Medication History",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img
                  alt="Current Acute Medications"
                  src={currentmedication}
                ></img>
              ),
              key: 100,
              page: "AcuteMedicationsContainer",
              component_id: "AcuteMedicationsContainer",

              useDynamicComponent: true,
            },
            {
              label: "Problems / Conditions",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Problems / Conditions" src={medicalhistory}></img>
              ),
              key: 2,
              page: "MedicalHistoryContainer",
              useDynamicComponent: true,
            },
            {
              label: "Test/Investigation",
              path: appRoutesConst.walkinAppointment,
              icon: (
                <img alt="Test/Investigation" src={testinvestigation}></img>
              ),
              page: "TestInvestigationContainer",
              key: 7,
              useDynamicComponent: true,
            },
          ],
        },
        {
          label: "Health Assessments",
          path: appRoutesConst.patientConsultationDetail,
          icon: <img src={healthassessment} alt="Health Assessments" />,
          page: "HealthAssessmentContainer",
          key: 112,
          useDynamicComponent: true,
        },
        {
          label: "Consultations",
          path: appRoutesConst.walkinAppointment,
          icon: (
            <img
              alt="Patient Details"
              title="Patient Details"
              src={patientdetails}
            ></img>
          ),
          key: 201,
          page: "PatientDetailContainer",
          component_id: "patient_detail",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Consultation Detail",
              component_id: "history",
              path: appRoutesConst.walkinAppointment,
              icon: <img alt="History" title="History" src={history}></img>,
              key: 90,
              page: "WalkinAppointmentContainer",
              useDynamicComponent: true,
            },
            // {
            //   label: "Examination",
            //   component_id: "examination",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Examination"
            //       title="Examination"
            //       src={examination}
            //     ></img>
            //   ),
            //   key: 91,
            //   page: "ExaminationContainer",
            //   useDynamicComponent: true,
            // },
            // {
            //   label: "Dignosis / Clinical Impression",
            //   component_id: "dignosis_clinical_impression",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Dignosis / Clinical Impression"
            //       title="Dignosis / Clinical Impression"
            //       src={diagnosis}
            //     ></img>
            //   ),
            //   key: 92,
            //   page: "DignosisContainer",
            //   useDynamicComponent: true,
            // },
            // {
            //   label: "Management Plan",
            //   component_id: "management_plan",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="Management Plan"
            //       title="Management Plan"
            //       src={managmentplan}
            //     ></img>
            //   ),
            //   key: 93,
            //   page: "ManagementPlanContainer",
            //   useDynamicComponent: true,
            // },
            // {
            //   label: "BARCODE Number",
            //   component_id: "barcode_number",
            //   path: appRoutesConst.walkinAppointment,
            //   icon: (
            //     <img
            //       alt="BARCODE Number"
            //       title="BARCODE Number"
            //       src={barcode}
            //     ></img>
            //   ),
            //   key: 94,
            //   page: "BarcodeContainer",
            //   useDynamicComponent: true,
            // },
          ],
        },
        // {
        //   label: "History",
        //   component_id: "history",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: <img alt="History" title="History" src={history}></img>,
        //   key: 90,
        //   page: "WalkinAppointmentContainer",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Examination",
        //   component_id: "examination",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: (
        //     <img alt="Examination" title="Examination" src={examination}></img>
        //   ),
        //   key: 91,
        //   page: "ExaminationContainer",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Dignosis / Clinical Impression",
        //   component_id: "dignosis_clinical_impression",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: (
        //     <img
        //       alt="Dignosis / Clinical Impression"
        //       title="Dignosis / Clinical Impression"
        //       src={diagnosis}
        //     ></img>
        //   ),
        //   key: 92,
        //   page: "DignosisContainer",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Management Plan",
        //   component_id: "management_plan",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: (
        //     <img
        //       alt="Management Plan"
        //       title="Management Plan"
        //       src={managmentplan}
        //     ></img>
        //   ),
        //   key: 93,
        //   page: "ManagementPlanContainer",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "BARCODE Number",
        //   component_id: "barcode_number",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: (
        //     <img
        //       alt="BARCODE Number"
        //       title="BARCODE Number"
        //       src={barcode}
        //     ></img>
        //   ),
        //   key: 94,
        //   page: "BarcodeContainer",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Referral",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   key: 95,
        //   page: "AddCallReferral",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Add Sick Note",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   key: 96,
        //   page: "AddCallSickNote",
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Prescribe",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   key: 97,
        //   page: "AddCallPrescription",
        //   useDynamicComponent: true,
        // },

        // {
        //   label: "Current Acute Medications",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: (
        //     <img alt="Current Acute Medications" src={currentmedication}></img>
        //   ),
        //   key: 4,
        //   page: "AcuteMedicationsContainer",
        //   component_id: "AcuteMedicationsContainer",

        //   useDynamicComponent: true,
        // },
        // {
        //   label: "NHS Repeat Medication",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: <img alt="NHS Repeat Medication" src={nhs}></img>,
        //   key: 5,
        //   page: "NHSContainer",
        //   useDynamicComponent: true,
        // },

        // {
        //   label: "Pre-Screening Questionnaire",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   page: "PreQuestionnaireContainer",
        //   key: 8,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Reports",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   page: "ReportsContainer",
        //   key: 9,
        //   useDynamicComponent: true,
        // },
        {
          label: (
            <span>
              Special Notes
              <span className="sidebar-notification-badge">
                {patientDetail && patientDetail.special_notes_count}
              </span>
            </span>
          ),
          path: appRoutesConst.walkinAppointment,
          icon: <img alt="Special Notes" src={specialnotes}></img>,
          page: "SpecialNotesContainer",
          key: 10,
          useDynamicComponent: true,
        },
        {
          label: "Pre. Encounters",
          path: appRoutesConst.walkinAppointment,
          icon: <img alt="Pre. Encounters" src={previousencounters}></img>,
          page: "PreEncountersContainer",
          key: 11,
          useDynamicComponent: true,
        },
        // {
        //   label: "Prescriptions",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   page: "PrescriptionsContainer",
        //   key: 12,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Sick Notes",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   page: "SickNotesContainer",
        //   key: 13,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Referral Notes",
        //   path: appRoutesConst.walkinAppointment,
        //   icon: null,
        //   page: "ReferralNotesContainer",
        //   key: 14,
        //   useDynamicComponent: true,
        // },
      ];
    }

    let sidBarArray2 = [
      // {
      //   label: "Appointment Reason",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: <img alt="Appointment Reason" src={specialnotes}></img>,
      //   page: "AppointmentReasonContainer",
      //   key: 1,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Screenshots",
      //   path: appRoutesConst.walkinAppointment,
      //   icon: <img alt="Screenshots" src={screenshots}></img>,
      //   page: "CallScreenShotContainer",
      //   key: 2,
      //   useDynamicComponent: true,
      // },
    ];

    return (
      <Layout
        sideBarArray2={sidBarArray2}
        sideBarArray={sideBarArray}
        showInformGP={this.state.showInformGP}
        isShowNHSPathFinder={this.state.isShowNHSPathFinder}
      >
        <Prompt
          message={(location, action) => {
            if (action === "PUSH") {
              console.log("check PUSH");
              let { onRequestSetComponent, onSetSidebarKey } =
                this.props.dynamicTabAction;
              if (
                window.location.pathname !== "/doctor/walkin-appointment" &&
                this.props.prev_appt_tab_key !== "unfinished"
              ) {
                if (typeof onRequestSetComponent === "function") {
                  onRequestSetComponent(null);
                  onSetSidebarKey(null);
                }
              }
              return true;
            }
            if (action === "POP") {
              console.log("check POP");

              let { onRequestSetComponent, onSetSidebarKey } =
                this.props.dynamicTabAction;
              if (
                window.location.pathname !== "/doctor/walkin-appointment" &&
                this.props.prev_appt_tab_key !== "unfinished"
              ) {
                if (typeof onRequestSetComponent === "function") {
                  onRequestSetComponent("UpcomingAppointmentContainer");
                }

                if (typeof onSetSidebarKey === "function") {
                  onSetSidebarKey([`APPT1`]);
                }
              }
              this._clearData();

              return false;
            }
            return true;
          }}
        />
        <Beforeunload onBeforeunload={(event) => event.preventDefault()}>
          <div className="rightmainsection walkin_appointment">
            <div className="container-fluid padding-0">
              <div className="row margin-0">
                <div className="selection_container">
                  <div className="walkin_detail_header padding-1-b padding-left-10">
                    <div className="walkinheaderleft relative">
                      <div className="patient_backarrow push-10-r in-block">
                        <img
                          className="cursor-pointer"
                          onClick={this._handleBack}
                          src={topbarback}
                          alt="Go Back"
                          title="Go Back"
                        ></img>
                      </div>
                      <div className="consultant_profile push-5-r in-block">
                        {verifyObject(patientDetail, "image", "") !== "" ? (
                          <img
                            src={verifyObject(
                              patientDetail,
                              "image",
                              require("../../assets/images/common/doctor_profile.svg")
                                .default
                            )}
                            alt=""
                            className="width-30px"
                          ></img>
                        ) : (
                          <img
                            src={
                              require("../../assets/images/common/doctor_profile.svg")
                                .default
                            }
                            alt=""
                            className="width-30px"
                          ></img>
                        )}
                      </div>
                      <div className="in-block padding-right-15">
                        <div className="push-5-l d-flex flex-wrap align-items-center gapx-1">
                          <div className="font-12 weight-400 text-capitalize d-flex flex-wrap align-items-center gapx-1 text-black">
                            <span>
                              {verifyObject(patientDetail, "first_name", "--")}
                            </span>
                            <span>
                              {verifyObject(patientDetail, "last_name", "--")}
                            </span>
                            {verifyObject(patientDetail, "user.status") ? (
                              <span
                                className={`custom-${
                                  verifyObject(patientDetail, "user.status") ===
                                  "unverified"
                                    ? "danger"
                                    : "bluelabel"
                                } font-10 in-block text-capitalize`}
                              >
                                {this.renderStatus(patientDetail)}
                              </span>
                            ) : null}
                            <div
                              className="in-block"
                              onClick={() => {
                                this.setState({ visible: true });
                              }}
                            >
                              <a className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue">
                                <img
                                  alt="Photo ID"
                                  title="Photo ID"
                                  src={photo_id}
                                ></img>{" "}
                                Photo ID
                              </a>
                            </div>
                          </div>
                          <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                            |
                          </div>

                          {verifyObject(patientDetail, "age", null) !== null ? (
                            <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                              {patientDetail.age} Yr
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="in-block padding-right-15">
                        <div className="font-12 weight-400 text-capitalize d-flex flex-wrap text-dark-gray push-5-r">
                          <span className="text-light-black">Gender:</span>{" "}
                          {verifyObject(patientDetail, "gender", null) !== null
                            ? `${patientDetail.gender}`
                            : ""}
                        </div>
                      </div>
                      <div className="in-block padding-right-15">
                        <div className="font-12 weight-400 text-capitalize d-flex flex-wrap text-dark-gray push-5-r">
                          <span className="text-light-black">DOB:</span>{" "}
                          {patientDetail && patientDetail.date_of_birth
                            ? moment(patientDetail.date_of_birth).format(
                                DATE_FORMAT
                              )
                            : "-"}
                        </div>
                      </div>
                      <div className="in-block flex-grow-1">
                        <div className="font-12 weight-400 text-capitalize d-flex flex-wrap text-dark-gray push-5-r">
                          <span className="text-light-black">Corporate:</span>{" "}
                          {verifyObject(patientDetail, "corporate_name", "-")}
                        </div>
                      </div>
                      <div className="walkin_collapse in-block float-right">
                        <CollapsiableSubHeader
                          patientDetail={patientDetail}
                          openPhotoID={() => {
                            this.setState({ visible: true });
                          }}
                        />
                      </div>
                    </div>

                    <div className="walkinheaderright remove-rota-issue relative d-flex flex-wrap align-items-center justify-content-between gapx-2 gapy-1">
                      <div className="d-flex flex-wrap align-items-center gapx-2 gapy-1">
                        {this.state.showBookingModal && (
                          <BookAppointmentForPatientContainer
                            showBookingModal={this.state.showBookingModal}
                            doctor_id={this.props?.userObj?.id}
                            patient_id={verifyObject(
                              patientDetail,
                              "user.id",
                              "--"
                            )}
                            forWholebooking={true}
                            bookingRecord={this.state.bookingRecord}
                            closeBookingModal={(isCancel) => {
                              this.setState({
                                isOutFromFullscreen: false,
                                resourceID: null,
                                showBookingModal: false,
                                bookingRecord: null,
                              });
                            }}
                            fromConsultation={true}
                            passedPatientName={`${verifyObject(
                              patientDetail,
                              "first_name",
                              "--"
                            )} ${verifyObject(
                              patientDetail,
                              "last_name",
                              "--"
                            )}`}
                          />
                        )}
                        {
                          <div className="in-block push-5-l">
                            {(this.props.userRole !==
                              ROLE_CLINICAL_PHARMACIST ||
                              this.props.userRole ===
                                ROLE_CLINICAL_PHARMACIST ||
                              this.props.userRole ===
                                ROLE_SUPERINTENDENT_PHARMACIST) &&
                              offlineCallStatus === null &&
                              (this.props.is_from_patient_search_listing ===
                                null ||
                                this.props.is_from_patient_search_listing ===
                                  false) && (
                                <Fragment>
                                  <button
                                    className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px p-0 height-30 new_btnwidth"
                                    onClick={() => this.callStartEnd("start")}
                                  >
                                    Start Consultation
                                  </button>
                                </Fragment>
                              )}

                            {(this.props.userRole !==
                              ROLE_CLINICAL_PHARMACIST ||
                              this.props.userRole ===
                                ROLE_CLINICAL_PHARMACIST ||
                              this.props.userRole ===
                                ROLE_SUPERINTENDENT_PHARMACIST) &&
                              (window.location.pathname ===
                                appRoutesConst.walkinAppointment ||
                                this.props.prev_appt_tab_key ===
                                  "unfinished") &&
                              (offlineCallStatus === "start" ||
                                this.props.is_from_patient_search_listing) && (
                                <div className="walkinheaderrt">
                                  <div className="timer_counter">
                                    <div className="timer-notification"></div>
                                    {this.state.connectedSocket ? (
                                      <Timer
                                        key={this.state.timerKey}
                                        initialTime={
                                          parseInt(
                                            this.state.reviewDuration,

                                            10
                                          ) * 1000
                                        }
                                      >
                                        <Timer.Minutes
                                          formatValue={(value) =>
                                            `${
                                              value < 10 ? `0${value}` : value
                                            }`
                                          }
                                        />
                                        :
                                        <Timer.Seconds
                                          formatValue={(value) =>
                                            `${
                                              value < 10 ? `0${value}` : value
                                            }`
                                          }
                                        />
                                      </Timer>
                                    ) : null}
                                  </div>
                                  <button
                                    className="ant-btn ant-btn-dangerous cancel_btn width-100px p-0 height-30  font-14 weight-500 new_btnwidth ml-2"
                                    onClick={() => this.callStartEnd("finish")}
                                    disabled={
                                      true
                                      // this.props.is_from_patient_search_listing
                                    }
                                  >
                                    End Consultation
                                  </button>
                                </div>
                              )}
                            {!this.state.prescriberCallEnded &&
                              (this.props.userRole ===
                                ROLE_CLINICAL_PHARMACIST ||
                                this.props.userRole ===
                                  ROLE_SUPERINTENDENT_PHARMACIST ||
                                this.props.userRole === ROLE_DOCTOR) &&
                              this.props.is_from_patient_search_listing &&
                              window.location.pathname !==
                                "/doctor/walkin-appointment" && (
                                <div className="timer_div push-5-t push-5-b">
                                  <div className="timer_counter">
                                    <div className="timer-notification"></div>
                                    <Timer
                                      initialTime={
                                        parseInt(
                                          this.state.reviewDuration,
                                          10
                                        ) * 1000
                                      }
                                    >
                                      <Timer.Minutes
                                        formatValue={(value) =>
                                          `${value < 10 ? `0${value}` : value}`
                                        }
                                      />
                                      :
                                      <Timer.Seconds
                                        formatValue={(value) =>
                                          `${value < 10 ? `0${value}` : value}`
                                        }
                                      />
                                    </Timer>
                                  </div>
                                  <button
                                    className="ant-btn ant-btn-dangerous cancel_btn width-100px p-0 height-30  font-14 weight-500 new_btnwidth ml-2"
                                    onClick={() =>
                                      this.handlePrescriberCallEnd("finish")
                                    }
                                    // let { dynamicInputs } = this.props;
                                    // let { dynamicInputs } = this.props;
                                    disabled={
                                      !this.props
                                        .isPrescriberSubmittedConsultation ||
                                      this.state.isCallEnding ||
                                      this.props.is_from_patient_search_listing
                                    }
                                  >
                                    {this.state.isCallEnding
                                      ? "Ending Consultation..."
                                      : " End Consultation"}
                                  </button>
                                </div>
                              )}
                            {this.state.prescriberCallEnded && (
                              <button
                                className="ant-btn ant-btn-dangerous cancel_btn width-100px p-0 height-30  font-14 weight-500"
                                // onClick={() =>
                                //   this.handlePrescriberCallEnd("finish")
                                // }
                              >
                                Call Ended
                              </button>
                            )}
                          </div>
                        }
                      </div>
                      <div className="d-flex flex-wrap align-items-center gapx-2 gapy-1">
                        <Button
                          type="primary"
                          className="font-14 weight-500 text-dark-blue text-center"
                          onClick={() => {
                            this.setState({
                              showBookingModal: true,
                            });
                          }}
                        >
                          Book Appointment
                        </Button>
                      </div>
                    </div>
                  </div>
                  {/* / common patient header / */}
                  {this.props.is_consultation_api_started && (
                    <CommonLoader text="Starting Consultation ..." />
                  )}

                  {!this.props.is_consultation_api_started &&
                    this._renderDynamicComponent()}
                </div>
                <ImageSliderModal
                  verifyPatientFunction={this.verifyPatientFunction}
                  visible={visible}
                  patientDetail={patientDetail}
                  handleCancel={() => this.setState({ visible: false })}
                  checkIdentity={this._SaveComponentStateToRedux}
                  isDocumentViewed={isDocumentViewed}
                  verifyingPatient={this.state.verifyingPatient}
                />
                {}
              </div>
            </div>
          </div>
          {/* <PresriberEndCallModal
            showModal={this.props.isPrescriberSubmittedConsultation}
            handlePrescriberCallEnd={this.handlePrescriberCallEnd}
            isCallEnding={this.state.isCallEnding}
          /> */}
        </Beforeunload>
      </Layout>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "PatientDetailContainer"
    ),
    patientId: verifyObject(state.localStore, "patientId", null),
    user: verifyObject(state.localStore, "user", null),

    isVideoCallStarted: verifyObject(
      state.localStore,
      "is_video_call_start",
      null
    ),
    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    isPrescriberSubmittedConsultation: verifyObject(
      state,
      "dynamicTab.isPrescriberSubmittedConsultation",
      false
    ),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    isLoadingDetail: verifyObject(state.patientDetails, "isLoading", false),
    componentId: verifyObject(state.localStore, "component_id", null),
    offlineCallStatus: verifyObject(
      state.localStore,
      "offline_call_status",
      null
    ),
    isDocumentViewed: verifyObject(
      state.localStore,
      "is_documents_viewed",
      null
    ),
    isWaitingPatient: verifyObject(
      state.actionCableData,
      "WaitingRoomSocketData.isLoading",
      false
    ),
    waitingSocketData: verifyObject(
      state.actionCableData,
      "WaitingRoomSocketData.data.data",
      null
    ),
    showCallDetailModal: verifyObject(
      state.localStore,
      "showCallDetailModal",
      false
    ),
    is_actual_call_started: verifyObject(
      state.localStore,
      "is_actual_call_started",
      false
    ),
    isPrescriptionAdded: verifyObject(
      state.localStore,
      "is_prescription_added.is_presription_added",
      null
    ),
    isOwnPharamcy: verifyObject(
      state.localStore,
      "is_prescription_added.is_own_pharamcy",
      false
    ),
    prescriptionCode: verifyObject(
      state.localStore,
      "is_prescription_added.prescription_code",
      null
    ),
    patientDOB: verifyObject(
      state.localStore,
      "patient_detail_obj.date_of_birth",
      null
    ),

    is_consultation_api_started: verifyObject(
      state,
      "dynamicTab.is_consultation_api_started",
      null
    ),
    initialSocketData: verifyObject(
      state.localStore,
      "initialSocketData",
      null
    ),
    userRole: verifyObject(state.localStore, "user.role", null),
    // isWaitingPatient: verifyObject(
    //   state.actionCableData,
    //   "WaitingRoomSocketData.isLoading",
    //   false
    // ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    prev_appt_tab_key: verifyObject(state, "localStore.prev_appt_tab_key", ""),

    // offlineCallStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onSetDynamicInput,
        onSetSidebarKey,
        onSetDynamicHealthInput,
      },
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    offlineCallActions: bindActionCreators(
      { setOfflineCallStatus, setDocumentViewedFlag },
      dispatch
    ),
    videoCallActions: bindActionCreators(
      { onRequestSetVideoCallStatus },
      dispatch
    ),
    navigateTo: bindActionCreators({ navigateTo }, dispatch),
    actionCableActions: bindActionCreators(actionCableActions, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(PatientDetailPage);

export default withRouter(ConnectedComponent);
