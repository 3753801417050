import React, { Fragment, useEffect, useState } from "react";
import ModalPopUp from "../../components/common/ModalPopUp";
import { Input, List, Radio, Skeleton, Spin } from "antd";

import { errorToast, getErrorObject, verifyObject } from "utilities/utils";
import { useSelector } from "react-redux";
import SingleCheckBox from "components/common/controls/SingleCheckBox";
import { isArray, isEmpty } from "lodash";
import TemplateDropDown from "components/PreConsult/TemplatesDropDown";
import { allAppointmentUsersOldApi } from "services/allAppointmentUsers";

export default function InformationalOutcomeModal({
  visible,
  treatment,
  followUp,
  referral,
  comment,
  _handleSubmit,
  _hideModal,
  changing,
  _handleTextChange,
  errors,
  editOutcome,
  _handleUpdateoutcome,
  isSubmitForReview,
  fromPreConsult,
  comment_other,
  enableOtherField,
  prev_appt_tab_key,
  reviewer,
  _handleReviewerChange,
  onIpUserSelect,
  review_by_id,
  current_corporate,
  fromConsultation,
  _handleTemplateChange,
  template_id,
  submitting,
}) {
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [ipUsers, setIpUsers] = useState([]);
  const [search, setSearch] = useState("");

  // const { localStore} = useSelector((state)=>state)
  // console.log("🚀 ~ localStore:", localStore)

  useEffect(() => {
    if (reviewer === "ip_users") {
      onGetAllIPUsers();
    }
  }, [reviewer]);

  const onGetAllIPUsers = async () => {
    try {
      await setLoading(true);
      let response = await allAppointmentUsersOldApi({
        // search: search,
        only_ip_user: true,
        // page,
      });
      if (response.data.data) {
        let pagination =
          verifyObject(response, "headers['x-pagination']", null) !== null
            ? JSON.parse(response.headers["x-pagination"])
            : null;

        let doctors = response?.data?.data;
        // let found = doctors.find((d) => d.id === doctor_id);
        // let newArra = doctors.filter((d) => d.id !== doctor_id);
        // console.log("New Array", newArra);
        setIpUsers(doctors);
        setPagination(pagination);
      }
      await setLoading(false);
      await setSearching(false);
      await setLoading(false);
    } catch (error) {
      const { message } = getErrorObject(error);
      await setLoading(false);
      errorToast({ content: message });
    }
  };

  const fetchMoreData = () => {
    console.log("calling fethc more data ");
    // setIsLoadMore(true);
    setPage(page + 1);
    setSearching(true);
  };
  const handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    // let { page, pagination } = initialValues;
    let total_pages = verifyObject(pagination, "total_pages", 0);
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      fetchMoreData();
    }
  };
  return (
    <ModalPopUp
      title={"Informational Outcomes"}
      handleCancel={_hideModal}
      visible={visible}
      footer={true}
      handleOk={
        editOutcome
          ? () => _handleUpdateoutcome(isSubmitForReview)
          : () => {
            _handleSubmit(isSubmitForReview);
          }
      }
      okText={"Save"}
      loading={changing || submitting}
      cancelBtnclassName="custom_cancel_btn"
      maskClosable={false}
    >
      <Spin spinning={changing || submitting} tip="Submitting...">
        <form>
          {isSubmitForReview && (
            <Fragment>
              {!fromPreConsult && (
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12 d-flex align-items-center">
                      <label htmlFor="sel1" className="font-12 mb-0 mr-2">
                        Reviewers:
                      </label>
                      <Radio.Group
                        onChange={_handleReviewerChange}
                        value={reviewer}
                        name="reviewer"
                        className="d-flex align-items-center"
                      >
                        <Radio value="healthya_corp">Healthya</Radio>
                        {current_corporate && current_corporate !== 66 && (
                          <Radio value="ip_users">Internal IPs</Radio>
                        )}
                      </Radio.Group>
                    </div>
                  </div>
                </div>
              )}
              {!fromPreConsult && (
                <div>
                  {reviewer === "ip_users" && (
                    <div className="form-group">
                      <div className="form-row">
                        <div className="col-md-12">
                          <label htmlFor="sel1" className="font-12">
                            Select IP User
                          </label>
                          {isArray(ipUsers) &&
                            !isEmpty(ipUsers) &&
                            ipUsers.length > 0 && (
                              <List
                                className="demo-loadmore-list"
                                // loading={loading}
                                itemLayout="horizontal"
                                // loadMore={this.fetchMoreData3}
                                style={{
                                  maxHeight: 200,
                                  overflow: "scroll",
                                }}
                                dataSource={ipUsers}
                                locale={{
                                  emptyText: "",
                                }}
                                renderItem={(d) => {
                                  return (
                                    <Skeleton
                                      avatar
                                      title={false}
                                      loading={loading}
                                      active
                                    >
                                      <List.Item
                                        // c={() => {
                                        //   this.setState({
                                        //     selectedPharamcy: d,
                                        //   });
                                        // }}
                                        onClick={async () =>
                                          onIpUserSelect(d.id)
                                        }
                                      >
                                        <SingleCheckBox
                                          label=""
                                          onChange={async () =>
                                            onIpUserSelect(d.id)
                                          }
                                          checked={review_by_id === d.id}
                                          className="font-8"
                                        />
                                        {/* <Checkbox/>÷ */}
                                        <List.Item.Meta
                                          avatar={null}
                                          title={
                                            <span className="text-capitalize">
                                              {d.name}
                                            </span>
                                          }
                                          // description={
                                          //   address
                                          //   //   `${d.line1 ? `${d.line1},` : ""}${
                                          //   //   d.line2 ? `${d.line2},` : ""
                                          //   // } ${d.line3 ? `${d.line3},` : ""}`
                                          // }
                                          style={{ cursor: "pointer" }}
                                        />
                                      </List.Item>
                                    </Skeleton>
                                  );
                                }}
                              ></List>
                            )}
                          {/* <Select
                          showSearch={true}
                          loading={loading}
                          placeholder="Select IP User"
                          optionFilterProp="children"
                          className={`form-control ${
                            errors && errors.review_by_id && "danger-border"
                          } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                          onChange={async (val) => onIpUserSelect(val)}
                          allowClear={true}
                          filterOption={true}
                          value={review_by_id}
                        >
                          {ipUsers.map((d) => {
                            return (
                              <Select.Option key={d.id} value={d?.id}>
                                {d.name}({d.role?.replaceAll("_", " ")})
                              </Select.Option>
                            );
                          })}
                        </Select> */}
                          {/* <select
                      onChange={_handleReviewerChange}
                      autoFocus
                      name="c"
                      value={treatment}
                      onScroll={handleScroll}
                      className={`form-control ${
                        errors && errors.treatment && "danger-border"
                      } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                    >
                      {ipUsers.map((d) => {
                        return <option value="ip_users">{d.name}({d.role})</option>;
                      })}
                    </select> */}
                          {errors && (
                            <span className="validate-danger">
                              {errors.review_by_id}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Fragment>
          )}

          {!fromPreConsult && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1" className="font-12">
                    Treatment
                  </label>
                  <select
                    onChange={_handleTextChange}
                    autoFocus
                    name="treatment"
                    value={treatment}
                    className={`form-control ${errors && errors.treatment && "danger-border"
                      } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                  >
                    <option value="" disabled="disabled" selected="selected">
                      Select Option
                    </option>
                    <option value="Private prescription">
                      Private prescription
                    </option>
                    <option value="NHS prescription">
                      NHS prescription
                    </option>
                    <option value="Lifestyle advice">Lifestyle advice</option>
                    <option value="Over the counter medication recommendation">
                      Over the counter medication recommendation
                    </option>
                    <option value="Home treatments/remedies">
                      Home treatments/remedies
                    </option>
                    <option value="Patient Specific Directive (PSD)">
                      Patient Specific Directive (PSD)
                    </option>
                    <option value="No treatment required">
                      No treatment required
                    </option>
                  </select>
                  {errors && (
                    <span className="validate-danger">{errors.treatment}</span>
                  )}
                </div>
              </div>
            </div>
          )}

          {!fromPreConsult && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1" className="font-12">
                    Follow Up
                  </label>
                  <select
                    onChange={_handleTextChange}
                    name="followUp"
                    value={followUp}
                    className={`form-control ${errors && errors.followUp && "danger-border"
                      } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                  >
                    <option value="" disabled="disabled" selected="selected">
                      Select Option
                    </option>
                    <option value="No follow up required">
                      No follow up required
                    </option>
                    <option value="Patient advised to follow up with own GP">
                      Patient advised to follow up with own GP
                    </option>
                    <option value="Patient advised to follow up with alldayDr">
                      Patient advised to follow up with alldayDr
                    </option>
                  </select>
                  {errors && (
                    <span className="validate-danger">{errors.followUp}</span>
                  )}
                </div>
              </div>
            </div>
          )}

          {!fromPreConsult && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1" className="font-12">
                    Referral
                  </label>
                  <select
                    onChange={_handleTextChange}
                    name="referral"
                    value={referral}
                    className={`form-control ${errors && errors.referral && "danger-border"
                      } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                  >
                    <option value="" disabled="disabled" selected="selected">
                      Select Option
                    </option>
                    <option value="No referral required">
                      No referral required
                    </option>
                    <option value="Safeguarding referral">
                      Safeguarding referral
                    </option>
                    <option value="Private referral">Private referral</option>
                    <option value="Referral recommendation to own GP">
                      Referral recommendation to own GP
                    </option>
                  </select>
                  {errors && (
                    <span className="validate-danger">{errors.referral}</span>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="sel1" className="font-12">
                  Comment
                </label>
                {fromPreConsult && (
                  <select
                    onChange={(e) =>
                      _handleTextChange(e, e.target.value === "Other")
                    }
                    name="comment"
                    value={comment}
                    className={`form-control ${errors && errors.referral && "danger-border"
                      } height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                  >
                    <option value="" disabled="disabled" selected="selected">
                      Select Option
                    </option>
                    {prev_appt_tab_key === "kiosk_reviewed" && (
                      <>
                        <option value="Normotensive - no further follow up required">
                          Normotensive - no further follow up required
                        </option>
                        <option value="Adult >40 with no diagnosis of HTN">
                          {`Adult > 40 with no diagnosis of HTN`}
                        </option>
                        <option value="Adult <40 with FH of HTN">
                          {`Adult < 40 with FH of HTN`}
                        </option>
                        <option value="35-40 Screened High Risk">
                          35-40 Screened High Risk
                        </option>
                        <option value="Diagnosed Hypertensive (Uncontrolled ) - referred to own GP">
                          Diagnosed Hypertensive (Uncontrolled ) - referred to
                          own GP
                        </option>
                      </>
                    )}
                    {prev_appt_tab_key !== "kiosk_reviewed" && (
                      <>
                        <option value="Advice given only (no medicine supply)">
                          Advice given only (no medicine supply)
                        </option>
                        <option value="Sale of an Over the Counter (OTC) medicine">
                          Sale of an Over the Counter (OTC) medicine
                        </option>
                        <option value="Referral into a pharmacy local minor ailments service (MAS)">
                          Referral into a pharmacy local minor ailments service
                          (MAS)
                        </option>
                        <option value="Referral into an appropriate locally commissioned NHS service, such as a patient group direction">
                          Referral into an appropriate locally commissioned NHS
                          service, such as a patient group direction
                        </option>
                        <option value="Onward referral to another pharmacy">
                          Onward referral to another pharmacy
                        </option>
                        <option value="Non-urgent signposting to another service">
                          Non-urgent signposting to another service
                        </option>
                        <option value="Urgent escalation to another service">
                          Urgent escalation to another service
                        </option>
                        <option value="Clinical pathways Consultation">
                          Clinical pathways Consultation
                        </option>
                        <option value="Medicines supply">
                          Medicines supply
                        </option>
                        <option value={true}>Other</option>
                      </>
                    )}
                  </select>
                )}
                {(enableOtherField || !fromPreConsult) && (
                  <Input.TextArea
                    autoSize={true}
                    rows="2"
                    onChange={_handleTextChange}
                    form="consultation"
                    value={!fromPreConsult ? comment : comment_other}
                    className={`form-control mt-2 ${errors && errors.comment_other && "danger-border"
                      } height-auto custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                    name={!fromPreConsult ? "comment" : "comment_other"}
                    placeholder="Comment"
                  />
                )}
                {/* {errors && (
                <span className="validate-danger">{errors.comment}</span>
              )} */}
              </div>
            </div>
          </div>
        </form>
      </Spin>
    </ModalPopUp>
  );
}
