/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Divider, Layout, Menu, Tag } from "antd";
import { isArray, isEmpty } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import {  }  from "react"
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetDynamicInput,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { actions as emergencyAlertActions } from "reducers/emergencyAlert";
import { actions as safeguardAlertActions } from "reducers/safeguardAlert";
// import {actions as afterCareEmailsActions} from "reducers/afterCareEmails";
import { appRoutesConst, navigateTo } from "../../app/navigation";
import { verifyObject } from "../../utilities/utils";
// import {actions as afterCareEmailsActions} from "reducers/afterCareEmails";
import ReferralAddModal from "../../containers/PatientConsultationConatainers/ReferralContainer/ReferralAddModal";
import SickNoteAddModal from "../../containers/PatientConsultationConatainers/SickNoteContainer/SickNoteAddModal";
import PrescriptionAddModal from "../../containers/PatientConsultationConatainers/PrescriptionContainer/PrescriptionAddModal";
import InformGPModal from "../../containers/WalkinAppointment/InformGPModal";
import EmergencyAlertContainer from "../../containers/alerts/EmergencyAlertContainer";
import SafeguardAlertContainer from "../../containers/alerts/SafeguardAlertContainer";
// import {Scrollbars} from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import { actions as shortCodesActions } from "reducers/shortCodes";
import { actions as prescriptionAbbreviationActions } from "reducers/prescriptionAbbreviation";
import store from "../../app/store";
import MordenCalendar from "./controls/MordenCalendar";
import moment from "moment";
import { Button, Drawer, Space } from "antd";
import {
  upcomingAppointmentsActions,
  previousAppointmentsActions,
  approveRejectAppointmentsActions,
} from "../../reducers/appointments";
import {
  DATE_FORMAT,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_DOCTOR,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
import SortCodesContainer from "../../containers/PatientConsultationConatainers/SortCodesContainer/SortCodesContainer";
// import AfterCareEmailModal from "containers/WalkinAppointment/AfterCareEmails/AfterCareEmailModal";
import ModalPopUp from "./ModalPopUp";
import SysmtopmFetchedLinks from "./SysmtopmFetchedLinks";
import CommonLoader from "./CommonLoader";
import { actions as patientDetailActions } from "../../reducers/patientDetail";
import { onSetPatient, onSetPatientAppointment } from "reducers/patients";
import { setOfflineCallStatus } from "../../reducers/onlineOfflineCall";
import ConditionLinks from "components/PreConsult/ConditionLinks";
import PreConsultReportModal from "components/PreConsult/PreConsultReportModal";
import FullCalendarDrawer from "./FullCalendarDrawer";
import { CalendarOutlined } from "@ant-design/icons";
import { usePrevious } from "react-use";
import { cancelPreviousPageRequests } from "services";
import NHSPathFinderContainer from "containers/NHSPathFinderContainer/NHSPathFinderContainer";

const allowedArray = [
  "/doctor/walkin-appointment",
  "/doctor/questionnaire_review/detail",
  "/doctor/reports_review/detail",
  "/doctor/video-appointment",
  "/doctor/script_review/detail",
  "/patient/detail",
  "/patient/consultation_detail",
  // "/doctor/dynamicQuestionnaire_review",
  "/doctor/dynamicQuestionnaire_review/detail",
];
const allowedArrayForPathFiner = [
  "/doctor/walkin-appointment",
  "/doctor/video-appointment",
  "/patient/consultation_detail",
];

const { Sider } = Layout;
const { SubMenu } = Menu;
// import CalendarComponet from "./Calendar";

const Sidebar = ({
  sideBarArray,
  sideBarArray2,
  navigationAction,
  selectedSidebarKey,
  appointmentId,
  appointmentObj,
  gpService,
  patientId,
  dynamicInputs,
  dynamicTabAction,
  emergencyAlertActions,
  safeguardAlertActions,
  afterCareEmailsActions,
  isCollapsed,
  previousAppointmentsActions,
  upcomingAppointmentsActions,
  approveRejectAppointmentsActions,
  isConsentGiven,
  showInformGP,
  isInformRequestSubmitted,
  userRole,
  show_prescriotion_modal_from_notes,
  offline_call_status,
  componentName,
  savedSelectedKey,
  is_from_patient_search_listing,
  is_clicked_start_call,
  is_clicked_view,
  prev_appt_tab_key,
  shortCodesActions,
  patientDetail,
  is_gp_informed,
  symptompFetchedLinks,
  symtopmpLinkFecthing,
  patientDetailActions,
  localStore,
  prescriptionAbbreviationActions,
  prescriptionAbbreviation,
  from_past_consultation_trigger,
  onSetPatient,
  onSetPatientAppointment,
  offlineCallActions,
  fetching_patient,
  consultation_obj,
  conditionFetchedLinks,
  designation,
  preConsultationSubmitLogsIsLoading,
  preConsultationReviewIsLoading,
  history,
  corporate_id,
  patient_detail_obj,
  userId,
  user,
  is_autosave_consultation_data,
  isShowNHSPathFinder
}) => {
  const rootSubmenuKeys = Object.values(sideBarArray).map((o) =>
    o && o.key ? o.key.toString() : null
  );
  // console.log("rootSubMenu", rootSubmenuKeys);
  let [openKeys, setOpenKeys] = useState([rootSubmenuKeys[0]]);

  let [collapsed, onCollapse] = useState(false);
  let [selectedMenu1, handleFirstMenu] = useState(selectedSidebarKey);
  let [selectedMenu2, handleSecondMenu] = useState(selectedSidebarKey);
  let [selectedDay, setSelectedDay] = useState(null);
  let [isClosable, setIsClosable] = useState(true);
  let [showShortCodeModal, setShowShortCodeModal] = useState(false);
  let [nhsPathFinderModal, setNHSPathFinderModal] = useState(false);

  let [isClearedFilters, setisClearedFilters] = useState("");
  let [tabKey, setTabKey] = useState(null);
  // const urlParams = new URLSearchParams(window.location.search);
  // const preConsultReview = urlParams.get("preConsultReview");
  const [openPreConsultReportModal, setOpenPreConsultReportModal] =
    useState(false);
  let [date, setDate] = useState([]);
  let [state, setState] = useState({ selectedRecord: null });
  let [isOpenDrawer, setIsOpenDrawer] = useState(false);

  // let [showReferral, setRefferal] = useState(false);
  const reffralModal = useRef();
  const sickNoteModal = useRef();
  const prescriptionModal = useRef();
  const informGPModalRef = useRef();
  const prevKeys = usePrevious(selectedSidebarKey);

  console.log("selectedSidebarKey", selectedSidebarKey);

  useEffect(() => {
    handleSecondMenu(selectedSidebarKey);
    // Router?.replace()
  }, [selectedSidebarKey]);
  // useEffect(() => {
  //   if (offline_call_status === "start") {
  //     let {onRequestSetComponent, onSetSidebarKey} = dynamicTabAction;
  //     if (typeof onRequestSetComponent === "function") {
  //       onRequestSetComponent("WalkinAppointmentContainer");
  //       onSetSidebarKey(["90"]);
  //     }
  //     setOpenKeys("201");
  //   }
  // }, [offline_call_status]);
  useEffect(() => {
    if (window.location.pathname === "/doctor/dashboard") {
      store.dispatch({
        type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
        payload: false,
      });
    }
  }, []);
  useEffect(() => {
    console.log("window.location.pathname", window.location.pathname);
    if (
      window.location.pathname !== "/doctor/walkin-appointment" &&
      window.location.pathname !== "/doctor/video-appointment" &&
      prev_appt_tab_key !== "pending" &&
      prev_appt_tab_key !== "unfinished"
    ) {
      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: false,
      });
    }
  }, [window.location.pathname, prev_appt_tab_key]);

  useEffect(() => {
    if (
      is_clicked_start_call &&
      window.location.pathname !== "/doctor/dashboard" &&
      window.location.pathname !== "/doctor/pre_consultation_review"
    ) {
      // let {onRequestSetComponent, onSetSidebarKey} = dynamicTabAction;
      if (prev_appt_tab_key === "pending") {
        setOpenKeys(["102"]);
      } else {
        // if (typeof onRequestSetComponent === "function") {
        //   onRequestSetComponent("WalkinAppointmentContainer");
        //   onSetSidebarKey(["90"]);
        // }
        // setOpenKeys(["201"]);
      }

      // store.dispatch({
      //   type: "IS_CLICKED_START_CALL",
      //   payload: false,
      // });
    }
  }, [is_clicked_start_call, prev_appt_tab_key]);

  const handleTabChange = (key) => {
    console.log("key", key);
    setisClearedFilters(Math.random());
    setTabKey(key);
  };
  useEffect(() => {
    if (tabKey) {
      _onRequestShortCodeList();
    }
  }, [tabKey]);
  useEffect(() => {
    if (is_clicked_view && window.location.pathname === "/patient/detail") {
      let { onRequestSetComponent, onSetSidebarKey } = dynamicTabAction;
      if (typeof onRequestSetComponent === "function") {
        onRequestSetComponent("PatientDetailContainer");
        onSetSidebarKey(["1"]);
      }
      // setOpenKeys(["90"]);
      store.dispatch({
        type: "IS_CLICKED_VIEW",
        payload: false,
      });
    } else if (
      is_clicked_view &&
      window.location.pathname === "/patient/consultation_detail" &&
      prev_appt_tab_key !== "unfinished"
    ) {
      let { onRequestSetComponent, onSetSidebarKey } = dynamicTabAction;
      if (typeof onRequestSetComponent === "function") {
        onRequestSetComponent("PatientConsultationContainer");
        onSetSidebarKey(["1"]);
      }
      setOpenKeys(["102"]);
      store.dispatch({
        type: "IS_CLICKED_VIEW",
        payload: false,
      });
    } else if (prev_appt_tab_key === "unfinished" && is_clicked_view) {
      let { onRequestSetComponent, onSetSidebarKey } = dynamicTabAction;

      if (verifyObject(appointmentObj, "appointment_type", null) !== "video") {
        if (typeof onRequestSetComponent === "function") {
          onRequestSetComponent("WalkinAppointmentContainer");
          onSetSidebarKey(["90"]);
        }
        setOpenKeys(["201"]);
      } else {
        onSetSidebarKey(["1"]);
        setOpenKeys(["102"]);
      }
      store.dispatch({
        type: "IS_CLICKED_VIEW",
        payload: false,
      });
    }
  }, [is_clicked_view]);
  useEffect(() => {
    if (showInformGP === true) {
      openModal("informGP");
      let { onRequestSetComponent, onSetSidebarKey } = dynamicTabAction;
      if (prev_appt_tab_key === "pending") {
        setOpenKeys(["102"]);
      } else {
        if (typeof onRequestSetComponent === "function") {
          onRequestSetComponent("WalkinAppointmentContainer");
          onSetSidebarKey(["90"]);
        }
        setOpenKeys(["201"]);
      }

      setIsClosable(false);
    } else {
      // if (componentName === "WalkinAppointmentContainer") setOpenKeys(["201"]);

      setIsClosable(true);
    }
  }, [showInformGP, dynamicTabAction, prev_appt_tab_key]);

  useEffect(() => {
    if (isConsentGiven) {
      let { onRequestSetComponent, onSetSidebarKey } = dynamicTabAction;
      if (
        typeof onRequestSetComponent === "function" &&
        prev_appt_tab_key !== "pending"
      ) {
        onRequestSetComponent("WalkinAppointmentContainer");
        onSetSidebarKey(["90"]);
      }
    }
  }, [isConsentGiven, dynamicTabAction, prev_appt_tab_key]);

  const openModal = (modalName, isCls) => {
    if (isCls === true) {
      setIsClosable(true);
    }
    if (
      modalName === "informGP" &&
      informGPModalRef &&
      !is_gp_informed &&
      informGPModalRef.current
    ) {
      informGPModalRef.current._handleShowModal();
    }
  };
  const onOpenEmergencyModal = () => {
    emergencyAlertActions.onOpenModal();
  };
  const onOpenAfterEmailCareModal = () => {
    afterCareEmailsActions.onOpenModal();
  };
  const onOpenSafeguardModal = () => {
    safeguardAlertActions.onOpenModal();
  };
  useEffect(() => {
    if (window.location.pathname === "/doctor/dashboard") {
      store.dispatch({ type: "PREV_APPT_TAB_KEY", payload: "finished" });

      console.log(
        "(selectedSidebarKey && selectedSidebarKey[0]",
        selectedSidebarKey && selectedSidebarKey[0]
      );
      if (selectedSidebarKey && selectedSidebarKey[0] === "APPT2") {
        onGetAppointments(null, "past");
      } else if (
        !selectedSidebarKey ||
        (selectedSidebarKey && selectedSidebarKey[0] === "APPT1") ||
        (selectedSidebarKey && selectedSidebarKey[0] !== "APPT1")
      ) {
        onGetAppointments(null, "future");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClear = (value) => {
    if (value === null) {
      setSelectedDay(value);
      handleFirstMenu(["APPT1"]);
      _handleNavigation({
        icon: null,
        key: "APPT1",
        label: "Upcoming",
        page: "UpcomingAppointmentContainer",
        path: "/doctor/dashboard",
        useDynamicComponent: true,
        sKey: "APPT1",
        selectedDate: null,
      });
      // onGetAppointments(null, "future");
    }
  };
  const _onRequestShortCodeList = () => {
    // let { shortCodesActions } = this.props;
    let { onRequest } = shortCodesActions;
    if (typeof onRequest === "function") {
      if (tabKey !== "prescription_abbreviation") {
        let codeKeyParams = {};
        if (!isEmpty(tabKey) && tabKey !== "all") {
          codeKeyParams = {
            code_key: tabKey,
          };
        }
        onRequest({ ...codeKeyParams });
      } else {
        let { onRequest } = prescriptionAbbreviationActions;
        if (typeof onRequest === "function") {
          onRequest({});
        }
      }
    }
  };
  useEffect(() => {
    console.log("prev_appt_tab_key", prev_appt_tab_key);
    if (prev_appt_tab_key === "unfinished") {
    }
  }, [prev_appt_tab_key]);
  const onGetAppointments = (date, type) => {
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);

    // console.log('selectedSidebarKey', selectedSidebarKey[0])
    if (type) {
      if (type === "past") {
        console.log("type 2", type);
        store.dispatch({
          type: "SET_SIDEBAR_KEY",
          payload: ["APPT2"],
        });
        localStorage.removeItem("previousFilters");
        let { onFilterChange, resetFilterState } = previousAppointmentsActions;
        if (typeof onFilterChange === "function") {
          let reqObj = {};
          if (date) {
            reqObj = {
              filters: {
                scheduled_date: date,
                zone: time_zone,
                // appointments_type: "past",
                appointment_type: prev_appt_tab_key,
              },
            };
          } else {
            reqObj = {
              filters: {
                // scheduled_date: date,
                // zone: time_zone,
                // appointments_type: "past",
                appointment_type: prev_appt_tab_key,
              },
            };
            resetFilterState();
          }
          onFilterChange(reqObj);
          store.dispatch({
            type: "IS_APPT_FILTER_APPLIED",
            payload: false,
          });
        }
      } else if (type === "future") {
        localStorage.removeItem("upcomingFilters");
        store.dispatch({
          type: "SET_SIDEBAR_KEY",
          payload: ["APPT1"],
        });
        let { onFilterChange, resetFilterState } = upcomingAppointmentsActions;
        if (typeof onFilterChange === "function") {
          let reqObj = {};
          if (date) {
            reqObj = {
              filters: {
                scheduled_date: date,
                zone: time_zone,
                appointments_type: "upcoming",
              },
            };
          } else {
            reqObj = {
              filters: {
                // scheduled_date: date,
                // zone: time_zone,
                appointments_type: "upcoming",
              },
            };
            resetFilterState();
          }

          onFilterChange(reqObj);
        }
      } else {
        let { onFilterChange } = approveRejectAppointmentsActions;
        // console.log(
        //   "Slel",
        //   selectedSidebarKey,
        //   approveRejectAppointmentsActions
        // );
        if (typeof onFilterChange === "function") {
          let reqObj = {};
          if (date) {
            reqObj = {
              filters: {
                scheduled_date: date,
                zone: time_zone,
                // appointments_type: "upcoming",
              },
            };
          } else {
            reqObj = {
              filters: {
                scheduled_date: date,
                zone: time_zone,
                // appointments_type: "upcoming",
              },
            };
          }

          onFilterChange(reqObj);
        }
      }
    }

    // }
  };

  const _handleDate = (dates) => {
    let day =
      dates.day.toString().length >= 2 ? dates.day.toString() : `0${dates.day}`;
    let month =
      dates.month.toString().length >= 2
        ? dates.month.toString()
        : `0${dates.month}`;

    let fullDate = `${dates.year}-${month}-${day}`.toString();
    console.log(
      "fullDate",
      moment(fullDate).format(DATE_FORMAT).toString(),
      moment().format(DATE_FORMAT).toString()
    );
    if (
      moment(fullDate).format(DATE_FORMAT).toString() ===
      moment().format(DATE_FORMAT).toString()
    ) {
      let selectedDate =
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate).format("YYYY-MM-DD")
          : null;
      _handleNavigation({
        icon: null,
        key: "APPT1",
        label: "Upcoming",
        page: "UpcomingAppointmentContainer",
        path: "/doctor/dashboard",
        useDynamicComponent: true,
        sKey: "APPT1",
        selectedDate: selectedDate,
      });
      handleFirstMenu(["APPT1"]);
      setDate(
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate)._d.toString()
          : null
      );
      setSelectedDay(dates);
    } else if (moment(fullDate) > moment()) {
      let selectedDate =
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate).format("YYYY-MM-DD")
          : null;
      _handleNavigation({
        icon: null,
        key: "APPT1",
        label: "Upcoming",
        page: "UpcomingAppointmentContainer",
        path: "/doctor/dashboard",
        useDynamicComponent: true,
        sKey: "APPT1",
        selectedDate: selectedDate,
      });
      handleFirstMenu(["APPT1"]);

      setDate(
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate)._d.toString()
          : null
      );
      setSelectedDay(dates);
      // onGetAppointments(selectedDate, "future");
    } else {
      let selectedDate =
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate).format("YYYY-MM-DD")
          : null;
      _handleNavigation({
        icon: null,
        key: "APPT2",
        label: "Previous",
        page: "PreviousAppointmentContainer",
        path: "/doctor/dashboard",
        useDynamicComponent: true,
        selectedDate: selectedDate,
      });
      handleFirstMenu(["APPT2"]);
      setDate(
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate)._d.toString()
          : null
      );
      setSelectedDay(dates);
    }
  };

  const _handleNavigation = (menu) => {
    console.log("🚀 ~ menu:", menu);
    // onCollapse(false);

    let { path } = menu;
    if (menu.useDynamicComponent) {
      // setSelectedDay(null);
      let { page } = menu;
      let { onRequestSetComponent, onRequestSetComponentID } = dynamicTabAction;
      if (typeof onRequestSetComponent === "function") {
        if (page === "AddCallReferral") {
          if (reffralModal && reffralModal.current) {
            reffralModal.current._handleShowModal();
          }
        } else if (page === "AddCallSickNote") {
          if (
            sickNoteModal &&
            sickNoteModal.current &&
            userRole !== ROLE_SUPERINTENDENT_PHARMACIST
          ) {
            sickNoteModal.current._handleShowModal();
          }
        } else if (page === "AddCallPrescription") {
          if (
            prescriptionModal &&
            prescriptionModal.current &&
            userRole !== ROLE_SUPERINTENDENT_PHARMACIST
          ) {
            prescriptionModal.current._handleShowModal();
          }
        } else if (page === "AppointmentsFullCalendarContainer") {
          setSelectedDay(null);
          onRequestSetComponent(page);
        } else {
          onRequestSetComponent(page);

          if (menu.key === "APPT1") {
            onGetAppointments(menu?.selectedDate, "future");
          } else if (menu.key === "APPT2") {
            onGetAppointments(menu?.selectedDate, "past");
          }
        }
      }

      if (menu.component_id) {
        onRequestSetComponentID(menu.component_id);
      } else {
        onRequestSetComponentID(null);
      }

      if (path === "/doctor/pre_consultation_review") {
        history.replace(path);
      }
    } else {
      let { navigateTo } = navigationAction;
      if (typeof navigateTo === "function") {
        navigateTo(path);
      }
    }
  };
  // useEffect(() => {
  //   let { PendingQuestionnaireChannel } = actionCableActions;
  //   if (typeof PendingQuestionnaireChannel === "function") {
  //     PendingQuestionnaireChannel();
  //   }
  // }, []);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    let submenu =
      sideBarArray.filter(
        (obj) => obj && obj.key && obj.key.toString() === latestOpenKey
      ).length > 0 &&
        sideBarArray.filter(
          (obj) => obj && obj.key && obj.key.toString() === latestOpenKey
        )[0].submenu
        ? sideBarArray.filter(
          (obj) => obj && obj.key && obj.key.toString() === latestOpenKey
        )[0].submenu
        : [];
    let firstSubmenuKey =
      submenu && submenu[0] && submenu.length > 0 && submenu[0].key
        ? submenu[0].key
        : null;

    firstSubmenuKey && handleFirstMenu([firstSubmenuKey.toString()]);
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [firstSubmenuKey],
    });

    submenu && submenu[0] && _handleNavigation(submenu[0]);
    console.log("on opeon change-------------------------->", latestOpenKey);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (show_prescriotion_modal_from_notes) {
      if (
        prescriptionModal &&
        prescriptionModal.current &&
        ((userRole === ROLE_SUPERINTENDENT_PHARMACIST &&
          designation.toLowerCase() === "pharmacist") ||
          userRole === ROLE_DOCTOR ||
          userRole === ROLE_CLINICAL_PHARMACIST)
      ) {
        prescriptionModal.current._handleShowModal();
        store.dispatch({
          type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
          payload: false,
        });
      }
    }
  }, [show_prescriotion_modal_from_notes]);
  useEffect(() => {
    if (from_past_consultation_trigger) {
      if (prescriptionModal && prescriptionModal.current) {
        prescriptionModal.current._handleShowModal();
        // store.dispatch({
        //   type: "FROM_PAST_CONSULTATION_TRIGGER",
        //   payload: false,
        // });
      }
    }
  }, [from_past_consultation_trigger]);

  useEffect(() => {
    handleFirstMenu(selectedSidebarKey);
  }, [selectedSidebarKey]);
  useEffect(() => {
    onCollapse(isCollapsed);
  }, [isCollapsed]);
  useEffect(() => {
    if (
      window.location.pathname === appRoutesConst.consultationReview &&
      selectedSidebarKey === null
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`CR1`] });
    }
    if (
      window.location.pathname === appRoutesConst.scriptReview &&
      selectedSidebarKey === null
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`SR1`] });
    }
    if (
      window.location.pathname === appRoutesConst.dashboard &&
      (selectedSidebarKey === null ||
        selectedSidebarKey[0] === "90" ||
        selectedSidebarKey[0] === "PCR3")
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`APPT1`] });
    }
    if (
      window.location.pathname === appRoutesConst.scriptReview &&
      selectedSidebarKey === null
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`SR1`] });
    }
    if (
      window.location.pathname === appRoutesConst.scriptReview &&
      selectedSidebarKey === null
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`SR1`] });
    }
    if (
      window.location.pathname === appRoutesConst.dashboard &&
      (selectedSidebarKey === null ||
        selectedSidebarKey[0] === "90" ||
        selectedSidebarKey[0] === "PCR3")
    ) {
      store.dispatch({ type: "SET_SIDEBAR_KEY", payload: [`APPT1`] });
    }
  }, [selectedSidebarKey]);
  useEffect(() => {
    // Clearing all filters...
    localStorage.removeItem("intakedFilters");
    localStorage.removeItem("filterState");
    localStorage.removeItem("upcomingFilters");
    localStorage.removeItem("previousFilters");
    localStorage.removeItem("approveRejectFilters");
    localStorage.removeItem("intakedFiltersNewQuestionnarie");
    localStorage.removeItem("intakedFiltersPastQuestionnarie");
    localStorage.removeItem("intakedFiltersNewReports");
    localStorage.removeItem("intakedFiltersPastReports");
  }, []);

  const _handleFirstMenu = (d) => {
    console.log("IN KEY REMOVED--------------------->", d);
    localStorage.removeItem("intakedFilters");
    localStorage.removeItem("filterState");
    localStorage.removeItem("upcomingFilters");
    localStorage.removeItem("previousFilters");
    localStorage.removeItem("approveRejectFilters");
    localStorage.removeItem("intakedFiltersNewQuestionnarie");
    localStorage.removeItem("intakedFiltersPastQuestionnarie");
    localStorage.removeItem("intakedFiltersNewReports");
    localStorage.removeItem("intakedFiltersPastReports");
    let { key } = d;
    let { onSetSidebarKey } = dynamicTabAction;
    onSetSidebarKey([key]);
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [key],
    });
    setState({ ...state, savedSelectedKey: [key] });
    if (+key === 1) {
      // for call patient detail api on sidebar click patient detail every time
      _getPatientDetails();
    }
    handleSecondMenu(["0"]);
    handleFirstMenu([key]);
    // navigateTo()
  };
  // useEffect(() => {
  //   if (prevKeys?.savedSelectedKey?.toString() !== savedSelectedKey) {
  //     console.log("SAVE_SELECTED_KEY",savedSelectedKey);
  //     console.log(
  //       "PREV SAVE_SELECTED_KEY",
  //       prevKeys.savedSelectedKey.toString()
  //     );
  //     cancelPreviousPageRequests(prevKeys?.savedSelectedKey?.toString());
  //   }
  // }, [savedSelectedKey,state.savedSelectedKey]);
  const _getPatientDetails = () => {
    let { onRequest } = patientDetailActions;
    if (typeof onRequest === "function") {
      onRequest({
        id: patientId ? patientId : verifyObject(localStore, "patientId", null),
      });
    }
  };
  const _handleSecondMenu = (d) => {
    localStorage.removeItem("intakedFilters");
    localStorage.removeItem("filterState");
    localStorage.removeItem("upcomingFilters");
    localStorage.removeItem("previousFilters");
    localStorage.removeItem("approveRejectFilters");
    localStorage.removeItem("intakedFiltersNewQuestionnarie");
    localStorage.removeItem("intakedFiltersPastQuestionnarie");
    localStorage.removeItem("intakedFiltersNewReports");
    localStorage.removeItem("intakedFiltersPastReports");
    let {key} = d;
    handleFirstMenu(["0"]);
    handleSecondMenu([key]);
  };

  const _handleEdit = (selectedRecord) => {
    setState({ ...state, selectedRecord: selectedRecord });
  };

  const _clearSelectedRecords = () => {
    setState({ ...state, selectedRecord: null });
  };
  // let pathName = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const preConsultReview = urlParams.get("preConsultReview");
  const preConsultReviewed = urlParams.get("preConsultReviewed");
  return (
    <Fragment>
      {sideBarArray && isArray(sideBarArray) && sideBarArray.length > 0 && (
        <Fragment>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
            theme="light"
            width="221"
            collapsedWidth="50"
            // collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            className="sidebar"
          >
            <PerfectScrollbar
            // autoHeight
            // style={{
            //   width: 300,
            // }}
            >
              <div className="sidebarmenuwrap">
                <div className="sidemenutop">
                  <Menu
                    onClick={(d) => _handleFirstMenu(d)}
                    theme="light"
                    mode="inline"
                    selectedKeys={selectedMenu1 ? selectedMenu1 : ["APPT1"]}
                    onOpenChange={onOpenChange}
                    openKeys={openKeys}
                    className="sidemenupart"
                  // defaultSelectedKeys={['115']}
                  >
                    {sideBarArray.map((menu) => {
                      if (menu.hasSubmenu) {
                        return (
                          <SubMenu
                            key={menu.key}
                            icon={menu.icon}
                            title={menu.label}
                            disabled={menu.disabled}
                          >
                            {menu.submenu.map((submenu) => {
                              // if(userRole === ROLE_SUPERINTENDENT_PHARMACIST){
                              //   submenu.isDisabled = true
                              // }
                              if (!submenu.isDisabled) {
                                return (
                                  <Menu.Item
                                    // disabled={submenu.isDisabled}
                                    onClick={() => {
                                      _handleNavigation(submenu);
                                    }}
                                    key={submenu.key}
                                    icon={submenu.icon}
                                  >
                                    {submenu.label}
                                  </Menu.Item>
                                );
                              }
                              return null;
                            })}
                          </SubMenu>
                        );
                      } else {
                        return (
                          <Menu.Item
                            onClick={() => _handleNavigation(menu)}
                            key={menu.key}
                            icon={menu.icon}
                            disabled={menu.disabled}
                          >
                            {menu.label}
                          </Menu.Item>
                        );
                      }
                    })}
                  </Menu>
                  {window.location.pathname ===
                    appRoutesConst.preconsultationReview && (
                      <div className="text-center dashboard_btns">
                        <button
                          onClick={() => setOpenPreConsultReportModal(true)}
                          disabled={isInformRequestSubmitted === true}
                          className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0"
                        >
                          Generate Report
                        </button>
                      </div>
                    )}
                  {sideBarArray2 &&
                    isArray(sideBarArray2) &&
                    sideBarArray2.length > 0 && (
                      <div className="second-sidebar-wrap">
                        <div className="text-left dashboard_btns">
                          {/* <Divider className="my-1 mt-0">
                            <Tag
                              color="processing"
                              className="width-161px height-30 m-0"
                            >
                              Kiosk Pre-Consult
                            </Tag>

                          </Divider> */}
                          <h6 className="weight-500 text-blue padding-left-12 padding-right-12 font-14">
                            Kiosk Pre-Consult
                          </h6>
                        </div>
                        <Menu
                          onClick={(d) => _handleSecondMenu(d)}
                          theme="light"
                          mode="inline"
                          selectedKeys={selectedMenu2}
                          onOpenChange={onOpenChange}
                          openKeys={openKeys}
                          className=""
                          style={{
                            // marginTop: "50px !important",
                            border: "none",
                          }}

                        // defaultSelectedKeys={['115']}
                        >
                          {sideBarArray2.map((menu) => {
                            if (menu.hasSubmenu) {
                              return (
                                <SubMenu
                                  key={menu.key}
                                  icon={menu.icon}
                                  title={menu.label}
                                >
                                  {menu.submenu.map((submenu) => {
                                    if (!submenu.isDisabled) {
                                      return (
                                        <Menu.Item
                                          disabled={submenu.isDisabled}
                                          onClick={() => {
                                            _handleNavigation(submenu);
                                          }}
                                          key={submenu.key}
                                          icon={submenu.icon}
                                        >
                                          {submenu.label}
                                        </Menu.Item>
                                      );
                                    }
                                    return null;
                                  })}
                                </SubMenu>
                              );
                            } else {
                              return (
                                <Menu.Item
                                  onClick={() => _handleNavigation(menu)}
                                  key={menu.key}
                                  icon={menu.icon}
                                  disabled={menu.disabled}
                                >
                                  {menu.label}
                                </Menu.Item>
                              );
                            }
                          })}
                        </Menu>
                      </div>
                    )}
                </div>
                <div className="btnswrap">
                  {(userRole !== ROLE_CLINICAL_PHARMACIST ||
                    userRole === ROLE_CLINICAL_PHARMACIST ||
                    userRole === ROLE_SUPERINTENDENT_PHARMACIST) &&
                    allowedArray.includes(window.location.pathname) && (
                      <div className="text-center dashboard_btns">
                        {/* {appointmentObj && (
                          <button
                            onClick={onOpenAfterEmailCareModal}
                            className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0"
                          >
                            Aftercare Emails
                          </button>
                        )} */}
                        {window.location.pathname !==
                          "/doctor/questionnaire_review/detail" &&
                          window.location.pathname !==
                          "/doctor/dynamicQuestionnaire_review/detail" &&
                          window.location.pathname !==
                          "/doctor/reports_review/detail" &&
                          window.location.pathname !==
                          "/doctor/script_review/detail" &&
                          window.location.pathname !== `/patient/detail` &&
                          (window.location.pathname !==
                            "/patient/consultation_detail" ||
                            prev_appt_tab_key === "unfinished") && (
                            <button
                              onClick={() => openModal("informGP", true)}
                              disabled={
                                isInformRequestSubmitted === true ||
                                is_gp_informed
                              }
                              className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0"
                            >
                              Inform GP
                            </button>
                          )}

                        <button
                          onClick={onOpenSafeguardModal}
                          className="btn btn-custom btn-green height-30 font-13 weight-500 safeguard_btn push-10-t width-161px p-0"
                        >
                          Safeguard Alert
                        </button>
                        <button
                          onClick={onOpenEmergencyModal}
                          className="btn btn-custom btn-danger height-30 font-13 weight-500 emergency_btn push-10-t width-161px push-20-b p-0"
                        >
                          Emergency Alert
                        </button>
                        {console.log("appointmentObj", appointmentObj)}
                        {/* {appointmentObj && (
                          <AfterCareEmailModal
                            appointmentId={appointmentId}
                            appointmentObj={appointmentObj}
                            patientId={patientDetail?.id}
                            userId={userId}
                            // patientDetail={patientDetail}
                          />
                        )} */}
                        <EmergencyAlertContainer />
                        <SafeguardAlertContainer />
                      </div>
                    )}

                  {conditionFetchedLinks &&
                    (preConsultReview || preConsultReviewed) &&
                    conditionFetchedLinks.length !== 0 ? (
                    <ConditionLinks
                      conditionFetchedLinks={conditionFetchedLinks}
                    />
                  ) : null}
                  {symtopmpLinkFecthing && (
                    <div className="links-loader">
                      {<CommonLoader size={"small"} />}
                    </div>
                  )}
                  {!symtopmpLinkFecthing && symptompFetchedLinks && (
                    <div className="syatemlinks">
                      {
                        <SysmtopmFetchedLinks
                          symptompFetchedLinks={symptompFetchedLinks}
                          symtopmpLinkFecthing={symtopmpLinkFecthing}
                          patientId={patientId}
                          onSetDynamicInput={onSetDynamicInput}
                          dynamicInputs={dynamicInputs}
                        />
                      }
                    </div>
                  )}
                  <div className="stickybtn">
   
                    { isShowNHSPathFinder &&
                      allowedArrayForPathFiner.includes(
                        window.location.pathname
                      ) && (
                        <button
                          onClick={() => {
                            setNHSPathFinderModal(true);
                          }}
                          className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0"
                        >
                          NHS Pathfinder
                        </button>
                      )}
                    {(userRole !== ROLE_CLINICAL_PHARMACIST ||
                      userRole === ROLE_CLINICAL_PHARMACIST ||
                      userRole === ROLE_SUPERINTENDENT_PHARMACIST) &&
                      allowedArray.includes(window.location.pathname) && (
                        <button
                          onClick={() => {
                            setTabKey("all");
                            setShowShortCodeModal(true);
                          }}
                          className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0"
                        >
                          Short Codes
                        </button>
                      )}
                  </div>
                </div>

                {/* NHS Pathfinder Modal */}
                {nhsPathFinderModal && (
                  <ModalPopUp
                    visible={true}
                    handleCancel={() => {
                      setNHSPathFinderModal(false);
                    }}
                    closable={true}
                    maskClosable={false}
                    footer={false}
                    width={1200}
                    title="NHS Pathfinder"
                  >
                    <NHSPathFinderContainer
                      pre_consult_id={null}
                      patient_id={patientId}
                      storedPreConsultOutCome={null}
                      passedPreConsultData={null}
                      closePathFinderModal={() => {
                        setNHSPathFinderModal(false);
                      }}
                      nhsPathFinderModal={nhsPathFinderModal}
                      // preConsultData={preConsultData}
                      selecteddHealthCondition={null}
                      corporate_id={corporate_id}
                      doctor_id={verifyObject(
                        appointmentObj,
                        "doctor_id",
                        null
                      )}
                      appointmentId={appointmentObj?.id}
                      patientDetail={patientDetail}
                      dynamicTabAction={dynamicTabAction}
                      dynamicInputs={dynamicInputs}
                      questionnaireToken={null}
                      fromVideoF2F={true}
                      updateReasonText={(e) => { }}
                    />
                  </ModalPopUp>
                )}
                {/* Shor Codes  Modal */}

                <ModalPopUp
                  visible={showShortCodeModal}
                  handleCancel={() => {
                    setShowShortCodeModal(false);
                    setisClearedFilters(Math.random());
                    setTabKey("all");
                    _clearSelectedRecords();
                  }}
                  closable={true}
                  maskClosable={false}
                  footer={false}
                  width={1200}
                  title="Short Codes"
                >
                  <SortCodesContainer
                    isClearedFilters={isClearedFilters}
                    isUpdated={Math.random()}
                    handleTabChange={handleTabChange}
                    tabKey={tabKey}
                    showShortCodeModal={showShortCodeModal}
                    _handleEdit={_handleEdit}
                    _clearSelectedRecords={_clearSelectedRecords}
                    selectedRecord={state.selectedRecord}
                    prescriptionAbbreviation={prescriptionAbbreviation}
                    prescriptionAbbreviationActions={
                      prescriptionAbbreviationActions
                    }
                  />
                </ModalPopUp>
                <div className="calender_box">
                  {window?.location?.pathname === "/doctor/dashboard" && (
                    <div
                      className="stickybtn mb-3"
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <button
                        onClick={() => {
                          setIsOpenDrawer(true);
                        }}
                        className="btn btn-custom btn-blue height-30 font-13 weight-500 informgp_btn safeguard_btn push-10-t width-161px p-0 w-100"
                      >
                        Appointment Schedule
                      </button>
                    </div>
                  )}
                  <FullCalendarDrawer
                    open={isOpenDrawer}
                    onCloseDrawer={() => {
                      setIsOpenDrawer(false);
                    }}
                    user={user}
                  />
                  {window.location.pathname ===
                    appRoutesConst.preconsultationReview &&
                    openPreConsultReportModal && (
                      <PreConsultReportModal
                        visible={openPreConsultReportModal}
                        onClose={() => setOpenPreConsultReportModal(false)}
                      />
                    )}
                  {appointmentObj && appointmentId && (
                    <ReferralAddModal
                      // _onRequestList={this._onRequestList}
                      ref={reffralModal}
                      // {...props}
                      appointmentId={appointmentId}
                      appointmentObj={appointmentObj}
                      patientId={patientId}
                      gpService={gpService}
                      fromSidebar={true}
                      dynamicInputs={dynamicInputs}
                      dynamicTabAction={dynamicTabAction}
                      patient_detail_obj={patientDetail}
                      is_autosave_consultation_data={
                        is_autosave_consultation_data
                      }
                      prev_appt_tab_key={prev_appt_tab_key}
                    />
                  )}
                  {appointmentObj && appointmentId && !is_gp_informed && (
                    <InformGPModal
                      // _onRequestList={this._onRequestList}
                      ref={informGPModalRef}
                      is_gp_informed={is_gp_informed}
                      // visible={!is_gp_informed}
                      fromPreConsultReview={preConsultReview === "true"}
                      // {...props}
                      appointmentId={appointmentId}
                      appointmentObj={appointmentObj}
                      patientId={patientId}
                      gpService={gpService}
                      fromSidebar={true}
                      dynamicInputs={dynamicInputs}
                      dynamicTabAction={dynamicTabAction}
                      isClosable={isClosable}
                      _getPatientDetails={_getPatientDetails}
                      patientDetail={patientDetail}
                      prev_appt_tab_key={prev_appt_tab_key}
                      onSetPatient={onSetPatient}
                      onSetPatientAppointment={onSetPatientAppointment}
                      offline_call_status={offline_call_status}
                      fetching_patient={fetching_patient}
                      is_from_patient_search_listing={
                        is_from_patient_search_listing
                      }
                    />
                  )}

                  {appointmentObj && appointmentId && (
                    <SickNoteAddModal
                      ref={sickNoteModal}
                      // {...props}
                      appointmentId={appointmentId}
                      appointmentObj={appointmentObj}
                      patientId={patientId}
                      fromSidebar={true}
                      dynamicInputs={dynamicInputs}
                      dynamicTabAction={dynamicTabAction}
                      is_gp_informed={is_gp_informed}
                      userRole={userRole}
                      patient_detail_obj={patient_detail_obj}
                      is_autosave_consultation_data={
                        is_autosave_consultation_data
                      }
                      prev_appt_tab_key={prev_appt_tab_key}
                    // _onRequestList={this._onRequestList}
                    />
                  )}
                  {appointmentObj && appointmentId && (
                    <PrescriptionAddModal
                      prev_appt_tab_key={prev_appt_tab_key}
                      from_past_consultation_trigger={
                        from_past_consultation_trigger
                      }
                      designation={designation}
                      ref={prescriptionModal}
                      // {...props}
                      is_from_patient_search_listing={
                        is_from_patient_search_listing
                      }
                      selectedSidebarKey={selectedSidebarKey}
                      appointmentId={appointmentId}
                      appointmentObj={appointmentObj}
                      patientId={patientId}
                      fromSidebar={true}
                      dynamicInputs={dynamicInputs}
                      dynamicTabAction={dynamicTabAction}
                      userRole={userRole}
                      patientDetail={patientDetail}
                      is_gp_informed={is_gp_informed}
                      prescriptionAbbreviationActions={
                        prescriptionAbbreviationActions
                      }
                      prescriptionAbbreviation={prescriptionAbbreviation}
                      consultation_obj={consultation_obj}
                      is_autosave_consultation_data={
                        is_autosave_consultation_data
                      }
                    // _onRequestList={this._onRequestList}
                    />
                  )}
                  {window.location.pathname === "/doctor/dashboard" && (
                    <MordenCalendar
                      selectedDay={selectedDay}
                      onsetSelectedDay={_handleDate}
                      date={date}
                      className="morden-cl-wrapper"
                      // isFutureDateDisabled={
                      //   selectedSidebarKey && selectedSidebarKey[0] === "APPT2"
                      // }
                      // isPastDateDisabled={
                      //   (selectedSidebarKey &&
                      //     selectedSidebarKey[0] === "APPT1") ||
                      //   selectedSidebarKey === null
                      // }
                      todayClassName={"remove-selected-date"}
                      handleClear={handleClear}
                      fromSidebar={true}
                    />
                  )}
                </div>
              </div>
            </PerfectScrollbar>
          </Sider>
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedSidebarKey: verifyObject(state, "dynamicTab.sidebar_key", [
      "APPT1",
    ]),
    offline_call_status: verifyObject(
      state,
      "localStore.offline_call_status",
      null
    ),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null)
      ? verifyObject(state.localStore, "appointment_obj.id", null)
      : verifyObject(
        state.localStore,
        "appointment_obj.appointment_obj.id",
        null
      ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    gpService: verifyObject(
      state.localStore,
      "appointment_obj.gp_service",
      null
    )
      ? verifyObject(state.localStore, "appointment_obj.gp_service", null)
      : verifyObject(state.localStore, "patient_detail_obj.gp_service", null)
        ? verifyObject(state.localStore, "patient_detail_obj.gp_service", null)
        : null,
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    is_clicked_start_call: verifyObject(
      state,
      "localStore.is_clicked_start_call",
      false
    ),
    is_clicked_view: verifyObject(state, "localStore.is_clicked_view", false),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    componentName: verifyObject(state, "localStore.componentName", null),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    savedSelectedKey: verifyObject(state, "localStore.savedSelectedKey", null),
    isCollapsed: verifyObject(state, "dynamicTab.isCollapsed", false),
    symptompFetchedLinks: verifyObject(
      state,
      "dynamicTab.symptompFetchedLinks",
      false
    ),
    symtopmpLinkFecthing: verifyObject(
      state,
      "dynamicTab.symtopmpLinkFecthing",
      false
    ),
    userRole: verifyObject(state, "localStore.user.role", null),
    userId: verifyObject(state, "localStore.user.id", null),
    user: verifyObject(state, "localStore.user", null),

    show_prescriotion_modal_from_notes: verifyObject(
      state,
      "dynamicTab.show_prescriotion_modal_from_notes",
      null
    ),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    localStore: verifyObject(state, "localStore", null),
    prescriptionAbbreviation: verifyObject(
      state,
      "prescriptionAbbreviation",
      []
    ),
    from_past_consultation_trigger: verifyObject(
      state,
      "localStore.from_past_consultation_trigger",
      null
    ),
    fetching_patient: verifyObject(state, "localStore.fetching_patient", false),
    consultation_obj: verifyObject(state, "localStore.consultation_obj", null),
    conditionFetchedLinks: verifyObject(
      state,
      "dynamicTab.dynamicInputs.condition_links",
      []
    ),
    designation: verifyObject(
      state,
      "localStore.user.profile.designation",
      null
    ),
    preConsultationReviewIsLoading: state?.preConsultationReview?.isLoading,
    preConsultationSubmitLogsIsLoading:
      state?.preConsultationSubmitLogs?.isLoading,
    corporate_id: verifyObject(state.localStore, "user.corporate_id", null),
    patient_detail_obj: verifyObject(
      state.localStore,
      "patient_detail_obj",
      null
    ),
    is_autosave_consultation_data: verifyObject(
      state.localStore,
      "is_autosave_consultation_data",
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigationAction: bindActionCreators({ navigateTo }, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onRequestSetComponentID,
        onSetDynamicInput,
        onSetSidebarKey,
      },
      dispatch
    ),
    emergencyAlertActions: bindActionCreators(emergencyAlertActions, dispatch),
    safeguardAlertActions: bindActionCreators(safeguardAlertActions, dispatch),
    // afterCareEmailsActions: bindActionCreators(
    //   afterCareEmailsActions,
    //   dispatch
    // ),
    previousAppointmentsActions: bindActionCreators(
      previousAppointmentsActions,
      dispatch
    ),
    upcomingAppointmentsActions: bindActionCreators(
      upcomingAppointmentsActions,
      dispatch
    ),
    approveRejectAppointmentsActions: bindActionCreators(
      approveRejectAppointmentsActions,
      dispatch
    ),
    shortCodesActions: bindActionCreators(shortCodesActions, dispatch),
    prescriptionAbbreviationActions: bindActionCreators(
      prescriptionAbbreviationActions,
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    onSetPatientAppointment: bindActionCreators(
      { onSetPatientAppointment },
      dispatch
    ),
    onSetPatient: bindActionCreators({ onSetPatient }, dispatch),
    offlineCallActions: bindActionCreators({ setOfflineCallStatus }, dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
