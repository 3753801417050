import React, {Component} from "react";

import {
  disableCopy,
  errorToast,
  getErrorObject,
  showToast,
  successToast,
  verifyObject,
} from "../../utilities/utils";
// import Highlighter from "react-highlight-words";
import SingleCheckBox from "../../components/common/controls/SingleCheckBox";
import ModalPopUp from "../../components/common/ModalPopUp";
import {InformGPRequest} from "../../services/informGP";
import {Button, Radio, Input, Select, Spin} from "antd";
// import {searchSnomedCode} from "services/snomedCodes";
import {debounce, isArray, isEmpty} from "lodash";
import {getSurgeriesApi, updatePatientDataForGP} from "services/patients";
import store from "../../app/store";
import validator from "validator";
import {
  InformGPStaticArrayDEV,
  InformGPStaticArrayLIVE,
  savedToast,
  savingToast,
} from "constants/common";
import {startAppointment} from "services/appointments";
import moment from "moment";
import {uniqBy} from "lodash";
import {handleAutoSaveConsultationAPI} from "services/patientConsultation";

export default class InformGPModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      specialityArray: [],
      specialityId: null,
      additional_note: "",
      field_of_practice: "",
      referral_reason: "",
      referred_own_gp: false,
      fieldsArray: [],
      visible:
        // this.props.prev_appt_tab_key === "pending" &&
        // isEmpty(
        //   verifyObject(this.props, "appointmentObj.inform_gp_request", null)
        // )
        //   ? true
        //   :
        this.props.prev_appt_tab_key === "pending" &&
        verifyObject(this.props, "appointmentObj.patient.gp_service.id", null)
          ? false
          : props.visible
          ? props.visible
          : false,
      patient_sought: false,
      reason: "",
      InformGPSnomedCode: [],
      surgery_id: null,
      surgeries: [],
      stateGp: null,
      pre_consult_outcome: "",
      selectedSnomedCodesArray: [],
      pre_consult_history: "",
      condition_links: [],
    };

    [
      "handleCheckBox",
      "_handleTextChange",
      "_submitReferralNote",
      "_clearData",
      "_handleShowModal",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.onSearchUpdate = debounce(this.getSurgeries, 1000);
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const preConsultReview = urlParams.get("preConsultReview");
    let {prev_appt_tab_key, is_gp_informed, appointmentObj} = this.props;

    if (
      prev_appt_tab_key === "pending" &&
      !verifyObject(appointmentObj, "patient.gp_service.id", false) &&
      !is_gp_informed &&
      isEmpty(verifyObject(appointmentObj, "appointment_obj")) &&
      preConsultReview
    ) {
      this._startAppointment();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      // (!isEmpty(verifyObject(this.props.patientDetail, "gp_service")) &&
      //   this.props.prev_appt_tab_key === "pending" &&
      //   this.state.visible) ||
      this.props.prev_appt_tab_key === "pending" &&
      this.state.visible &&
      !isEmpty(verifyObject(this.props.appointmentObj, "inform_gp_request"))
    ) {
      console.log(
        "VISIBLE-----",
        !isEmpty(verifyObject(this.props.patientDetail, "gp_service")),
        !isEmpty(
          verifyObject(
            this.props.appointmentObj,
            "inform_gp_request.inform_gp_request"
          )
        ),
        this.props.prev_appt_tab_key === "pending",
        this.state.visible
      );
      this.setState({visible: false}, () => {
        // let {onSetPatient} = this.props.onSetPatient;
        let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
        let snomed_code = verifyObject(
          this.props.appointmentObj,
          "health_condition.health_condition.snomed_code",
          null
        )
          ? [
              {
                id: verifyObject(
                  this.props.appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      this.props.appointmentObj,
                      "health_condition.health_condition.snomed_code",
                      null
                    ).id
                  : null,
                code: verifyObject(
                  this.props.appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      this.props.appointmentObj,
                      "health_condition.health_condition.snomed_code.code",
                      null
                    )
                  : null,
                description: verifyObject(
                  this.props.appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      this.props.appointmentObj,
                      "health_condition.health_condition.snomed_code.description",
                      null
                    )
                  : null,
              },
            ]
          : [];
        let consentArray = verifyObject(
          this.props.appointmentObj,
          "inform_gp_request.inform_gp_request.patient_sought",
          null
        )
          ? process.env.REACT_APP_ENV === "dev"
            ? [InformGPStaticArrayDEV[0], ...snomed_code] //0 index for patient sought yes
            : [InformGPStaticArrayLIVE[0], ...snomed_code]
          : process.env.REACT_APP_ENV === "dev"
          ? [InformGPStaticArrayDEV[1], ...snomed_code] //1 index for patient sought no
          : [InformGPStaticArrayLIVE[1], ...snomed_code];

        let managementPlan =
          "Patient made aware of the importance of good medical record sharing, declined";
        if (
          verifyObject(
            this.props.appointmentObj,
            "inform_gp_request.inform_gp_request.patient_sought",
            null
          )
        ) {
          // isConsentGiven = true;
          managementPlan =
            "Patient made aware of the importance of good medical record sharing, Consent given";
        }

        let condition_links = verifyObject(
          this.props.appointmentObj,
          "health_condition.health_condition.condition_links",
          null
        )
          ? verifyObject(
              this.props.appointmentObj,
              "health_condition.health_condition.condition_links",
              null
            )
          : [];
        let managementPlanFull = verifyObject(
          this.props.appointmentObj,
          "outcome.pre_consult_outcome.outcome",
          null
        )
          ? `${verifyObject(
              this.props.appointmentObj,
              "outcome.pre_consult_outcome.outcome",
              null
            )}\n${managementPlan}\n`
          : managementPlan;
        let history = verifyObject(
          this.props.appointmentObj,
          "health_condition.health_condition.name",
          null
        );
        let selectedSnomedCodesArray = consentArray;
        let selectedCodesArray = consentArray;

        let {onSetDynamicInput} = this.props.dynamicTabAction;

        let Inputs = {
          ...this.props.dynamicInputs,
          managementPlan: managementPlanFull,
          history,
          selectedSnomedCodesArray,
          selectedCodesArray,
          condition_links,
        };
        if (typeof onSetDynamicInput === "function") {
          onSetDynamicInput(Inputs);
        }

        if (
          typeof onSetPatientAppointment === "function" &&
          !isEmpty(
            verifyObject(this.props.appointmentObj, "appointment_obj", null)
          )
        ) {
          onSetPatientAppointment(
            verifyObject(this.props.appointmentObj, "appointment_obj", null)
          );
        }
      });
    }
    // if (
    //   this.props.prev_appt_tab_key === "pending" &&
    //   !this.props.is_gp_informed &&
    //   !this.state.visible
    // ) {
    //   this.setState({visible: true});
    // }
  }

  _handleShowModal() {
    this.setState({visible: true});
  }

  async _handleTextChange(e) {
    let errors = null;

    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
      if (this.state[e.target.name] !== "") {
        let data = {
          [e.target.name]: e.target.value,
        };
        const errors = this.ValidateInput(data);

        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  }
  ValidateInput = (data) => {
    let errors = {};

    if (
      data.reason !== undefined &&
      (validator.isEmpty(data.reason) || data.reason.trim().length === 0)
    ) {
      errors.reason = "Reason is required";
    }

    return {errors, isValid: isEmpty(errors)};
  };
  handleAutoSaveConsultationTrigger = async (data) => {
    const {appointmentObj} = this.props;
    const formData = new FormData();

    // if (isAutoSaveConsultationData) {
    this.setState({isTriggeredAutoSave: true});

    setTimeout(async () => {
      showToast(savingToast, {
        id: "handleAutoSaveConsultationAPI",
        duration: 2000,
      });
      formData.set(
        "temp_consultation_info[appointment_id]",
        verifyObject(appointmentObj, "id")
      );

      for (const [key, value] of Object.entries(data)) {
        if (key !== "snomed_code_data") {
          formData.set(`temp_consultation_info[${key}]`, value);
        }
        if (key === "snomed_code_data") {
          if (
            data.snomed_code_data &&
            isArray(data.snomed_code_data) &&
            data.snomed_code_data.length > 0
          ) {
            data.snomed_code_data.forEach((item, index) => {
              if (item.id)
                formData.set(
                  `temp_consultation_info[snomed_code_data][${index}][id]`,
                  item.id
                );
              if (item.code)
                formData.set(
                  `temp_consultation_info[snomed_code_data][${index}][code]`,
                  item.code
                );
              if (item.description)
                formData.set(
                  `temp_consultation_info[snomed_code_data][${index}][description]`,
                  item.description
                );
            });
          } else {
            formData.set(`temp_consultation_info[snomed_code_data]`, []);
          }
        }
      }

      try {
        this.setState({isTriggeredAutoSave: true});
        let response = await handleAutoSaveConsultationAPI(formData);
        if (response.data.status === 200) {
          showToast(savedToast, {
            id: "handleAutoSaveConsultationAPI",
          });
        }
      } catch (error) {
        console.error("Auto-save failed:", error);
      } finally {
        this.setState({isTriggeredAutoSave: false});
      }
    }, 1000);
    // }
  };
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];
    if (stateArray) {
      for (const item of stateArray) {
        if (
          item !== undefined &&
          item.id !== undefined &&
          item.id !== null &&
          !uniqueItems[item.id]
        ) {
          uniqueItems[item.id] = true;
          newArray.push(item);
        }
      }
    }
    return newArray;
  };
  _SaveComponentStateToRedux = () => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {patient_sought} = this.state;
      // let isConsentGiven = false;
      let managementPlan =
        "Patient made aware of the importance of good medical record sharing, declined";
      if (patient_sought) {
        // isConsentGiven = true;
        managementPlan =
          "Patient made aware of the importance of good medical record sharing, Consent given";
      }

      let {dynamicInputs, prev_appt_tab_key, is_from_patient_search_listing} =
        this.props;
      let managementPlanParams = {
        managementPlan:
          prev_appt_tab_key === "pending" &&
          this.state.pre_consult_outcome !== ""
            ? `${this.state.pre_consult_outcome}\n${managementPlan}\n`
            : dynamicInputs && dynamicInputs.managementPlan
            ? `${dynamicInputs.managementPlan}\n${managementPlan}\n`
            : `${managementPlan}\n`,
      };
      if (is_from_patient_search_listing) {
        // debugger;
        this.handleAutoSaveConsultationTrigger({
          management_plan:
            dynamicInputs && dynamicInputs.managementPlan
              ? `${dynamicInputs.managementPlan}\n${managementPlan}\n`
              : `${managementPlan}\n`,
          snomed_code_data: this.removeDuplicates(
            this.state.InformGPSnomedCode
          ),
        });
      }
      let payload = {
        ...dynamicInputs,
        condition_links: this.state.condition_links,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history:
          prev_appt_tab_key === "pending" &&
          this.state.pre_consult_history !== ""
            ? `${this.state.pre_consult_history}\n`
            : dynamicInputs
            ? dynamicInputs.history
            : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray:
          prev_appt_tab_key === "pending" &&
          this.state.selectedSnomedCodesArray.length !== 0
            ? this.state.selectedSnomedCodesArray
            : this.props.fromSidebar || prev_appt_tab_key === "pending"
            ? patient_sought
              ? process.env.REACT_APP_ENV === "dev" ||
                process.env.REACT_APP_ENV === "stage"
                ? [InformGPStaticArrayDEV[0]] //0 index for patient sought yes
                : [InformGPStaticArrayLIVE[0]]
              : process.env.REACT_APP_ENV === "dev" ||
                process.env.REACT_APP_ENV === "stage"
              ? [InformGPStaticArrayDEV[1]] //1 index for patient sought no
              : [InformGPStaticArrayLIVE[1]]
            : dynamicInputs
            ? dynamicInputs.selectedSnomedCodesArray
            : [],
        selectedCodesArray:
          prev_appt_tab_key === "pending" &&
          this.state.selectedSnomedCodesArray.length !== 0
            ? this.state.selectedSnomedCodesArray
            : dynamicInputs
            ? dynamicInputs.selectedSnomedCodesArray
            : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        ...managementPlanParams,
        isConsentGiven: this.removeDuplicates(this.state.InformGPSnomedCode),
        reason_text: !isEmpty(this.state.reason_text)
          ? this.state.reason_text
          : dynamicInputs
          ? dynamicInputs.reason_text
          : [],
      };
      localStorage.setItem(
        "isConsentGiven",
        JSON.stringify(this.state.InformGPSnomedCode)
      );

      onSetDynamicInput(payload);
    }
  };

  async _submitReferralNote() {
    let {
      patientId,
      gpService,
      patientDetail,
      appointmentObj,
      prev_appt_tab_key,
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const pre_consult_id = urlParams.get("pre_consult_id");
    let {patient_sought, reason} = this.state;
    let surgeryParams = {};
    if (gpService !== null || this.state.surgery_id) {
      surgeryParams = {
        surgery_id: gpService ? gpService.id : this.state.surgery_id,
      };
    }
    let pre_tab_params = {};
    if (prev_appt_tab_key === "pending") {
      pre_tab_params = {
        pre_consult_id: pre_consult_id,
      };
    }

    let payload = {
      reason: reason,
      patient_sought: patient_sought,
      appointment_id: !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
        ? verifyObject(appointmentObj, "appointment_obj.id")
        : verifyObject(appointmentObj, "id"),
      patient_id: patientId
        ? patientId
        : verifyObject(patientDetail, "user.id"),
      ...surgeryParams,
      ...pre_tab_params,
      is_consent: patient_sought,
    };
    let passFromPre_consult_param =
      prev_appt_tab_key === "pending" ? {from_pre_consultation: true} : {};
    try {
      this.setState({changing: true});
      let response = await InformGPRequest({
        inform_request: {
          ...payload,
        },
        ...passFromPre_consult_param,
      });
      let {fromSidebar, prev_appt_tab_key, dynamicInputs} = this.props;
      let consentArray = patient_sought
        ? process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
          ? [InformGPStaticArrayDEV[0]] //0 index for patient sought yes
          : [InformGPStaticArrayLIVE[0]]
        : process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
        ? [InformGPStaticArrayDEV[1]] //1 index for patient sought no
        : [InformGPStaticArrayLIVE[1]];
      this.setState({
        InformGPSnomedCode:
          dynamicInputs?.selectedSnomedCodesArray || consentArray,
      });
      if (fromSidebar) {
        if (
          prev_appt_tab_key === "pending" &&
          verifyObject(appointmentObj, "health_condition")
        ) {
          this.setState(
            {
              InformGPSnomedCode:
                dynamicInputs?.selectedSnomedCodesArray || consentArray,
              condition_links: verifyObject(
                appointmentObj,
                "health_condition.health_condition.condition_links",
                null
              )
                ? verifyObject(
                    appointmentObj,
                    "health_condition.health_condition.condition_links",
                    null
                  )
                : [],
              pre_consult_outcome: verifyObject(
                appointmentObj,
                "outcome.pre_consult_outcome.outcome",
                null
              )
                ? verifyObject(
                    appointmentObj,
                    "outcome.pre_consult_outcome.outcome",
                    null
                  )
                : "",
              pre_consult_history: verifyObject(
                appointmentObj,
                "health_condition.health_condition.name",
                null
              ),

              selectedSnomedCodesArray: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code",
                null
              )
                ? [
                    {
                      id: verifyObject(
                        appointmentObj,
                        "health_condition.health_condition.snomed_code",
                        null
                      ).id,
                      code: verifyObject(
                        appointmentObj,
                        "health_condition.health_condition.snomed_code",
                        null
                      ).code,
                      description: verifyObject(
                        appointmentObj,
                        "health_condition.health_condition.snomed_code",
                        null
                      ).description,
                    },
                    ...consentArray,
                  ]
                : [...consentArray],
            },
            () => {
              this._SaveComponentStateToRedux();
            }
          );
        }
        this._SaveComponentStateToRedux();
        if (this.props.prev_appt_tab_key === "pending") {
          let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
          let {onSetPatient} = this.props.onSetPatient;

          if (typeof onSetPatient === "function") {
            onSetPatient({
              id: patientId
                ? patientId
                : verifyObject(patientDetail, "user.id"),
            });
          }
          if (
            typeof onSetPatientAppointment === "function" &&
            verifyObject(appointmentObj, "appointment_obj") &&
            !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
          ) {
            onSetPatientAppointment(
              verifyObject(appointmentObj, "appointment_obj")
            );
          }
        }
      }

      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: true,
      });
      successToast({content: verifyObject(response, "data.message", "")});
      store.dispatch({
        type: "IS_CONSULTATION_API_STARTED",
        payload: false,
      });
      this._clearData();

      // localStorage.setItem("is_gp_informed", true);
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }

  _startAppointment = async () => {
    let {patientDetail, appointmentObj} = this.props;
    try {
      this.setState({isLoading: true, startingConsultation: true});

      let response = await startAppointment({
        patient_id: verifyObject(appointmentObj, "patient.id")
          ? verifyObject(appointmentObj, "patient.id")
          : verifyObject(appointmentObj, "patient_id")
          ? verifyObject(appointmentObj, "patient_id")
          : verifyObject(patientDetail, "user.id"),
        start_time: moment().format(),
        pre_consult_id: verifyObject(appointmentObj, "id"),
      });

      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
      // let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
      let {onSetPatient} = this.props.onSetPatient;
      let {onSetPatientAppointment} = this.props.onSetPatientAppointment;

      this.setState({
        condition_links: verifyObject(
          appointmentObj,
          "health_condition.health_condition.condition_links",
          null
        )
          ? verifyObject(
              appointmentObj,
              "health_condition.health_condition.condition_links",
              null
            )
          : [],
        pre_consult_outcome: verifyObject(
          appointmentObj,
          "outcome.pre_consult_outcome.outcome",
          null
        )
          ? verifyObject(
              appointmentObj,
              "outcome.pre_consult_outcome.outcome",
              null
            )
          : "",
        pre_consult_history: verifyObject(
          appointmentObj,
          "health_condition.health_condition.name",
          null
        ),
        selectedSnomedCodesArray: verifyObject(
          appointmentObj,
          "health_condition.health_condition.snomed_code",
          null
        )
          ? [
              {
                id: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                ).id,
                code: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                ).code,
                description: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                ).description,
              },
            ]
          : [],
      });
      if (this.props.prev_appt_tab_key === "pending") {
        if (typeof onSetPatient === "function") {
          onSetPatient({
            id: verifyObject(patientDetail, "user.id")
              ? verifyObject(patientDetail, "user.id")
              : verifyObject(appointmentObj, "patient.id"),
          });
        }
        if (
          typeof onSetPatientAppointment === "function" &&
          verifyObject(response, "data.data")
        ) {
          onSetPatientAppointment(verifyObject(response, "data.data"));
        }
      }
      // if (typeof onSetDynamicHealthInput === "function") {
      //   onSetDynamicHealthInput(null);
      // }
      this.setState(
        {isLoading: false, startingConsultation: false},
        async () => {}
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false, startingConsultation: false});
      errorToast({content: message});
    }
  };

  handleCheckBox(e, v) {
    if (v === "yes") {
      this.setState({
        patient_sought: true,
        patient_sought_yes: true,
        patient_sought_no: false,
      });
      this._getSnomedCodes("768321000000106", true);
    } else {
      this.setState({
        patient_sought: false,
        patient_sought_yes: false,
        patient_sought_no: true,
      });
      this._getSnomedCodes("768361000000103", false);
    }
  }

  async _getSnomedCodes(search, isYes) {
    try {
      this.setState({searchingSnomed: true});
      // let response = await searchSnomedCode({
      //   search: search,
      // });
      this.setState(
        {
          InformGPSnomedCode: isYes
            ? process.env.REACT_APP_ENV === "dev" ||
              process.env.REACT_APP_ENV === "stage"
              ? [InformGPStaticArrayDEV[0]] //0 index for patient sought yes
              : [InformGPStaticArrayLIVE[0]]
            : process.env.REACT_APP_ENV === "dev" ||
              process.env.REACT_APP_ENV === "stage"
            ? [InformGPStaticArrayDEV[1]] //1 index for patient sought no
            : [InformGPStaticArrayLIVE[1]],

          // verifyObject(response, "data.data", []).length !== 0
          //   ? uniqBy(response.data.data, "code")
          //   : [],
        },
        () => {
          // debugger;
        }
      );
      this.setState({searchingSnomed: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({searchingSnomed: false});

      errorToast({content: message});
    }
  }

  _clearData() {
    this.setState({
      visible: false,
      changing: false,
      reason: "",
      patient_sought: false,
      surgery_id: null,
      surgeries: [],
    });
  }
  getSurgeries = async (searchString) => {
    try {
      await this.setState({loading: true});
      let response = await getSurgeriesApi({
        search: searchString,
      });

      let surgeries = response.data.data;
      // let surgeriesCodes = surgeries.map((surgery) => {
      //   return {
      //     id: surgery.id,
      //     practice_name: surgery.practice_name,
      //   };
      // });

      await this.setState({
        surgeries: surgeries,
        loading: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: message});
    }
  };
  onSurgeryChange = (val) => {
    let foundSurgery = this.state.surgeries.filter((s) => s.id === val);

    this.setState({
      surgery_id: val,
      surgeries: foundSurgery,
    });
  };
  handleUpdateGP = async () => {
    let payload = {
      surgery_id: this.state.surgery_id,
      id: this.props.patientId
        ? this.props.patientId
        : verifyObject(this.props.patientDetail, "user.id"),
    };
    try {
      await this.setState({isAdding: true});
      let response = await updatePatientDataForGP(payload, true);

      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      if (
        typeof this.props._getPatientDetails === "function"
        // &&
        // this.props.prev_appt_tab_key !== "pending"
      ) {
        this.props._getPatientDetails();
      }
      await this.setState({
        isAdding: false,
        stateGp: verifyObject(response, "data.data.gp_service", null),
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isAdding: false});
      errorToast({content: message});
    }
  };
  // _getPatientDetails = () => {
  //   let {onRequest} = this.props.patientDetailActions;
  //   if (typeof onRequest === "function") {
  //     onRequest({
  //       id: this.props.patientId
  //         ? this.props.patientId
  //         : verifyObject(this.props.localStore, "patientId", null),
  //     });
  //   }
  // };
  render() {
    let {
      changing,
      patient_sought_yes,
      patient_sought_no,
      reason,
      visible,
      stateGp,
    } = this.state;
    let {gpService, isLoading, referralNote, fromSidebar, isClosable} =
      this.props;
    let isDisabled = true;
    if (patient_sought_yes) {
      isDisabled = false;
    }
    if (patient_sought_no) {
      isDisabled = reason === "";
    }
    if (gpService === null && stateGp === null) {
      isDisabled = true;
    }
    if (gpService === null && stateGp === null && patient_sought_no) {
      isDisabled = reason === "";
    }

    return (
      <div>
        {!isLoading && referralNote === null && !fromSidebar && (
          <a onClick={this._handleShowModal}>Add Referral Note</a>
        )}
        <ModalPopUp
          title={"Add Inform GP"}
          handleCancel={!isClosable ? () => {} : this._clearData}
          visible={visible}
          closable={isClosable}
          styleProps={{width: 920}}
          width={920}
          footer={true}
          // maskClosable={false}
          handleOk={() => {
            this._submitReferralNote();
          }}
          okButtonProps={{disabled: isDisabled}}
          disabled={isDisabled || this.state.searchingSnomed}
          okText={"Save"}
          loading={changing}
          cancelButtonProps={{
            disabled: !isClosable,
            // style: {display: "none"},
          }}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={isClosable}
          fromInformGP={true}
          className="informgpmodal"
        >
          <div className="informgp-form">
            <Spin spinning={this.props.fetching_patient}>
              <form>
                {gpService === null && stateGp === null && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12 gptitle">
                        <span>Patient has not added his GP</span>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="sel1" className="font-10">
                          Select Surgery
                          {/* <sup className="text-danger font-15 top-2">*</sup> */}
                        </label>
                        <Select
                          showSearch={true}
                          loading={this.state.loading}
                          placeholder="Select Surgery"
                          optionFilterProp="children"
                          className="form-control select_gp"
                          onChange={async (val) =>
                            await this.onSurgeryChange(val)
                          }
                          allowClear={true}
                          onSearch={async (val) =>
                            await this.onSearchUpdate(val)
                          }
                          filterOption={false}
                          value={this.state.surgery_id}
                        >
                          {this.state.surgeries.map((surgery, index) => {
                            const address = [
                              surgery.address,
                              surgery.city,
                              surgery.county,
                              surgery.post_code,
                            ]
                              .filter((d) => d)
                              .join(", ");
                            // const address = `${
                            //   surgery.address ? `${surgery.address},` : ""
                            // }${surgery.city ? `${surgery.city},` : ""}${
                            //   surgery.county ? `${surgery.county},` : ""
                            // }${surgery.post_code ? `${surgery.post_code},` : ""}`;

                            return (
                              <Select.Option
                                key={surgery.id}
                                value={surgery.id}
                                className="full_txt"
                              >
                                <b
                                  className="practicename"
                                  style={{textTransform: "capitalize"}}
                                >
                                  {surgery.practice_name}
                                </b>
                                <br />
                                <span className="address">{address}</span>
                              </Select.Option>
                            );
                          })}
                        </Select>
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.surgery_id}
                          </span>
                        )}
                      </div>
                      <div className="col-md-12 mt-14">
                        <Button
                          type="primary"
                          onClick={this.handleUpdateGP}
                          disabled={
                            this.state.surgery_id === null ||
                            !this.state.surgery_id ||
                            this.state.isAdding
                          }
                        >
                          Add GP
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {gpService === null && stateGp !== null && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <div
                          className="address_box push-0-t push-b-8"
                          onCopy={disableCopy}
                        >
                          <p
                            style={{fontWeight: "bolder"}}
                            className="font-10 m-0 text-light-black text-capitalize"
                          >
                            {" "}
                            {verifyObject(stateGp, "practice_name", "")}{" "}
                          </p>
                          <p className="font-10 push-3-t margin-bottom-0 weight-400 text-dark-gray line-1-43">
                            {" "}
                            {verifyObject(stateGp, "address", "")},{" "}
                            {verifyObject(stateGp, "city", "")} ,
                            {verifyObject(stateGp, "county", "")} ,
                            {verifyObject(stateGp, "post_code", "")}
                          </p>
                          <p className="font-10 margin-bottom-5 weight-400 text-dark-gray line-1-43 ng-binding ng-scope">
                            {" "}
                            {verifyObject(stateGp, "phone", "")}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {gpService !== null && stateGp !== null && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <div
                          className="address_box push-0-t push-b-8"
                          onCopy={disableCopy}
                        >
                          <p
                            style={{fontWeight: "bolder"}}
                            className="font-10 m-0 text-light-black text-capitalize"
                          >
                            {" "}
                            {verifyObject(stateGp, "practice_name", "")}{" "}
                          </p>
                          <p className="font-10 push-3-t margin-bottom-0 weight-400 text-dark-gray line-1-43">
                            {" "}
                            {verifyObject(stateGp, "address", "")},{" "}
                            {verifyObject(stateGp, "city", "")} ,
                            {verifyObject(stateGp, "county", "")} ,
                            {verifyObject(stateGp, "post_code", "")}
                          </p>
                          <p className="font-10 margin-bottom-5 weight-400 text-dark-gray line-1-43 ng-binding ng-scope">
                            {" "}
                            {verifyObject(stateGp, "phone", "")}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {gpService !== null && stateGp === null && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <div
                          className="address_box push-0-t push-b-8"
                          onCopy={disableCopy}
                        >
                          <p
                            style={{fontWeight: "bolder"}}
                            className="font-10 m-0 text-light-black text-capitalize"
                          >
                            {" "}
                            {verifyObject(gpService, "practice_name", "")}{" "}
                          </p>
                          <p className="font-10 push-3-t margin-bottom-0 weight-400 text-dark-gray line-1-43">
                            {" "}
                            {verifyObject(gpService, "address", "")},{" "}
                            {verifyObject(gpService, "city", "")} ,
                            {verifyObject(gpService, "county", "")} ,
                            {verifyObject(gpService, "post_code", "")}
                          </p>
                          <p className="font-10 margin-bottom-5 weight-400 text-dark-gray line-1-43 ng-binding ng-scope">
                            {" "}
                            {verifyObject(gpService, "phone", "")}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="">
                    <div className="col-md-12 service_deliverybox">
                      <p className="mt-3">
                        Consent is required for the service provision and to
                        share information about this consultation:{" "}
                      </p>
                      <ul>
                        <li>
                          Consent for the healthcare provider sharing
                          information with the patient's GP practice
                        </li>
                        <li>
                        Consent for the pharmacy sharing information with NHS England and ICBs (for reporting purposes){" "}
                        </li>
                        <li>
                          Consent to a third person other than patient &
                          pharmacist being present in consultation room (if
                          applicable)
                        </li>
                        <li>
                          Consent to forward referral to another community
                          pharmacy (if applicable)
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex mt-3 consentradio">
                      <span className="d-flex line-height-1 mb-3">
                        <p className="text-danger">*</p> Consent for the
                        pharmacy sharing information with NHSBSA and NHS England
                        for the purpose of contract management and as part of
                        postpayment verification (PPV) consent has been given?
                      </span>
                      <div style={{display: "flex"}} className="mt-1">
                        <SingleCheckBox
                          // disabled={gpService === null}
                          label="Yes"
                          name="yes"
                          checked={patient_sought_yes}
                          onChange={(e) => this.handleCheckBox(e, "yes")}
                        />

                        <SingleCheckBox
                          // disabled={gpService === null}
                          label="No"
                          name="no"
                          checked={patient_sought_no}
                          onChange={(e) => this.handleCheckBox(e, "no")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">
                        Give consent to share record ?
                      </label>
                      <div style={{display: "flex"}}>
                        <SingleCheckBox
                          // disabled={gpService === null}
                          label="Yes"
                          name="yes"
                          checked={patient_sought_yes}
                          onChange={(e) => this.handleCheckBox(e, "yes")}
                        />

                        <SingleCheckBox
                          // disabled={gpService === null}
                          label="No"
                          name="no"
                          checked={patient_sought_no}
                          onChange={(e) => this.handleCheckBox(e, "no")}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {patient_sought_no && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="sel1">Reason</label>
                        <Input.TextArea
                          className="form-control margin-0 placeholder-10 height-auto"
                          name="reason"
                          placeholder="Reason why they need to inform GP"
                          value={reason}
                          onChange={this._handleTextChange}
                          rows="1"
                          autoSize={true}
                        />
                        {this.state.errors && (
                          <span className="validate-danger">
                            {this.state.errors.reason}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </Spin>
          </div>
        </ModalPopUp>
      </div>
    );
  }
}
