/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
import React, {Component, Fragment} from "react";
import {
  addConsultationDetailAPI,
  addHealthAssessmentPatientAPI,
  endPrescriberAppointment,
  getPatientHealthAssessmentDetailApi,
  // getConsultationDetailAPI,
  updateAppointmentReasonApi,
  updateConsultationDetailAPI,
} from "../../services/patientConsultation";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
  checkboxArray,
  Health_Assessment_KVP,
  resizeTextArea,
  getLivePerformaQuestionnaire,
} from "../../utilities/utils";
import {connect} from "react-redux";
import {
  Input,
  Tooltip,
  InputNumber,
  Tabs,
  Radio,
  Button,
  Select,
  Checkbox,
  Popover,
  Spin,
} from "antd";
import {searchSnomedCode} from "../../services/snomedCodes";
import {isEmpty, debounce, map, uniq, uniqBy, isArray, isNumber} from "lodash";
import {Image} from "antd";
import CommonLoader from "../../components/common/CommonLoader";
import store from "../../app/store";
// import addImage from "../../assets/images/common/add-ic.png";
import deleteImage from "../../assets/images/common/delete-ic.png";
import CallScreenShotContainer from "../WalkinAppointment/CallScreenShotContainer";

import {
  onRequestSetComponent,
  onSetSidebarKey,
  onSetDynamicHealthInput,
  onSetDynamicInput,
  types,
} from "../../reducers/dynamicTab";
import {bindActionCreators} from "redux";
// import Highlighter from "react-highlight-words";
import InformationalOutcomeModal from "../PatientConsultationConatainers/InformationalOutcomeModal";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import {ValidateInput} from "../PatientConsultationConatainers/PatientConsultationValidations";
import {
  DATE_FORMAT_24_HR,
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  DP_QUESTIONNAIRE,
  ROLE_SUPERINTENDENT_PHARMACIST,
  InformGPStaticArrayDEV,
  InformGPStaticArrayLIVE,
  // DP_QUESTIONNAIRE_LIVE,
} from "constants/common";
import PreEncountersContainer from "../PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import moment from "moment";
import CreatableSelect from "react-select/creatable";
import AutoTextArea from "components/AutoTextArea";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import "react-perfect-scrollbar/dist/css/styles.css";
// import SplitPane from "react-split-pane";
import SplitPane, {Pane} from "react-split-pane-next";
// import QuestionnaireFillUp from "./QuestionnaireFillUp";
// import SymmtomDiagnosisTabs from "./SymmtomDiagnosisTabs";
import {
  fetchDiagnosisApi,
  fetchRegionsAPI,
  fetchSymptomsApi,
} from "services/symptoms";
import ModalPopUp from "components/common/ModalPopUp";
import {
  PictureFilled,
  CaretUpOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
// import MaskDateInputPicker from "components/patientAddForm/MaskDateInputPicker";
import InputMask from "react-input-mask";
import SymmtomDiagnosisTabs from "containers/WalkinAppointment/SymmtomDiagnosisTabs";
import {
  setOfflineCallStatus,
  setDocumentViewedFlag,
} from "../../reducers/onlineOfflineCall";
import PreConsultSubmittedQuestionnaire from "containers/WalkinAppointment/PreConsultSubmittedQuestionnaire";
import QuestionnaireFillUp from "containers/WalkinAppointment/QuestionnaireFillUp";
import {startAppointment} from "services/appointments";
import {InformGPRequest} from "services/informGP";
import {onSetPatient, onSetPatientAppointment} from "reducers/patients";
import {fetchSubmittedPreConsultations} from "services/preConsultConditions";
import {Spinner} from "components/common";
// import ExaminationEditor from "containers/WalkinAppointment/ExaminationEditor";
// import {change} from "redux-form";
import axios from "axios";

// const SET_CONSULTATION_OBJ = "SET_CONSULTATION_OBJ";
const {TabPane} = Tabs;
// const { TextArea } = Input;
const presentingpopover = (
  <div className="infobodymain">
    <ul className="mainlist">
      <li>
        Enter{" "}
        <b>
          <u>clinical features</u>
        </b>{" "}
        as an individual concept or comma separated, click enter or the ‘plus’
        sign to add terms.
      </li>
      <li>
        Enter terms in free text or select from predictive text that appears as
        you start typing.
      </li>
      <li>
        Use terms as they would appear in medical resources, convert numerical
        values, avoid abbreviations, avoid normal / negative features & avoid
        repetition.
      </li>
      <li>
        Examples of how to enter clinical features:
        <div className="featureslist">
          <ul class="clinical_info_list">
            <li>diffuse severe pain left side</li>
            <li>accidental injury</li>
            <li>left side bruising</li>
            <li>tachycardic</li>
            <li>elevated creatinine</li>
            <li>low wbc</li>
          </ul>
        </div>
      </li>
      <li>
        Once you have entered all your symptoms, click on 'Get Checklist' and
        wait for a result.
      </li>
    </ul>
    <div className="plaintext">
      <p>
        If you are concerned about Infectious diseases or your patient has
        travelled to another
        <b className="ml-1">
          <u>region</u>
        </b>
        , then change the
        <b className="mx-1">
          <u>region</u>
        </b>
        accordingly.
      </p>
    </div>
  </div>
);
function reasonParse(inputString) {
  // console.log("reason-------",reason)

  // Split the inputString by newline characters
  const lines = inputString ? inputString.split("\n") : [];

  // Map the array of lines to an array of objects with "value" and "label" properties
  const resultArray = lines.map((line) => {
    return {
      value: line.includes("-:") ? line.replace("-:", "").trim() : line.trim(), // Trim to remove leading/trailing spaces
      label: line.includes("-:") ? line.replace("-:", "").trim() : line.trim(), // Use the trimmed string as both value and label
    };
  });

  return resultArray;
}
class PreConsultDetailContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationDetail: null,
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      pre_consult_note: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      addingDetail: false,
      updatingDetail: false,
      isLoading: false,
      isShowSubmit: false,
      componentId: null,
      visible: false,
      showAddSkipbutton: true,
      pulseObject: {},
      pulse_per_minute: "",
      height: "",
      heightObject: {},
      weight: "",
      respiratory_rate: "",
      weightObject: {},
      respiratory_rateObject: {},
      body_temperature: "",
      body_temperatureObject: {},
      systolic_bp: "",
      systolic_bpObject: {},
      diastolic_bp: "",
      diastolic_bpObject: {},
      blood_oxygen_saturation: "",
      blood_oxygen_saturationObject: {},
      bmiObject: {},
      bmi: "",
      reason_text:
        verifyObject(this.props.appointmentObj, "reason_text", "") !== ""
          ? reasonParse(
              verifyObject(this.props.appointmentObj, "reason_text", "")
            )
          : verifyObject(
              this.props.appointmentObj,
              "health_condition.health_condition",
              null
            )
          ? [
              {
                value: verifyObject(
                  this.props.appointmentObj,
                  "health_condition.health_condition",
                  null
                ).id,
                label: verifyObject(
                  this.props.appointmentObj,
                  "health_condition.health_condition",
                  null
                ).name,
              },
            ]
          : "",
      snomedCodeId: "",
      tabKey:
        this.props.consultation_tab_key !== null
          ? this.props.consultation_tab_key
          : "preencounters",
      checkboxStateArray: checkboxArray,
      healthStateArray: Health_Assessment_KVP,
      selectedCheckbox: [],
      isModalOpen: false,
      // reason_text: [],
      pageSymptom: 1,
      searchSymptom: "",
      paginationSymptom: "",
      isLoadmoreSymptom: false,
      symptomsData: [],
      searchingSymptom: false,
      is_pregnant: null,
      getDiagnosisModal: false,
      timer: null,
      enterCount: 0,
      getPresentingComModal: false,
      getPresentingInfoModal: false,
      splitSize: true,
      fetchingRegion: false,
      regionsData: [],
      recentTravel: false,
      region_id: 1,
      searchingDiagnose: false,
      symptompSnomedCode: [],
      submitedQuestionnairesArray: [],
      historyForPGQ: null,
      comment_other: "",
      newselectedCodesArray: [],
      subimittedQuestionnaireAPICount: 0,
      changing: false,
    };
    this.scollRef = React.createRef();
    this.creatableRef = React.createRef();
    [
      // "_getPatientConultationDetails",
      "_handleSubmit",
      "_handleTextChange",
      "_hideModal",
      "_showCodeModal",
      "_hideCodeModal",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleModalSubmission",
      "_handleEdit",
      "_handleUpdateoutcome",
      "_handleUpdate",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
    this.searchUpdateSymptom = debounce(this._handleSymptomSearch, 1000);
  }

  async componentDidMount() {
    // this._getPatientConultationDetails();
    const urlParams = new URLSearchParams(window.location.search);
    const preConsultReview = urlParams.get("preConsultReview");
    const fromPreConsultPopup = urlParams.get("fromPreConsultPopup");
    const pre_consult_id = urlParams.get("pre_consult_id");
    const gp_exist = urlParams.get("gp_exist");

    let {dynamicInputs, dynamicHealthInputs, submitedPreConsultQuestionnaire} =
      this.props;

    if (
      !fromPreConsultPopup &&
      pre_consult_id &&
      submitedPreConsultQuestionnaire &&
      submitedPreConsultQuestionnaire.length === 0
    ) {
      this._getPreConsultSubmittedQuestionnaire();
    } else {
      let {prev_appt_tab_key, is_gp_informed, appointmentObj, patientDetail} =
        this.props;
      this.setState({
        submitedQuestionnairesArray: submitedPreConsultQuestionnaire,
        // visible: true
      });
    }
    let {prev_appt_tab_key, is_gp_informed, appointmentObj, patientDetail} =
      this.props;

    console.log(
      "appointmentObj QQQ",
      appointmentObj && appointmentObj?.appointment_obj,
      preConsultReview,
      appointmentObj?.inform_gp_request,
      is_gp_informed,
      prev_appt_tab_key
    );
    if (
      prev_appt_tab_key === "pending" &&
      appointmentObj &&
      appointmentObj?.appointment_obj &&
      Object.keys(appointmentObj?.appointment_obj).length === 0 &&
      preConsultReview &&
      appointmentObj?.inform_gp_request === null &&
      !is_gp_informed
    ) {
      console.log("Appointment patientDetail", appointmentObj);
      if (appointmentObj && appointmentObj?.patient?.gp_service !== null) {
        this._startAppointment(true);
      }
    }
    if (prev_appt_tab_key === "pending" && preConsultReview === "true") {
      let {onSetPatient} = this.props.onSetPatient;
      // let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
      let snomed_code = verifyObject(
        appointmentObj,
        "health_condition.health_condition.snomed_code",
        null
      )
        ? [
            {
              id: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code",
                null
              )
                ? verifyObject(
                    appointmentObj,
                    "health_condition.health_condition.snomed_code",
                    null
                  ).id
                : null,
              code: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code",
                null
              )
                ? verifyObject(
                    appointmentObj,
                    "health_condition.health_condition.snomed_code.code",
                    null
                  )
                : null,
              description: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code",
                null
              )
                ? verifyObject(
                    appointmentObj,
                    "health_condition.health_condition.snomed_code.description",
                    null
                  )
                : null,
            },
          ]
        : [];
      let consentArray = verifyObject(
        appointmentObj,
        "inform_gp_request.inform_gp_request.patient_sought"
      )
        ? process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
          ? [InformGPStaticArrayDEV[0], ...snomed_code] //0 index for patient sought yes
          : [InformGPStaticArrayLIVE[0], ...snomed_code]
        : process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
        ? [InformGPStaticArrayDEV[1], ...snomed_code] //1 index for patient sought no
        : [InformGPStaticArrayLIVE[1], ...snomed_code];

      let managementPlan =
        "Patient made aware of the importance of good medical record sharing, Consent given";

      this.setState(
        {
          condition_links: verifyObject(
            appointmentObj,
            "health_condition.health_condition.condition_links",
            null
          )
            ? verifyObject(
                appointmentObj,
                "health_condition.health_condition.condition_links",
                null
              )
            : [],
          managementPlan: verifyObject(
            appointmentObj,
            "outcome.pre_consult_outcome.outcome",
            null
          )
            ? `${verifyObject(
                appointmentObj,
                "outcome.pre_consult_outcome.outcome",
                null
              )}\n${managementPlan}\n`
            : managementPlan,
          history: verifyObject(
            appointmentObj,
            "health_condition.health_condition.name",
            null
          ),
          selectedSnomedCodesArray: consentArray,
          selectedCodesArray: consentArray,
        },
        () => {
          let {onSetDynamicInput} = this.props.dynamicTabAction;

          let {
            condition_links,
            managementPlan,
            history,
            selectedSnomedCodesArray,
            selectedCodesArray,
          } = this.state;

          let Inputs = {
            ...this.props.dynamicInputs,
            managementPlan,
            history,
            selectedSnomedCodesArray,
            selectedCodesArray,
            condition_links,
          };
          if (typeof onSetDynamicInput === "function") {
            onSetDynamicInput(Inputs);
          }
          if (typeof onSetPatient === "function") {
            onSetPatient({
              id: verifyObject(appointmentObj, "patient.id", null)
                ? verifyObject(appointmentObj, "patient.id", null)
                : verifyObject(
                    appointmentObj,
                    "appointment_obj.patient_id",
                    null
                  ),
            });
          }
          // if (
          //   typeof onSetPatientAppointment === "function" &&
          //   !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
          // ) {
          //   onSetPatientAppointment(
          //     verifyObject(appointmentObj, "appointment_obj", null)
          //   );
          // }

          this.setState(
            {isLoading: false, startingConsultation: false},
            async () => {}
          );
        }
      );
    }
    // this._startAppointment(true);
    // }
    if (
      prev_appt_tab_key === "pending" &&
      appointmentObj?.appointment_obj &&
      Object.keys(appointmentObj && appointmentObj?.appointment_obj).length !==
        0 &&
      preConsultReview &&
      verifyObject(this.props, "appointmentObj.patient.gp_service.id", null)
    ) {
      // alert("in");

      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
      if (appointmentObj && !appointmentObj.inform_gp_request) {
        store.dispatch({type: "SET_IS_GP_INFORMED", payload: false});
        await this._submitReferralNote(
          verifyObject(appointmentObj, "appointment_obj.id"),
          verifyObject(appointmentObj, "appointment_obj.patient_id")
        );
        //done only for apt only inform gp req. is null

        let {onSetPatient} = this.props.onSetPatient;
        // let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
        let snomed_code = verifyObject(
          appointmentObj,
          "health_condition.health_condition.snomed_code",
          null
        )
          ? [
              {
                id: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code",
                      null
                    ).id
                  : null,
                code: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code.code",
                      null
                    )
                  : null,
                description: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code.description",
                      null
                    )
                  : null,
              },
            ]
          : [];
        let consentArray =
          process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
            ? [InformGPStaticArrayDEV[0], ...snomed_code] //0 index for patient sought yes
            : [InformGPStaticArrayLIVE[0], ...snomed_code];

        let managementPlan =
          "Patient made aware of the importance of good medical record sharing, Consent given";

        this.setState(
          {
            condition_links: verifyObject(
              appointmentObj,
              "health_condition.health_condition.condition_links",
              null
            )
              ? verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.condition_links",
                  null
                )
              : [],
            managementPlan: verifyObject(
              appointmentObj,
              "outcome.pre_consult_outcome.outcome",
              null
            )
              ? `${verifyObject(
                  appointmentObj,
                  "outcome.pre_consult_outcome.outcome",
                  null
                )}\n${managementPlan}\n`
              : managementPlan,
            history: verifyObject(
              appointmentObj,
              "health_condition.health_condition.name",
              null
            ),
            selectedSnomedCodesArray: consentArray,
            selectedCodesArray: consentArray,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            let {
              condition_links,
              managementPlan,
              history,
              selectedSnomedCodesArray,
              selectedCodesArray,
            } = this.state;

            let Inputs = {
              ...this.props.dynamicInputs,
              managementPlan,
              history,
              selectedSnomedCodesArray,
              selectedCodesArray,
              condition_links,
            };
            if (typeof onSetDynamicInput === "function") {
              onSetDynamicInput(Inputs);
            }
            if (typeof onSetPatient === "function") {
              onSetPatient({
                id: verifyObject(appointmentObj, "patient.id", null)
                  ? verifyObject(appointmentObj, "patient.id", null)
                  : verifyObject(
                      appointmentObj,
                      "appointment_obj.patient_id",
                      null
                    ),
              });
            }
            // if (
            //   typeof onSetPatientAppointment === "function" &&
            //   !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
            // ) {
            //   onSetPatientAppointment(appointmentObj);
            // }

            this.setState(
              {isLoading: false, startingConsultation: false},
              async () => {}
            );
          }
        );
      } else {
        let {onSetPatient} = this.props.onSetPatient;
        // let { onSetPatientAppointment } = this.props.onSetPatientAppointment;

        let snomed_code = verifyObject(
          appointmentObj,
          "health_condition.health_condition.snomed_code",
          null
        )
          ? [
              {
                id: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code",
                      null
                    ).id
                  : null,
                code: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code.code",
                      null
                    )
                  : null,
                description: verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.snomed_code",
                  null
                )
                  ? verifyObject(
                      appointmentObj,
                      "health_condition.health_condition.snomed_code.description",
                      null
                    )
                  : null,
              },
            ]
          : [];
        let consentArray = verifyObject(
          appointmentObj,
          "inform_gp_request.inform_gp_request.patient_sought"
        )
          ? process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "stage"
            ? [InformGPStaticArrayDEV[0], ...snomed_code] //0 index for patient sought yes
            : [InformGPStaticArrayLIVE[0], ...snomed_code]
          : process.env.REACT_APP_ENV === "dev" ||
            process.env.REACT_APP_ENV === "stage"
          ? [InformGPStaticArrayDEV[1], ...snomed_code] //1 index for patient sought no
          : [InformGPStaticArrayLIVE[1], ...snomed_code];
        let managementPlan =
          "Patient made aware of the importance of good medical record sharing, declined";
        if (
          verifyObject(
            appointmentObj,
            "inform_gp_request.inform_gp_request.patient_sought"
          )
        ) {
          // isConsentGiven = true;
          managementPlan =
            "Patient made aware of the importance of good medical record sharing, Consent given";
        }

        this.setState(
          {
            condition_links: verifyObject(
              appointmentObj,
              "health_condition.health_condition.condition_links",
              null
            )
              ? verifyObject(
                  appointmentObj,
                  "health_condition.health_condition.condition_links",
                  null
                )
              : [],
            managementPlan: verifyObject(
              appointmentObj,
              "outcome.pre_consult_outcome.outcome",
              null
            )
              ? `${verifyObject(
                  appointmentObj,
                  "outcome.pre_consult_outcome.outcome",
                  null
                )}\n${managementPlan}\n`
              : managementPlan,
            history: verifyObject(
              appointmentObj,
              "health_condition.health_condition.name",
              null
            ),
            selectedSnomedCodesArray: consentArray,
            selectedCodesArray: consentArray,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            let {condition_links} = this.state;

            let Inputs = {
              ...this.props.dynamicInputs,

              condition_links,
            };
            if (typeof onSetDynamicInput === "function") {
              onSetDynamicInput(Inputs);
            }
            if (typeof onSetPatient === "function") {
              onSetPatient({
                id: verifyObject(patientDetail, "user.id")
                  ? verifyObject(patientDetail, "user.id")
                  : verifyObject(appointmentObj, "patient.id", null),
              });
            }
            // if (
            //   typeof onSetPatientAppointment === "function" &&
            //   !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
            // ) {
            //   onSetPatientAppointment(appointmentObj);
            // }

            this.setState(
              {isLoading: false, startingConsultation: false},
              async () => {}
            );
          }
        );
        let condition = verifyObject(dynamicInputs, "regionsData", []);
        // let submittedQuestionnaire = verifyObject(
        //   dynamicInputs,
        //   "submitedQuestionnairesArray",
        //   []
        // );
        if (!dynamicHealthInputs) {
          let patient_id = appointmentObj?.patient_id
            ? appointmentObj?.patient_id
            : appointmentObj?.patient?.id
            ? appointmentObj?.patient?.id
            : this.props.patient_id;
          this.getPatientHealthAssessmentDetail(patient_id);
        }
        if (isEmpty(condition)) {
          this.fetchRegions();
        }
      }
      // let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
    }

    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: null,
      });
    } else {
    }
  }
  _startAppointment = async (is_gp_informed) => {
    let {patientDetail, appointmentObj, dynamicHealthInputs, dynamicInputs} =
      this.props;
    try {
      this.setState({isLoading: true, startingConsultation: true});

      let response = await startAppointment({
        patient_id: verifyObject(appointmentObj, "patient.id")
          ? verifyObject(appointmentObj, "patient.id")
          : verifyObject(appointmentObj, "patient_id")
          ? verifyObject(appointmentObj, "patient_id")
          : verifyObject(patientDetail, "user.id"),
        start_time: moment().format(),
        pre_consult_id: verifyObject(appointmentObj, "id"),
      });
      if (response?.status === 200) {
        // const urlParams = new URLSearchParams(window.location.search);
        // const fromPreConsultPopup = urlParams.get("fromPreConsultPopup");
        // const pre_consult_id = urlParams.get("pre_consult_id");
        // if (!fromPreConsultPopup && pre_consult_id) {
        //   this._getPreConsultSubmittedQuestionnaire();
        // }
      }

      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
      // let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
      let {onSetPatient} = this.props.onSetPatient;
      let {onSetPatientAppointment} = this.props.onSetPatientAppointment;

      let snomed_code = verifyObject(
        appointmentObj,
        "health_condition.health_condition.snomed_code",
        null
      )
        ? [
            {
              id: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code.id",
                null
              ),
              code: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code.code",
                null
              ),
              description: verifyObject(
                appointmentObj,
                "health_condition.health_condition.snomed_code.description",
                null
              ),
            },
          ]
        : [];
      let consentArray =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "stage"
          ? [InformGPStaticArrayDEV[0], ...snomed_code] //0 index for patient sought yes
          : [InformGPStaticArrayLIVE[0], ...snomed_code];
      let managementPlan =
        "Patient made aware of the importance of good medical record sharing, Consent given";

      this.setState(
        {
          condition_links: verifyObject(
            appointmentObj,
            "health_condition.health_condition.condition_links",
            null
          )
            ? verifyObject(
                appointmentObj,
                "health_condition.health_condition.condition_links",
                null
              )
            : [],
          managementPlan: verifyObject(
            appointmentObj,
            "outcome.pre_consult_outcome.outcome",
            null
          )
            ? `${verifyObject(
                appointmentObj,
                "outcome.pre_consult_outcome.outcome",
                null
              )}\n${managementPlan}`
            : managementPlan,
          history: verifyObject(
            appointmentObj,
            "health_condition.health_condition.name",
            ""
          ),
          selectedSnomedCodesArray: consentArray,
          selectedCodesArray: consentArray,
        },
        () => {
          let condition = verifyObject(dynamicInputs, "regionsData", []);
          if (!dynamicHealthInputs) {
            let patient_id = appointmentObj?.patient_id
              ? appointmentObj?.patient_id
              : appointmentObj?.patient?.id
              ? appointmentObj?.patient?.id
              : this.props.patient_id;
            this.getPatientHealthAssessmentDetail(patient_id);
          }
          if (isEmpty(condition)) {
            this.fetchRegions();
          }
          // let submittedQuestionnaire = verifyObject(
          //   dynamicInputs,
          //   "submitedQuestionnairesArray",
          //   []
          // );

          let {onSetDynamicInput} = this.props.dynamicTabAction;

          let {
            history,
            condition_links,
            selectedCodesArray,
            selectedSnomedCodesArray,
            managementPlan,
            reason_text,
          } = this.state;

          let Inputs = {
            ...this.props.dynamicInputs,
            history: history,
            managementPlan: managementPlan,
            condition_links,
            selectedSnomedCodesArray: selectedSnomedCodesArray,
            selectedCodesArray: selectedCodesArray,
            reason_text,
          };
          if (typeof onSetDynamicInput === "function") {
            onSetDynamicInput(Inputs);
          }
          if (typeof onSetPatient === "function") {
            onSetPatient({id: verifyObject(patientDetail, "user.id")});
          }
          if (
            typeof onSetPatientAppointment === "function" &&
            verifyObject(response, "data.data", null)
          ) {
            onSetPatientAppointment({
              ...appointmentObj,
              appointment_obj: verifyObject(response, "data.data", null),
            });
          }
          // if (!is_gp_informed) {
          this._submitReferralNote(
            verifyObject(response, "data.data.id"),
            verifyObject(response, "data.data.patient_id")
          );
          // }

          this.setState(
            {isLoading: false, startingConsultation: false},
            async () => {}
          );
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:668 ~ PreConsultDetailContainer ~ _startAppointment= ~ message:",
        message
      );
      this.setState({isLoading: false, startingConsultation: false});
      errorToast({content: message});
    }
  };
  _submitReferralNote = async (appointmentId, patient_id) => {
    const urlParams = new URLSearchParams(window.location.search);
    const pre_consult_id = urlParams.get("pre_consult_id");
    let {patientId, patientDetail, appointmentObj} = this.props;

    let surgeryParams = {};
    if (
      verifyObject(appointmentObj, "patient.gp_service.id") ||
      verifyObject(patientDetail, "gp_service.id")
    ) {
      surgeryParams = {
        surgery_id: verifyObject(appointmentObj, "patient.gp_service.id")
          ? verifyObject(appointmentObj, "patient.gp_service.id")
          : verifyObject(patientDetail, "gp_service.id"),
      };
    }
    let payload = {
      reason: "",
      patient_sought: true,
      appointment_id: appointmentId,
      patient_id: patient_id
        ? patient_id
        : patientId
        ? patientId
        : verifyObject(appointmentObj, "patient_id")
        ? verifyObject(appointmentObj, "patient_id")
        : verifyObject(patientDetail, "user.id"),
      ...surgeryParams,
      is_consent: true,
      pre_consult_id: pre_consult_id,
    };

    try {
      this.setState({changing: true});
      let response = await InformGPRequest({
        inform_request: {
          ...payload,
        },
        from_pre_consultation: true,
      });
      this.setState({changing: false}, () => {
        let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
        let {onSetPatient} = this.props.onSetPatient;

        if (typeof onSetPatient === "function") {
          onSetPatient({id: verifyObject(patientDetail, "user.id")});
        }
        if (
          typeof onSetPatientAppointment === "function" &&
          !isEmpty(verifyObject(appointmentObj, "appointment_obj"))
        ) {
          onSetPatientAppointment(
            verifyObject(appointmentObj, "appointment_obj")
          );
        }
      });

      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: true,
      });

      successToast({content: verifyObject(response, "data.message", "")});

      // localStorage.setItem("is_gp_informed", true);
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:713 ~ PreConsultDetailContainer ~ _submitReferralNote= ~ message:",
        message
      );
      await this.setState({changing: false});
      errorToast({content: message});
    }
  };
  _getPreConsultSubmittedQuestionnaire = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const pre_consult_id = urlParams.get("pre_consult_id");
    // const consentShared = urlParams.get("consentShared");

    // const fromPreConsultPopup = urlParams.get("fromPreConsultPopup");
    this.setState(
      {
        fetchingSubmittedQuestionnaire: true,
      },
      async () => {
        try {
          let response = await fetchSubmittedPreConsultations({
            pre_consult_id: pre_consult_id,
          });
          if (response?.data?.data && response.status === 200) {
            this.setState(
              {
                submitedQuestionnairesArray: response?.data?.data,
                fetchingSubmittedQuestionnaire: false,
                subimittedQuestionnaireAPICount:
                  this.state.subimittedQuestionnaireAPICount + 1,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {submitedQuestionnairesArray} = this.state;
                if (submitedQuestionnairesArray?.length === 0) {
                  //  submitedQuestionnairesArray = [];
                  errorToast({
                    content: "Submitted questionnaire not available",
                  });
                }

                console.log(
                  "submitedQuestionnairesArray",
                  submitedQuestionnairesArray
                );
                // let preConsultNoteParams = {};
                // if (consentShared) {
                //   preConsultNoteParams = {
                //     pre_consult_note:
                //       this.props.dynamicInputs?.pre_consult_note +
                //       `\n\n\n` +
                //       "Safety netting provided to patient",
                //   };
                // }

                let Inputs = {
                  ...this.props.dynamicInputs,
                  // ...preConsultNoteParams,
                  submitedQuestionnairesArray,
                };
                if (typeof onSetDynamicInput === "function") {
                  onSetDynamicInput(Inputs);
                }
              }
            );
          }
        } catch (e) {
          let {message} = getErrorObject(e);
          console.log(
            "🚀 ~ file: PreConsultDetailContainer.js:748 ~ PreConsultDetailContainer ~ _getPreConsultSubmittedQuestionnaire= ~ message:",
            message
          );
          this.setState({
            fetchingSubmittedQuestionnaire: false,
          });
          errorToast({
            content: message,
          });
        }
      }
    );
  };
  getPatientHealthAssessmentDetail = async (patient_id) => {
    try {
      this.setState({isLoadingHealthAssessment: true});
      let response = await getPatientHealthAssessmentDetailApi(patient_id);
      let data = verifyObject(response, "data.data", []);
      // verifyObject(response, "data.data", []).length > 0 &&
      //   verifyObject(response, "data.data", []).map((item, index) => {
      //     return
      this.setState(
        {
          pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
          heightObject: this.getObjectFromData(data, "height"),
          weightObject: this.getObjectFromData(data, "weight"),
          body_temperatureObject: this.getObjectFromData(
            data,
            "body_temperature"
          ),
          blood_oxygen_saturationObject: this.getObjectFromData(
            data,
            "blood_oxygen_saturation"
          ),
          systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
          diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
          bmiObject: this.getObjectFromData(data, "bmi"),
        },
        async () => {
          let {
            pulseObject,
            heightObject,
            weightObject,
            blood_oxygen_saturationObject,
            body_temperatureObject,
            bmiObject,
            systolic_bpObject,
            diastolic_bpObject,
          } = this.state;
          let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
          if (typeof onSetDynamicHealthInput === "function") {
            let Inputs = {
              ...this.props.dynamicHealthInputs,
              pulseObject,
              heightObject,
              weightObject,
              blood_oxygen_saturationObject,
              body_temperatureObject,
              bmiObject,
              systolic_bpObject,
              diastolic_bpObject,
            };
            await onSetDynamicHealthInput(Inputs);
          }
        }
      );

      await this.setState({isLoadingHealthAssessment: false});
    } catch (error) {
      // const {message} = getErrorObject(error);
      await this.setState({isLoadingHealthAssessment: false});
      // errorToast({content: message});
    }
  };
  getObjectFromData = (data, value) => {
    let found;
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.find((item, index) => {
        if (item.name === value) return (found = item);
      });
    }

    return found;
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.managementPlan !== this.state.managementPlan) {
      if (this.scollRef && this.scollRef.current) {
        if (this.scollRef.current) {
          this.scollRef.current.focus();
        }
      }
    }
    if (
      prevState.height !== this.state.height ||
      prevState.weight !== this.state.weight
    ) {
      this.setState(
        {
          bmi: this.calculateBMI(this.state.height, this.state.weight),
        },
        () => {
          let {height, weight} = this.state;
          if (height && height !== "" && weight && weight !== "") {
            this.setState({bmi: this.calculateBMI(height, weight)}, () => {
              this.setState(
                (state) => {
                  let stateItems = state.healthStateArray;
                  stateItems[8]["value"] = this.state.bmi;
                  return {
                    healthStateArray: stateItems,
                  };
                },
                () => {
                  let obj = this.state.healthStateArray.find((i, index) => {
                    if (i.key === "bmi") {
                      return i;
                    }
                  });
                  let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
                  if (typeof onSetDynamicHealthInput === "function") {
                    let Inputs = {
                      ...this.props.dynamicHealthInputs,

                      bmi: this.state.bmi,
                    };
                    onSetDynamicHealthInput(Inputs);
                  }
                  setTimeout(() => {
                    this._handleHealthStateArray(obj.id, obj.value);
                  }, 1200);
                }
              );
            });
          }
        }
      );
    }

    let {
      dynamicInputs,
      prev_appt_tab_key,
      dynamicHealthInputs,
      appointmentObj,
      is_gp_informed,
    } = this.props;

    let consentArray = verifyObject(dynamicInputs, "isConsentGiven", []);

    if (
      this.state.selectedCodesArray &&
      consentArray.length !== 0 &&
      this.state.selectedCodesArray.length === 0
    ) {
      this.setState(
        {
          ...dynamicInputs,
          ...dynamicHealthInputs,
          selectedCodesArray:
            dynamicInputs &&
            dynamicInputs.selectedSnomedCodesArray !== null &&
            dynamicInputs.isConsentGiven &&
            isArray(dynamicInputs.isConsentGiven)
              ? isArray(dynamicInputs.selectedSnomedCodesArray)
                ? [...dynamicInputs.selectedSnomedCodesArray, ...consentArray]
                : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
              : [...consentArray],
          tabKey:
            this.props.consultation_tab_key !== null
              ? this.props.consultation_tab_key
              : "preencounters",
        },
        () => {
          this.setState({
            selectedSnomedCodesArray: uniq([
              ...(this.state.selectedSnomedCodesArray || []),
              ...(this.state.selectedCodesArray || []),
            ]),
          });
        }
      );
    }
    const urlParams = new URLSearchParams(window.location.search);
    const fromPreConsultPopup = urlParams.get("fromPreConsultPopup");
    const preConsultReview = urlParams.get("preConsultReview");

    console.log("URL form consult 1", fromPreConsultPopup);
    if (fromPreConsultPopup === "true") {
      let preConsultTextarea = document.querySelector("#pre_consult_note");
      if (preConsultTextarea) {
        console.log("preConsultTextarea", preConsultTextarea);
        preConsultTextarea.style.height =
          preConsultTextarea.scrollHeight + "px";
      }
    }
  }

  componentWillUnmount() {
    store.dispatch({type: "CONSULTATION_TAB_KEY", payload: "preencounters"});
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          // snomedCodes: [],
          snomedCodeId: value,
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = axios.CancelToken.source();
          this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              await this._getSnomedCodes(false, value, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  _getSnomedCodes = async (isLoadMore, search, cancelToken) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode(
        {
          page: this.state.page,
          search: this.state.search,
        },
        cancelToken
      );
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...(this.state.snomedCodes || []), ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      if (error !== "REQ_CANCELLED") {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      } else {
        this.setState({searching: false});
        setTimeout(() => {
          this.setState({searching: true});
        }, [1000]);
      }
    }
  };
  _handleCustomCodeAdd(value) {
    // let {snomedCodeText} = this.state;

    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState(
      {
        snomedCodeText: "",
        snomedCodeId: "",

        // snomedCodeText: "",
        selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
      },
      () => {
        this.setState(
          {
            selectedSnomedCodesArray: uniq([
              ...(this.state.selectedSnomedCodesArray || []),
              ...(this.state.selectedCodesArray || []),
            ]),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            let {
              history,
              examination,
              managementPlan,
              pre_consult_note,
              barcodeNumber,
              isShowSubmit,
              // selectedSnomedCodesArray,
            } = this.state;

            let Inputs = {
              ...this.props.dynamicInputs,

              history: history,
              examination: examination,
              managementPlan: managementPlan,
              pre_consult_note,
              barcodeNumber: barcodeNumber,
              selectedSnomedCodesArray: this.state.selectedCodesArray,
              selectedCodesArray: this.state.selectedCodesArray,
              isShowSubmit: isShowSubmit,
            };
            onSetDynamicInput(Inputs);
          }
        );
      }
    );
  }
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = axios.CancelToken.source();
        this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            await this._getSnomedCodes(false, null, cancelToken);
          }
        );
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
    // if (this.componentInitial) {
    //   onSetSidebarKey(["APPT1"]);
    // }
  };

  // async _getPatientConultationDetails() {
  //   let {appointmentId} = this.props;
  //   try {
  //     await this.setState({isLoading: true});
  //     let response = await getConsultationDetailAPI({
  //       appointment_id: appointmentId,
  //     });
  //     let consultationDetail = verifyObject(response, "data.data", null);
  //     store.dispatch({
  //       type: SET_CONSULTATION_OBJ,
  //       payload: consultationDetail,
  //     });
  //     await this.setState({
  //       consultationDetail: consultationDetail,
  //       selectedSnomedCodesArray:
  //         verifyObject(consultationDetail, "snomed_codes", []).length !== 0
  //           ? uniqBy(consultationDetail.snomed_codes, "description")
  //           : [],
  //       isLoading: false,
  //     });
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     this.setState({isLoading: false});
  //     errorToast({content: message});
  //   }
  // }

  _handleTextChange(e, controlName) {
    let errors = null;
    let name = null;
    if (controlName) {
      name = controlName;
    } else {
      name = e.target.name;
    }
    // let value = e.target.value;
    console.log("TEXT CHANGE --->", e);
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState(
      {
        [name]: controlName ? e : e.target.value,
        errors: errors,
      },
      () => {
        if (
          isEmpty(this.state.history) ||
          isEmpty(this.state.examination) ||
          isEmpty(this.state.managementPlan)
        ) {
          this.setState({isShowSubmit: false}, () => {
            this._SaveComponentStateToRedux();
          });
        } else {
          this._SaveComponentStateToRedux();
        }
      }
    );
  }

  _SaveComponentStateToRedux = () => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {
        history,
        examination,
        managementPlan,
        pre_consult_note,
        barcodeNumber,
        selectedSnomedCodesArray,
        isShowSubmit,
        isConsentGiven,
        reason_text,
        selectedCheckbox,
        checkboxStateArray,
      } = this.state;
      let inputObj = {};
      // eslint-disable-next-line no-unused-vars
      let inputs = checkboxStateArray.map((item) => {
        if (this.state[item.id]) {
          // {`${item.id}`:this.state[item.id]}
          inputObj = {
            ...inputObj,
            [item.id]: this.state[item.id],
          };
        }
      });

      let Inputs = {
        ...this.props.dynamicInputs,
        ...inputObj,
        history: history,
        examination: examination,
        managementPlan: managementPlan,
        pre_consult_note,
        barcodeNumber: barcodeNumber,
        selectedSnomedCodesArray: selectedSnomedCodesArray,
        selectedCodesArray: selectedSnomedCodesArray,
        isShowSubmit: isShowSubmit,
        isConsentGiven: isConsentGiven,
        reason_text: reason_text,
        selectedCheckbox: selectedCheckbox,
      };
      onSetDynamicInput(Inputs);
    }
  };

  componentWillReceiveProps(nextProps) {
    let {dynamicInputs, dynamicHealthInputs} = nextProps;
    this.setState({
      ...dynamicInputs,
      ...dynamicHealthInputs,
    });
  }

  async _handleSubmit(isSubmitForReview) {
    this.setState({visible: true, isSubmitForReview});
  }

  async _handleModalSubmission() {
    let {
      // treatment,
      // followUp,
      // referral,
      comment,
      history,
      examination,
      managementPlan,
      pre_consult_note,
      barcodeNumber,
      presriptionPlan,
      reason_text,
      selectedCodesArray,
      comment_other,
      enableOtherField,
    } = this.state;
    let consentArray = verifyObject(
      this.props.dynamicInputs,
      "isConsentGiven",
      []
    );
    let toBeValidateObj2 = {
      // treatment: treatment,
      // followUp: followUp,
      // referral: referral,
      comment: enableOtherField ? comment_other : comment,
      visible: true,
    };
    const errors = ValidateInput(toBeValidateObj2, "fromPreConsult");

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {
        appointmentId,
        patientId,
        pulse_per_minute,
        systolic_bp,
        diastolic_bp,
        body_temperature,
        blood_oxygen_saturation,
        height,
        weight,
        bmi,
        respiratory_rate,
        appointmentObj,
      } = this.props;
      let {selectedSnomedCodesArray} = this.state;
      console.log("updateAppointmentReasonApi", appointmentId, appointmentObj);

      let payload = {
        consultation: {
          appointment_id: appointmentId,
          pre_consult_id: verifyObject(appointmentObj, "pre_consult_id"),
          pre_consult_note: pre_consult_note,
          pre_consult_tag: "pharmacy_first",
          history: history,
          examination: examination,
          management_plan: managementPlan,
          prescription_plan: presriptionPlan,
          barcode: barcodeNumber,
          patient_id: patientId,
          respiratory_rate: respiratory_rate,
          outcome: {
            // treatment: treatment,
            // follow_up: followUp,
            // referral: referral,
            comment: enableOtherField ? comment_other : comment,
          },
        },
        snomed_codes:
          selectedSnomedCodesArray && selectedSnomedCodesArray.length !== 0
            ? this.removeDuplicates([
                ...(selectedSnomedCodesArray || []),
                ...consentArray,
              ])
            : this.removeDuplicates([
                ...(selectedCodesArray || []),
                ...consentArray,
              ]),
      };
      let health_summaryPayload = {
        health_summary: {},
      };
      if (pulse_per_minute && pulse_per_minute !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            pulse_per_minute,
          },
        };
      }

      if (systolic_bp && systolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            systolic_bp,
          },
        };
      }
      if (diastolic_bp && diastolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            diastolic_bp,
          },
        };
      }
      if (blood_oxygen_saturation && blood_oxygen_saturation !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            blood_oxygen_saturation,
          },
        };
      }
      if (body_temperature && body_temperature !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            body_temperature,
          },
        };
      }
      if (height && height !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            height,
          },
        };
      }
      if (weight && weight !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            weight,
          },
        };
      }

      if (respiratory_rate && respiratory_rate !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            respiratory_rate,
          },
        };
      }
      // if (bmi && bmi !== "") {
      //   health_summaryPayload = {
      //     health_summary: {
      //       ...health_summaryPayload.health_summary,
      //       bmi,
      //     },
      //   };
      // }

      let healthPayload = {
        appointment_id: appointmentId,
        ...health_summaryPayload,
      };
      let reasonP = {};
      if (
        (this.props.dynamicInputs && this.props.dynamicInputs.reason_text) ||
        reason_text
      ) {
        reasonP = {
          reason: this.props.dynamicInputs.reason_text
            ? this.props.dynamicInputs.reason_text &&
              isArray(this.props.dynamicInputs.reason_text)
              ? this.props.reason_text.map((item) => item.label).join(",")
              : this.props.dynamicInputs.reason_text
            : reason_text,
        };
      }
      let payloadReason = {
        id: appointmentId,
        ...reasonP,
      };
      console.log(
        "🚀 ~ PreConsultDetailContainer ~ _handleModalSubmission ~ payloadReason:",
        payloadReason
      );
      let historyParams = {
        history: history,
      };
      if (!isEmpty(this.state.historyForPGQ)) {
        historyParams = {
          history: !isEmpty(history)
            ? history + "\n" + this.state.historyForPGQ
            : this.state.historyForPGQ,
        };
      }
      try {
        this.setState({addingDetail: true, changing: true});
        if (reason_text && !isEmpty(reason_text)) {
          this.setState({addingDetail: true, changing: true});

          let reasonResponse = await updateAppointmentReasonApi(payloadReason);

          successToast({
            content: verifyObject(reasonResponse, "data.message", "Success"),
          });
          if (
            healthPayload &&
            Object.keys(healthPayload.health_summary).length > 0
          ) {
            let healthResponse = await addHealthAssessmentPatientAPI(
              healthPayload
            );
            successToast({
              content: verifyObject(healthResponse, "data.message", "Success"),
            });
          }
          payload = {
            consultation: {
              appointment_id: appointmentId,
              ...historyParams,
              examination: isArray(examination)
                ? examination.join(",")
                : examination,
              // examination !== "" && examination.trim().length !== 0
              //   ? `${examination + "\n" + append_text.join("\n")}\n`
              //   : `${append_text.join("\n")}`,
              pre_consult_id: verifyObject(appointmentObj, "pre_consult_id"),
              pre_consult_note: pre_consult_note,
              pre_consult_tag: "pharmacy_first",
              management_plan: managementPlan,
              prescription_plan: presriptionPlan,
              barcode: barcodeNumber,
              patient_id: patientId,
              respiratory_rate: respiratory_rate,
              outcome: {
                // treatment: treatment,
                // follow_up: followUp,
                // referral: referral,
                comment: enableOtherField ? comment_other : comment,
              },
            },
            snomed_codes: selectedSnomedCodesArray,
          };
          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
          }
          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        } else if (
          healthPayload &&
          Object.keys(healthPayload.health_summary).length > 0
        ) {
          this.setState({addingDetail: true, changing: true});

          let healthResponse = await addHealthAssessmentPatientAPI(
            healthPayload
          );
          successToast({
            content: verifyObject(healthResponse, "data.message", "Success"),
          });
          //# TODO: append text into examination here

          payload = {
            consultation: {
              appointment_id: appointmentId,
              ...historyParams,
              examination: isArray(examination)
                ? examination.join(",")
                : examination,

              // examination !== "" && examination.trim().length !== 0
              //   ? `${examination + "\n" + append_text.join("\n")}\n`
              //   : `${append_text.join("\n")}`,
              pre_consult_id: verifyObject(appointmentObj, "pre_consult_id"),
              pre_consult_note: pre_consult_note,
              pre_consult_tag: "pharmacy_first",
              management_plan: managementPlan,
              prescription_plan: presriptionPlan,
              barcode: barcodeNumber,
              patient_id: patientId,
              respiratory_rate: respiratory_rate,
              outcome: {
                // treatment: treatment,
                // follow_up: followUp,
                // referral: referral,
                comment: enableOtherField ? comment_other : comment,
              },
            },
            snomed_codes: selectedSnomedCodesArray,
          };
          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
            store.dispatch({
              type: "SET_SYMPTOMP_FETCHED_LINKS",
              payload: null,
            });
          }
        } else {
          this.setState({addingDetail: true, changing: true});

          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          if (typeof this.props.onSetDynamicHealthInput === "function") {
            await this.props.onSetDynamicHealthInput(null);
          }
          store.dispatch({
            type: "SET_SYMPTOMP_FETCHED_LINKS",
            payload: null,
          });
        }
        this.handleClearCheckboxValue();

        let {navigateTo} = this.props.navigationActions;

        if (
          this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
          this.props.userRole === ROLE_SUPERINTENDENT_PHARMACIST ||
          this.props.userRole === ROLE_DOCTOR
        ) {
          store.dispatch({
            type: types.ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER,
            payload: true,
          });
          if (typeof this.handlePrescriberCallEnd === "function") {
            this.handlePrescriberCallEnd("finish");
          }
        } else {
          if (typeof navigateTo === "function") {
            await navigateTo(appRoutesConst.preconsultationReview);
          }
        }
        this.setState({
          addingDetail: false,
          changing: false,
        });
        this._clearData();
        // this._clearComponents();
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({addingDetail: false, changing: false});
        errorToast({content: message});
      }
    }
  }
  handlePrescriberCallEnd = async (status) => {
    let {appointmentId} = this.props;

    let formData = new FormData();
    formData.set("from_pre_consultation", true);

    let {setOfflineCallStatus} = this.props.offlineCallActions;
    if (typeof setOfflineCallStatus === "function") {
      setOfflineCallStatus(status);
    }
    await setOfflineCallStatus(status);
    try {
      await this.setState({
        isCallEnding: true,
      });
      let response = await endPrescriberAppointment(formData, appointmentId);
      if (response.data && response.data.message) {
        store.dispatch({
          type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
          payload: null,
        });
        store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
        store.dispatch({
          type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
          payload: null,
        });
        store.dispatch({
          type: "IS_CLICKED_START_CALL",
          payload: false,
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
        if (typeof onSetDynamicHealthInput === "function") {
          onSetDynamicHealthInput(null);
        }

        let {navigateTo} = this.props.navigationActions;
        if (typeof navigateTo === "function") {
          await navigateTo(appRoutesConst.preconsultationReview);
        }
        this.setState({prescriberCallEnded: true, isCallEnding: false});

        store.dispatch({
          type: "ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER",
          payload: false,
        });
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:1581 ~ PreConsultDetailContainer ~ handlePrescriberCallEnd= ~ message:",
        message
      );
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  };
  handleClearCheckboxValue = () => {
    this.setState((state) => {
      let stateItems = state.checkboxStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        checkboxStateArray: stateItems,
      };
    });
  };
  handleClearHealthboxValue = () => {
    this.setState((state) => {
      let stateItems = state.healthStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        healthStateArray: stateItems,
      };
    });
  };

  _clearData() {
    this.setState({
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      pre_consult_note: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      addingDetail: false,
      updatingDetail: false,
      visible: false,
    });
    store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: null,
    });
    let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicHealthInput === "function") {
      onSetDynamicHealthInput(null);
    }
  }

  async _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d?.id === value?.id);
    let ids =
      this.state.selectedCodesArray.length > 0 &&
      map(this.state.selectedCodesArray, "id");

    if (found) {
      if (ids === false) {
        this.setState(
          {
            snomedCodeText: "",
            snomedCodeId: "",
            // snomedCodes: [],
            newselectedCodesArray: this.state.newselectedCodesArray.concat({
              id: found.id,
              code: found.value,
              description: found.label,
            }),
            // snomedCodeId: null,
          },
          () => {
            this.setState(
              {
                snomedCodes: [],

                selectedCodesArray: uniq([
                  ...(this.state.selectedCodesArray || []),
                  ...(this.state.newselectedCodesArray || []),
                ]),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {
                  history,
                  examination,
                  managementPlan,
                  pre_consult_note,
                  barcodeNumber,
                  isShowSubmit,
                  // selectedSnomedCodesArray,
                } = this.state;
                let Inputs = {
                  ...this.props.dynamicInputs,

                  history: history,
                  examination: examination,
                  managementPlan: managementPlan,
                  pre_consult_note,
                  barcodeNumber: barcodeNumber,
                  newselectedCodesArray: this.state.newselectedCodesArray,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                  selectedCodesArray: this.state.selectedCodesArray,
                  isShowSubmit: isShowSubmit,
                };

                onSetDynamicInput(Inputs);
              }
            );
          }
        );
      } else {
        this.setState(
          {
            snomedCodeText: "",
            // snomedCodes: [],
            snomedCodeId: "",

            // snomedCodes: this.state.snomedCodes,
            newselectedCodesArray: this.state.newselectedCodesArray.concat({
              id: found.id,
              code: found.value,
              description: found.label,
            }),
          },
          () => {
            this.setState(
              {
                showCodeModal: false,
                selectedCodesArray: uniq([
                  ...(this.state.selectedCodesArray || []),
                  ...(this.state.newselectedCodesArray || []),
                ]),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {
                  history,
                  examination,
                  managementPlan,
                  pre_consult_note,
                  barcodeNumber,
                  isShowSubmit,
                  // selectedSnomedCodesArray,
                } = this.state;

                let Inputs = {
                  ...this.props.dynamicInputs,

                  history: history,
                  examination: examination,
                  managementPlan: managementPlan,
                  pre_consult_note: pre_consult_note,
                  barcodeNumber: barcodeNumber,
                  newselectedCodesArray: this.state.newselectedCodesArray,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                  selectedCodesArray: this.state.selectedCodesArray,
                  isShowSubmit: isShowSubmit,
                };

                onSetDynamicInput(Inputs);
              }
            );
          }
        );
      }
    }
  }
  _hideModal() {
    this.setState({
      visible: false,
      errors: null,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      editOutcome: false,
      changing: false,
    });
  }
  _showCodeModal() {
    this.setState({showCodeModal: true});
  }
  _hideCodeModal() {
    this.setState({showCodeModal: false});
  }
  _removeCode = (record) => {
    if (this.state.newselectedCodesArray.length === 0) {
    } else {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.filter(
            (d) => d.id !== record.id || d.description !== record.description
          ),
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
              selectedSnomedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    }
  };

  _handleEdit(state) {
    this.setState({[state]: !this.state[state]}, () => {
      if (this.state.editOutcome) {
        this.setState({visible: true});
      }
    });
  }

  async _handleUpdateoutcome() {
    let {
      // treatment,
      // followUp,
      // referral,
      comment,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
      comment_other,
      enableOtherField,
    } = this.state;
    let submittedReviewParamas = {};

    if (this.state.isSubmitForReview) {
      submittedReviewParamas = {
        submitted_to_review: true,
      };
    }

    let payload = {
      consultation: {
        outcome: {
          // treatment: treatment,
          // follow_up: followUp,
          // referral: referral,
          comment: enableOtherField ? comment_other : comment,
        },
        ...submittedReviewParamas,
      },
      consultation_id: consultation_id,
    };

    try {
      this.setState({changing: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Outcome.`,
        });
        this.setState({
          changing: false,
          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:1896 ~ PreConsultDetailContainer ~ _handleUpdateoutcome ~ message:",
        message
      );
      this.setState({updatingDetail: false, change: false});
      errorToast({content: message});
    }
  }
  async _handleSnomedCodeUpdate() {
    let {
      selectedCodesArray,
      selectedSnomedCodesArray,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      snomed_codes: uniq([
        ...(selectedSnomedCodesArray || []),
        ...(selectedCodesArray || []),
      ]),
      consultation_id: consultation_id,
    };
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Snomed Codes.`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          selectedSnomedCodesArray:
            verifyObject(response, "data.data.snomed_codes", []).length !== 0
              ? uniqBy(response.data.data.snomed_codes, "description")
              : [],
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:1935 ~ PreConsultDetailContainer ~ _handleSnomedCodeUpdate ~ message:",
        message
      );
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];
        onSetSidebarKey(payload);
      }
      // if (key === null) {
      //   this.setState({ isShowSubmit: true });
      // }
    }
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };

  async _handleUpdate(param, state) {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      consultation: {
        [param]: this.state[param],
      },
      consultation_id: consultation_id,
    };

    if (param === "barcode") {
      payload = {
        consultation: {
          barcode: this.state.barcodeNumber,
        },
        consultation_id: consultation_id,
      };
    }

    if (param === "managementPlan") {
      payload = {
        consultation: {
          management_plan: this.state.managementPlan,
        },
        consultation_id: consultation_id,
      };
    }
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated ${param}`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:2006 ~ PreConsultDetailContainer ~ _handleUpdate ~ message:",
        message
      );
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  handleAddPrescription = () => {
    return store.dispatch({
      type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
      payload: true,
    });
  };
  handleSkip = () => {
    this.setState({isShowSubmit: true, showAddSkipbutton: false}, () => {
      this._SaveComponentStateToRedux();
    });
  };
  handleHealthTextChange = (e, name) => {
    this.setState({[name]: e}, () => {
      let {
        pulse_per_minute,
        height,
        weight,
        blood_oxygen_saturation,
        body_temperature,
        systolic_bp,
        diastolic_bp,
        respiratory_rate,
        bmi,
      } = this.state;
      if (
        height &&
        height !== "" &&
        weight &&
        weight !== "" &&
        (!bmi || bmi === "")
      ) {
        this.setState({bmi: this.calculateBMI(height, weight)}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = this.state.bmi;
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                this._handleHealthStateArray(obj.id, obj.value);
              }, 1200);
            }
          );
        });
      } else if (
        (!height || height === "" || !weight || weight === "") &&
        bmi
      ) {
        this.setState({bmi: null}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = "";
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                // this.handleBlurHA(obj, obj.id);
                // if (item.value) {

                this._handleRemoveHealthStateArray(obj.id, 8);
              }, 1200);
            }
          );
        });
      } else {
        let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
        if (typeof onSetDynamicHealthInput === "function") {
          let Inputs = {
            ...this.props.dynamicHealthInputs,

            pulse_per_minute,
            height,
            weight,
            blood_oxygen_saturation,
            body_temperature,
            bmi: this.state.bmi,
            systolic_bp,
            diastolic_bp,
            respiratory_rate,
          };
          onSetDynamicHealthInput(Inputs);
        }
      }
    });
  };
  calculateBMI = (height, weight) => {
    // Convert height to meters
    const heightInMeters = height / 100;

    // Calculate BMI
    const bmiValue = weight / (heightInMeters * heightInMeters);

    // Update state with the calculated BMI
    return bmiValue.toFixed(2);
  };

  renderInputColor = (value, field) => {
    if (value && !isEmpty(value.toString())) {
      // if (_bp < 60) return "#0872FB"; //!LOW
      // if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
      // if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
      // if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
      // if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
    } //!SEVERE

    switch (field) {
      case "diastolic_bp":
        if (value && value < 60) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 60 && value < 80) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 80 && value < 90) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 90 && value < 100) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 100 && value < 120) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 120) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "systolic_bp":
        if (value && value >= 70 && value < 90) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 90 && value < 120) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 120 && value < 140) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 140 && value < 160) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 160 && value < 180) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 180) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }

      case "pulse_per_minute":
        if (value && value >= 40 && value < 59) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 60 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "body_temperature":
        if (value && value < 36) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 36.1 && value < 37.2) {
          return "bg-success text-white custom-input-number";
        } else if (value > 37.2) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "blood_oxygen_saturation":
        if (value && value >= 1 && value <= 95) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 96 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      default:
        return "custom-input-number";
    }
  };
  updateHeight = () => {
    let activePane = document.querySelector(".ant-tabs-tabpane-active");
    let sectionSCroll = activePane.querySelector(".sectionsubscroll");
    let posTop = activePane.getBoundingClientRect().top;
    setTimeout(() => {
      if (sectionSCroll)
        sectionSCroll.style.height = `calc(100vh - ${posTop + 0}px)`;
    }, 500);
  };
  handleTabChange = (key) => {
    return this.setState({tabKey: key}, () => {
      store.dispatch({type: "CONSULTATION_TAB_KEY", payload: key});
      setTimeout(() => {
        this.updateHeight();
      }, 500);
    });
  };

  async _handleSymptomSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          searchSymptom: value,
        },
        async () => {
          await this.onGetSymptoms(value);
        }
      );
    } else {
    }
  }
  onGetSymptoms = async (value) => {
    try {
      this.setState({searchingSymptom: true});

      let response = await fetchSymptomsApi({
        search: this.state.searchSymptom,
      });

      let newSymptomsData = verifyObject(response, "data.data", []);
      if (response.data.data) {
        this.setState(
          {
            symptomsData: newSymptomsData.map((item) => {
              return {value: item.id, label: item.name};
            }),
            searchingSymptom: false,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                symptomsData: this.state.symptomsData,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }
      this.setState({searchingSymptom: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:2184 ~ PreConsultDetailContainer ~ onGetSymptoms= ~ message:",
        message
      );

      await this.setState({searchingSymptom: false});
      errorToast({content: message});
    }
  };
  handleGetDiagnosis = async () => {
    let {appointmentObj, patientDetail} = this.props;
    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        let payload = {
          patient_id: verifyObject(appointmentObj, "patient.id")
            ? verifyObject(appointmentObj, "patient.id")
            : verifyObject(appointmentObj, "patient_id")
            ? verifyObject(appointmentObj, "patient_id")
            : verifyObject(patientDetail, "user.id"),
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: "",
          region_id: this.state.region_id, //default region id western europe is 1
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: (this.state.selectedCodesArray || []).filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2?.code === +item1?.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }

              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          // next();
          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: this.state.reason_text,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,
                    // region_id: this.state.region_id,
                    // recentTravel: this.state.recentTravel,
                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          console.log(
            "🚀 ~ file: PreConsultDetailContainer.js:2302 ~ PreConsultDetailContainer ~ handleGetDiagnosis= ~ message:",
            message
          );
          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleGetDiagnosisFemale = async () => {
    let {appointmentObj, patientDetail} = this.props;

    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        // setFindedName(findName.map((item) => item.name).join(","));

        let payload = {
          patient_id: verifyObject(appointmentObj, "patient.id")
            ? verifyObject(appointmentObj, "patient.id")
            : verifyObject(appointmentObj, "patient_id")
            ? verifyObject(appointmentObj, "patient_id")
            : verifyObject(patientDetail, "user.id"),
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: this.state.is_pregnant,
          region_id: this.state.region_id,
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: this.state.selectedCodesArray.filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2?.code === +item1?.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }
              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: findName.map((item) => item.name).join(","),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,

                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
              getDiagnosisModal: false,
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          console.log(
            "🚀 ~ file: PreConsultDetailContainer.js:2421 ~ PreConsultDetailContainer ~ handleGetDiagnosisFemale= ~ message:",
            message
          );

          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleClear = () => {
    let reasonStringArray = (this.state.reason_text || []).map(
      (item) => item.label || ""
    );
    let removeHistoryStringArray = this.state.history
      .split("\n")
      .filter((item1) => {
        return !reasonStringArray.some((item2) => item2 === item1);
      });

    return this.setState(
      {
        reason_text: [],
        diagnosisData: {},
        history: removeHistoryStringArray.join("\n"),
        region_id: 1,
        recentTravel: false,
        selectedCodesArray: this.state.selectedCodesArray.filter((item1) => {
          return ![
            ...(this.state.symptompSnomedCode || []),
            ...(process.env.REACT_APP === "prod"
              ? getLivePerformaQuestionnaire(this.props.current_corporate)
              : DP_QUESTIONNAIRE),
          ].some(
            (item2) =>
              +item2.id === +item1.id || +item2.snomed_code_id === +item1.id
          );
        }),
        symptompSnomedCode: [],
      },
      () => {
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            history: this.state.history,
            reason_text_string: "",
            recentTravel: this.state.recentTravel,
            region_id: this.state.region_id,
            diagnosisData: this.state.diagnosisData,
            selectedCodesArray: this.state.selectedCodesArray,
            selectedSnomedCodesArray: this.state.selectedCodesArray,
            symptompSnomedCode: this.state.symptompSnomedCode,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  customAbbrivationChange = (e) => {
    this.setState(
      {
        isCustomAbbrevation: true,
        reason_text: [
          ...this.state.reason_text,
          {
            value: e,
            label: e,
          },
        ],
        symptomsData: [
          ...this.state.symptomsData,
          {
            value: e,
            label: e,
          },
        ],
      },
      () => {
        this.setState({custom_text: ""});
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            reason_text_string: "",
            symptomsData: this.state.symptomsData,
            diagnosisData: this.state.diagnosisData,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  // showPresentingInfoModal = () => {
  //   this.setState({ getPresentingInfoModal: true });
  // };
  showpresentingModal = () => {
    this.setState({getPresentingComModal: true});
  };
  handleOk = () => {
    this.setState({getPresentingComModal: false});
  };
  handleCancel = () => {
    this.setState({getPresentingComModal: false});
    // this.setState({ getPresentingInfoModal: false });
  };

  handleButtonClick = () => {
    this.setState(
      (prevState) => ({
        splitSize: !prevState.splitSize,
      }),
      () => {
        // Call updateHeight in the callback to ensure it runs after the state has been updated
        this.updateHeight();
      }
    );
  };
  //pregnancy date validation
  validateDate = (dateString) => {
    // Check if the input matches the "DD/MM/YYYY" format
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return false; // Doesn't match the expected format
    }

    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/").map(Number);

    // Check if the month is valid (between 01 and 12)
    if (month < 1 || month > 12) {
      return false;
    }

    // Check if the day is valid based on the month (e.g., 31 days in December)
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    // Check if the year is a valid positive number (you can add additional validation here)
    if (year < 1 || year > new Date().getFullYear()) {
      return false;
    }

    return true; // Passed all validation checks
  };

  /**
   * Handles the blur event for a checkbox item.
   * @param {object} item - The checkbox item.
   * @param {number} index - The index of the checkbox item in the array.
   */
  handleBlur = (item, index, isPregnancy) => {
    if (item.value) {
      if (
        +item.id === 611741 &&
        (item.value === "__/__/____" || !this.validateDate(item.value))
      ) {
        this._handleRemoveCheckboxStateArray(item.id, index);
      } else {
        if (item.value === "__/__/____") {
          this._handleRemoveCheckboxStateArray(item.id, index);
        } else {
          this._handleCheckboxStateArray(item.id, item.value);
        }
      }
    } else {
      this._handleRemoveCheckboxStateArray(item.id, index);
    }
  };

  _handleCheckboxStateArray = (key, val) => {
    if (val === "__/__/____") {
    } else {
      this.setState(
        (prevState) => {
          let newExamination = prevState.examination;
          let newSelectedCodesArray = isArray(prevState.selectedCodesArray)
            ? [...(prevState.selectedCodesArray || [])]
            : [];

          let found = prevState.checkboxStateArray.find((i) => i.id === key);

          if (found) {
            let newText = isArray(prevState.examination)
              ? prevState.examination
              : prevState.examination.split("\n");
            let newExaminationItem = newText.find((item) =>
              item.includes(found.long_text)
            );

            if (newExaminationItem) {
              newExamination = prevState.examination
                .split("\n")
                .filter((item) => !item.includes(newExaminationItem))
                .map((item) => `${item.replace(/,$/, "")}`);
              newExamination = [
                ...newExamination,
                `${found.long_text} [${val}]`,
              ];
            } else {
              newExamination =
                prevState.examination !== "" && prevState.examination
                  ? // &&
                    // prevState.examination.trim().length !== 0
                    prevState.examination + "\n" + `${found.long_text}[${val}]`
                  : `${found.long_text} [${val}]`;
            }

            newSelectedCodesArray.push({
              code: found.snomed_code,
              description: found.snomed_code_description,
              id: found.id,
            });
          }

          return {
            examination: isArray(newExamination)
              ? newExamination.join("\n")
              : newExamination,
            newselectedCodesArray: this.removeDuplicates([
              ...(prevState.newselectedCodesArray || []),
              ...newSelectedCodesArray,
            ]),
            // selectedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
            // selectedSnomedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
          };
        },
        () => {
          let {selectedCodesArray} = this.state;
          let removedDuplicatesArray =
            this.removeDuplicates(selectedCodesArray);

          this.setState(
            {
              newselectedCodesArray: this.removeDuplicates(
                this.state.newselectedCodesArray || []
              ),
              selectedCodesArray: [
                ...removedDuplicatesArray,
                ...this.state.newselectedCodesArray,
              ],
              selectedSnomedCodesArray: [
                ...removedDuplicatesArray,
                ...(this.state.newselectedCodesArray || []),
              ],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  examination: this.state.examination,
                  newselectedCodesArray: this.state.newselectedCodesArray,
                  selectedCodesArray: this.state.selectedCodesArray,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                };
                onSetDynamicInput(payload);
              }
            }
          );
          this.scrollToBottomForTextArea("examination_textarea");
        }
      );
    }
  };

  _handleRemoveCheckboxStateArray = (key, index) => {
    let newText = this.state.examination
      ? isArray(this.state.examination)
        ? this.state.examination
        : this.state.examination.split("\n")
      : [];
    let removingItem = this.state.checkboxStateArray.find((i) => i.id === key);
    let newExamination = newText.filter(
      (item) => !item.includes(removingItem.long_text)
    );

    this.setState(
      (state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState(
          {
            examination: newExamination.join("\n"),
            selectedCodesArray: this.state.selectedCodesArray.filter(
              (d) => d.id !== key
            ),
          },
          () => {
            this.setState(
              {
                selectedSnomedCodesArray: this.state.selectedCodesArray.filter(
                  (d) => d.id !== key
                ),
              },
              () => {
                // this._SaveComponentStateToRedux();
              }
            );
          }
        );
      }
    );
  };
  handleBlurHA = (item, index) => {
    if (item.value) {
      this._handleHealthStateArray(item.id, item.value);
    } else {
      this._handleRemoveHealthStateArray(item.id, index);
    }
  };
  _handleHealthStateArray = (key, val) => {
    this.setState(
      (prevState) => {
        let newExamination = prevState.examination ? prevState.examination : "";

        let found = prevState.healthStateArray.find((i) => i.id === key);

        if (found) {
          let newText;

          if (typeof prevState.examination === "string") {
            newText = prevState.examination.split("\n");
          } else if (isArray(prevState.examination)) {
            newText = prevState.examination;
          } else {
            newText = []; // Default value if examination is neither a string nor an array
          }
          let newExaminationItem = newText.find((item) =>
            item.includes(found.label)
          );

          if (newExaminationItem) {
            newExamination = isArray(prevState.examination)
              ? prevState.examination
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`)
              : prevState.examination
                  .split("\n")
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`);

            newExamination = [
              ...newExamination,
              `${found.label}: ${val} ${found.unit}`,
            ];
          } else {
            newExamination =
              prevState.examination !== ""
                ? // &&
                  // prevState.examination.trim().length !== 0
                  prevState.examination +
                  "\n" +
                  `${found.label}: ${val} ${found.unit}`
                : `${found.label}: ${val} ${found.unit}`;
          }
        }

        return {
          examination: isArray(newExamination)
            ? this.rearrangeHealthData(newExamination.join("\n"))
            : this.rearrangeHealthData(newExamination),

          // selectedSnomedCodesArray: this.state.selectedCodesArray,
        };
      },
      () => {
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            examination: this.state.examination,
            // selectedCodesArray: this.state.selectedCodesArray,
            // selectedSnomedCodesArray: this.state.selectedCodesArray,
          };
          onSetDynamicInput(payload);
        }

        this.scrollToBottomForTextArea("examination_textarea");
      }
    );
  };
  rearrangeHealthData = (data) => {
    let lines;

    // Convert to array if the input is a string
    if (typeof data === "string") {
      lines = data.split("\n");
    } else if (Array.isArray(data)) {
      lines = data;
    } else {
      console.error("Error: Invalid input format. Expected string or array.");
      return data;
    }

    // Define the order in which lines should appear
    const desiredOrder = [
      "Pulse",
      "SpO2",
      "Height",
      "Weight",
      "BMI",
      "Temperature",
      "Systolic",
      "Diastolic",
      "Respiratory rate",
    ];

    // Sort lines based on their order in desiredOrder
    lines.sort((a, b) => {
      let indexA = desiredOrder.indexOf(a.split(":")[0].trim());
      let indexB = desiredOrder.indexOf(b.split(":")[0].trim());
      return indexA - indexB;
    });

    // Convert back to string if the input was a string
    if (typeof data === "string") {
      return lines.join("\n");
    } else {
      return lines;
    }
  };
  _handleRemoveHealthStateArray = (key, index) => {
    let newText = isArray(this.state.examination)
      ? this.state.examination
      : this.state.examination
      ? this.state.examination.split("\n")
      : [];
    let removingItem = this.state.healthStateArray.find((i) => i.id === index);
    let newExamination = newText.filter(
      (item) => !item.includes(removingItem.label)
    );

    this.setState(
      (state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState({
          examination: this.rearrangeHealthData(newExamination.join("\n")),
          // selectedSnomedCodesArray: this.state.selectedCodesArray,
        });
      }
    );
  };
  handleKeyPress = (e, Key, index) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleBlur(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      });
    }
  };
  handleKeyPressHA = (e, Key, index) => {
    if (e.key === "Enter") {
      this.handleBlurHA(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      });
    }
  };

  handleOnChange = (value, key, name) => {
    if (name === "Pregnancy") {
      this.setState({[key]: value.target.value}, () => {});
    } else {
      this.setState({[key]: value});
    }
  };
  hanleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      this.setState(
        (prevState) => ({
          enterCount: prevState.enterCount + 1,
        }),
        async () => {
          if (this.state.enterCount === 2) {
            if (this.props.gender !== "female") {
              await this.handleGetDiagnosis();
            } else {
              await this.handleGetDiagnosisFemale();
            }
            // Call your function here
            // this.callYourFunction();
            // Reset the count
            this.setState({
              enterCount: 0,
            });
          }
        }
      );
    }
  };
  fetchRegions = async () => {
    let {dynamicInputs, dynamicHealthInputs} = this.props;

    try {
      this.setState({fetchingRegion: true});

      const response = await fetchRegionsAPI();

      this.setState(
        {
          fetchingRegion: false,
          regionsData: verifyObject(response, "data.data", []),
          ...dynamicInputs,
          ...dynamicHealthInputs,

          tabKey:
            this.props.consultation_tab_key !== null
              ? this.props.consultation_tab_key
              : "preencounters",
        },
        () => {
          let {onSetDynamicInput} = this.props.dynamicTabAction;
          let {dynamicInputs} = this.props;

          if (typeof onSetDynamicInput === "function") {
            let payload = {
              ...dynamicInputs,
              regionsData: this.state.regionsData,
            };
            onSetDynamicInput(payload);
          }
          let foundValueCheckbox = checkboxArray.find(
            (item) => item.value !== null
          );
          let foundValueHealthbox = Health_Assessment_KVP.find(
            (item) => item.value !== null
          );

          if (this.state.examination === "" && foundValueCheckbox) {
            this.handleClearCheckboxValue();
          }
          if (this.state.examination === "" && foundValueHealthbox) {
            this.handleClearHealthboxValue();
          }
          this.setState((prevState) => {
            const selectedSnomedCodesArray = Array.isArray(
              prevState.selectedSnomedCodesArray
            )
              ? prevState.selectedSnomedCodesArray
              : [];

            const selectedCodesArray = Array.isArray(
              prevState.selectedCodesArray
            )
              ? prevState.selectedCodesArray
              : [];

            return {
              selectedSnomedCodesArray: uniq([
                ...(selectedSnomedCodesArray || []),
                ...(selectedCodesArray || []),
              ]),
            };
          });
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      console.log(
        "🚀 ~ file: PreConsultDetailContainer.js:2986 ~ PreConsultDetailContainer ~ fetchRegions= ~ message:",
        message
      );
      this.setState({
        fetchingRegion: false,
      });
      errorToast({content: message});
    }
  };
  handleFormater = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    return value.toString().slice(0, 5); // Restrict to 3 digits
  };

  handleParser = (value, allowedNegativeValue) => {
    // Remove non-digit and non-dot characters
    const cleanValue = value.replace(/[^\d.-]/g, "");

    // Split the value into integer and decimal parts
    let [integerPart, decimalPart] = cleanValue.split(".");

    // Check if the value is negative
    const isNegative = cleanValue.startsWith("-");

    // Disallow negative values if allowedNegativeValue is false
    if (!allowedNegativeValue && isNegative) {
      return "";
    }

    // Keep only the first 3 digits in the integer part
    const truncatedIntegerPart = integerPart.slice(0, 3);

    // Ensure decimalPart is at most 2 digits
    const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";

    // Combine the truncated parts back into a valid number format
    const truncatedValue = decimalPart
      ? `${truncatedIntegerPart}.${truncatedDecimalPart}`
      : truncatedIntegerPart;

    // Add back the negative sign if it was initially present
    return truncatedValue;
  };

  scrollToBottomForTextArea = (id) => {
    let historyTextArea = document.getElementById(id);
    if (historyTextArea) {
      historyTextArea.scrollTop = historyTextArea.scrollHeight;
    }
  };
  handleKeyUp(event) {
    //key code for enter
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }
  //function for remove multiple codes in diagnosis as same
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];
    if (stateArray) {
      for (const item of stateArray) {
        if (
          item !== undefined &&
          item.id !== undefined &&
          item.id !== null &&
          !uniqueItems[item.id]
        ) {
          uniqueItems[item.id] = true;
          newArray.push(item);
        }
      }
    }
    return newArray;
  };
  //function for filter symtom checker or questionnaire reason only in presenting complaint
  filterUniqueItems = (array1, array2) => {
    const uniqueItems = array1.filter((item2) => {
      let foundSnomedCode = array2.find((item) => item.id === item2.value);

      if (foundSnomedCode) {
        this.setState(
          {
            selectedCodesArray: this.state.selectedCodesArray.filter(
              (item) => +item.id !== +foundSnomedCode.snomed_code_id
            ),
            reason_text: !array2.some((item1) => item1.id === item2.value),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                reason_text: this.state.reason_text,

                selectedCodesArray: this.state.selectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }

      return !array2.some((item1) => item1.id === item2.value);
    });

    return uniqueItems;
  };
  onquestionChange = (e) => {
    this.setState({recentTravel: e.target.checked}, () => {
      // if (this.state.recentTravel) {
      let {onSetDynamicInput} = this.props.dynamicTabAction;
      let {dynamicInputs} = this.props;
      if (dynamicInputs && isEmpty(dynamicInputs.regionsData)) {
        this.fetchRegions();
      }
      if (typeof onSetDynamicInput === "function") {
        let payload = {
          ...dynamicInputs,
          recentTravel: this.state.recentTravel,
          regionsData: this.state.regionsData,
        };
        onSetDynamicInput(payload);
      }
      // }
    });
  };

  render() {
    let {
      history,
      examination,
      presriptionPlan,
      managementPlan,
      pre_consult_note,
      barcodeNumber,
      isLoading,
      errors,
      selectedCodesArray,
      searching,
      snomedCodes,
      visible,
      addingDetail,
      checkboxStateArray,
      splitSize,
      newselectedCodesArray,
    } = this.state;
    console.log("STATE-----", this.state);
    let secondPanelClassName = splitSize ? "up-arrow" : "down-arrow";
    let {dynamicInputs, appointmentObj, prev_appt_tab_key, userRole} =
      this.props;
    let havePharmacistDesignationCondition =
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed"
        ? (this.props.designation &&
            this.props.designation.toLowerCase() === "pharmacist") ||
          userRole === ROLE_DOCTOR ||
          userRole === ROLE_CLINICAL_PHARMACIST
        : this.props.userRole !== ROLE_SUPERINTENDENT_PHARMACIST;

    let {
      // data,
      isLoading: isLoadingPreEncounter,
      pagination: paginationPreEncounter,
    } = this.props.preEnccounter;
    let {
      // data,
      isLoading: isLoadingPrescription,
      pagination: paginationPrescription,
    } = this.props.prescriptions;

    let reasonClass = "";
    if (
      window.location.pathname === "/doctor/walkin-appointment" ||
      window.location.pathname === "/doctor/video-appointment"
    ) {
      reasonClass =
        verifyObject(appointmentObj, "reason_text", "") !== "" ||
        verifyObject(appointmentObj, "reason_image", "") !== ""
          ? "showreasonfield"
          : window.location.pathname === "/doctor/walkin-appointment" &&
            this.props.is_from_patient_search_listing
          ? "showreasonfield"
          : window.location.pathname === "/doctor/video-appointment" &&
            (verifyObject(appointmentObj, "reason_text", "") === "" ||
              verifyObject(appointmentObj, "reason_image", "") === "")
          ? "hidereasonfield"
          : window.location.pathname === "/doctor/walkin-appointment" &&
            (verifyObject(appointmentObj, "reason_text", "") === "" ||
              verifyObject(appointmentObj, "reason_image", "") === "")
          ? "hidereasonfield"
          : "";
    }

    if (isLoading || this.props.isCallEnding) {
      return <CommonLoader />;
    }
    // let { submitedPreConsultQuestionnaire } = this.props;
    return (
      <Fragment>
        <div
          className={`mainappoinmentwrap ${
            this.props.is_from_patient_search_listing ? "" : reasonClass
          }`}
        >
          <SplitPane split="horizontal" onChange={this.updateHeight}>
            <Pane>
              <div className="sectionscroll pre_encountersecscroll">
                <div className="push-20-b walkin_appnt_wrapper padding-15-t">
                  <div className="selection_container container-fluid padding-left-10 padding-right-10">
                    <div className="form-group relative push-10-b">
                      <div className="form-row">
                        <div className="col-md-7 maincol">
                          <div className="reason_wrapper">
                            <div className="d-flex justify-content-between align-items-center push-5-b">
                              <div className="labelicon d-flex align-items-center">
                                <p className="font-12 weight-400 text-left text-blue in-block margin-0 text-capitalize">
                                  Presenting Complaints
                                </p>
                                {verifyObject(
                                  this.props,
                                  "appointmentOb.reason_image"
                                ) ? (
                                  <div className="pres_icon ml-3">
                                    <Button
                                      className="imagebtn test"
                                      onClick={this.showpresentingModal}
                                    >
                                      <PictureFilled />
                                    </Button>

                                    <ModalPopUp
                                      title={"Presenting Complaint"}
                                      className="presentingcomp_popup"
                                      visible={this.state.getPresentingComModal}
                                      handleOk={this.handleOk}
                                      handleCancel={this.handleCancel}
                                      okText="Okay"
                                      cancelText="Cancel"
                                      bodyStyle={{height: 400}}
                                    >
                                      {this.props.appointmentObj
                                        .reason_image !== "" &&
                                      this.props.appointmentObj.reason_image !==
                                        undefined ? (
                                        <Image
                                          src={
                                            this.props.appointmentObj
                                              .reason_image !== ""
                                              ? this.props.appointmentObj
                                                  .reason_image
                                              : ""
                                          }
                                          className="cursor-pointer"
                                          alt="Patient"
                                          width="100%"
                                        ></Image>
                                      ) : (
                                        <img
                                          src={
                                            require("../../assets/images/common/img-placeholder.png")
                                              .default
                                          }
                                          alt="Patient"
                                          className="cursor-pointer"
                                        ></img>
                                      )}
                                    </ModalPopUp>
                                  </div>
                                ) : null}
                                <div className="infoicon ml-2">
                                  <Popover
                                    content={presentingpopover}
                                    title="Info"
                                  >
                                    <Button
                                      className="infobtn"
                                      // onClick={
                                      //   this.showPresentingInfoModal
                                      // }
                                    >
                                      <InfoCircleOutlined />
                                    </Button>
                                  </Popover>
                                  {/* <ModalPopUp
                                          title={"Info"}
                                          className="presinfo_popup"
                                          handleCancel={this.handleCancel}
                                          visible={
                                            this.state.getPresentingInfoModal
                                          }
                                        >
                                          <div className="infobodymain">
                                            <ul className="mainlist">
                                              <li>
                                                Enter{" "}
                                                <b>
                                                  <u>clinical features</u>
                                                </b>{" "}
                                                as an individual concept or
                                                comma separated, click enter or
                                                the ‘plus’ sign to add terms.
                                              </li>
                                              <li>
                                                Enter terms in free text or
                                                select from predictive text that
                                                appears as you start typing.
                                              </li>
                                              <li>
                                                Use terms as they would appear
                                                in medical resources, convert
                                                numerical values, avoid
                                                abbreviations, avoid normal /
                                                negative features & avoid
                                                repetition.
                                              </li>
                                              <li>
                                                Examples of how to enter
                                                clinical features:
                                                <div className="featureslist">
                                                  <ul class="clinical_info_list">
                                                    <li>
                                                      diffuse severe pain left
                                                      side
                                                    </li>
                                                    <li>accidental injury</li>
                                                    <li>left side bruising</li>
                                                    <li>tachycardic</li>
                                                    <li>elevated creatinine</li>
                                                    <li>low wbc</li>
                                                  </ul>
                                                </div>
                                              </li>
                                              <li>
                                                Once you have entered all your
                                                symptoms, click on 'Get
                                                Checklist' and wait for a
                                                result.
                                              </li>
                                            </ul>
                                            <div className="plaintext">
                                              <p>
                                                If you are concerned about
                                                Infectious diseases or your
                                                patient has travelledavel to another
                                                <b className="ml-1">
                                                  <u>region</u>
                                                </b>
                                                , then change the
                                                <b className="mx-1">
                                                  <u>region</u>
                                                </b>
                                                accordingly.
                                              </p>
                                            </div>
                                          </div>
                                        </ModalPopUp> */}
                                </div>
                              </div>
                              {this.props.patientDetail &&
                                this.props.patientDetail?.gender ===
                                  "female" && (
                                  <div className="ispregnentbox">
                                    {this.props.patientDetail?.age > 13 &&
                                      this.props.patientDetail?.age < 65 && (
                                        <div className="checkboxgroup">
                                          <span className="d-flex checkboxmaintitle">
                                            Is the patient pregnantis?
                                          </span>
                                          <Radio.Group
                                            value={this.state.is_pregnant}
                                            onChange={(e) => {
                                              this.setState(
                                                {
                                                  is_pregnant: e.target.value,
                                                },
                                                () => {
                                                  if (
                                                    this.state.is_pregnant ===
                                                    "y"
                                                  ) {
                                                    this.setState(
                                                      {
                                                        selectedCodesArray: [
                                                          ...(this.state
                                                            .selectedCodesArray ||
                                                            []),
                                                          {
                                                            id:
                                                              process.env
                                                                .REACT_APP_ENV ===
                                                              "dev"
                                                                ? 1719078
                                                                : 47928,
                                                            code: 169571009,
                                                            description:
                                                              "Patient pregnant NOS",
                                                          },
                                                        ],
                                                      },
                                                      () => {
                                                        let {
                                                          onSetDynamicInput,
                                                        } =
                                                          this.props
                                                            .dynamicTabAction;
                                                        let {dynamicInputs} =
                                                          this.props;

                                                        if (
                                                          typeof onSetDynamicInput ===
                                                          "function"
                                                        ) {
                                                          let payload = {
                                                            ...dynamicInputs,
                                                            selectedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            selectedSnomedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            is_pregnant:
                                                              this.state
                                                                .is_pregnant,
                                                          };
                                                          onSetDynamicInput(
                                                            payload
                                                          );
                                                        }
                                                      }
                                                    );
                                                  } else {
                                                    this.setState(
                                                      {
                                                        selectedCodesArray:
                                                          this.state.selectedCodesArray.filter(
                                                            (item) =>
                                                              item.id !==
                                                              169571009
                                                          ),
                                                        selectedSnomedCodesArray:
                                                          this.state.selectedSnomedCodesArray.filter(
                                                            (item) =>
                                                              item.id !==
                                                              169571009
                                                          ),
                                                      },
                                                      () => {
                                                        let {
                                                          onSetDynamicInput,
                                                        } =
                                                          this.props
                                                            .dynamicTabAction;
                                                        let {dynamicInputs} =
                                                          this.props;

                                                        if (
                                                          typeof onSetDynamicInput ===
                                                          "function"
                                                        ) {
                                                          let payload = {
                                                            ...dynamicInputs,
                                                            selectedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            selectedSnomedCodesArray:
                                                              this.state
                                                                .selectedCodesArray,
                                                            is_pregnant:
                                                              this.state
                                                                .is_pregnant,
                                                          };
                                                          onSetDynamicInput(
                                                            payload
                                                          );
                                                        }
                                                      }
                                                    );
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            <Radio value="y">Yes</Radio>
                                            <Radio value="n">No</Radio>
                                            {/* <Radio value="">Other</Radio> */}
                                          </Radio.Group>
                                        </div>
                                      )}
                                  </div>
                                )}
                              {/* {this.props.fromOngoingConsultation && ( */}
                              <div className="d-flex align-items-center">
                                <a
                                  className="font-12 weight-500 text-right text-blue in-block mr-2 text-capitalize search_symtoms"
                                  onClick={() => {
                                    if (this.props.gender !== "female") {
                                      this.handleGetDiagnosis();
                                    } else {
                                      this.handleGetDiagnosisFemale();
                                    }
                                    // this._SaveComponentStateToRedux();
                                  }}
                                  disabled={
                                    (isArray(this.state.reason_text) &&
                                      this.state.reason_text.length === 0) ||
                                    this.state.searchingDiagnose
                                  }
                                  loading={this.state.searchingDiagnose}
                                >
                                  Get Results
                                </a>
                                <a
                                  disabled={
                                    isArray(this.state.reason_text) &&
                                    this.state.reason_text.length === 0
                                  }
                                  onClick={this.handleClear}
                                  className="font-12 weight-500 text-right text-blue in-block text-capitalize search_symtoms"
                                >
                                  Clear
                                </a>
                              </div>
                              {/* )} */}
                            </div>
                            <div className="">
                              <div className="d-flex full-width align-items-center">
                                <CreatableSelect
                                  isMulti
                                  options={this.state.symptomsData}
                                  isValidNewOption={() => false}
                                  allowCreateWhileLoading={false}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  value={
                                    // this.state.reason_text === ""
                                    //   ? []
                                    //   :
                                    this.state.reason_text
                                  }
                                  onKeyDown={(e) => this.hanleEnterKeyPress(e)}
                                  ref={this.creatableRef}
                                  onChange={(value, actionMeta) => {
                                    if (value && value.length > 0) {
                                      this.setState(
                                        {
                                          reason_text: this.filterUniqueItems(
                                            value,
                                            process.env.REACT_APP === "prod"
                                              ? getLivePerformaQuestionnaire(this.props.current_corporate)
                                              : DP_QUESTIONNAIRE
                                          ),
                                        },
                                        () => {
                                          if (
                                            actionMeta.action === "remove-value"
                                          ) {
                                            let newArray = [];

                                            let {dynamicInputs} = this.props;
                                            let removeStringArray =
                                              dynamicInputs?.reason_text.filter(
                                                (item) => {
                                                  return !value.some(
                                                    (item2) =>
                                                      item2.label === item.label
                                                  );
                                                }
                                              );
                                            let historyStringArray =
                                              this.state.history
                                                .split("\n")
                                                .filter(
                                                  (item) =>
                                                    item !==
                                                    removeStringArray[0].label
                                                );

                                            newArray = historyStringArray;

                                            this.setState(
                                              {
                                                history: newArray.join("\n"),
                                              },
                                              () => {
                                                let {onSetDynamicInput} =
                                                  this.props.dynamicTabAction;
                                                let {dynamicInputs} =
                                                  this.props;

                                                if (
                                                  typeof onSetDynamicInput ===
                                                  "function"
                                                ) {
                                                  let payload = {
                                                    ...dynamicInputs,
                                                    history: this.state.history,
                                                    reason_text:
                                                      this.state.reason_text,
                                                  };
                                                  onSetDynamicInput(payload);
                                                }
                                              }
                                            );
                                          } else {
                                            let newArray = [];

                                            let newHistory =
                                              this.state.history !== "" &&
                                              this.state.history
                                                ? this.state.history +
                                                  "\n" +
                                                  value
                                                    .map((item) => item.label)
                                                    .join("\n")
                                                : value
                                                    .map((item) => item.label)
                                                    .join("\n");

                                            const uniqueItems = {};
                                            for (const item of newHistory.split(
                                              "\n"
                                            )) {
                                              if (!uniqueItems[item]) {
                                                uniqueItems[item] = true;
                                                newArray.push(item);
                                              }
                                            }

                                            this.setState(
                                              {
                                                history: newArray.join("\n"),
                                              },
                                              () => {
                                                let {onSetDynamicInput} =
                                                  this.props.dynamicTabAction;
                                                let {dynamicInputs} =
                                                  this.props;

                                                if (
                                                  typeof onSetDynamicInput ===
                                                  "function"
                                                ) {
                                                  let payload = {
                                                    ...dynamicInputs,
                                                    history: this.state.history,
                                                    reason_text:
                                                      this.state.reason_text,
                                                  };
                                                  onSetDynamicInput(payload);
                                                }
                                              }
                                            );
                                          }
                                        }
                                      );
                                    } else {
                                      let reasonStringArray =
                                        this.state.reason_text.map(
                                          (item) => item.label
                                        );
                                      let removeHistoryStringArray =
                                        this.state.history
                                          .split("\n")
                                          .filter((item1) => {
                                            return !reasonStringArray.some(
                                              (item2) => item2 === item1
                                            );
                                          });

                                      this.setState(
                                        {
                                          reason_text: value,
                                          history:
                                            removeHistoryStringArray.join("\n"),
                                          diagnosisData: [],
                                          selectedCodesArray:
                                            this.state.selectedCodesArray.filter(
                                              (item1) => {
                                                return !this.state.symptompSnomedCode.some(
                                                  (item2) =>
                                                    +item2?.code ===
                                                    +item1?.code
                                                );
                                              }
                                            ),
                                          symptompSnomedCode: [],
                                        },
                                        () => {
                                          store.dispatch({
                                            type: "SET_SYMPTOMP_FETCHED_LINKS",
                                            payload: null,
                                          });
                                          let {onSetDynamicInput} =
                                            this.props.dynamicTabAction;
                                          let {dynamicInputs} = this.props;

                                          if (
                                            typeof onSetDynamicInput ===
                                            "function"
                                          ) {
                                            let payload = {
                                              ...dynamicInputs,
                                              reason_text:
                                                this.state.reason_text,
                                              history: this.state.history,
                                              reason_text_string: "",
                                              symptomsData:
                                                this.state.symptomsData,
                                              diagnosisData:
                                                this.state.diagnosisData,
                                              selectedCodesArray:
                                                this.state.selectedCodesArray,
                                              selectedSnomedCodesArray:
                                                this.state.selectedCodesArray,
                                              symptompSnomedCode:
                                                this.state.symptompSnomedCode,
                                            };
                                            onSetDynamicInput(payload);
                                          }
                                        }
                                      );
                                    }
                                    if (
                                      actionMeta &&
                                      actionMeta.action === "select-option"
                                    ) {
                                      if (
                                        this.creatableRef &&
                                        this.creatableRef.current
                                      ) {
                                        if (
                                          typeof this.creatableRef.current
                                            .select.select.inputRef.focus ===
                                          "function"
                                        ) {
                                          this.creatableRef.current.select.select.inputRef.focus();
                                        }
                                      }
                                    }
                                  }}
                                  placeholder="Search and select"
                                  noOptionsMessage={() => "No matching options"}
                                  // onCreateOption={
                                  //   this.customAbbrivationChange
                                  // }
                                  filterOption={() => true}
                                  components={{
                                    DropdownIndicator: null,
                                  }}
                                  isLoading={
                                    this.state.searchingSymptom ||
                                    this.state.searchingDiagnose
                                  }
                                  onInputChange={(value) => {
                                    this.setState({
                                      custom_text: value,
                                    });
                                    this.searchUpdateSymptom(value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          {this.state.fetchingSubmittedQuestionnaire && (
                            <p className="text-center">
                              <p>
                                <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                  Fetching Submitted Questionnaire...
                                </p>
                                <Spinner />
                              </p>
                            </p>
                          )}
                          {!this.state.fetchingSubmittedQuestionnaire && (
                            <PreConsultSubmittedQuestionnaire
                              submitedQuestionnairesArray={
                                this.state.submitedQuestionnairesArray
                              }
                              selectedCodesArray={this.state.selectedCodesArray}
                              symptompSnomedCode={this.state.symptompSnomedCode}
                              history={this.state.history}
                              dynamicInputs={verifyObject(
                                this.props,
                                "dynamicInputs",
                                null
                              )}
                              gender={this.props.gender}
                              handlePreconsultNoteChange={(
                                preconsultNoteString
                              ) => {
                                this.setState(
                                  {
                                    // pre_consult_note: isEmpty(
                                    //   this.state.pre_consult_note
                                    // )
                                    //   ? preconsultNoteString
                                    //   : this.state.pre_consult_note +
                                    //     "\n" +
                                    //     preconsultNoteString,
                                    pre_consult_note: preconsultNoteString,
                                    diagnosisData: null,
                                  },
                                  () => {
                                    this.scrollToBottomForTextArea(
                                      "pre_consult_note"
                                    );

                                    let {onSetDynamicInput} =
                                      this.props.dynamicTabAction;
                                    let {dynamicInputs} = this.props;

                                    if (
                                      typeof onSetDynamicInput === "function"
                                    ) {
                                      let payload = {
                                        ...dynamicInputs,
                                        history: this.state.history,
                                        pre_consult_note:
                                          this.state.pre_consult_note,
                                        // reason_text: this.state.reason_text,
                                        // reason_text_string: "",
                                        symptomsData: this.state.symptomsData,
                                        diagnosisData: null,
                                        selectedCodesArray:
                                          this.state.selectedCodesArray,
                                        selectedSnomedCodesArray:
                                          this.state.selectedCodesArray,
                                      };
                                      onSetDynamicInput(payload);
                                    }
                                    resizeTextArea("#pre_consult_note");
                                  }
                                );
                              }}
                            />
                          )}
                          {!this.state.fetchingSubmittedQuestionnaire && (
                            <div className="form-row push-10-b">
                              <div className="col-md-12 consultant_review">
                                <p
                                  id="pre_consult_lbl"
                                  className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp"
                                >
                                  Pre Consult
                                </p>

                                <AutoTextArea
                                  autoSize={true}
                                  type="text"
                                  className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                    errors &&
                                    errors.pre_consult_note &&
                                    "danger-border"
                                  } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                  name="pre_consult_note"
                                  value={pre_consult_note}
                                  onChange={this._handleTextChange}
                                  placeholder="Add your pre consult text"
                                  ref={this.scollRef}
                                  id="pre_consult_note"
                                  scrollToBottomForTextArea={() =>
                                    this.scrollToBottomForTextArea(
                                      "pre_consult_note"
                                    )
                                  }
                                />
                                {/* {errors && (
                          <span className="validate-danger font-14">
                            {errors.pre_consult_note}
                          </span>
                        )} */}
                              </div>
                            </div>
                          )}
                          {this.props.post_gt_questionnaire && (
                            <QuestionnaireFillUp
                              selectedCodesArray={this.state.selectedCodesArray}
                              symptompSnomedCode={this.state.symptompSnomedCode}
                              history={this.state.history}
                              gender={this.props.gender}
                              fromPreConsult={true}
                              post_gt_questionnaire={
                                this.props.post_gt_questionnaire
                              }
                              handleExaminationChange={(examinationString) => {
                                this.setState(
                                  {
                                    historyForPGQ: isEmpty(this.state.history)
                                      ? examinationString
                                      : this.state.history +
                                        "\n" +
                                        examinationString,
                                    diagnosisData: null,
                                  }
                                  // () => {
                                  //   this.scrollToBottomForTextArea(
                                  //     "history_textarea"
                                  //   );
                                  //   let { onSetDynamicInput } =
                                  //     this.props.dynamicTabAction;
                                  //   let { dynamicInputs } = this.props;

                                  //   if (
                                  //     typeof onSetDynamicInput === "function"
                                  //   ) {
                                  //     let payload = {
                                  //       ...dynamicInputs,
                                  //       history: this.state.history,
                                  //       // reason_text: this.state.reason_text,
                                  //       // reason_text_string: "",
                                  //       symptomsData: this.state.symptomsData,
                                  //       diagnosisData: null,
                                  //       selectedCodesArray:
                                  //         this.state.selectedCodesArray,
                                  //       selectedSnomedCodesArray:
                                  //         this.state.selectedCodesArray,
                                  //     };
                                  //     onSetDynamicInput(payload);
                                  //   }
                                  // }
                                );
                              }}
                              updateReasonText={(reason) => {
                                this.setState({
                                  symptomsData: [
                                    // ...this.state.searchSymptom,
                                    reason,
                                  ],
                                  diagnosisData: null,
                                  reason_text: [
                                    // ...this.state.reason_text,
                                    reason,
                                  ],
                                  recentTravel: false,
                                  region_id: 1,
                                });
                              }}
                              updateSnomedCodes={(
                                found,
                                removeOldSnomedCodeArray,
                                removeOldSymptomSnomedCode
                              ) => {
                                this.setState(
                                  {
                                    selectedCodesArray: found.snomed_code
                                      ? removeOldSnomedCodeArray
                                          .concat({
                                            id: found.snomed_code_id,
                                            code: found.snomed_code,
                                            description:
                                              found.snomed_code_title,
                                          })
                                          .filter((item1) => {
                                            return !this.state.symptompSnomedCode.some(
                                              (item2) => +item2.id === +item1.id
                                            );
                                          })
                                      : removeOldSnomedCodeArray.filter(
                                          (item1) => {
                                            return !this.state.symptompSnomedCode.some(
                                              (item2) => +item2.id === +item1.id
                                            );
                                          }
                                        ),
                                    symptompSnomedCode: [],
                                    // removeOldSymptomSnomedCode.concat({
                                    //   id: found.snomed_code_id,
                                    //   code: found.snomed_code,
                                    //   description:
                                    //     found.snomed_code_title,
                                    // }),
                                    diagnosisData: null,
                                    recentTravel: false,
                                    region_id: 1,
                                  },
                                  () => {
                                    store.dispatch({
                                      type: "SET_SYMPTOMP_FETCHED_LINKS",
                                      payload: null,
                                    });
                                    let {onSetDynamicInput} =
                                      this.props.dynamicTabAction;
                                    let {dynamicInputs} = this.props;

                                    if (
                                      typeof onSetDynamicInput === "function"
                                    ) {
                                      let payload = {
                                        ...dynamicInputs,
                                        history: this.state.history,
                                        reason_text: this.state.reason_text,
                                        recentTravel: this.state.recentTravel,
                                        region_id: this.state.region_id,
                                        reason_text_string: "",
                                        symptomsData: this.state.symptomsData,
                                        diagnosisData: null,
                                        selectedCodesArray:
                                          this.state.selectedCodesArray,
                                        selectedSnomedCodesArray:
                                          this.state.selectedCodesArray,
                                        symptompSnomedCode:
                                          this.state.symptompSnomedCode,
                                      };
                                      onSetDynamicInput(payload);
                                    }
                                  }
                                );
                              }}
                            />
                          )}
                          <div className="consultant_review push-10-b">
                            <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                              History
                            </p>

                            <AutoTextArea
                              autoSize={true}
                              type="text"
                              className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                errors && errors.history && "danger-border"
                              } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                              name="history"
                              value={history}
                              onChange={this._handleTextChange}
                              placeholder="Write patient history here"
                              rows="2"
                              id="history_textarea"
                              scrollToBottomForTextArea={() =>
                                this.scrollToBottomForTextArea(
                                  "history_textarea"
                                )
                              }
                            />
                            {errors && (
                              <span className="validate-danger font-14">
                                {errors.history}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-5 consultantion-info-table-wrapper">
                          {/* {this.props.fromOngoingConsultation && ( */}
                          <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12 religon_drop">
                                <div className="walkingquestions">
                                  <div className="hideshowcheckbox">
                                    <Checkbox
                                      checked={this.state.recentTravel}
                                      onChange={this.onquestionChange}
                                    >
                                      <label htmlFor="sel1 text-high-dark-blue-imp">
                                        Any recent foreign travel?
                                      </label>
                                    </Checkbox>
                                  </div>
                                </div>
                                {/* {this.state.recentTravel && ( */}
                                <Select
                                  // showSearch
                                  getPopupContainer={() => document.querySelector('.consultantion-info-table-wrapper')}
                                  disabled={!this.state.recentTravel}
                                  loading={this.state.fetchingRegion}
                                  className="form-control margin-0 form-control-textarea input-bg-f9f9f9 full-width custom-input font-12 weight-400 placeholder-10"
                                  placeholder="Select region"
                                  optionFilterProp="children"
                                  value={
                                    !this.state.recentTravel
                                      ? this.state.fetchingRegion
                                        ? null
                                        : 1
                                      : this.state.fetchingRegion
                                      ? null
                                      : this.state.region_id
                                  }
                                  onChange={(value) =>
                                    this.setState(
                                      {
                                        region_id: value,
                                      },
                                      async () => {
                                        let {onSetDynamicInput} =
                                          this.props.dynamicTabAction;
                                        let {dynamicInputs} = this.props;

                                        if (
                                          typeof onSetDynamicInput ===
                                          "function"
                                        ) {
                                          let payload = {
                                            ...dynamicInputs,
                                            region_id: this.state.region_id,
                                          };
                                          onSetDynamicInput(payload);
                                        }
                                        // TODO:417
                                        if (this.props.gender !== "female") {
                                          await this.handleGetDiagnosis();
                                        } else {
                                          await this.handleGetDiagnosisFemale();
                                        }
                                      }
                                    )
                                  }
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                >
                                  {this.state.regionsData.length > 0 &&
                                    this.state.regionsData.map((region) => {
                                      return (
                                        <Select.Option
                                          value={region.nhs_id}
                                        >{`${region.name} `}</Select.Option>
                                      );
                                    })}
                                </Select>
                                {/* )} */}
                              </div>
                            </div>
                          </div>
                          {/* )} */}
                          {
                            // this.props.fromOngoingConsultation &&
                            this.state.diagnosisData !== null && (
                              <div className="">
                                {this.state.diagnosisData &&
                                  !isEmpty(this.state.diagnosisData) && (
                                    // this.state.diagnosisData.length > 0 &&
                                    <div className="tabledata push-10-b">
                                      {this.state.searchingDiagnose ? (
                                        <span className="d-flex justify-content-center align-items-center">
                                          <Spin />
                                        </span>
                                      ) : (
                                        <SymmtomDiagnosisTabs
                                          gender={this.props.gender}
                                          diagnosisData={
                                            this.state.diagnosisData
                                          }
                                          loading={this.state.searchingDiagnose}
                                          patient_id={
                                            this.props.patientId ||
                                            this.props.patient_id
                                          }
                                          updateSnomedCodes={(found) => {
                                            this.setState(
                                              {
                                                selectedCodesArray: uniqBy(
                                                  [
                                                    ...(selectedCodesArray ||
                                                      []),
                                                    {
                                                      id: found.id,
                                                      code: found.code,
                                                      description:
                                                        found.description,
                                                    },
                                                  ],
                                                  "code"
                                                ).filter((item) => {
                                                  return !this.state.symptompSnomedCode.some(
                                                    (item2) =>
                                                      +item2.id === item.id
                                                  );
                                                }),
                                                newselectedCodesArray: uniqBy(
                                                  [
                                                    ...(newselectedCodesArray ||
                                                      []),
                                                    {
                                                      id: found.id,
                                                      code: found.code,
                                                      description:
                                                        found.description,
                                                    },
                                                  ],
                                                  "code"
                                                ).filter((item) => {
                                                  return !this.state.symptompSnomedCode.some(
                                                    (item2) =>
                                                      +item2.id === item.id
                                                  );
                                                }),
                                                symptompSnomedCode: [
                                                  // ...this.state
                                                  //   .symptompSnomedCode,
                                                  {
                                                    id: found.id,
                                                    code: found.code,
                                                    description:
                                                      found.description,
                                                  },
                                                ],
                                              },
                                              () => {
                                                let {onSetDynamicInput} =
                                                  this.props.dynamicTabAction;
                                                let {dynamicInputs} =
                                                  this.props;

                                                if (
                                                  typeof onSetDynamicInput ===
                                                  "function"
                                                ) {
                                                  let payload = {
                                                    ...dynamicInputs,
                                                    history: this.state.history,
                                                    reason_text:
                                                      this.state.reason_text,
                                                    reason_text_string: "",
                                                    symptomsData:
                                                      this.state.symptomsData,
                                                    diagnosisData:
                                                      this.state.diagnosisData,
                                                    selectedCodesArray:
                                                      this.state
                                                        .selectedCodesArray,
                                                    selectedSnomedCodesArray:
                                                      this.state
                                                        .selectedCodesArray,
                                                    symptompSnomedCode:
                                                      this.state
                                                        .symptompSnomedCode,
                                                    newselectedCodesArray:
                                                      this.state
                                                        .newselectedCodesArray,
                                                  };
                                                  onSetDynamicInput(payload);
                                                }
                                              }
                                            );
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                              </div>
                            )
                          }
                          {/* <div className="push-10-b">
                                <div className="infoshortcodebox">
                                  <AutoTextArea
                                    type="text"
                                    className="form-control-textarea input-bg-f9f9f9 form-control
                                     custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus"
                                    value="Adding Information about educating on Short Codes"
                                  />
                                </div>
                              </div> */}
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-7">
                          <div className="push-10-b">
                            <div className="consultant_review">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                                Examination
                              </p>

                              <AutoTextArea
                                autoSize={true}
                                type="text"
                                className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                  errors &&
                                  errors.examination &&
                                  "danger-border"
                                } custom-input font-12 weight-400 placeholder-10  no-focus min-height-92`}
                                name="examination"
                                value={examination}
                                onChange={this._handleTextChange}
                                placeholder="Write examination detail here"
                                id="examination_textarea"
                                scrollToBottomForTextArea={() =>
                                  this.scrollToBottomForTextArea(
                                    "examination_textarea"
                                  )
                                }
                              />
                              {/* <ExaminationEditor
                                _handleTextChange={(e) =>
                                  this._handleTextChange(e, "examination")
                                }
                                errors={errors}
                                setState={this.setState}
                                examination={examination}
                              /> */}
                              {errors && (
                                <span className="validate-danger font-14">
                                  {errors.examination}
                                </span>
                              )}
                            </div>
                          </div>
                          {
                            <div className="checkboxgroup">
                              <div className="form-row m-0">
                                {checkboxStateArray.map((item, index) => {
                                  return (
                                    <div
                                      className={`col p-0 ${
                                        item.short_text === "Pregnancy"
                                          ? "preg_input"
                                          : item.short_text === "Stroke"
                                          ? "highlighted-stroke"
                                          : item.short_text === "Sepsis"
                                          ? "highlighted-sepsis"
                                          : item.short_text === "PHQ-9"
                                          ? "highlighted-phq9"
                                          : item.short_text === "GAD-7"
                                          ? "highlighted-gad7"
                                          : item.short_text === "Centor throat"
                                          ? "highlighted_throat"
                                          : item.short_text === "Wells DVT"
                                          ? "highlighted_dvt"
                                          : ""
                                      }`}
                                      key={item.snomed_code}
                                    >
                                      <div className="inputbox highlightedtext d-flex align-items-center">
                                        <a
                                          href={item.link}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {item.short_text}
                                        </a>
                                        <div className="numberinput">
                                          {item.short_text !== "Pregnancy" && (
                                            <InputNumber
                                              className="custom-input-number"
                                              formatter={this.handleFormater}
                                              parser={(value) =>
                                                this.handleParser(
                                                  value,
                                                  item.allowNegativeValue
                                                )
                                              }
                                              onChange={(e) =>
                                                this.setState((state) => {
                                                  let stateItems =
                                                    state.checkboxStateArray;
                                                  stateItems[index]["value"] =
                                                    e;
                                                  return {
                                                    checkboxStateArray:
                                                      stateItems,
                                                  };
                                                })
                                              }
                                              value={item.value || ""}
                                              type="number"
                                              onBlur={() =>
                                                this.handleBlur(item, index)
                                              }
                                              onKeyPress={(e) =>
                                                this.handleKeyPress(
                                                  e,
                                                  item,
                                                  index
                                                )
                                              }
                                              precision={2}
                                              onKeyUp={this.handleKeyUp}
                                            />
                                          )}
                                          {item.short_text === "Pregnancy" && (
                                            <div>
                                              <InputMask
                                                mask={"99/99/9999"}
                                                placeholder="DD/MM/YYYY"
                                                // className="custom-input-number"
                                                {...this.props}
                                                onChange={(e) => {
                                                  this.setState((state) => {
                                                    let stateItems =
                                                      state.checkboxStateArray;
                                                    stateItems[index]["value"] =
                                                      e.target.value;
                                                    return {
                                                      checkboxStateArray:
                                                        stateItems,
                                                    };
                                                  });
                                                }}
                                                value={item.value}
                                                onBlur={() =>
                                                  this.handleBlur(
                                                    item,
                                                    index,
                                                    true
                                                  )
                                                }
                                                onKeyDown={(e) =>
                                                  this.handleKeyPress(
                                                    e,
                                                    item,
                                                    index,
                                                    true
                                                  )
                                                }
                                                onKeyUp={() =>
                                                  this.handleKeyUp(true)
                                                }
                                              >
                                                {(inputProps) => (
                                                  <input
                                                    {...inputProps}
                                                    type="text"
                                                    className="custom-input-number"
                                                    name={this.props.name}
                                                  />
                                                )}
                                              </InputMask>
                                              {/* <Input
                                                    className="custom-input-number"
                                                    onChange={(e) =>
                                                      this.setState((state) => {
                                                        let stateItems =
                                                          state.checkboxStateArray;
                                                        stateItems[index][
                                                          "value"
                                                        ] = e.target.value;
                                                        return {
                                                          checkboxStateArray:
                                                            stateItems,
                                                        };
                                                      })
                                                    }
                                                    value={item.value}
                                                    onBlur={() =>
                                                      this.handleBlur(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                    onKeyPress={(e) =>
                                                      this.handleKeyPress(
                                                        e,
                                                        item,
                                                        index
                                                      )
                                                    }
                                                    precision={2}
                                                    onKeyUp={this.handleKeyUp}
                                                  /> */}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          }
                          <div className="push-10-b">
                            <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize diagnosisdrop">
                              Diagnosis / Clinical Impressions
                            </p>

                            <div className="consultaiondetail-main">
                              {/* //commented poup flow, extarct to main screen */}

                              <div className="in-block d-flex">
                                {/* //commented poup flow, extarct to main screen */}

                                <form className="createtableform">
                                  <div className="form-group push-10-b">
                                    <div className="form-row">
                                      <div className="col-md-12">
                                        <CreatableSelect
                                          // isMulti
                                          // className={`form-control margin-0 ${
                                          //   errors && errors.snomedCodeId && "danger-border"
                                          // } indication-dropdown`}
                                          placeholder="Search and select..."
                                          className="disselectfield"
                                          classNamePrefix="react-select"
                                          isClearable
                                          onChange={(value) =>
                                            this._handleDropDownChange(
                                              value,
                                              "snomedCodeId"
                                            )
                                          }
                                          components={{
                                            DropdownIndicator: null,
                                          }}
                                          onInputChange={(value) => {
                                            this.setState({
                                              snomedCodeId: value,
                                            });
                                            this.searchUpdate(value);
                                          }}
                                          onCreateOption={
                                            this._handleCustomCodeAdd
                                          }
                                          value={
                                            this.state.snomedCodeId
                                              ? this.state.snomedCodeId
                                              : ""
                                          }
                                          inputValue={
                                            this.state.snomedCodeId
                                              ? this.state.snomedCodeId
                                              : ""
                                          }
                                          name="snomedCodeId"
                                          options={
                                            snomedCodes.length > 0
                                              ? snomedCodes
                                              : []
                                          }
                                          isLoading={searching}
                                          loadingMessage={() => (
                                            <span>Fetching Snomed ...</span>
                                          )}
                                          onMenuScrollToBottom={
                                            this.handleScroll
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xs-12 bg-white coding_modal_table push-10-t">
                                    <table className="table live-talk2-table">
                                      <thead>
                                        <tr>
                                          <th className="font-12 weight-500 text-black">
                                            Code
                                          </th>
                                          <th className="font-12 weight-500 text-black">
                                            Description
                                          </th>
                                          <th className="font-12 weight-500 text-black"></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {selectedCodesArray
                                          ? selectedCodesArray.length === 0 && (
                                              <tr className="ng-hide">
                                                <td
                                                  colSpan="3"
                                                  className="text-center text-dark-gray font-12 weight-400 default-padding"
                                                >
                                                  No codes selected
                                                </td>
                                              </tr>
                                            )
                                          : null}
                                        {selectedCodesArray
                                          ? isArray(selectedCodesArray) &&
                                            selectedCodesArray.length > 0 &&
                                            this.removeDuplicates(
                                              selectedCodesArray
                                            ).map((code) => {
                                              let ids = map(
                                                this.state
                                                  .newselectedCodesArray ?? [],
                                                "id"
                                              );

                                              let disabledClass = ids.includes(
                                                code?.id
                                              )
                                                ? ""
                                                : "disabled-view";
                                              return (
                                                <tr>
                                                  <td className="font-12 weight-400 default-padding text-light-black">
                                                    {verifyObject(
                                                      code,
                                                      "code",
                                                      ""
                                                    )}
                                                  </td>
                                                  <td className="font-12 weight-400 default-padding text-light-black">
                                                    {verifyObject(
                                                      code,
                                                      "description",
                                                      ""
                                                    )}
                                                  </td>
                                                  <td
                                                    className={`font-12 weight-400 default-padding text-light-black text-right relative ${disabledClass}`}
                                                  >
                                                    {" "}
                                                    <span
                                                      onClick={() =>
                                                        this._removeCode(code)
                                                      }
                                                      className="text-right text-dark-blue weight-400 hide_outline"
                                                      ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                                      role="button"
                                                      tabIndex="0"
                                                    >
                                                      <img
                                                        src={deleteImage}
                                                        alt="Add Coding"
                                                        height="12"
                                                        width="12"
                                                      />
                                                    </span>
                                                  </td>
                                                </tr>
                                              );
                                            })
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 consultantion-info-table-wrapper p-t-0">
                          <div className="table-wrapper maintable">
                            <div className="border">
                              <div className="bg-gray tablehead d-flex">
                                <div className="padding-5 w-43">
                                  <b>Key</b>
                                </div>
                                <div className="padding-5 w-23">
                                  <b>Value</b>
                                </div>
                                <div className="padding-5 flex-1"></div>
                              </div>
                              <div className="tablebodymain">
                                <div className="tablerow d-flex">
                                  <div className="highlightedtext d-flex">
                                    <div className="keytd">Pulse</div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.pulseObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.pulseObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.pulseObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.pulseObject,
                                              "value",
                                              ""
                                            )
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          // className="custom-input-number"
                                          className={this.renderInputColor(
                                            this.state.pulse_per_minute,
                                            "pulse_per_minute"
                                          )}
                                          min={30}
                                          max={200}
                                          type="number"
                                          value={this.state.pulse_per_minute}
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[0]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "pulse_per_minute"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "pulse_per_minute"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 0);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "pulse_per_minute"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 0);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.pulse_per_minute}
                                          </span>
                                        )}

                                        <span className="ml-1">bpm</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="highlightedtext d-flex">
                                    <div className="keytd">SpO2</div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state
                                              .blood_oxygen_saturationObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state
                                                    .blood_oxygen_saturationObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state
                                            .blood_oxygen_saturationObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state
                                                .blood_oxygen_saturationObject,
                                              "value",
                                              ""
                                            )
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          // className="custom-input-number"
                                          className={this.renderInputColor(
                                            this.state.blood_oxygen_saturation,
                                            "blood_oxygen_saturation"
                                          )}
                                          min={50}
                                          max={100}
                                          type="number"
                                          value={
                                            this.state.blood_oxygen_saturation
                                          }
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[3]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "blood_oxygen_saturation"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key ===
                                                    "blood_oxygen_saturation"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 3);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key ===
                                                    "blood_oxygen_saturation"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 3);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.blood_oxygen_saturation}
                                          </span>
                                        )}

                                        <span className="ml-1">%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tablerow d-flex">
                                  <div className="highlightedtext d-flex">
                                    <div className="keytd">Height </div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.heightObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.heightObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.heightObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.heightObject,
                                              "value",
                                              ""
                                            ) * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          className="custom-input-number"
                                          min={100}
                                          max={250}
                                          type="number"
                                          value={this.state.height}
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[5]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "height"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (i.key === "height") {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 5);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (i.key === "height") {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 5);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.height}
                                          </span>
                                        )}

                                        <span className="ml-1">cm</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="highlightedtext d-flex bigfields">
                                    <div className="keytd">Weight</div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.weightObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.weightObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.weightObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.weightObject,
                                              "value",
                                              ""
                                            )
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          className="custom-input-number"
                                          min={25}
                                          max={250}
                                          type="number"
                                          value={this.state.weight}
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[6]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "weight"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (i.key === "weight") {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 6);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (i.key === "weight") {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 6);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.weight}
                                          </span>
                                        )}

                                        <span className="ml-1">kg</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* BMI CHANGES START */}
                                <div className="tablerow d-flex">
                                  <div className="highlightedtext d-flex">
                                    <div className="keytd">BMI </div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.bmiObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.bmiObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.bmiObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.bmiObject,
                                              "value",
                                              ""
                                            )
                                          : // * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                            "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          className="custom-input-number"
                                          // min={100}
                                          // max={250}
                                          type="number"
                                          readOnly
                                          value={this.state.bmi}
                                          // onChange={(e) =>
                                          //   this.setState(
                                          //     (state) => {
                                          //       let stateItems =
                                          //         state.healthStateArray;
                                          //       stateItems[5]["value"] = e;
                                          //       return {
                                          //         healthStateArray:
                                          //           stateItems,
                                          //       };
                                          //     },
                                          //     () => {
                                          //       this.handleHealthTextChange(
                                          //         e,
                                          //         "height"
                                          //       );
                                          //     }
                                          //   )
                                          // }
                                          // onBlur={() => {
                                          //   let obj =
                                          //     this.state.healthStateArray.find(
                                          //       (i, index) => {
                                          //         if (i.key === "height") {
                                          //           return i;
                                          //         }
                                          //       }
                                          //     );

                                          //   this.handleBlurHA(obj, 5);
                                          // }}
                                          // onKeyPress={(e) => {
                                          //   let obj =
                                          //     this.state.healthStateArray.find(
                                          //       (i, index) => {
                                          //         if (i.key === "height") {
                                          //           return i;
                                          //         }
                                          //       }
                                          //     );
                                          //   this.handleKeyPressHA(
                                          //     e,
                                          //     obj,
                                          //     5
                                          //   );
                                          // }}
                                          // onKeyUp={this.handleKeyUp}
                                        />
                                        {/* {errors && (
                                              <span className="validate-danger font-14">
                                                {errors.bmi}
                                              </span>
                                            )} */}

                                        {/* <span className="ml-1">cm</span> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="highlightedtext d-flex bigfields">
                                    <div className="keytd">Temp</div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.body_temperatureObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state
                                                    .body_temperatureObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.body_temperatureObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.body_temperatureObject,
                                              "value",
                                              ""
                                            )
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          // className="custom-input-number"
                                          className={this.renderInputColor(
                                            this.state.body_temperature,
                                            "body_temperature"
                                          )}
                                          min={25}
                                          max={60}
                                          type="number"
                                          value={this.state.body_temperature}
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[4]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "body_temperature"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "body_temperature"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 4);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "body_temperature"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 4);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.body_temperature}
                                          </span>
                                        )}

                                        <span className="ml-1">°C</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* BMI CHANGES END */}

                                <div className="tablerow d-flex">
                                  <div className="highlightedtext d-flex bpfields bigfields">
                                    <div className="keytd">
                                      BP (systolic) /(diastolic)
                                    </div>
                                    <div className="valuetd">
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.systolic_bpObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.systolic_bpObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.systolic_bpObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? `${verifyObject(
                                              this.state.systolic_bpObject,
                                              "value",
                                              ""
                                            )} /`
                                          : "-"}
                                      </Tooltip>
                                      <br />
                                      <Tooltip
                                        placement="top"
                                        title={` ${
                                          verifyObject(
                                            this.state.diastolic_bpObject,
                                            "created_at",
                                            ""
                                          ) !== ""
                                            ? moment(
                                                verifyObject(
                                                  this.state.diastolic_bpObject,
                                                  "created_at",
                                                  ""
                                                )
                                              ).format(DATE_FORMAT_24_HR)
                                            : ""
                                        }`}
                                      >
                                        {verifyObject(
                                          this.state.diastolic_bpObject,
                                          "value",
                                          ""
                                        ) !== ""
                                          ? verifyObject(
                                              this.state.diastolic_bpObject,
                                              "value",
                                              ""
                                            )
                                          : "-"}
                                      </Tooltip>
                                    </div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center two_input">
                                        <div className="input-wrapper">
                                          <InputNumber
                                            // className="custom-input-number"
                                            className={this.renderInputColor(
                                              this.state.systolic_bp,
                                              "systolic_bp"
                                            )}
                                            min={40}
                                            max={300}
                                            type="number"
                                            value={this.state.systolic_bp}
                                            onChange={(e) =>
                                              this.setState(
                                                (state) => {
                                                  let stateItems =
                                                    state.healthStateArray;
                                                  stateItems[2]["value"] = e;
                                                  return {
                                                    healthStateArray:
                                                      stateItems,
                                                  };
                                                },
                                                () => {
                                                  this.handleHealthTextChange(
                                                    e,
                                                    "systolic_bp"
                                                  );
                                                }
                                              )
                                            }
                                            onBlur={() => {
                                              let obj =
                                                this.state.healthStateArray.find(
                                                  (i, index) => {
                                                    if (
                                                      i.key === "systolic_bp"
                                                    ) {
                                                      return i;
                                                    }
                                                  }
                                                );

                                              this.handleBlurHA(obj, 2);
                                            }}
                                            onKeyPress={(e) => {
                                              let obj =
                                                this.state.healthStateArray.find(
                                                  (i, index) => {
                                                    if (
                                                      i.key === "systolic_bp"
                                                    ) {
                                                      return i;
                                                    }
                                                  }
                                                );
                                              this.handleKeyPressHA(e, obj, 2);
                                            }}
                                            onKeyUp={this.handleKeyUp}
                                          />
                                          {errors && (
                                            <span className="validate-danger font-14">
                                              {errors.systolic_bp}
                                            </span>
                                          )}

                                          <span>/</span>
                                          <InputNumber
                                            // className="custom-input-number"
                                            className={this.renderInputColor(
                                              this.state.diastolic_bp,
                                              "diastolic_bp"
                                            )}
                                            min={20}
                                            max={200}
                                            type="number"
                                            value={this.state.diastolic_bp}
                                            onChange={(e) =>
                                              this.setState(
                                                (state) => {
                                                  let stateItems =
                                                    state.healthStateArray;
                                                  stateItems[1]["value"] = e;
                                                  return {
                                                    healthStateArray:
                                                      stateItems,
                                                  };
                                                },
                                                () => {
                                                  this.handleHealthTextChange(
                                                    e,
                                                    "diastolic_bp"
                                                  );
                                                }
                                              )
                                            }
                                            onBlur={() => {
                                              let obj =
                                                this.state.healthStateArray.find(
                                                  (i, index) => {
                                                    if (
                                                      i.key === "diastolic_bp"
                                                    ) {
                                                      return i;
                                                    }
                                                  }
                                                );

                                              this.handleBlurHA(obj, 1);
                                            }}
                                            onKeyPress={(e) => {
                                              let obj =
                                                this.state.healthStateArray.find(
                                                  (i, index) => {
                                                    if (
                                                      i.key === "diastolic_bp"
                                                    ) {
                                                      return i;
                                                    }
                                                  }
                                                );
                                              this.handleKeyPressHA(e, obj, 1);
                                            }}
                                            onKeyUp={this.handleKeyUp}
                                          />
                                          {errors && (
                                            <span className="validate-danger font-14">
                                              {errors.diastolic_bp}
                                            </span>
                                          )}
                                        </div>
                                        <span className="ml-1">mmHg</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tablerow d-flex">
                                  <div className="highlightedtext d-flex">
                                    <div className="keytd">
                                      Respiratory rate
                                    </div>
                                    <div className="valuetd"></div>
                                    <div className="inputtd">
                                      <div className="d-flex align-items-center">
                                        <InputNumber
                                          className="custom-input-number"
                                          min={10}
                                          max={60}
                                          type="number"
                                          value={this.state.respiratory_rate}
                                          onChange={(e) =>
                                            this.setState(
                                              (state) => {
                                                let stateItems =
                                                  state.healthStateArray;
                                                stateItems[7]["value"] = e;
                                                return {
                                                  healthStateArray: stateItems,
                                                };
                                              },
                                              () => {
                                                this.handleHealthTextChange(
                                                  e,
                                                  "respiratory_rate"
                                                );
                                              }
                                            )
                                          }
                                          onBlur={() => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "respiratory_rate"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );

                                            this.handleBlurHA(obj, 7);
                                          }}
                                          onKeyPress={(e) => {
                                            let obj =
                                              this.state.healthStateArray.find(
                                                (i, index) => {
                                                  if (
                                                    i.key === "respiratory_rate"
                                                  ) {
                                                    return i;
                                                  }
                                                }
                                              );
                                            this.handleKeyPressHA(e, obj, 7);
                                          }}
                                          onKeyUp={this.handleKeyUp}
                                        />
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.respiratory_rate}
                                          </span>
                                        )}

                                        <span className="ml-1">bpm</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-row push-10-b">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                          Management Plan
                        </p>
                        {/* {`managementPlan 1212 :`} */}

                        <AutoTextArea
                          autoSize={true}
                          type="text"
                          className={`form-control-textarea input-bg-f9f9f9 form-control ${
                            errors && errors.managementPlan && "danger-border"
                          } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                          name="managementPlan"
                          value={managementPlan}
                          onChange={this._handleTextChange}
                          placeholder="Add your Management Plan"
                          ref={this.scollRef}
                          id="management_textarea"
                          scrollToBottomForTextArea={() =>
                            this.scrollToBottomForTextArea(
                              "management_textarea"
                            )
                          }
                        />
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.managementPlan}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* )} */}
                    {presriptionPlan && presriptionPlan !== "" && (
                      <div
                        className="form-row push-10-b"
                        // ref={this.scollRef}
                      >
                        <div className="col-md-12 consultant_review">
                          <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                            Prescription Plan
                          </p>

                          <AutoTextArea
                            autoSize={true}
                            type="text"
                            className={`form-control-textarea input-bg-f9f9f9 form-control ${
                              errors && errors.managementPlan && "danger-border"
                            } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                            name="presriptionPlan"
                            value={presriptionPlan}
                            // onChange={this._handleTextChange}
                            // disabled
                            placeholder="Add your Prescription Plan"
                            id="prescription_textarea"
                            scrollToBottomForTextArea={() =>
                              this.scrollToBottomForTextArea(
                                "prescription_textarea"
                              )
                            }
                          />
                          {errors && (
                            <span className="validate-danger font-14">
                              {errors.presriptionPlan}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    {!isEmpty(barcodeNumber) && (
                      <div className="form-row push-10-b">
                        <div className="col-md-12 consultant_review">
                          <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                            BARCODE NUMBER
                          </p>
                          <Input.TextArea
                            autoSize={true}
                            type="text"
                            className={`form-control-textarea input-bg-f9f9f9 form-control ${
                              errors && errors.barcodeNumber && "danger-border"
                            } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                            name="barcodeNumber"
                            value={barcodeNumber}
                            onChange={this._handleTextChange}
                            placeholder="Enter BARCODE Number"
                          />
                          {errors && (
                            <span className="validate-danger font-14">
                              {errors.barcodeNumber}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {
                      <div className="push-10-t">
                        {havePharmacistDesignationCondition && (
                          <button
                            onClick={this.handleAddPrescription}
                            className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 mr-1"
                          >
                            Add Prescription
                          </button>
                        )}
                        {(this.props.userRole === ROLE_DOCTOR ||
                          this.props.userRole ===
                            ROLE_SUPERINTENDENT_PHARMACIST ||
                          this.props.userRole === ROLE_CLINICAL_PHARMACIST) && (
                          <button
                            // disabled={
                            //   this.props.offlineCallStatus !== "finish"
                            // }
                            onClick={() => this._handleSubmit(false)}
                            className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0"
                          >
                            Submit
                          </button>
                          // </div>
                        )}
                      </div>
                    }
                  </div>
                </div>
                <InformationalOutcomeModal
                  _handleSubmit={this._handleModalSubmission}
                  _hideModal={this._hideModal}
                  visible={visible}
                  changing={addingDetail}
                  submitting={this.state.addingDetail}
                  _handleTextChange={this._handleTextChange}
                  _handleUpdateoutcome={this._handleUpdateoutcome}
                  fromPreConsult={true}
                  {...this.state}
                />
              </div>
            </Pane>
            <Pane initialSize={splitSize ? "53px" : "495px"}>
              <div
                className={`walkingappointmentsecscroll mt-10 ${secondPanelClassName}`}
              >
                <div className="dragbtn">
                  <a className="d-flex btn-updown" onClick={this.handleButtonClick}>
                    <span className="uprrow">
                      <CaretUpOutlined />
                    </span>
                    <span className="downarrow">
                      <CaretDownOutlined />
                    </span>
                  </a>
                </div>
                <div className="selection_container container-fluid padding-left-10 padding-right-10">
                  {
                    <div className="commnspace">
                      <Tabs
                        onChange={this.handleTabChange}
                        // defaultActiveKey="preencounters"
                        activeKey={this.state.tabKey}
                        destroyInactiveTabPane={true}
                      >
                        <TabPane
                          tab={
                            <div>
                              <span className="icons_wrapper">
                                <span className="icons_wrapper_img_box"></span>
                                <span className="icons_wrapper_title">
                                  Prev.Encounters{" "}
                                  {this.state.tabKey === "preencounters" && (
                                    <span className="in-block">
                                      {isLoadingPreEncounter
                                        ? ""
                                        : `(${verifyObject(
                                            paginationPreEncounter,
                                            "total",
                                            ""
                                          )})`}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </div>
                          }
                          key={"preencounters"}
                          disabled={isLoading}
                        >
                          {this.props.appointmentId && (
                            <PreEncountersContainer fromConsultation={true} />
                          )}
                        </TabPane>
                        <TabPane
                          tab={
                            <div>
                              <span className="icons_wrapper">
                                <span className="icons_wrapper_img_box"></span>
                                <span className="icons_wrapper_title">
                                  Prescriptions{" "}
                                  {this.state.tabKey === "prescriptions" && (
                                    <span className="in-block">
                                      {isLoadingPrescription
                                        ? ""
                                        : `(${verifyObject(
                                            paginationPrescription,
                                            "total",
                                            ""
                                          )})`}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </div>
                          }
                          key={"prescriptions"}
                          disabled={isLoading}
                        >
                          <PrescriptionsContainer
                            fromConsultation={true}
                            // requestFirst={
                            //   dynamicInputs === null && history === ""
                            //     ? true
                            //     : false
                            // }
                          />
                        </TabPane>{" "}
                        <TabPane
                          tab={
                            <div>
                              <span className="icons_wrapper">
                                <span className="icons_wrapper_img_box"></span>
                                <span className="icons_wrapper_title">
                                  Sick Note{" "}
                                  {this.state.tabKey === "sicknote" && (
                                    <span className="in-block">
                                      {isLoadingPrescription
                                        ? ""
                                        : `(${verifyObject(
                                            paginationPrescription,
                                            "total",
                                            ""
                                          )})`}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </div>
                          }
                          key={"sicknote"}
                          disabled={isLoading}
                        >
                          <SickNotesContainer
                            fromConsultation={true}
                            requestFirst={
                              dynamicInputs === null && history === ""
                                ? true
                                : false
                            }
                          />
                        </TabPane>{" "}
                        <TabPane
                          tab={
                            <div>
                              <span className="icons_wrapper">
                                <span className="icons_wrapper_img_box"></span>
                                <span className="icons_wrapper_title">
                                  Referral Note{" "}
                                  {this.state.tabKey === "referralnote" && (
                                    <span className="in-block">
                                      {isLoadingPrescription
                                        ? ""
                                        : `(${verifyObject(
                                            paginationPrescription,
                                            "total",
                                            ""
                                          )})`}
                                    </span>
                                  )}
                                </span>
                              </span>
                            </div>
                          }
                          key={"referralnote"}
                          disabled={isLoading}
                        >
                          <ReferralNotesContainer
                            fromConsultation={true}
                            // requestFirst={
                            //   dynamicInputs === null && history === ""
                            //     ? true
                            //     : false
                            // }
                          />
                        </TabPane>
                        <TabPane
                          tab={
                            <div>
                              <span className="icons_wrapper">
                                <span className="icons_wrapper_img_box"></span>
                                <span className="icons_wrapper_title">
                                  Screenshots
                                </span>
                              </span>
                            </div>
                          }
                          key={"screenshots"}
                          disabled={isLoading}
                        >
                          <CallScreenShotContainer
                            fromConsultation={true}
                            // requestFirst={
                            //   dynamicInputs === null && history === ""
                            //     ? true
                            //     : false
                            // }
                          />
                        </TabPane>
                      </Tabs>
                    </div>
                  }
                </div>
              </div>
            </Pane>
          </SplitPane>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  console.log(
    "PRE dynamicInputs",
    verifyObject(state, "dynamicTab.dynamicInputs", null)
  );
  return {
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    prev_appt_tab_key: verifyObject(
      state.localStore,
      "prev_appt_tab_key",
      "finished"
    ),
    preEnccounter: verifyObject(state, "preEnccounter", []),
    prescriptions: verifyObject(state, "referralNotes", []),
    appointmentId: state.localStore?.appointment_obj?.appointment_obj
      ? verifyObject(
          state.localStore,
          "appointment_obj.appointment_obj.id",
          null
        )
      : state.localStore?.appointment_obj.id,
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    isPrescriptionAdded: verifyObject(
      state.localStore,
      "is_prescription_added.is_presription_added",
      null
    ),
    isOwnPharamcy: verifyObject(
      state.localStore,
      "is_prescription_added.is_own_pharamcy",
      false
    ),
    prescriptionCode: verifyObject(
      state.localStore,
      "is_prescription_added.prescription_code",
      null
    ),
    patientDOB: verifyObject(
      state.localStore,
      "patient_detail_obj.date_of_birth",
      null
    ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    consultation_tab_key: verifyObject(
      state.localStore,
      "consultation_tab_key",
      "preencounters"
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    systolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.systolic_bp",
      ""
    ),
    diastolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.diastolic_bp",
      ""
    ),
    pulse_per_minute: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.pulse_per_minute",
      ""
    ),
    respiratory_rate: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.respiratory_rate",
      ""
    ),
    blood_oxygen_saturation: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.blood_oxygen_saturation",
      ""
    ),
    body_temperature: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.body_temperature",
      ""
    ),
    height: verifyObject(state.dynamicTab, "dynamicHealthInputs.height", ""),
    submitedPreConsultQuestionnaire: verifyObject(
      state.localStore,
      "dynamicInputs.submitedQuestionnairesArray",
      []
    ),

    weight: verifyObject(state.dynamicTab, "dynamicHealthInputs.weight", ""),
    bmi: verifyObject(state.dynamicTab, "dynamicHealthInputs.bmi", ""),
    userRole: verifyObject(state, "localStore.user.role", null),
    show_submit_from_notes: verifyObject(
      state,
      "dynamicTab.show_submit_from_notes",
      false
    ),
    offlineCallStatus: verifyObject(
      state.localStore,
      "offline_call_status",
      null
    ),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    show_prescriotion_modal_from_notes: verifyObject(
      state,
      "dynamicTab.show_prescriotion_modal_from_notes",
      null
    ),

    gender: verifyObject(state.localStore, "patient_detail_obj.gender", ""),
    patient_id: verifyObject(
      state.localStore,
      "patient_detail_obj.user.id",
      ""
    ),

    reason_text: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text",
      ""
    ),
    current_corporate : state?.localStore?.user?.corporate_id,
    reason_text_string: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text_string",
      ""
    ),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    is_pharmsmart_user: verifyObject(
      state,
      "localStore.user.is_pharmsmart_user",
      null
    ),
    is_consultation_api_started: verifyObject(
      state,
      "dynamicTab.is_consultation_api_started",
      null
    ),
    designation: verifyObject(
      state,
      "localStore.user.profile.designation",
      null
    ),
    post_gt_questionnaire: verifyObject(
      state,
      "localStore.post_gt_questionnaire",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onSetSidebarKey,
        onSetDynamicInput,
        onSetDynamicHealthInput,
      },
      dispatch
    ),
    offlineCallActions: bindActionCreators(
      {setOfflineCallStatus, setDocumentViewedFlag},
      dispatch
    ),
    navigationActions: bindActionCreators({navigateTo}, dispatch),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreConsultDetailContainer);

export default ConnectedComponent;
