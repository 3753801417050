import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as emergencyAlertActions } from "reducers/emergencyAlert";
import EmergencyAlertModal from "containers/alerts/EmergencyAlertModal";
import { verifyObject } from "../../utilities/utils";

class EmergencyAlertContainer extends Component {
  componentDidMount() {}
  componentWillUnmount() {
    //this.props.emergencyAlertActions.resetState();
  }
  onSubmit = (values) => {
    return this.props.emergencyAlertActions.onCreateEmergency({
      emergency_alert: {
        ...values,
        appointment_id: this.props.appointmentId,
        patient_id:
          this.props?.patientId ||
          this.props?.patient_id ||
          this.props?.localStore?.patient_detail_obj?.user?.id,
      },
    });
  };
  onClose = () => {
    this.props.emergencyAlertActions.onCloseModal();
  };

  render() {
    const { emergencyAlert } = this.props;
    return (
      <Fragment>
        {emergencyAlert.modal.isOpen ? (
          <EmergencyAlertModal
            onSubmit={this.onSubmit}
            emergencyAlert={emergencyAlert}
            onClose={this.onClose}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    emergencyAlert: state.emergencyAlert,
    patientId: verifyObject(
      state.localStore,
      "patientId",
      null
    ),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    localStore: verifyObject(state, "localStore", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emergencyAlertActions: bindActionCreators(emergencyAlertActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmergencyAlertContainer);
