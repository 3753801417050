/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { isArray, isEmpty, map } from "lodash";
import {
  errorToast,
  getErrorObject,
  resizeTextArea,
  successToast,
  verifyObject,
} from "utilities/utils";
import {
  createKioskPreconsultationAPI,
  createPreconsultation,
  fetchHealthConditions,
  fetchPreConsultQuestionnaire,
  sendPreConsultNotification,
  updatePreconsultation,
} from "services/preConsultConditions";

// import PreConsultQuestionnaire from "./PreConsultQuestionnaire";
import store from "app/store";
import { appRoutesConst } from "app/navigation";
import CommonLoader from "components/common/CommonLoader";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ValidateInput } from "components/patientAddForm/patientAddFormValidations";
import validator from "validator";
import { SPLIT_SYMBOL } from "constants/common";
import PreConsultQuestionnaire from "components/PreConsult/PreConsultQuestionnaire";
import { getQuestionnairDetailApi } from "services/questionnaireReview";
// import BookAppointmentReuse from "./BookAppointmentReuse";
// import "../../assets/css/components/book-appointment.css";

export default function NHSPathFinderContainer({
  patient_id,
  corporate_id,
  patientDetail,
  patient_age,
  dynamicInputs,
  dynamicTabAction,
  isFromPreConsultListing,
  isVisible,
  selecteddHealthCondition,
  hideModalFromPreConsult,
  disabledPreConsultButton,
  fromReviewPreConsult,
  preConsultStatus,
  pre_consult_id,
  next_questionnaire_id,
  doctor_id,
  user,
  preConsultationReviewActions,
  hypertensive,
  systolic_bp,
  diastolic_bp,
  pulse_per_minute,
  screened_bp_values,
  health_scan_log_id,
  preConsultResponseData,
  refreshPreConsultList,
  newSelecteddHealthCondition,
  newPatientDetail,
  questionnaireToken,
  fromVideoF2F,
  updateReasonText,
  appointmentId,
  storedPreConsultOutCome,
  passedPreConsultData,
  closePathFinderModal,
  nhsPathFinderModal,
  ...rest
}) {
  console.log("NOTIFICATIONS selecteddHealthCondition", passedPreConsultData);
  const [visible, setVisible] = React.useState(false);
  const [state, setState] = React.useState({
    // health_condition_id: null,
    send_url_via: null,
    healthConditions: [],
    qrCode: null,
    selectedItem: null,
    preConsultQuestionnaire: [],
    questionDetails: [],
    preConsultOutCome: null,
    happy_to_review_submit: false,
    count: 0,
    isFetching: false,
    isOutComePresent: false,
    isQuestionnaireSubmitted: false,
    questionnaire_indexes: null,
    phone: verifyObject(patientDetail, "phone", null),
    email: verifyObject(patientDetail, "email", ""),
    annswerIntiate: true,

    systolic_bp: systolic_bp,
    diastolic_bp: diastolic_bp,
    pulse_per_minute: pulse_per_minute,
    disabledToggleSlotType: true,
    next_questionnaire_id: next_questionnaire_id,
    isEditedQuestion: false,
    // passedNextQuestionnaireIndex: null,
  });
  const [slotType, setSlotType] = useState("slot_view");
  const [health_condition_id, setHealthConditionId] = React.useState([]);
  const [preConsultQuestionnaire, setPreConsultQuestionnaire] = React.useState(
    []
  );
  const [passedNextQuestionnaireIndex, setPassedNextQuestionnaireIndex] =
    React.useState([]);
  const [search, setSearch] = React.useState("");

  const [isShowBookingAppt, setIsShowBookingAppt] = React.useState(null);
  const [expandClicked, setExpandClicked] = React.useState(null);

  useEffect(() => {
    setState({
      ...state,
      phone: verifyObject(patientDetail, "phone", null),
      email: verifyObject(patientDetail, "email", ""),
    });
  }, []);
  useEffect(() => {
    const debounceHandle = setTimeout(() => {
      _handleSearch(search);
    }, 1000);
    return () => {
      clearTimeout(debounceHandle);
    };
  }, [search]);

  useEffect(() => {
    if (selecteddHealthCondition) {
      setState({
        ...state,
        selectedItem: selecteddHealthCondition,
      });
    }
  }, [visible]);
  useEffect(() => {
    if (visible) {
      setState({
        ...state,
        phone: verifyObject(patientDetail, "phone", null),
        email: verifyObject(patientDetail, "email", ""),
      });
    }
  }, [visible]);
  useEffect(() => {
    // if (isFromPreConsultListing && isVisible && selecteddHealthCondition) {
    //   setVisible(true);
    //   // _fetchPreConsultQuestionnaire(selecteddHealthCondition?.id);
    // }
    if (isVisible) {
      setVisible(true);
      // _fetchPreConsultQuestionnaire(selecteddHealthCondition?.id);
    }
  }, [isVisible]);

  //new changes
  useEffect(() => {
    if (visible && !isFromPreConsultListing) {
      console.log("patient_age", patient_age);
      _getHeatlConditions();
    }
  }, [visible]);
  const _handleSearch = async (value) => {
    if (!isEmpty(value)) {
      setState({
        ...state,
        health_condition_id: [],
        page: 1,
        // searching: true,
        isLoadmore: false,
        // healthConditions: [],
        search: value,
      });

      // await _getHeatlConditions(false);
    } else {
      // await setState({...state, healthConditions: []});
    }
  };

  const _getHeatlConditions = async (isLoadMore) => {
    try {
      let response = await fetchHealthConditions({});
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let healthConditions = verifyObject(response, "data.data", []);
      setState({
        ...state,
        healthConditions: isLoadMore
          ? [...state.healthConditions, ...healthConditions]
          : healthConditions,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const { message } = getErrorObject(error);
      await setState({ ...state, searching: false });
      errorToast({ content: message });
    }
  };
  const fetchMoreData = async () => {
    await setState((prevState) => {
      return {
        ...prevState,
        page: prevState.page + 1,
        readMarked: false,
        searching: true,
        isLoadmore: true,
      };
    });

    await _getHeatlConditions(true);
  };
  const handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let { page, pagination } = state;
    let total_pages = verifyObject(pagination, "total_pages", 0);
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      fetchMoreData();
    }
  };


  useEffect(() => {
    if (nhsPathFinderModal) {
      _fetchPreConsultQuestionnaire();
    }
  }, [nhsPathFinderModal]);

  const _fetchPreConsultQuestionnaire = async (passedId) => {
    // console.log("patientDetail",pass)

    try {
      let payload = {
        // health_condition_id: 135,
        // patient_id: patient_id,
        // corporate_id: corporate_id,
        // ...tokenParams,
        id: process.env.REACT_APP_ENV === "prod" ? 150 : 982, //150
      };
      setState({
        ...state,
        isFetching: true,
      });
      let response = await getQuestionnairDetailApi(payload);
      if (response?.status === 200) {
        const questionnairetemplate =
          response?.data?.data?.questionnaire_template;

        let newPreConsultQuestionnaire =
          questionnairetemplate.question_details.map((e) => {
            e.answer =
              isArray(e.pre_consult_answer?.actual_answer) &&
                e.pre_consult_answer?.actual_answer?.length > 0 &&
                e.pre_consult_answer?.actual_answer[0]
                ? e.pre_consult_answer?.actual_answer[0]
                : e.pre_consult_answer?.actual_answer
                  ? e.pre_consult_answer?.actual_answer
                  : e.answer;
            if (e.category === "multi_choice") {
              // let an = [];
              let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                (d) =>
                  `${d}${SPLIT_SYMBOL}${e?.question_options?.find((v) => v.title === d)?.id
                  }`
              );

              e.chekedAnswer = checkedAnswers;
              e.answer =
                isArray(e.pre_consult_answer?.actual_answer) &&
                  e.pre_consult_answer?.actual_answer.length > 0
                  ? e.pre_consult_answer?.actual_answer
                  : e.answer;
              console.log(
                "IN ANSWWR MULTIC",
                isArray(e.pre_consult_answer?.actual_answer) &&
                  e.pre_consult_answer?.actual_answer.length > 0
                  ? e.pre_consult_answer?.actual_answer
                  : e.answer
              );
            }
            if (e.category === "drop_down") {
              e.answer = map(e.pre_consult_answer?.actual_answer, "id", []);
            }
            e.children.map((f) => {
              f.answer =
                isArray(f.pre_consult_answer?.actual_answer) &&
                  f.pre_consult_answer?.actual_answer?.length > 0 &&
                  f.pre_consult_answer?.actual_answer[0]
                  ? f.pre_consult_answer?.actual_answer[0]
                  : f.pre_consult_answer?.actual_answer
                    ? f.pre_consult_answer?.actual_answer
                    : f.answer;
              if (f.category === "drop_down") {
                f.answer = map(f.pre_consult_answer?.actual_answer, "id", []);
              }
              if (f.category === "multi_choice") {
                // let an = [];
                let checkedAnswers = e.pre_consult_answer?.actual_answer?.map(
                  (d) =>
                    `${d}${SPLIT_SYMBOL}${e?.question_options?.find((v) => v.title === d)?.id
                    }`
                );

                f.chekedAnswer = checkedAnswers;
                f.answer =
                  isArray(f.pre_consult_answer?.actual_answer) &&
                    f.pre_consult_answer?.actual_answer?.length > 0 &&
                    f.pre_consult_answer?.actual_answer.length > 0
                    ? f.pre_consult_answer?.actual_answer
                    : f.answer;
              }
              f.children.map((g) => {
                g.answer =
                  isArray(g.pre_consult_answer?.actual_answer) &&
                    g.pre_consult_answer?.actual_answer?.length > 0 &&
                    g.pre_consult_answer?.actual_answer[0]
                    ? g.pre_consult_answer?.actual_answer[0]
                    : g.pre_consult_answer?.actual_answer
                      ? g.pre_consult_answer?.actual_answer
                      : g.answer;
                return g;
              });
              return f;
            });
            return e;
          });

        console.log("ON SEARCH----", state);
        setState((prevState) => {
          return {
            ...prevState,
            isFetching: false,
            count: prevState.count + 1,
            // questionnaire_indexes: questionnaire_indexes,
            // passedOutCome: passedOutCome,
            // next_questionnaire_id: next_questionnaire_id,
            isEditedQuestion: false,
          };
        });
        console.log("newPreConsultQuestionnaire", newPreConsultQuestionnaire);
        setPreConsultQuestionnaire([questionnairetemplate]);
        // setPassedNextQuestionnaireIndex(nextQuestionnaireIndex);
      }
    } catch (e) {
      let { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      setState({
        ...state,
        isFetching: false,
        count: state.count + 1,
      });
    }
  };
  const _updatepreConsultation = async () => {
    try {
      let { preConsultOutCome } = state;
      console.log(
        "🚀 ~ file: PreConsultNotification.jsx:248 ~ const_createpreConsultation= ~ preConsultOutCome:",
        preConsultOutCome
      );
      //setIsSafetyNetting
      // let formData = new FormData();
      let payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id: preConsultOutCome?.latestAnswerId,
        token: preConsultOutCome?.token,
      };
      // if (preConsultOutCome?.isGateWayPoint) {
      //   formData.set("corporate_id", corporate_id);
      //   formData.set("health_condition_id", health_condition_id);
      //   formData.set(
      //     "patient_answer_ids",
      //     preConsultOutCome?.patient_answer_ids
      //       ?.filter((d) => d !== undefined)
      //       ?.toString()
      //   );
      //   formData.set("patient_id", patient_id);
      //   formData.set("latest_answer_id", preConsultOutCome?.latestAnswerId);
      //   formData.set("token", preConsultOutCome?.token);
      // } else {
      let managementParams =
        preConsultOutCome?.consultationNotes?.management_plan;
      // if (patientDetail?.gp_service) {
      //   managementParams = `${preConsultOutCome?.consultationNotes?.management_plan}\n Patient made aware of the importance of good medical record sharing, Consent given`;
      // }
      let preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note,
      };
      // if (preConsultOutCome.is_saftey_netting) {
      //   preConsultNoteParams = {
      //     pre_consult_note:
      //       preConsultOutCome?.consultationNotes?.pre_consult_note +
      //       "\n" +
      //       "\n" +
      //       "\n" +
      //       "Safety netting provided to patient",
      //   };
      // }
      payload = {
        id: pre_consult_id,
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id:
          preConsultOutCome?.patient_answer_ids?.length === 1
            ? preConsultOutCome?.patient_answer_ids?.toString()
            : preConsultOutCome?.patient_answer_ids[
            preConsultOutCome?.patient_answer_ids?.length - 1
            ],
        token: preConsultOutCome?.token,
        template_id: preConsultOutCome?.template_id,
        consultation: {
          history: preConsultOutCome?.consultationNotes?.history,
          management_plan: managementParams,
          patient_id: patient_id,
          ...preConsultNoteParams,
          pre_consult_tag:
            preConsultOutCome?.consultationNotes?.pre_consult_tag,
          outcome: {
            comment: preConsultOutCome?.consultationNotes?.outcome?.comment,
          },
        },
        snomed_codes: [
          {
            code: preConsultOutCome?.consultationNotes?.snomed_codes?.code,
            description:
              preConsultOutCome?.consultationNotes?.snomed_codes?.description,
            full_code:
              preConsultOutCome?.consultationNotes?.snomed_codes?.full_code,
            id: preConsultOutCome?.consultationNotes?.snomed_codes?.id,
          },
        ],
      };

      setState({ ...state, loading: true });
      let response = await updatePreconsultation(payload);
      console.log("response --->", response);
      store.dispatch({
        type: "SET_PRE_CONSULT_DATA",
        payload: response?.data?.data?.pre_consult,
      });
      // const elmConfirmBtn =  document.getElementById("confirm_btn_answer_submit")
      // if(elmConfirmBtn){
      //   elmConfirmBtn.style.display = "none";
      // }
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      setState({ ...state, loading: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setState({ ...state, loading: false });
    }
  };
  let { selectedItem } = state;
  const _createpreConsultation = async () => {
    try {
      let { preConsultOutCome } = state;
      const urlParams = new URLSearchParams(window.location.search);
      const isfromDetailPreConsultPopup = urlParams.get(
        "isfromDetailPreConsultPopup"
      );

      // console.log(
      //   "🚀 ~ _createpreConsultation storedPreConsultOutCome:",
      //   storedPreConsultOutCome , questionnaireToken,isfromDetailPreConsultPopup
      // );
      console.log("questionnaireToken", questionnaireToken);
      let payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: isfromDetailPreConsultPopup
          ? storedPreConsultOutCome?.patient_answer_ids?.toString()
          : preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id: isfromDetailPreConsultPopup
          ? storedPreConsultOutCome?.latestAnswerId
          : preConsultOutCome?.latestAnswerId,
        token: preConsultOutCome?.token
          ? preConsultOutCome?.token
          : questionnaireToken,
      };

      let managementParams =
        preConsultOutCome?.consultationNotes?.management_plan;

      let preConsultNoteParams = {
        pre_consult_note:
          preConsultOutCome?.consultationNotes?.pre_consult_note,
      };
      if (isfromDetailPreConsultPopup) {
        preConsultNoteParams = {
          pre_consult_note:
            storedPreConsultOutCome?.consultationNotes?.pre_consult_note,
        };
      }
      // if (preConsultOutCome.is_saftey_netting) {
      //   preConsultNoteParams = {
      //     pre_consult_note:
      //       preConsultOutCome?.consultationNotes?.pre_consult_note +
      //       "\n" +
      //       "\n" +
      //       "\n" +
      //       "Safety netting provided to patient",
      //   };
      // }

      let serviceNameParams = {};
      const selectedServices = selecteddHealthCondition
        ? verifyObject(selecteddHealthCondition, "services", [])
        : verifyObject(selectedItem, "services", []);
      if (selectedServices && selectedServices.length === 1) {
        serviceNameParams = {
          service_name: selectedServices[0],
        };
      } else if (selectedServices && selectedServices.length === 2) {
        // if (preConsultOutCome.serviceName) {
        //   serviceNameParams = {
        //     service_name: preConsultOutCome.serviceName,
        //   };
        // }
      } else {
        serviceNameParams = {};
      }

      payload = {
        corporate_id: corporate_id,
        health_condition_id: health_condition_id,
        patient_answer_ids: preConsultOutCome?.patient_answer_ids?.toString(),
        patient_id: patient_id,
        latest_answer_id:
          preConsultOutCome?.patient_answer_ids?.length === 1
            ? preConsultOutCome?.patient_answer_ids?.toString()
            : preConsultOutCome?.patient_answer_ids[
            preConsultOutCome?.patient_answer_ids?.length - 1
            ],
        token: preConsultOutCome?.token
          ? preConsultOutCome?.token
          : questionnaireToken,
        template_id: preConsultOutCome?.template_id,
        consultation: {
          history: preConsultOutCome?.consultationNotes?.history,
          management_plan: managementParams,
          patient_id: patient_id,
          ...preConsultNoteParams,
          pre_consult_tag:
            preConsultOutCome?.consultationNotes?.pre_consult_tag,
          outcome: {
            comment: preConsultOutCome?.consultationNotes?.outcome?.comment,
          },
        },
        snomed_codes: [
          {
            code: preConsultOutCome?.consultationNotes?.snomed_codes?.code,
            description:
              preConsultOutCome?.consultationNotes?.snomed_codes?.description,
            full_code:
              preConsultOutCome?.consultationNotes?.snomed_codes?.full_code,
            id: preConsultOutCome?.consultationNotes?.snomed_codes?.id,
          },
        ],
        ...serviceNameParams,
        appointment_id: appointmentId,
      };
      if (isfromDetailPreConsultPopup) {
        payload = {
          corporate_id: corporate_id,
          health_condition_id: health_condition_id,
          patient_answer_ids:
            storedPreConsultOutCome?.patient_answer_ids?.toString(),
          patient_id: patient_id,
          latest_answer_id:
            storedPreConsultOutCome?.patient_answer_ids?.length === 1
              ? storedPreConsultOutCome?.patient_answer_ids?.toString()
              : storedPreConsultOutCome?.patient_answer_ids[
              storedPreConsultOutCome?.patient_answer_ids?.length - 1
              ],
          token: storedPreConsultOutCome?.token
            ? storedPreConsultOutCome?.token
            : questionnaireToken,
          template_id: storedPreConsultOutCome?.template_id,
          consultation: {
            history: storedPreConsultOutCome?.consultationNotes?.history,
            management_plan: managementParams,
            patient_id: patient_id,
            ...preConsultNoteParams,
            pre_consult_tag:
              storedPreConsultOutCome?.consultationNotes?.pre_consult_tag,
            outcome: {
              comment:
                storedPreConsultOutCome?.consultationNotes?.outcome?.comment,
            },
          },
          snomed_codes: [
            {
              code: storedPreConsultOutCome?.consultationNotes?.snomed_codes
                ?.code,
              description:
                storedPreConsultOutCome?.consultationNotes?.snomed_codes
                  ?.description,
              full_code:
                storedPreConsultOutCome?.consultationNotes?.snomed_codes
                  ?.full_code,
              id: storedPreConsultOutCome?.consultationNotes?.snomed_codes?.id,
            },
          ],
          ...serviceNameParams,
          appointment_id: appointmentId,
        };
      }

      // }
      setState({ ...state, loading: true });
      let response = await createPreconsultation(payload);
      store.dispatch({
        type: "SET_PRE_CONSULT_DATA",
        payload: response?.data?.data?.pre_consult,
      });
      updateReasonText(
        response?.data?.data?.pre_consult?.health_condition?.health_condition
          ?.name
      );
      const elmConfirmBtn = document.getElementById(
        "confirm_btn_answer_submit"
      );
      if (elmConfirmBtn) {
        elmConfirmBtn.style.display = "none";
      }
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      setState({ ...state, loading: false });
      // }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setState({ ...state, loading: false });
    }
  };
  console.log("preConsultQuestionnaire 222", preConsultQuestionnaire);
  return (
    <Fragment>
      <>
        {/* Patient details section */}

        {
          <div className="quetionanirewrap">
            {state?.isFetching && <CommonLoader size={"small"} />}
            {preConsultQuestionnaire.length > 0 && (
              <Fragment>
                <PreConsultQuestionnaire
                  selectedItem={null}
                  fromVideoF2FConsultationDetails={fromVideoF2F}
                  fromSearchView={false}
                  fromVideoF2FAnswerReviewShow={fromVideoF2F}
                  questionnaire={preConsultQuestionnaire}
                  gender={patientDetail?.gender}
                  preConsultStatus={preConsultStatus}
                  healthConditionId={health_condition_id}
                  healthConditionName={
                    selecteddHealthCondition
                      ? selecteddHealthCondition?.name
                      : selectedItem?.name
                  }
                  isFromNHSPathFinder={true}
                  pathFinderappointmentId={appointmentId}
                  // submitPreConsult={}
                  questionnaireToken={questionnaireToken}
                  selectedService={
                    selecteddHealthCondition
                      ? selecteddHealthCondition?.services
                      : selectedItem?.services
                  }
                  fromConsultationDetails={true}
                  expandClicked={expandClicked}
                  systolic_bp={systolic_bp}
                  diastolic_bp={diastolic_bp}
                  pulse_per_minute={pulse_per_minute}
                  screened_bp_values={screened_bp_values}
                  hypertensive={hypertensive}
                  health_scan_log_id={health_scan_log_id}
                  next_questionnaire_id={state?.next_questionnaire_id}
                  setState={setState}
                  state={state}
                  healthConditionDescription={
                    selecteddHealthCondition
                      ? selecteddHealthCondition?.description
                      : selectedItem?.description
                  }
                  healthConditionSnomedCode={
                    selecteddHealthCondition
                      ? selecteddHealthCondition?.snomed_code
                      : selectedItem?.snomed_code
                  }
                  fromReviewPreConsult={fromReviewPreConsult}
                  passedOutCome={state.passedOutCome}
                  questionnaireIndexes={state?.questionnaire_indexes}
                  passedToken={questionnaireToken}
                  patientDetail={patientDetail}
                  passedNextQuestionnaireIndex={passedNextQuestionnaireIndex}
                  patiendId={patient_id}
                  corporate_id={corporate_id}
                  pre_consult_id={pre_consult_id}
                  closePathFinderModal={() => {
                    setPreConsultQuestionnaire([]);
                    closePathFinderModal();
                  }}
                  setNextQuestionnaireIdForParent={(nxt_id) => {
                    setState({
                      ...state,
                      next_questionnaire_id: nxt_id,
                    });
                  }}
                  setIsEditModeState={(v) => {
                    setState({
                      ...state,
                      isEditedQuestion: v,
                      next_questionnaire_id: null,
                    });
                  }}
                  getOutcome={(
                    outCome,
                    happy_to_review_submit,
                    outComePayloads
                  ) => {
                    console.log(
                      "payloadForConsultation",
                      outCome,
                      outComePayloads
                    );

                    setState({
                      ...state,
                      preConsultOutCome: outComePayloads
                        ? {
                          outCome: outCome,
                          ...outComePayloads,
                        }
                        : null,
                      isOutComePresent: outCome,
                      isQuestionnaireSubmitted:
                        outComePayloads?.isQuestionnaireSubmitted
                          ? true
                          : false,
                      happy_to_review_submit: happy_to_review_submit,
                    });
                    store.dispatch({
                      type: "SET_PRE_CONSULT_OUTCOME",
                      payload: outComePayloads
                        ? {
                          outCome: outCome,
                          ...outComePayloads,
                        }
                        : null,
                    });
                  }}
                  dynamicTabAction={dynamicTabAction}
                  dynamicInputs={dynamicInputs}
                  isShowBookingAppt={isShowBookingAppt}
                  _createpreConsultationFromDetailView={_updatepreConsultation}
                  PassedBookAppointmentReuse={null}
                  _updatepreConsultation={_updatepreConsultation}
                  _createpreConsultationfromPopView={_createpreConsultation}
                  passedPreConsultData={passedPreConsultData}
                />
              </Fragment>
            )}
          </div>
        }
      </>
    </Fragment>
  );
}
