/* eslint-disable array-callback-return */
import React, {Component, Fragment} from "react";
import {
  addConsultationDetailAPI,
  addHealthAssessmentPatientAPI,
  getConsultationDetailAPI,
  getPatientHealthAssessmentDetailApi,
  markAsReviewedAPI,
  updateAppointmentReasonApi,
  updateConsultationDetailAPI,
} from "../../services/patientConsultation";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
  checkboxArray,
  Health_Assessment_KVP,
  getLivePerformaQuestionnaire,
} from "../../utilities/utils";
import {connect} from "react-redux";
import {ValidateInput} from "./PatientConsultationValidations";
// import ModalPopUp from "../../components/common/ModalPopUp";
import InformationalOutcomeModal from "./InformationalOutcomeModal";
import {
  Checkbox,
  Image,
  // Input,
  InputNumber,
  Popover,
  Radio,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
// import Highlighter from "react-highlight-words";
import {searchSnomedCode} from "../../services/snomedCodes";
import {isEmpty, debounce, map, uniq, uniqBy, isArray, isNumber} from "lodash";
// import addImage from "../../assets/images/common/add-ic.png";
import deleteImage from "../../assets/images/common/delete-ic.png";
// import verifiedImage from "../../assets/images/common/ic_right.svg";
// import unverifiedImage from "../../assets/images/common/ic_cancel.svg";
// import editImage from "../../assets/images/common/edit-ic.png";
import moment from "moment";
import CommonLoader from "../../components/common/CommonLoader";
import store from "../../app/store";
import {
  DATE_FORMAT_24_HR,
  DP_QUESTIONNAIRE,
  ROLE_SUPERINTENDENT_PHARMACIST,
  // ROLE_CLINICAL_PHARMACIST,
  // ROLE_DOCTOR,
  presentingpopover,
} from "../../constants/common";
import {
  EditOutlined,
  CaretUpOutlined,
  // CloseCircleOutlined,
  CaretDownOutlined,
  PictureFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
// import PrescriptionAddModal from "./PrescriptionContainer/PrescriptionAddModal";
import {bindActionCreators} from "redux";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetDynamicInput,
  onSetDynamicHealthInput,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import {actions as preEncountersActions} from "../../reducers/preEnccounter";

import CreatableSelect from "react-select/creatable";
import {Button} from "antd";
import PreEncountersContainer from "../PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import CallScreenShotContainer from "../WalkinAppointment/CallScreenShotContainer";
// import ScrollBar from "react-perfect-scrollbar";
import AutoTextArea from "components/AutoTextArea";
import {actions as prescriptionAbbreviationActions} from "reducers/prescriptionAbbreviation";

import PerfectScrollbar from "react-perfect-scrollbar";
// import SplitPane from "react-split-pane";
import SplitPane, {Pane} from "react-split-pane-next";
import {withRouter} from "react-router-dom";
import {appRoutesConst} from "app/navigation";
import ModalPopUp from "components/common/ModalPopUp";
import QuestionnaireFillUp from "containers/WalkinAppointment/QuestionnaireFillUp";
import SymmtomDiagnosisTabs from "containers/WalkinAppointment/SymmtomDiagnosisTabs";
import InputMask from "react-input-mask";
import {
  fetchDiagnosisApi,
  fetchRegionsAPI,
  fetchSymptomsApi,
} from "services/symptoms";
// import ExaminationEditor from "containers/WalkinAppointment/ExaminationEditor";
import Axios from "axios";

// const {Option} = Select;
const SET_CONSULTATION_OBJ = "SET_CONSULTATION_OBJ";
class PatientConsultationContainerForInReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationDetail: null,
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      editDiagnosis: false,
      managementPlan: "",
      editManagementPlan: false,
      editPrescriptionPlan: false,
      prescription_plan: "",
      // barcodeNumber: "",
      // editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      newselectedCodesArray: [],
      selectedSnomedCodesArray: [],
      addingDetail: false,
      updatingDetail: false,
      isLoading: false,
      snomedCodeId: "",
      splitSize: true,
      fetchingRegion: false,
      regionsData: [],
      recentTravel: false,
      region_id: 1,
      searchingDiagnose: false,
      symptompSnomedCode: [],
      checkboxStateArray: [...checkboxArray],
      healthStateArray: [...Health_Assessment_KVP],
      enterCount: 0,
      isFinshedEdit: true,
      updatingConsultationDetail: false,
      isReviewing: false,
    };
    this.prescriptionModal = React.createRef();

    [
      "_getPatientConultationDetails",
      "_handleSubmit",
      "_handleTextChange",
      "_hideModal",
      "_showCodeModal",
      "_hideCodeModal",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleModalSubmission",
      "_handleEdit",
      "_handleUpdateoutcome",
      "_handleUpdate",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
    this.searchUpdateSymptom = debounce(this._handleSymptomSearch, 1000);
  }

  componentDidMount() {
    this._getPatientConultationDetails();
    this.handleMountDynamicInput();
    if (
      verifyObject(this.props, "dynamicInputs.regionsData", []).length === 0
    ) {
      this.fetchRegions();
    }
    console.log(
      "HEALTHINPUT---",
      verifyObject(this.props, "dynamicHealthInputs")
    );

    if (!verifyObject(this.props, "dynamicHealthInputs", false)) {
      this.getPatientHealthAssessmentDetail();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.managementPlan !== this.state.managementPlan) {
      if (this.scollRef && this.scollRef.current) {
        if (this.scollRef.current) {
          this.scollRef.current.focus();
        }
      }
    }
    if (
      prevState.height !== this.state.height ||
      prevState.weight !== this.state.weight
    ) {
      this.setState(
        {
          bmi: this.calculateBMI(this.state.height, this.state.weight),
        },
        () => {
          let {height, weight} = this.state;
          if (height && height !== "" && weight && weight !== "") {
            this.setState({bmi: this.calculateBMI(height, weight)}, () => {
              this.setState(
                (state) => {
                  let stateItems = state.healthStateArray;
                  stateItems[8]["value"] = this.state.bmi;
                  return {
                    healthStateArray: stateItems,
                  };
                },
                () => {
                  let obj = this.state.healthStateArray.find((i, index) => {
                    if (i.key === "bmi") {
                      return i;
                    }
                  });
                  let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
                  if (typeof onSetDynamicHealthInput === "function") {
                    let Inputs = {
                      ...this.props.dynamicHealthInputs,

                      bmi: this.state.bmi,
                    };
                    onSetDynamicHealthInput(Inputs);
                  }
                  setTimeout(() => {
                    this._handleHealthStateArray(obj.id, obj.value);
                  }, 1200);
                }
              );
            });
          }
        }
      );
    }

    let {dynamicInputs, dynamicHealthInputs} = this.props;

    let consentArray = verifyObject(dynamicInputs, "isConsentGiven", []);

    if (
      consentArray.length !== 0 &&
      this.state.selectedCodesArray.length === 0
    ) {
      this.setState(
        {
          ...dynamicInputs,
          ...dynamicHealthInputs,
          selectedCodesArray:
            dynamicInputs &&
            dynamicInputs.selectedSnomedCodesArray !== null &&
            dynamicInputs.isConsentGiven &&
            isArray(dynamicInputs.isConsentGiven)
              ? isArray(dynamicInputs.selectedSnomedCodesArray)
                ? [
                    ...(dynamicInputs.selectedSnomedCodesArray || []),
                    ...consentArray,
                  ]
                : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
              : [...consentArray],
          // tabKey:
          //   this.props.consultation_tab_key !== null
          //     ? this.props.consultation_tab_key
          //     : "preencounters",
        },
        () => {},
        () => {
          this.setState({
            selectedSnomedCodesArray: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.selectedCodesArray,
            ]),
          });
        }
      );
    }
  }
  _SaveComponentStateToRedux = (fromClearUpdateConsultation) => {
    let {onSetDynamicInput, onSetDynamicHealthInput} =
      this.props.dynamicTabAction;
    if (fromClearUpdateConsultation) {
      if (
        typeof onSetDynamicInput === "function" ||
        typeof onSetDynamicHealthInput === "function"
      ) {
        onSetDynamicInput(null);
        onSetDynamicHealthInput(null);
      }
    } else {
      if (typeof onSetDynamicInput === "function") {
        let {
          history,
          examination,
          managementPlan,
          barcodeNumber,
          selectedSnomedCodesArray,
          isShowSubmit,
          isConsentGiven,
          reason_text,
          selectedCheckbox,
          // checkboxStateArray,
        } = this.state;
        let inputObj = {};
        // eslint-disable-next-line no-unused-vars
        // let inputs = checkboxStateArray.map((item) => {
        //   if (this.state[item.id]) {
        //     // {`${item.id}`:this.state[item.id]}
        //     inputObj = {
        //       ...inputObj,
        //       [item.id]: this.state[item.id],
        //     };
        //   }
        // });

        let Inputs = {
          ...this.props.dynamicInputs,
          ...inputObj,
          history: history,
          examination: examination,
          managementPlan: managementPlan,
          barcodeNumber: barcodeNumber,
          selectedSnomedCodesArray: selectedSnomedCodesArray,
          selectedCodesArray: selectedSnomedCodesArray,

          isShowSubmit: isShowSubmit,
          isConsentGiven: isConsentGiven,
          reason_text: reason_text,
          selectedCheckbox: selectedCheckbox,
          isFinshedEdit: this.state.isFinshedEdit,
          regionsData: this.state.regionsData,
        };
        if (fromClearUpdateConsultation) {
          onSetDynamicInput(null);
          onSetDynamicHealthInput(null);
        } else {
          onSetDynamicInput(Inputs);
        }
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    let {dynamicInputs} = nextProps;

    console.log(
      "dynamicInputs && dynamicInputs.presriptionPlan",
      dynamicInputs && dynamicInputs.presriptionPlan
    );

    this.setState({
      ...dynamicInputs,
      prescription_plan: dynamicInputs && dynamicInputs.presriptionPlan,
    });
  }
  fetchRegions = async () => {
    try {
      this.setState({fetchingRegion: true});
      const response = await fetchRegionsAPI();
      let {dynamicInputs, dynamicHealthInputs, is_gp_informed} = this.props;
      let consentArray =
        is_gp_informed &&
        verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
          ? JSON.parse(localStorage.getItem("isConsentGiven"))
            ? JSON.parse(localStorage.getItem("isConsentGiven"))
            : []
          : verifyObject(dynamicInputs, "isConsentGiven", []);
      let managementPlanLocal =
        is_gp_informed &&
        verifyObject(dynamicInputs, "managementPlan", "") === ""
          ? +consentArray[0].code === 768321000000106
            ? "Patient made aware of the importance of good medical record sharing, Consent given"
            : "Patient made aware of the importance of good medical record sharing, declined"
          : verifyObject(dynamicInputs, "managementPlan", "");

      this.setState(
        {
          fetchingRegion: false,
          regionsData: verifyObject(response, "data.data", []),
          ...dynamicInputs,
          ...dynamicHealthInputs,
          selectedCodesArray:
            dynamicInputs &&
            dynamicInputs.selectedSnomedCodesArray !== null &&
            dynamicInputs.isConsentGiven &&
            isArray(dynamicInputs.isConsentGiven)
              ? isArray(dynamicInputs.selectedSnomedCodesArray)
                ? [
                    ...(dynamicInputs.selectedSnomedCodesArray || []),
                    ...consentArray,
                  ]
                : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
              : [
                  ...consentArray,
                  ...verifyObject(
                    dynamicInputs,
                    "selectedSnomedCodesArray",
                    []
                  ),
                ],
          tabKey:
            this.props.consultation_tab_key !== null
              ? this.props.consultation_tab_key
              : "preencounters",
          managementPlan: managementPlanLocal,
        },
        () => {
          let {onSetDynamicInput} = this.props.dynamicTabAction;

          if (typeof onSetDynamicInput === "function") {
            let payload = {
              ...dynamicInputs,
              regionsData: this.state.regionsData,
            };
            onSetDynamicInput(payload);
          }
          let foundValueCheckbox = checkboxArray.find(
            (item) => item.value !== null
          );
          let foundValueHealthbox = Health_Assessment_KVP.find(
            (item) => item.value !== null
          );

          if (this.state.examination === "" && foundValueCheckbox) {
            this.handleClearCheckboxValue();
          }
          if (this.state.examination === "" && foundValueHealthbox) {
            this.handleClearHealthboxValue();
          }
          this.setState({
            selectedSnomedCodesArray: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.selectedCodesArray,
            ]),
          });
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({
        fetchingRegion: false,
      });
      errorToast({content: message});
    }
  };
  getPatientHealthAssessmentDetail = async () => {
    console.log(
      "called----------------------------------------------------------------"
    );
    let {patientId, dynamicHealthInputs} = this.props;
    if (!dynamicHealthInputs) {
      try {
        this.setState({isLoadingHealthAssessment: true});
        let response = await getPatientHealthAssessmentDetailApi(patientId);
        let data = verifyObject(response, "data.data", []);
        // verifyObject(response, "data.data", []).length > 0 &&
        //   verifyObject(response, "data.data", []).map((item, index) => {
        //     return
        this.setState(
          {
            pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
            heightObject: this.getObjectFromData(data, "height"),
            weightObject: this.getObjectFromData(data, "weight"),
            body_temperatureObject: this.getObjectFromData(
              data,
              "body_temperature"
            ),
            blood_oxygen_saturationObject: this.getObjectFromData(
              data,
              "blood_oxygen_saturation"
            ),
            systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
            diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
            bmiObject: this.getObjectFromData(data, "bmi"),
          },
          async () => {
            let {
              pulseObject,
              heightObject,
              weightObject,
              blood_oxygen_saturationObject,
              body_temperatureObject,
              bmiObject,
              systolic_bpObject,
              diastolic_bpObject,
            } = this.state;
            let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
            if (typeof onSetDynamicHealthInput === "function") {
              let Inputs = {
                ...this.props.dynamicHealthInputs,
                pulseObject,
                heightObject,
                weightObject,
                blood_oxygen_saturationObject,
                body_temperatureObject,
                bmiObject,
                systolic_bpObject,
                diastolic_bpObject,
              };
              await onSetDynamicHealthInput(Inputs);
            }
          }
        );

        await this.setState({isLoadingHealthAssessment: false});
      } catch (error) {
        // const {message} = getErrorObject(error);
        await this.setState({isLoadingHealthAssessment: false});
        // errorToast({content: message});
      }
    }
  };
  handleMountDynamicInput = async () => {
    let {dynamicInputs, dynamicHealthInputs, is_gp_informed} = this.props;
    let consentArray =
      is_gp_informed &&
      verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
        ? JSON.parse(localStorage.getItem("isConsentGiven"))
          ? JSON.parse(localStorage.getItem("isConsentGiven"))
          : []
        : verifyObject(dynamicInputs, "isConsentGiven", []);
    let managementPlanLocal =
      is_gp_informed && verifyObject(dynamicInputs, "managementPlan", "") === ""
        ? +consentArray[0].code === 768321000000106
          ? "Patient made aware of the importance of good medical record sharing, Consent given"
          : "Patient made aware of the importance of good medical record sharing, declined"
        : verifyObject(dynamicInputs, "managementPlan", "");

    this.setState(
      {
        fetchingRegion: false,
        // regionsData: verifyObject(response, "data.data", []),
        ...dynamicInputs,
        ...dynamicHealthInputs,
        selectedCodesArray:
          dynamicInputs &&
          dynamicInputs.selectedSnomedCodesArray !== null &&
          dynamicInputs.isConsentGiven &&
          isArray(dynamicInputs.isConsentGiven)
            ? isArray(dynamicInputs.selectedSnomedCodesArray)
              ? [
                  ...(dynamicInputs.selectedSnomedCodesArray || []),
                  ...consentArray,
                ]
              : [dynamicInputs.selectedSnomedCodesArray, ...consentArray]
            : [
                ...consentArray,
                ...verifyObject(dynamicInputs, "selectedSnomedCodesArray", []),
              ],
        tabKey:
          this.props.consultation_tab_key !== null
            ? this.props.consultation_tab_key
            : "preencounters",
        managementPlan: managementPlanLocal,
      },
      () => {
        let foundValueCheckbox = checkboxArray.find(
          (item) => item.value !== null
        );
        let foundValueHealthbox = Health_Assessment_KVP.find(
          (item) => item.value !== null
        );

        if (this.state.examination === "" && foundValueCheckbox) {
          this.handleClearCheckboxValue();
        }
        if (this.state.examination === "" && foundValueHealthbox) {
          this.handleClearHealthboxValue();
        }
        this.setState((prevState) => {
          const selectedSnomedCodesArray = isArray(
            prevState.selectedSnomedCodesArray
          )
            ? prevState.selectedSnomedCodesArray
            : [];

          const selectedCodesArray = isArray(prevState.selectedCodesArray)
            ? prevState.selectedCodesArray
            : [];

          return {
            selectedSnomedCodesArray: uniq([
              ...selectedSnomedCodesArray,
              ...selectedCodesArray,
            ]),
          };
        });
      }
    );
  };
  componentWillUnmount() {
    // let {onSetDynamicInput} = this.props.dynamicTabAction;
    // if (typeof onSetDynamicInput === "function") {
    //   onSetDynamicInput(null);
    // }
    // window.onpopstate = null;
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          // snomedCodes: [],
          // snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios.CancelToken.source();
          this.setState(
            {
              cancelToken: cancelToken,
            },
            async () => {
              await this._getSnomedCodes(false, value, cancelToken);
            }
          );
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  _getSnomedCodes = async (isLoadMore, search, cancelToken) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode(
        {
          page: this.state.page,
          search: this.state.search,
        },
        cancelToken
      );
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      if (error !== "REQ_CANCELLED") {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      } else {
        this.setState({searching: false});
        setTimeout(() => {
          this.setState({searching: true});
        }, [1000]);
      }
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        if (this.state.cancelToken && this.state.cancelToken.token) {
          this.state.cancelToken.cancel("Canceling previous request");
        }
        let cancelToken = Axios.CancelToken.source();
        this.setState(
          {
            cancelToken: cancelToken,
          },
          async () => {
            await this._getSnomedCodes(true, null, cancelToken);
          }
        );
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  async _getPatientConultationDetails() {
    let {appointmentId, dynamicInputs} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationDetailAPI({
        appointment_id: appointmentId,
      });
      let consultationDetail = verifyObject(response, "data.data", null);
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: consultationDetail,
      });
      await this.setState(
        {
          consultationDetail: consultationDetail,
          newselectedCodesArray: dynamicInputs?.newselectedCodesArray
            ? dynamicInputs?.newselectedCodesArray
            : [],
          selectedSnomedCodesArray: dynamicInputs?.selectedSnomedCodesArray
            ? dynamicInputs?.selectedSnomedCodesArray
            : verifyObject(consultationDetail, "snomed_codes", []).length !== 0
            ? uniqBy(consultationDetail.snomed_codes, "description")
            : [],
          selectedCodesArray: dynamicInputs?.selectedCodesArray
            ? dynamicInputs?.selectedCodesArray
            : verifyObject(consultationDetail, "snomed_codes", []).length !== 0
            ? uniqBy(consultationDetail.snomed_codes, "description")
            : [],
          prescription_plan: dynamicInputs?.prescription_plan
            ? dynamicInputs?.prescription_plan
            : verifyObject(
                consultationDetail,
                "consultation_log.prescription_plan",
                null
              ),
          prescriptionPlan: dynamicInputs?.prescription_plan
            ? dynamicInputs?.prescription_plan
            : verifyObject(
                consultationDetail,
                "consultation_log.prescription_plan",
                null
              ),
          isLoading: false,
        },
        () => {
          let {onSetDynamicInput} = this.props.dynamicTabAction;

          let Inputs = {
            ...this.props.dynamicInputs,
            newselectedCodesArray: this.state.newselectedCodesArray,
            selectedSnomedCodesArray: this.state.selectedSnomedCodesArray,
            selectedCodesArray: this.state.selectedCodesArray,
            prescriptionPlan: this.state.prescriptionPlan,
            prescription_plan: this.state.prescription_plan,
          };
          if (typeof onSetDynamicInput === "function") {
            onSetDynamicInput(Inputs);
          }
        }
      );
    } catch (error) {
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: null,
      });
      const {message} = getErrorObject(error);
      this.setState({isLoading: false});
      if (error?.data?.status !== 404) {
        errorToast({content: message});
      }
    }
  }

  _handleTextChange(e, controlName) {
    let errors = null;
    let name = null;
    if (controlName) {
      name = controlName;
    } else {
      name = e.target.name;
    }
    // let value = e.target.value;
    console.log("TEXT CHANGE --->", e);
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState(
      {
        [name]: controlName ? e : e.target.value,
        errors: errors,
      },
      () => {
        if (
          isEmpty(this.state.history) ||
          isEmpty(this.state.examination) ||
          isEmpty(this.state.managementPlan)
        ) {
          this.setState({isShowSubmit: false}, () => {
            this._SaveComponentStateToRedux();
          });
        } else {
          this._SaveComponentStateToRedux();
        }
      }
    );
  }

  _handleCustomCodeAdd(value) {
    // let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState(
      {
        snomedCodeText: "",
        snomedCodeId: "",
        newselectedCodesArray: this.state.newselectedCodesArray.concat(Obj),
      },
      () => {
        if (this.state.consultationDetail === null) {
          this.setState({
            // showCodeModal: false,
            selectedSnomedCodesArray: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.newselectedCodesArray,
            ]),
          });
        } else {
          // this._handleSnomedCodeUpdate(); // need to update on click of save button
        }
      }
    );
  }

  async _handleSubmit(isSubmitForReview) {
    let {
      history,
      examination,
      managementPlan,
      // prescription_plan,
      // barcodeNumber,
    } = this.state;

    let toBeValidateObj = {
      history: history,
      examination: examination,
      managementPlan: managementPlan,
      // barcodeNumber: barcodeNumber,
      // prescription_plan: prescription_plan,
    };

    const errors = ValidateInput(toBeValidateObj);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      this.setState(
        {
          visible: true,
          isSubmitForReview,
        },
        () => {}
      );
    }
  }

  async _handleModalSubmission() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      history,
      examination,
      managementPlan,
      prescription_plan,
      // barcodeNumber,
    } = this.state;

    let toBeValidateObj2 = {
      treatment: treatment,
      followUp: followUp,
      referral: referral,
      comment: comment,
      visible: true,
    };
    const errors = ValidateInput(toBeValidateObj2);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {appointmentId, patientId} = this.props;
      let {selectedSnomedCodesArray, selectedCodesArray} = this.state;
      let submittedReviewParamas = {};

      if (this.state.isSubmitForReview) {
        submittedReviewParamas = {
          status: "submitted_to_review", //or finished
        };
      }
      let payload = {
        consultation: {
          appointment_id: appointmentId,
          history: history,
          examination: examination,
          management_plan: managementPlan,
          prescription_plan: prescription_plan,
          // barcode: barcodeNumber,
          patient_id: patientId,
          outcome: {
            treatment: treatment,
            follow_up: followUp,
            referral: referral,
            comment: comment,
          },
          ...submittedReviewParamas,
        },
        snomed_codes: uniq([
          ...selectedCodesArray,
          ...selectedSnomedCodesArray,
        ]),
      };

      try {
        this.setState({addingDetail: true});
        let response = await addConsultationDetailAPI(payload);
        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });

        this._getPatientConultationDetails();
        this.setState({
          addingDetail: false,
        });
        this._clearData();
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({addingDetail: false});
        errorToast({content: message});
      }
    }
  }

  _clearData() {
    this.setState({
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      editDiagnosis: false,
      managementPlan: "",
      editManagementPlan: false,
      prescription_plan: "",
      editPrescriptionPlan: false,
      // barcodeNumber: "",
      // editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      // p@
      addingDetail: false,
      updatingDetail: false,
      visible: false,
    });
    store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: null,
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d.id == value.id);
    let ids = map(this.state.newselectedCodesArray, "id");

    // if (found) {
    if (ids.includes(found.id) === false) {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.concat({
            id: found.id,
            code: found.value,
            description: found.label,
          }),
          snomedCodeId: "",
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedCodesArray,
                ...this.state.newselectedCodesArray,
              ]),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    } else {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.concat({
            id: found.id,
            code: found.value,
            description: found.label,
          }),
          snomedCodeId: "",
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedCodesArray,
                ...this.state.newselectedCodesArray,
              ]),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    }
    // }
  }
  _removeCode = (record) => {
    if (this.state.newselectedCodesArray.length === 0) {
    } else {
      this.setState(
        {
          newselectedCodesArray: this.state.newselectedCodesArray.filter(
            (d) => d.id !== record.id || d.description !== record.description
          ),
        },
        () => {
          this.setState(
            {
              showCodeModal: false,
              selectedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
              selectedSnomedCodesArray: uniq([
                ...this.state.selectedSnomedCodesArray,
                ...this.state.newselectedCodesArray,
              ]).filter(
                (d) =>
                  d.id !== record.id || d.description !== record.description
              ),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;

              let Inputs = {
                ...this.props.dynamicInputs,
                newselectedCodesArray: this.state.newselectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
                selectedCodesArray: this.state.selectedCodesArray,
              };
              if (typeof onSetDynamicInput === "function") {
                onSetDynamicInput(Inputs);
              }
            }
          );
        }
      );
    }
  };
  _hideModal() {
    this.setState({
      visible: false,
      errors: null,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      editOutcome: false,
      changing: false,
    });
  }
  _showCodeModal() {
    this.setState({showCodeModal: true});
  }
  _hideCodeModal() {
    this.setState({showCodeModal: false});
  }

  _handleEdit(state) {
    if (state === "editPrescriptionPlan") {
      if (this.prescriptionModal && this.prescriptionModal.current) {
        this.prescriptionModal.current._handleShowModal();
      }
    } else {
      this.setState({[state]: !this.state[state]}, () => {
        if (this.state.editOutcome) {
          this.setState({visible: true});
        }
      });
    }
  }
  async _handleUpdateoutcome() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;

    let payload = {
      consultation: {
        outcome: {
          treatment: treatment,
          follow_up: followUp,
          referral: referral,
          comment: comment,
        },
        from_review_section: true,
      },
      consultation_id: consultation_id,
    };

    try {
      this.setState({changing: true});
      let response = await updateConsultationDetailAPI(payload, true);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Outcome.`,
        });
        this._onRequestListPre();
        this.setState({
          ...this.state,
          changing: false,

          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearIndividualData("outcome", payload.outcome);
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({changing: false});
      errorToast({content: message});
    }
  }
  async _handleSnomedCodeUpdate() {
    let {
      // selectedCodesArray,
      newselectedCodesArray,
      selectedSnomedCodesArray,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;

    let payload = {
      snomed_codes: uniq([
        ...selectedSnomedCodesArray,
        ...newselectedCodesArray,
      ]),
      consultation_id: consultation_id,
      from_review_section: true,
    };
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Snomed Codes.`,
        });
        this.setState({
          ...this.state,
          updatingDetail: false,
          consultationDetail: response.data.data,
          selectedSnomedCodesArray:
            verifyObject(response, "data.data.snomed_codes", []).length !== 0
              ? uniqBy(response.data.data.snomed_codes, "description")
              : [],
          newselectedCodesArray: [],
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearIndividualData("snomedCodeId", "editDiagnosis");
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  async _handleUpdate(param, value) {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      consultation: {
        [param]: this.state[param],
        from_review_section: true,
      },
      consultation_id: consultation_id,
    };

    // if (param === "barcode") {
    //   payload = {
    //     consultation: {
    //       barcode: this.state.barcodeNumber,
    //     },
    //     consultation_id: consultation_id,
    //   };
    // }

    if (param === "managementPlan") {
      payload = {
        consultation: {
          management_plan: this.state.managementPlan,
          from_review_section: true,
        },
        consultation_id: consultation_id,
      };
    }
    if (param === "prescription_plan") {
      payload = {
        consultation: {
          prescription_plan: value,
          from_review_section: true,
        },
        consultation_id: consultation_id,
      };
    }

    try {
      this.setState({[`updating${param}`]: true});
      let response;
      if (param === "prescription_plan" && !value) {
      } else {
        response = await updateConsultationDetailAPI(payload, true);
        if (response.data && response.data.message) {
          successToast({
            content: `Updated Consultation Record.`,
          });
          this._onRequestListPre();

          // this._getPatientConultationDetails();
          this.setState({
            ...this.state,
            [`updating${param}`]: false,

            consultationDetail: verifyObject(response, "data.data", null),
            // [state]: false,
          });
        }
      }

      // this._getPatientConultationDetails();
      this._clearIndividualData(param, value); //creating new function for individual fields save and clear
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({[`updating${param}`]: false});
      errorToast({content: message});
    }
  }
  _clearIndividualData = (param, value) => {
    if (param === "outcome") {
      return this.setState(
        {
          treatment: "",
          followUp: "",
          referral: "",
          comment: "",
          visible: false,
        },
        () => {
          store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
          store.dispatch({
            type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
            payload: null,
          });
        }
      );
    } else {
      return this.setState(
        {
          [param]: "",
          [value]: false,
        },
        () => {
          // if (param === "barcode") {
          //   this.setState({barcodeNumber: ""});
          // }
          store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
          store.dispatch({
            type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
            payload: null,
          });
        }
      );
    }
  };
  updateHeight = () => {
    let activePane = document.querySelector(".ant-tabs-tabpane-active");
    let sectionSCroll = activePane.querySelector(".sectionsubscroll");
    let posTop = activePane.getBoundingClientRect().top;
    setTimeout(() => {
      sectionSCroll.style.height = `calc(100vh - ${posTop + 30}px)`;
    }, 500);
  };
  handleButtonClick = () => {
    this.setState(
      (prevState) => ({
        splitSize: !prevState.splitSize,
      }),
      () => {
        // Call updateHeight in the callback to ensure it runs after the state has been updated
        this.updateHeight();
      }
    );
  };
  handleTabChange = (key) => {
    return this.setState({tabKey: key}, () => {
      store.dispatch({type: "CONSULTATION_TAB_KEY", payload: key});
      setTimeout(() => {
        this.updateHeight();
      }, 500);
    });
  };
  _markAsReviewed = async () => {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
      pulse_per_minute,
      height,
      weight,
      blood_oxygen_saturation,
      body_temperature,
      systolic_bp,
      diastolic_bp,
      respiratory_rate,
      reason_text,

      managementPlan,
      examination,
      history,
    } = this.state;

    console.log("this.state.isSubmitForReview", this.state.isSubmitForReview);

    let payload = {
      consultation_id: consultation_id,
      from_review_section: true,
    };
    let consultation = {};
    if (this.state.history && this.handleDynamicCondition(this.state.history)) {
      consultation = {
        // consultation: {
        ...consultation,
        history,
        // },
      };
    }
    if (
      this.state.examination &&
      this.handleDynamicCondition(this.state.examination)
    ) {
      consultation = {
        // consultation: {
        ...consultation,
        examination: isArray(examination) ? examination.join(",") : examination,
        // },
      };
    }
    if (
      this.state.managementPlan &&
      this.handleDynamicCondition(this.state.managementPlan)
    ) {
      consultation = {
        // consultation: {
        ...consultation,
        management_plan: managementPlan,
        // },
      };
    }

    if (
      this.state.prescription_plan &&
      this.handleDynamicCondition(this.state.prescription_plan)
    ) {
      if (
        verifyObject(
          this.state.consultationDetail,
          "consultation_log.prescription_plan"
        ) === null
      ) {
        consultation = {
          ...consultation,
          prescription_plan: this.state.prescription_plan,
        };
      } else if (
        this.state.prescription_plan &&
        verifyObject(
          this.state.consultationDetail,
          "consultation_log.prescription_plan",
          null
        ) !== null
      ) {
        consultation = {
          ...consultation,
          prescription_plan: verifyObject(
            this.props.dynamicInputs,
            "inReviewPrescriptionPlan",
            ""
          ),
        };
      }
    }
    if (
      this.state.selectedCodesArray &&
      (isArray(this.state.selectedCodesArray) ||
        isArray(this.state.selectedSnomedCodesArray))
    ) {
      payload = {
        ...payload,
        snomed_codes: uniq([
          ...this.state.selectedSnomedCodesArray,
          ...this.state.selectedCodesArray,
        ]),
      };
    }
    if (this.state.outcome && !isEmpty(this.state.outcome)) {
      consultation = {
        ...consultation,
        outcome: {
          comment: this.state.comment,
          follow_up: this.state.followUp,
          referral: this.state.referral,
          treatment: this.state.treatment,
        },
      };
    }

    let health_summaryPayload = {
      health_summary: {},
    };
    if (pulse_per_minute && pulse_per_minute !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          pulse_per_minute,
        },
      };
    }

    if (systolic_bp && systolic_bp !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          systolic_bp,
        },
      };
    }
    if (diastolic_bp && diastolic_bp !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          diastolic_bp,
        },
      };
    }
    if (blood_oxygen_saturation && blood_oxygen_saturation !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          blood_oxygen_saturation,
        },
      };
    }
    if (body_temperature && body_temperature !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          body_temperature,
        },
      };
    }
    if (height && height !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          height,
        },
      };
    }
    if (weight && weight !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          weight,
        },
      };
    }

    if (respiratory_rate && respiratory_rate !== "") {
      health_summaryPayload = {
        health_summary: {
          ...health_summaryPayload.health_summary,
          respiratory_rate,
        },
      };
    }

    let healthPayload = {
      appointment_id: this.props.appointmentId,
      ...health_summaryPayload,
    };
    let reasonP = {};
    if (
      (this.props.dynamicInputs && this.props.dynamicInputs.reason_text) ||
      reason_text
    ) {
      reasonP = {
        reason: this.props.dynamicInputs.reason_text
          ? this.props.dynamicInputs.reason_text &&
            isArray(this.props.dynamicInputs.reason_text)
            ? this.props.reason_text.map((item) => item.label).join(",")
            : this.props.dynamicInputs.reason_text
          : reason_text,
      };
    }
    let payloadReason = {
      id: this.props.appointmentId,
      ...reasonP,
    };
    payload = {
      ...payload,
      consultation: {
        ...consultation,
        // appointment_id: this.props.appointmentId,
        // patient_id: this.props.patientId,
      },
    };
    try {
      this.setState({updatingConsultationDetail: true, isReviewing: true});

      if (reason_text && !isEmpty(reason_text)) {
        let reasonResponse = await updateAppointmentReasonApi(payloadReason);

        successToast({
          content: verifyObject(reasonResponse, "data.message", "Success"),
        });
        if (
          healthPayload &&
          Object.keys(healthPayload.health_summary).length > 0
        ) {
          let healthResponse = await addHealthAssessmentPatientAPI(
            healthPayload
          );
          successToast({
            content: verifyObject(healthResponse, "data.message", "Success"),
          });
        }
        // payload = {
        //   ...payload,
        //   consultation: {
        //     ...consultation,
        //     // appointment_id: this.props.appointmentId,
        //     // patient_id: this.props.patientId,
        //   },
        // };
        let response = await updateConsultationDetailAPI(payload, true);

        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });

        if (
          typeof this.props.onSetDynamicHealthInput === "function" &&
          typeof this.props.onSetDynamicInput === "function"
        ) {
          await this.props.onSetDynamicHealthInput(null);
          await this.props.onSetDynamicInput(null);
          this.setState({updatingConsultationDetail: false});
        }
        this.handleClearUpdateDetails(response);

        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let res = await markAsReviewedAPI({
          appointment_id: this.props.appointmentId,
        });
        if (res.data.status === 200) {
          this.setState({isReviewing: false});
          successToast({content: res.data.message || "Marked as reviewed"});
          this.props.history.push(
            `${appRoutesConst.consultationReview}?focused=in_review`
          );
        }
      } else if (
        healthPayload &&
        Object.keys(healthPayload.health_summary).length > 0
      ) {
        let healthResponse = await addHealthAssessmentPatientAPI(healthPayload);
        successToast({
          content: verifyObject(healthResponse, "data.message", "Success"),
        });
        //# TODO: append text into examination here
        // payload = {
        //   ...payload,
        //   consultation: {
        //     ...consultation,
        //     // appointment_id: this.props.appointmentId,
        //     // patient_id: this.props.patientId,
        //   },
        // };
        console.log("ALL PAYLOAD---", payload);
        let response = await updateConsultationDetailAPI(payload, true);

        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        if (
          typeof this.props.onSetDynamicHealthInput === "function" &&
          typeof this.props.onSetDynamicInput === "function"
        ) {
          await this.props.onSetDynamicHealthInput(null);
          await this.props.onSetDynamicInput(null);
          this.setState({updatingConsultationDetail: false});
        }
        this.handleClearUpdateDetails(response);

        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let res = await markAsReviewedAPI({
          appointment_id: this.props.appointmentId,
        });
        if (res.data.status === 200) {
          this.setState({isReviewing: false});
          successToast({content: res.data.message || "Marked as reviewed"});
          this.props.history.push(
            `${appRoutesConst.consultationReview}?focused=in_review`
          );
        }
      } else {
        let response = await updateConsultationDetailAPI(payload, true);

        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        if (
          typeof this.props.onSetDynamicHealthInput === "function" &&
          typeof this.props.onSetDynamicInput === "function"
        ) {
          await this.props.onSetDynamicHealthInput(null);
          await this.props.onSetDynamicInput(null);
          this.setState({updatingConsultationDetail: false});
        }
        this.handleClearUpdateDetails(response);

        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let res = await markAsReviewedAPI({
          appointment_id: this.props.appointmentId,
        });
        if (res.data.status === 200) {
          this.setState({isReviewing: false});
          successToast({content: res.data.message || "Marked as reviewed"});
          this.props.history.push(
            `${appRoutesConst.consultationReview}?focused=in_review`
          );
        }
      }
      this.setState({updatingConsultationDetail: false, isReviewing: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingConsultationDetail: false, isReviewing: false});

      errorToast({content: message});
    }
  };

  // componentWillReceiveProps(nextProps) {
  //   let {dynamicInputs} = nextProps;
  //   console.log(
  //     "dynamicInputs && dynamicInputs.presriptionPlan",
  //     dynamicInputs && dynamicInputs.presriptionPlan
  //   );

  //   this.setState({
  //     prescription_plan: dynamicInputs && dynamicInputs.presriptionPlan,
  //   });
  // }
  handleSaveSnomedCode = async () => {
    return this._handleSnomedCodeUpdate();
  };
  _onRequestListPre() {
    let {patientId, preEncountersActions} = this.props;
    let {onRequest} = preEncountersActions;
    if (typeof onRequest === "function") {
      onRequest({
        patient_id: patientId,
        page: 1,
        document_type: "Prescription",
      });
    }
  }
  renderInputColor = (value, field) => {
    if (value && !isEmpty(value.toString())) {
      // if (_bp < 60) return "#0872FB"; //!LOW
      // if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
      // if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
      // if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
      // if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
    } //!SEVERE

    switch (field) {
      case "diastolic_bp":
        if (value && value < 60) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 60 && value < 80) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 80 && value < 90) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 90 && value < 100) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 100 && value < 120) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 120) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "systolic_bp":
        if (value && value >= 70 && value < 90) {
          return "ha-lowBP text-white custom-input-number";
        } else if (value >= 90 && value < 120) {
          return "ha-normalBP text-white custom-input-number";
        } else if (value >= 120 && value < 140) {
          return "ha-preHyperBP text-white custom-input-number";
        } else if (value >= 140 && value < 160) {
          return "ha-stage1BP text-white custom-input-number";
        } else if (value >= 160 && value < 180) {
          return "ha-stage2BP text-white custom-input-number";
        } else if (value >= 180) {
          return "ha-stage3BP text-white custom-input-number";
        } else {
          return "custom-input-number";
        }

      case "pulse_per_minute":
        if (value && value >= 40 && value < 59) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 60 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "body_temperature":
        if (value && value < 36) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 36.1 && value < 37.2) {
          return "bg-success text-white custom-input-number";
        } else if (value > 37.2) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      case "blood_oxygen_saturation":
        if (value && value >= 1 && value <= 95) {
          return "bg-primary text-white custom-input-number";
        } else if (value >= 96 && value < 100) {
          return "bg-success text-white custom-input-number";
        } else if (value > 100) {
          return "bg-danger text-white custom-input-number";
        } else {
          return "custom-input-number";
        }
      default:
        return "custom-input-number";
    }
  };
  // removeDuplicates = (stateArray) => {
  //   const uniqueItems = {};
  //   const newArray = [];
  //   for (const item of stateArray) {
  //     if (!uniqueItems[item.id]) {
  //       uniqueItems[item.id] = true;
  //       newArray.push(item);
  //     }
  //   }
  //   return newArray;
  // };
  handleFormater = (value) => {
    if (value === null || value === undefined) {
      return "";
    }
    return value.toString().slice(0, 5); // Restrict to 3 digits
  };

  handleParser = (value, allowedNegativeValue) => {
    // Remove non-digit and non-dot characters
    const cleanValue = value.replace(/[^\d.-]/g, "");

    // Split the value into integer and decimal parts
    let [integerPart, decimalPart] = cleanValue.split(".");

    // Check if the value is negative
    const isNegative = cleanValue.startsWith("-");

    // Disallow negative values if allowedNegativeValue is false
    if (!allowedNegativeValue && isNegative) {
      return "";
    }

    // Keep only the first 3 digits in the integer part
    const truncatedIntegerPart = integerPart.slice(0, 3);

    // Ensure decimalPart is at most 2 digits
    const truncatedDecimalPart = decimalPart ? decimalPart.slice(0, 2) : "";

    // Combine the truncated parts back into a valid number format
    const truncatedValue = decimalPart
      ? `${truncatedIntegerPart}.${truncatedDecimalPart}`
      : truncatedIntegerPart;

    // Add back the negative sign if it was initially present
    return truncatedValue;
  };

  scrollToBottomForTextArea = (id) => {
    let historyTextArea = document.getElementById(id);
    if (historyTextArea) {
      historyTextArea.scrollTop = historyTextArea.scrollHeight;
    }
  };
  handleKeyUp(event) {
    //key code for enter
    if (event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
  }
  //function for remove multiple codes in diagnosis as same
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];

    if (stateArray) {
      for (const item of stateArray) {
        const key = item.id || item.code;

        if (key !== undefined && key !== null && !uniqueItems[key]) {
          uniqueItems[key] = true;
          newArray.push(item);
        }
      }
    }

    return newArray;
  };
  //function for filter symtom checker or questionnaire reason only in presenting complaint
  filterUniqueItems = (array1, array2) => {
    const uniqueItems = array1.filter((item2) => {
      let foundSnomedCode = array2.find((item) => item.id === item2.value);

      if (foundSnomedCode) {
        this.setState(
          {
            selectedCodesArray: this.state.selectedCodesArray.filter(
              (item) => +item.id !== +foundSnomedCode.snomed_code_id
            ),
            reason_text: !array2.some((item1) => item1.id === item2.value),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                reason_text: this.state.reason_text,

                selectedCodesArray: this.state.selectedCodesArray,
                selectedSnomedCodesArray: this.state.selectedCodesArray,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }

      return !array2.some((item1) => item1.id === item2.value);
    });

    return uniqueItems;
  };
  onquestionChange = (e) => {
    this.setState({recentTravel: e.target.checked}, () => {
      // if (this.state.recentTravel) {
      let {onSetDynamicInput} = this.props.dynamicTabAction;
      let {dynamicInputs} = this.props;

      if (typeof onSetDynamicInput === "function") {
        let payload = {
          ...dynamicInputs,
          recentTravel: this.state.recentTravel,
        };
        onSetDynamicInput(payload);
      }
      // }
    });
  };
  validateDate = (dateString) => {
    // Check if the input matches the "DD/MM/YYYY" format
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return false; // Doesn't match the expected format
    }

    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split("/").map(Number);

    // Check if the month is valid (between 01 and 12)
    if (month < 1 || month > 12) {
      return false;
    }

    // Check if the day is valid based on the month (e.g., 31 days in December)
    const daysInMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > daysInMonth) {
      return false;
    }

    // Check if the year is a valid positive number (you can add additional validation here)
    if (year < 1 || year > new Date().getFullYear()) {
      return false;
    }

    return true; // Passed all validation checks
  };

  /**
   * Handles the blur event for a checkbox item.
   * @param {object} item - The checkbox item.
   * @param {number} index - The index of the checkbox item in the array.
   */
  handleBlur = (item, index, isPregnancy) => {
    if (item.value) {
      console.log(
        "is valida date---->",
        item.value,
        this.validateDate(item.value)
      );
      if (
        +item.id === 611741 &&
        (item.value === "__/__/____" || !this.validateDate(item.value))
      ) {
        this._handleRemoveCheckboxStateArray(item.id, index);
      } else {
        if (item.value === "__/__/____") {
          this._handleRemoveCheckboxStateArray(item.id, index);
        } else {
          this._handleCheckboxStateArray(item.id, item.value);
        }
      }
    } else {
      this._handleRemoveCheckboxStateArray(item.id, index);
    }
  };

  _handleCheckboxStateArray = (key, val) => {
    if (val === "__/__/____") {
    } else {
      this.setState(
        (prevState) => {
          let newExamination = prevState.examination;
          let newSelectedCodesArray = [
            ...(prevState.newselectedCodesArray || []),
          ];

          let found = prevState.checkboxStateArray.find((i) => i.id === key);

          if (found) {
            let newText;

            if (typeof prevState.examination === "string") {
              newText = prevState.examination.split("\n");
            } else if (isArray(prevState.examination)) {
              newText = prevState.examination;
            } else {
              newText = []; // Default value if examination is neither a string nor an array
            }
            let newExaminationItem = newText.find((item) =>
              item.includes(found.long_text)
            );

            if (newExaminationItem) {
              newExamination = prevState.examination
                ? prevState.examination
                    .split("\n")
                    .filter((item) => !item.includes(newExaminationItem))
                    .map((item) => `${item.replace(/,$/, "")}`)
                : "";
              newExamination = [
                ...newExamination,
                `${found.long_text} [${val}]`,
              ];
            } else {
              newExamination =
                prevState.examination !== "" && prevState.examination
                  ? // &&
                    // prevState.examination.trim().length !== 0
                    // eslint-disable-next-line no-useless-concat
                    prevState.examination + "\n" + `${found.long_text}[${val}]`
                  : `${found.long_text} [${val}]`;
            }

            newSelectedCodesArray.push({
              code: found.snomed_code,
              description: found.snomed_code_description,
              id: found.id,
            });
          }

          return {
            examination: isArray(newExamination)
              ? newExamination
                  .filter(
                    (element) =>
                      element !== "undefined" || element !== undefined
                  )
                  .join("\n")
              : newExamination,
            newselectedCodesArray: this.removeDuplicates([
              ...prevState.newselectedCodesArray,
              ...newSelectedCodesArray,
            ]),
            // selectedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
            // selectedSnomedCodesArray: [
            //   ...prevState.newselectedCodesArray,
            //   ...newSelectedCodesArray,

            // ],
          };
        },
        () => {
          let {selectedCodesArray} = this.state;
          let removedDuplicatesArray =
            this.removeDuplicates(selectedCodesArray);

          this.setState(
            {
              newselectedCodesArray: this.removeDuplicates(
                this.state.newselectedCodesArray || []
              ),
              selectedCodesArray: [
                ...(removedDuplicatesArray || []),
                ...(this.state.newselectedCodesArray || []),
              ],
              selectedSnomedCodesArray: [
                ...(removedDuplicatesArray || []),
                ...(this.state.newselectedCodesArray || []),
              ],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  examination: this.state.examination,
                  newselectedCodesArray: this.state.newselectedCodesArray,
                  selectedCodesArray: this.state.selectedCodesArray,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                };
                onSetDynamicInput(payload);
              }
            }
          );
          this.scrollToBottomForTextArea("examination_textarea");
        }
      );
    }
  };

  _handleRemoveCheckboxStateArray = (key, index) => {
    let newText = this.state.examination
      ? isArray(this.state.examination)
        ? this.state.examination
        : this.state.examination.split("\n")
      : [];
    let removingItem = this.state.checkboxStateArray.find((i) => i.id === key);
    let newExamination = newText.filter(
      (item) => !item.includes(removingItem.long_text)
    );

    this.setState(
      (state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState(
          {
            examination: newExamination.join("\n"),
          },
          () => {
            if (this.state.newselectedCodesArray.length === 0) {
            } else {
              this.setState(
                {
                  newselectedCodesArray:
                    this.state.newselectedCodesArray.filter(
                      (d) => d.id !== key
                    ),
                },
                () => {
                  this.setState(
                    {
                      showCodeModal: false,
                      selectedCodesArray: uniq([
                        ...this.state.selectedSnomedCodesArray,
                        ...this.state.newselectedCodesArray,
                      ]).filter((d) => d.id !== key),
                      selectedSnomedCodesArray: uniq([
                        ...this.state.selectedSnomedCodesArray,
                        ...this.state.newselectedCodesArray,
                      ]).filter((d) => d.id !== key),
                    },
                    () => {
                      let {onSetDynamicInput} = this.props.dynamicTabAction;

                      let Inputs = {
                        ...this.props.dynamicInputs,
                        newselectedCodesArray: this.state.newselectedCodesArray,
                        selectedSnomedCodesArray: this.state.selectedCodesArray,
                        selectedCodesArray: this.state.selectedCodesArray,
                      };
                      if (typeof onSetDynamicInput === "function") {
                        onSetDynamicInput(Inputs);
                      }
                    }
                  );
                }
              );
            }
          }
        );
      }
    );
  };
  handleBlurHA = (item, index) => {
    if (item.value) {
      this._handleHealthStateArray(item.id, item.value);
    } else {
      this._handleRemoveHealthStateArray(item.id, index);
    }
  };
  _handleHealthStateArray = (key, val) => {
    this.setState(
      (prevState) => {
        let newExamination = prevState.examination ? prevState.examination : "";

        let found = prevState.healthStateArray.find((i) => i.id === key);

        if (found) {
          let newText;

          if (typeof prevState.examination === "string") {
            newText = prevState.examination.split("\n");
          } else if (isArray(prevState.examination)) {
            newText = prevState.examination;
          } else {
            newText = []; // Default value if examination is neither a string nor an array
          }
          let newExaminationItem = newText.find((item) =>
            item.includes(found.label)
          );

          if (newExaminationItem) {
            newExamination = isArray(prevState.examination)
              ? prevState.examination
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`)
              : prevState.examination
                  .split("\n")
                  .filter((item) => !item.includes(newExaminationItem))
                  .map((item) => `${item.replace(/,$/, "")}`);

            newExamination = [
              ...newExamination,
              `${found.label}: ${val} ${found.unit}`,
            ];
          } else {
            newExamination =
              prevState.examination !== "" && prevState.examination
                ? // &&
                  // prevState.examination.trim().length !== 0
                  prevState.examination +
                  "\n" +
                  `${found.label}: ${val} ${found.unit}`
                : `${found.label}: ${val} ${found.unit}`;
          }
        }

        return {
          examination: isArray(newExamination)
            ? this.rearrangeHealthData(newExamination.join("\n"))
            : this.rearrangeHealthData(newExamination),

          // selectedSnomedCodesArray: this.state.selectedCodesArray,
        };
      },
      () => {
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            examination: this.state.examination,
            // selectedCodesArray: this.state.selectedCodesArray,
            // selectedSnomedCodesArray: this.state.selectedCodesArray,
          };
          onSetDynamicInput(payload);
        }

        this.scrollToBottomForTextArea("examination_textarea");
      }
    );
  };
  rearrangeHealthData = (data) => {
    let lines;

    // Convert to array if the input is a string
    if (typeof data === "string") {
      lines = data.split("\n");
    } else if (Array.isArray(data)) {
      lines = data;
    } else {
      console.error("Error: Invalid input format. Expected string or array.");
      return data;
    }

    // Define the order in which lines should appear
    const desiredOrder = [
      "Pulse",
      "SpO2",
      "Height",
      "Weight",
      "BMI",
      "Temperature",
      "Systolic",
      "Diastolic",
      "Respiratory rate",
    ];

    // Sort lines based on their order in desiredOrder
    lines.sort((a, b) => {
      let indexA = desiredOrder.indexOf(a.split(":")[0].trim());
      let indexB = desiredOrder.indexOf(b.split(":")[0].trim());
      return indexA - indexB;
    });

    // Convert back to string if the input was a string
    if (typeof data === "string") {
      return lines.join("\n");
    } else {
      return lines;
    }
  };
  _handleRemoveHealthStateArray = (key, index) => {
    let newText = isArray(this.state.examination)
      ? this.state.examination
      : this.state.examination
      ? this.state.examination.split("\n")
      : [];
    let removingItem = this.state.healthStateArray.find((i) => i.id === index);
    let newExamination = newText.filter(
      (item) => !item.includes(removingItem.label)
    );

    this.setState(
      (state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      },
      () => {
        // Scrolling to the bottom of the textarea
        this.setState({
          examination: this.rearrangeHealthData(newExamination.join("\n")),
          // selectedSnomedCodesArray: this.state.selectedCodesArray,
        });
      }
    );
  };
  handleHealthTextChange = (e, name) => {
    this.setState({[name]: e}, () => {
      let {
        pulse_per_minute,
        height,
        weight,
        blood_oxygen_saturation,
        body_temperature,
        systolic_bp,
        diastolic_bp,
        respiratory_rate,
        bmi,
      } = this.state;
      if (
        height &&
        height !== "" &&
        weight &&
        weight !== "" &&
        (!bmi || bmi === "")
      ) {
        this.setState({bmi: this.calculateBMI(height, weight)}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = this.state.bmi;
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                this._handleHealthStateArray(obj.id, obj.value);
              }, 1200);
            }
          );
        });
      } else if (
        (!height || height === "" || !weight || weight === "") &&
        bmi
      ) {
        this.setState({bmi: null}, () => {
          this.setState(
            (state) => {
              let stateItems = state.healthStateArray;
              stateItems[8]["value"] = "";
              return {
                healthStateArray: stateItems,
              };
            },
            () => {
              let obj = this.state.healthStateArray.find((i, index) => {
                if (i.key === "bmi") {
                  return i;
                }
              });
              let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
              if (typeof onSetDynamicHealthInput === "function") {
                let Inputs = {
                  ...this.props.dynamicHealthInputs,

                  pulse_per_minute,
                  height,
                  weight,
                  blood_oxygen_saturation,
                  body_temperature,
                  bmi: this.state.bmi,
                  systolic_bp,
                  diastolic_bp,
                  respiratory_rate,
                };
                onSetDynamicHealthInput(Inputs);
              }
              setTimeout(() => {
                // this.handleBlurHA(obj, obj.id);
                // if (item.value) {

                this._handleRemoveHealthStateArray(obj.id, 8);
              }, 1200);
            }
          );
        });
      } else {
        let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
        if (typeof onSetDynamicHealthInput === "function") {
          let Inputs = {
            ...this.props.dynamicHealthInputs,

            pulse_per_minute,
            height,
            weight,
            blood_oxygen_saturation,
            body_temperature,
            bmi: this.state.bmi,
            systolic_bp,
            diastolic_bp,
            respiratory_rate,
          };
          onSetDynamicHealthInput(Inputs);
        }
      }
    });
  };
  calculateBMI = (height, weight) => {
    // Convert height to meters
    const heightInMeters = height / 100;

    // Calculate BMI
    const bmiValue = weight / (heightInMeters * heightInMeters);

    // Update state with the calculated BMI
    return bmiValue.toFixed(2);
  };
  handleKeyPress = (e, Key, index) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleBlur(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.checkboxStateArray;
        stateItems[index]["value"] = "";
        return {
          checkboxStateArray: stateItems,
        };
      });
    }
  };
  handleKeyPressHA = (e, Key, index) => {
    if (e.key === "Enter") {
      this.handleBlurHA(Key, index);
    } else if (e.key === "Backspace" && !this.state[Key]) {
      this.setState((state) => {
        let stateItems = state.healthStateArray;
        stateItems[index]["value"] = "";
        return {
          healthStateArray: stateItems,
        };
      });
    }
  };

  handleOnChange = (value, key, name) => {
    if (name === "Pregnancy") {
      this.setState({[key]: value.target.value});
    } else {
      this.setState({[key]: value});
    }
  };
  handleAddPrescription = () => {
    // if (this.props.show_prescriotion_modal_from_notes === null) {
    // this.handleDynamicKey(97, "PrescriptionsContainer"); //?NOTE: on next frommanagement it should redirect to prescription modal open,
    return store.dispatch({
      type: "FROM_PAST_CONSULTATION_TRIGGER",
      payload: true,
    });
    // }
  };
  handleOnChange = (value, key, name) => {
    if (name === "Pregnancy") {
      this.setState({[key]: value.target.value});
    } else {
      this.setState({[key]: value});
    }
  };
  hanleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      this.setState(
        (prevState) => ({
          enterCount: prevState.enterCount + 1,
        }),
        async () => {
          if (this.state.enterCount === 2) {
            if (this.props.gender !== "female") {
              await this.handleGetDiagnosis();
            } else {
              await this.handleGetDiagnosisFemale();
            }
            // Call your function here
            // this.callYourFunction();
            // Reset the count
            this.setState({
              enterCount: 0,
            });
          }
        }
      );
    }
  };
  async _handleSymptomSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          searchSymptom: value,
        },
        async () => {
          await this.onGetSymptoms(value);
        }
      );
    } else {
    }
  }
  onGetSymptoms = async (value) => {
    try {
      this.setState({searchingSymptom: true});

      let response = await fetchSymptomsApi({
        search: this.state.searchSymptom,
      });

      let newSymptomsData = verifyObject(response, "data.data", []);
      if (response.data.data) {
        this.setState(
          {
            symptomsData: newSymptomsData.map((item) => {
              return {value: item.id, label: item.name};
            }),
            searchingSymptom: false,
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;
            let {dynamicInputs} = this.props;

            if (typeof onSetDynamicInput === "function") {
              let payload = {
                ...dynamicInputs,
                symptomsData: this.state.symptomsData,
              };
              onSetDynamicInput(payload);
            }
          }
        );
      }
      this.setState({searchingSymptom: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searchingSymptom: false});
      errorToast({content: message});
    }
  };
  handleGetDiagnosis = async () => {
    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        let payload = {
          patient_id: this.props.patientId,
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: "",
          region_id: this.state.region_id, //default region id western europe is 1
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: this.state.selectedCodesArray.filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2.code === +item1.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }

              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          // next();
          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: this.state.reason_text,
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,
                    // region_id: this.state.region_id,
                    // recentTravel: this.state.recentTravel,
                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleGetDiagnosisFemale = async () => {
    if (
      isArray(this.state.reason_text) &&
      this.state.reason_text.length !== 0
    ) {
      let findName = this.state.reason_text.map((item) => item.label).join(",");
      if (findName) {
        // setFindedName(findName.map((item) => item.name).join(","));

        let payload = {
          patient_id: this.props.patientId,
          search: findName,
          symptom_id: this.state.reason_text
            .map((item) => {
              if (isNumber(item.value)) return item.value;
            })
            .join(","),
          is_pregnant: this.state.is_pregnant,
          region_id: this.state.region_id,
        };
        if (this.state.region_id && this.state.recentTravel) {
          payload = {
            ...payload,
            region_id: this.state.region_id,
          };
        }

        try {
          await this.setState(
            {
              searchingDiagnose: true,
              diagnosisData: [],
              selectedCodesArray: this.state.selectedCodesArray.filter(
                (item1) => {
                  return !this.state.symptompSnomedCode.some(
                    (item2) => +item2.code === +item1.code
                  );
                }
              ),
              symptompSnomedCode: [],
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  selectedCodesArray: this.state.selectedCodesArray,
                  symptompSnomedCode: this.state.symptompSnomedCode,
                };
                onSetDynamicInput(payload);
              }
              store.dispatch({
                type: "SET_SYMPTOMP_FETCHED_LINKS",
                payload: null,
              });
            }
          );

          let response = await fetchDiagnosisApi(payload);

          if (response.data.data) {
            this.setState(
              {
                diagnosisData: response.data.data,
                searchingDiagnose: false,

                // reason_text: findName.map((item) => item.name).join(","),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;
                let {dynamicInputs} = this.props;

                if (typeof onSetDynamicInput === "function") {
                  let payload = {
                    ...dynamicInputs,
                    reason_text: this.state.reason_text,
                    reason_text_string: findName,

                    diagnosisData: this.state.diagnosisData,
                  };
                  onSetDynamicInput(payload);
                }
              }
            );
          }

          await this.setState(
            {
              searchingDiagnose: false,
              // reason_text: findName.map((item) => item.name).join(","),
              getDiagnosisModal: false,
            },
            () => {
              let {onSetDynamicInput} = this.props.dynamicTabAction;
              let {dynamicInputs} = this.props;

              if (typeof onSetDynamicInput === "function") {
                let payload = {
                  ...dynamicInputs,
                  reason_text: this.state.reason_text,
                  reason_text_string: findName,

                  diagnosisData: this.state.diagnosisData,
                };
                onSetDynamicInput(payload);
              }
            }
          );
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({searchingDiagnose: false});
          errorToast({content: message});
        }
      }
    }
  };
  handleClear = () => {
    let reasonStringArray = (this.state.reason_text || []).map(
      (item) => item.label || ""
    );
    let removeHistoryStringArray = this.state.history
      .split("\n")
      .filter((item1) => {
        return !reasonStringArray.some((item2) => item2 === item1);
      });

    return this.setState(
      {
        reason_text: [],
        diagnosisData: {},
        history: removeHistoryStringArray.join("\n"),
        region_id: 1,
        recentTravel: false,
        selectedCodesArray: this.state.selectedCodesArray.filter((item1) => {
          return ![...this.state.symptompSnomedCode, ...DP_QUESTIONNAIRE].some(
            (item2) =>
              +item2.id === +item1.id || +item2.snomed_code_id === +item1.id
          );
        }),
        symptompSnomedCode: [],
      },
      () => {
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: null,
        });
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            history: this.state.history,
            reason_text_string: "",
            recentTravel: this.state.recentTravel,
            region_id: this.state.region_id,
            diagnosisData: this.state.diagnosisData,
            selectedCodesArray: this.state.selectedCodesArray,
            selectedSnomedCodesArray: this.state.selectedCodesArray,
            symptompSnomedCode: this.state.symptompSnomedCode,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  customAbbrivationChange = (e) => {
    this.setState(
      {
        isCustomAbbrevation: true,
        reason_text: [
          ...this.state.reason_text,
          {
            value: e,
            label: e,
          },
        ],
        symptomsData: [
          ...this.state.symptomsData,
          {
            value: e,
            label: e,
          },
        ],
      },
      () => {
        this.setState({custom_text: ""});
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            reason_text_string: "",
            symptomsData: this.state.symptomsData,
            diagnosisData: this.state.diagnosisData,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  handleClearCheckboxValue = () => {
    this.setState((state) => {
      let stateItems = state.checkboxStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        checkboxStateArray: stateItems,
      };
    });
  };

  handleClearHealthboxValue = () => {
    this.setState((state) => {
      let stateItems = state.healthStateArray;
      stateItems.map((item, index) => {
        return (stateItems[index]["value"] = null);
      });

      return {
        healthStateArray: stateItems,
      };
    });
  };
  getObjectFromData = (data, value) => {
    let found;
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.find((item, index) => {
        // console.log("checked=========>", item.name, value, item.name === value);
        if (item.name === value) return (found = item);
      });
    }
    console.log("found--------------------", found);
    return found;
  };
  handleDynamicCondition = (param, forPrescrptionPLan) => {
    let condition = forPrescrptionPLan
      ? param && param !== undefined && param !== ""
      : param &&
        param !== undefined &&
        param !== "" &&
        param.trim().length !== 0;
    return condition;
  };
  handleClearUpdateDetails = (consultationData) => {
    return this.setState(
      (prevState) => {
        // let stateItems = prevState.checkboxStateArray.slice();
        // let stateHealthItems = prevState.healthStateArray.slice();
        // let reasonStringArray = this.state.reason_text.map(
        //   (item) => item.label
        // );
        // let removeHistoryStringArray = this.state.history
        //   .split("\n")
        //   .filter((item1) => {
        //     return !reasonStringArray.some((item2) => item2 === item1);
        //   });
        // let checkboxData = stateItems.forEach((item, index) => {
        //   stateItems[index]["value"] = null;
        // });

        // let healthData = stateHealthItems.forEach((item, index) => {
        //   stateHealthItems[index]["value"] = null;
        // });

        return {
          consultationDetail: consultationData
            ? consultationData.data.data
            : this.state.consultationDetail,
          selectedSnomedCodesArray: consultationData
            ? verifyObject(consultationData, "data.data.snomed_codes", [])
                .length !== 0
              ? uniqBy(consultationData.data.data.snomed_codes, "description")
              : []
            : this.state.selectedSnomedCodesArray,
          newselectedCodesArray: [],
          updatingConsultationDetail: false,
          reason_text: [],
          diagnosisData: {},
          // history: removeHistoryStringArray.join("\n"),
          region_id: 1,
          recentTravel: false,
          checkboxStateArray: [...checkboxArray],
          healthStateArray: [...Health_Assessment_KVP],
          history: "",
          editHistory: false,
          examination: "",
          editExamination: false,
          editDiagnosis: false,
          managementPlan: "",
          editManagementPlan: false,
          prescription_plan: "",
          editPrescriptionPlan: false,
          // barcodeNumber: "",
          // editBarcodeNumber: false,
          editOutcome: false,
          treatment: "",
          followUp: "",
          referral: "",
          comment: "",
          snomedCodes: [],
          searching: false,
          isLoadmore: false,
          search: false,
          snomedCodeText: "",
          showCodeModal: false,
          addingDetail: false,
          updatingDetail: false,
          visible: false,
          isFinshedEdit: true,
          outcome: {},
          reason: null,
          symptomsData: [],
          pulse_per_minute: "",
          diastolic_bp: "",
          systolic_bp: "",
          blood_oxygen_saturation: "",
          body_temperature: "",
          height: "",
          weight: "",
          respiratory_rate: "",
          bmi: "",
        };
      },
      () => {
        this._SaveComponentStateToRedux(true); // passed true for clear all input from redux also
      }
    );
  };
  render() {
    let {
      consultationDetail,
      history,
      examination,
      managementPlan,
      // barcodeNumber,
      // changing,
      visible,
      searching,
      // isLoadmore,
      snomedCodes,
      // snomedCodeText,
      // showCodeModal,
      // selectedCodesArray,
      newselectedCodesArray,
      // selectedSnomedCodesArray,
      addingDetail,
      // editExamination,
      // editHistory,
      // editOutcome,
      // editBarcodeNumber,
      // editManagementPlan,
      // editPrescriptionPlan,
      prescription_plan,
      // editDiagnosis,
      // updatingDetail,
      isLoading,
      errors,
      splitSize,
    } = this.state;

    let {
      // presriptionPlan,

      selectedCodesArray,

      checkboxStateArray,
    } = this.state;
    let secondPanelClassName = splitSize ? "up-arrow" : "down-arrow";

    let {
      // appointmentId, patientId,
      // userId,
      appointmentObj,
    } = this.props;
    let {
      // data,
      isLoading: isLoadingPreEncounter,
      pagination: paginationPreEncounter,
    } = this.props.preEnccounter;
    let {
      // data,
      isLoading: isLoadingPrescription,
      pagination: paginationPrescription,
    } = this.props.prescriptions;
    // let editViewCondition =
    //   window.location.pathname === "/patient/consultation_detail" ||
    //   window.location.pathname === "/doctor/walkin-appointment" ||
    //   window.location.pathname === "/doctor/video-appointment";

    const urlParams = new URLSearchParams(window.location.search);
    const isReview = urlParams.get("isReview");
    if (isLoading) {
      return <CommonLoader />;
    }
    console.log("STATE------>", this.state);
    // console.log("userId---->", userId);

    return (
      <Fragment>
        <Spin spinning={this.state.isReviewing}>
          <div className="">
            <div className="selection_container">
              <div className="patientdetailsmain padding-15-lr consultaiondetail-main inreviewconsultion">
                <div
                  className={`mainappoinmentwrap ${
                    this.props.prev_appt_tab_key === "in_review"
                      ? "showreasonfield"
                      : appointmentObj &&
                        verifyObject(appointmentObj, "reason_text", false)
                      ? "showreasonfield"
                      : "hidereasonfield"
                  }`}
                >
                  <SplitPane split="horizontal" onChange={this.updateHeight}>
                    <Pane>
                      <PerfectScrollbar>
                        <div className="sectionscroll pre_encountersecscroll">
                          <div className="push-20-b walkin_appnt_wrapper padding-15-t">
                            <div className="selection_container container-fluid padding-left-10 padding-right-10">
                              <div className="form-group relative push-10-b">
                                {/* {consultationDetail !== null && (
                                <div className="">
                                  {this.state.isFinshedEdit ? (
                                    <Button
                                      onClick={
                                        () =>
                                          this.setState(
                                            {
                                              isFinshedEdit: false,
                                            },
                                            () => {
                                              this.handleRegionHAfunction();
                                            }
                                          )
                                        // this._handleEdit("editPrescriptionPlan")
                                      }
                                      className="add-coding cursor font-12 weight-400"
                                    >
                                      <EditOutlined /> Edit
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        this.setState(
                                          {
                                            isFinshedEdit: true,
                                          },
                                          () => {
                                            this.handleClearUpdateDetails();
                                          }
                                        )
                                      }
                                      className="add-coding cursor font-12 weight-400"
                                    >
                                      <CloseCircleOutlined /> Close
                                    </Button>
                                  )}
                                </div>
                              )} */}
                                <div className="form-row">
                                  <div className="col-md-7 maincol">
                                    {/* {this.props.fromOngoingConsultation && (  commenting as ADD-10528*/}
                                    {
                                      <div className="reason_wrapper">
                                        <div className="d-flex justify-content-between align-items-center push-5-b">
                                          <div className="labelicon d-flex align-items-center">
                                            <p className="font-12 weight-400 text-left text-blue in-block margin-0 text-capitalize">
                                              Presenting Complaint
                                            </p>
                                            {verifyObject(
                                              this.props,
                                              "appointmentOb.reason_image"
                                            ) ? (
                                              <div className="pres_icon ml-3">
                                                <Button
                                                  className="imagebtn test"
                                                  onClick={
                                                    this.showpresentingModal
                                                  }
                                                >
                                                  <PictureFilled />
                                                </Button>

                                                <ModalPopUp
                                                  title={"Presenting Complaint"}
                                                  className="presentingcomp_popup"
                                                  visible={
                                                    this.state
                                                      .getPresentingComModal
                                                  }
                                                  handleOk={this.handleOk}
                                                  handleCancel={
                                                    this.handleCancel
                                                  }
                                                  okText="Okay"
                                                  cancelText="Cancel"
                                                  bodyStyle={{height: 400}}
                                                >
                                                  {this.props.appointmentObj
                                                    .reason_image !== "" &&
                                                  this.props.appointmentObj
                                                    .reason_image !==
                                                    undefined ? (
                                                    <Image
                                                      src={
                                                        this.props
                                                          .appointmentObj
                                                          .reason_image !== ""
                                                          ? this.props
                                                              .appointmentObj
                                                              .reason_image
                                                          : ""
                                                      }
                                                      className="cursor-pointer"
                                                      alt="Patient"
                                                      width="100%"
                                                    ></Image>
                                                  ) : (
                                                    <img
                                                      src={
                                                        require("../../assets/images/common/img-placeholder.png")
                                                          .default
                                                      }
                                                      alt="Patient"
                                                      className="cursor-pointer"
                                                    ></img>
                                                  )}
                                                </ModalPopUp>
                                              </div>
                                            ) : null}
                                            <div className="infoicon ml-2">
                                              <Popover
                                                content={presentingpopover}
                                                title="Info"
                                              >
                                                <Button
                                                  className="infobtn"
                                                  // onClick={
                                                  //   this.showPresentingInfoModal
                                                  // }
                                                >
                                                  <InfoCircleOutlined />
                                                </Button>
                                              </Popover>
                                            </div>
                                          </div>
                                          {this.props.patientDetail &&
                                            this.props.patientDetail?.gender ===
                                              "female" && (
                                              <div className="ispregnentbox">
                                                {this.props.patientDetail?.age >
                                                  13 &&
                                                  this.props.patientDetail
                                                    ?.age < 65 && (
                                                    <div className="checkboxgroup">
                                                      <span className="d-flex checkboxmaintitle">
                                                        Is the patient pregnant?
                                                      </span>
                                                      <Radio.Group
                                                        value={
                                                          this.state.is_pregnant
                                                        }
                                                        onChange={(e) => {
                                                          this.setState(
                                                            {
                                                              is_pregnant:
                                                                e.target.value,
                                                            },
                                                            () => {
                                                              if (
                                                                this.state
                                                                  .is_pregnant ===
                                                                "y"
                                                              ) {
                                                                this.setState(
                                                                  {
                                                                    selectedCodesArray:
                                                                      [
                                                                        ...this
                                                                          .state
                                                                          .selectedCodesArray,
                                                                        {
                                                                          id:
                                                                            process
                                                                              .env
                                                                              .REACT_APP_ENV ===
                                                                            "dev"
                                                                              ? 1719078
                                                                              : 47928,
                                                                          code: 169571009,
                                                                          description:
                                                                            "Patient pregnant NOS",
                                                                        },
                                                                      ],
                                                                  },
                                                                  () => {
                                                                    let {
                                                                      onSetDynamicInput,
                                                                    } =
                                                                      this.props
                                                                        .dynamicTabAction;
                                                                    let {
                                                                      dynamicInputs,
                                                                    } =
                                                                      this
                                                                        .props;

                                                                    if (
                                                                      typeof onSetDynamicInput ===
                                                                      "function"
                                                                    ) {
                                                                      let payload =
                                                                        {
                                                                          ...dynamicInputs,
                                                                          selectedCodesArray:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                          selectedSnomedCodesArray:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                          is_pregnant:
                                                                            this
                                                                              .state
                                                                              .is_pregnant,
                                                                        };
                                                                      onSetDynamicInput(
                                                                        payload
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              } else {
                                                                this.setState(
                                                                  {
                                                                    selectedCodesArray:
                                                                      this.state.selectedCodesArray.filter(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.id !==
                                                                          169571009
                                                                      ),
                                                                    selectedSnomedCodesArray:
                                                                      this.state.selectedSnomedCodesArray.filter(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.id !==
                                                                          169571009
                                                                      ),
                                                                  },
                                                                  () => {
                                                                    let {
                                                                      onSetDynamicInput,
                                                                    } =
                                                                      this.props
                                                                        .dynamicTabAction;
                                                                    let {
                                                                      dynamicInputs,
                                                                    } =
                                                                      this
                                                                        .props;

                                                                    if (
                                                                      typeof onSetDynamicInput ===
                                                                      "function"
                                                                    ) {
                                                                      let payload =
                                                                        {
                                                                          ...dynamicInputs,
                                                                          selectedCodesArray:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                          selectedSnomedCodesArray:
                                                                            this
                                                                              .state
                                                                              .selectedCodesArray,
                                                                          is_pregnant:
                                                                            this
                                                                              .state
                                                                              .is_pregnant,
                                                                        };
                                                                      onSetDynamicInput(
                                                                        payload
                                                                      );
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <Radio value="y">
                                                          Yes
                                                        </Radio>
                                                        <Radio value="n">
                                                          No
                                                        </Radio>
                                                        {/* <Radio value="">Other</Radio> */}
                                                      </Radio.Group>
                                                    </div>
                                                  )}
                                              </div>
                                            )}
                                          {/* {this.props.fromOngoingConsultation && ( */}
                                          <div className="d-flex align-items-center">
                                            <a
                                              className="font-12 weight-500 text-right text-blue in-block mr-2 text-capitalize search_symtoms"
                                              onClick={() => {
                                                if (
                                                  this.props.gender !== "female"
                                                ) {
                                                  this.handleGetDiagnosis();
                                                } else {
                                                  this.handleGetDiagnosisFemale();
                                                }
                                                // this._SaveComponentStateToRedux();
                                              }}
                                              disabled={
                                                (isArray(
                                                  this.state.reason_text
                                                ) &&
                                                  this.state.reason_text
                                                    .length === 0) ||
                                                this.state.searchingDiagnose
                                              }
                                              loading={
                                                this.state.searchingDiagnose
                                              }
                                            >
                                              Get Results
                                            </a>
                                            <a
                                              disabled={
                                                isArray(
                                                  this.state.reason_text
                                                ) &&
                                                this.state.reason_text
                                                  .length === 0
                                              }
                                              onClick={this.handleClear}
                                              className="font-12 weight-500 text-right text-blue in-block text-capitalize search_symtoms"
                                            >
                                              Clear
                                            </a>
                                          </div>
                                          {/* )} */}
                                        </div>
                                        <div className="">
                                          <div className="d-flex full-width align-items-center">
                                            <CreatableSelect
                                              isMulti
                                              options={this.state.symptomsData}
                                              isValidNewOption={() => false}
                                              allowCreateWhileLoading={false}
                                              className="react-select-container"
                                              classNamePrefix="react-select"
                                              value={
                                                // this.state.reason_text === ""
                                                //   ? []
                                                //   :
                                                this.state.reason_text
                                              }
                                              onKeyDown={(e) =>
                                                this.hanleEnterKeyPress(e)
                                              }
                                              ref={this.creatableRef}
                                              onChange={(value, actionMeta) => {
                                                if (value && value.length > 0) {
                                                  this.setState(
                                                    {
                                                      reason_text:
                                                        this.filterUniqueItems(
                                                          value,
                                                          process.env
                                                            .REACT_APP ===
                                                            "prod"
                                                            ? getLivePerformaQuestionnaire(this.props.current_corporate)
                                                            : DP_QUESTIONNAIRE
                                                        ),
                                                    },
                                                    () => {
                                                      if (
                                                        actionMeta.action ===
                                                        "remove-value"
                                                      ) {
                                                        let newArray = [];

                                                        let {dynamicInputs} =
                                                          this.props;
                                                        let removeStringArray =
                                                          dynamicInputs?.reason_text.filter(
                                                            (item) => {
                                                              return !value.some(
                                                                (item2) =>
                                                                  item2.label ===
                                                                  item.label
                                                              );
                                                            }
                                                          );
                                                        let historyStringArray =
                                                          this.state.history
                                                            .split("\n")
                                                            .filter(
                                                              (item) =>
                                                                item !==
                                                                removeStringArray[0]
                                                                  .label
                                                            );

                                                        newArray =
                                                          historyStringArray;

                                                        this.setState(
                                                          {
                                                            history:
                                                              newArray.join(
                                                                "\n"
                                                              ),
                                                          },
                                                          () => {
                                                            let {
                                                              onSetDynamicInput,
                                                            } =
                                                              this.props
                                                                .dynamicTabAction;
                                                            let {
                                                              dynamicInputs,
                                                            } = this.props;

                                                            if (
                                                              typeof onSetDynamicInput ===
                                                              "function"
                                                            ) {
                                                              let payload = {
                                                                ...dynamicInputs,
                                                                history:
                                                                  this.state
                                                                    .history,
                                                                reason_text:
                                                                  this.state
                                                                    .reason_text,
                                                              };
                                                              onSetDynamicInput(
                                                                payload
                                                              );
                                                            }
                                                          }
                                                        );
                                                      } else {
                                                        let newArray = [];

                                                        let newHistory =
                                                          this.state.history !==
                                                            "" &&
                                                          this.state.history
                                                            ? this.state
                                                                .history +
                                                              "\n" +
                                                              value
                                                                .map(
                                                                  (item) =>
                                                                    item.label
                                                                )
                                                                .join("\n")
                                                            : value
                                                                .map(
                                                                  (item) =>
                                                                    item.label
                                                                )
                                                                .join("\n");

                                                        const uniqueItems = {};
                                                        for (const item of newHistory.split(
                                                          "\n"
                                                        )) {
                                                          if (
                                                            !uniqueItems[item]
                                                          ) {
                                                            uniqueItems[
                                                              item
                                                            ] = true;
                                                            newArray.push(item);
                                                          }
                                                        }

                                                        this.setState(
                                                          {
                                                            history:
                                                              newArray.join(
                                                                "\n"
                                                              ),
                                                          },
                                                          () => {
                                                            let {
                                                              onSetDynamicInput,
                                                            } =
                                                              this.props
                                                                .dynamicTabAction;
                                                            let {
                                                              dynamicInputs,
                                                            } = this.props;

                                                            if (
                                                              typeof onSetDynamicInput ===
                                                              "function"
                                                            ) {
                                                              let payload = {
                                                                ...dynamicInputs,
                                                                history:
                                                                  this.state
                                                                    .history,
                                                                reason_text:
                                                                  this.state
                                                                    .reason_text,
                                                              };
                                                              onSetDynamicInput(
                                                                payload
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }
                                                    }
                                                  );
                                                } else {
                                                  let reasonStringArray = (
                                                    this.state.reason_text || []
                                                  ).map(
                                                    (item) => item.label || ""
                                                  );
                                                  let removeHistoryStringArray =
                                                    this.state.history
                                                      ? this.state.history
                                                          .split("\n")
                                                          .filter(
                                                            (item1) =>
                                                              !reasonStringArray.some(
                                                                (item2) =>
                                                                  item2 ===
                                                                  item1
                                                              )
                                                          )
                                                      : [];

                                                  this.setState(
                                                    {
                                                      reason_text: value,
                                                      history:
                                                        removeHistoryStringArray.join(
                                                          "\n"
                                                        ),
                                                      diagnosisData: [],
                                                      selectedCodesArray:
                                                        this.state.selectedCodesArray.filter(
                                                          (item1) => {
                                                            return !this.state.symptompSnomedCode.some(
                                                              (item2) =>
                                                                +item2.code ===
                                                                +item1.code
                                                            );
                                                          }
                                                        ),
                                                      symptompSnomedCode: [],
                                                    },
                                                    () => {
                                                      store.dispatch({
                                                        type: "SET_SYMPTOMP_FETCHED_LINKS",
                                                        payload: null,
                                                      });
                                                      let {onSetDynamicInput} =
                                                        this.props
                                                          .dynamicTabAction;
                                                      let {dynamicInputs} =
                                                        this.props;

                                                      if (
                                                        typeof onSetDynamicInput ===
                                                        "function"
                                                      ) {
                                                        let payload = {
                                                          ...dynamicInputs,
                                                          reason_text:
                                                            this.state
                                                              .reason_text,
                                                          history:
                                                            this.state.history,
                                                          reason_text_string:
                                                            "",
                                                          symptomsData:
                                                            this.state
                                                              .symptomsData,
                                                          diagnosisData:
                                                            this.state
                                                              .diagnosisData,
                                                          selectedCodesArray:
                                                            this.state
                                                              .selectedCodesArray,
                                                          selectedSnomedCodesArray:
                                                            this.state
                                                              .selectedCodesArray,
                                                          symptompSnomedCode:
                                                            this.state
                                                              .symptompSnomedCode,
                                                        };
                                                        onSetDynamicInput(
                                                          payload
                                                        );
                                                      }
                                                    }
                                                  );
                                                }
                                                if (
                                                  actionMeta &&
                                                  actionMeta.action ===
                                                    "select-option"
                                                ) {
                                                  if (
                                                    this.creatableRef &&
                                                    this.creatableRef.current
                                                  ) {
                                                    if (
                                                      typeof this.creatableRef
                                                        .current.select.select
                                                        .inputRef.focus ===
                                                      "function"
                                                    ) {
                                                      this.creatableRef.current.select.select.inputRef.focus();
                                                    }
                                                  }
                                                }
                                              }}
                                              placeholder="Search and select"
                                              noOptionsMessage={() =>
                                                "No matching options"
                                              }
                                              // onCreateOption={
                                              //   this.customAbbrivationChange
                                              // }
                                              filterOption={() => true}
                                              components={{
                                                DropdownIndicator: null,
                                              }}
                                              isLoading={
                                                this.state.searchingSymptom ||
                                                this.state.searchingDiagnose
                                              }
                                              onInputChange={(value) => {
                                                this.setState({
                                                  custom_text: value,
                                                });
                                                this.searchUpdateSymptom(value);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    }
                                    {/* )} */}
                                    {
                                      <QuestionnaireFillUp
                                        selectedCodesArray={
                                          this.state.selectedCodesArray
                                        }
                                        symptompSnomedCode={
                                          this.state.symptompSnomedCode
                                        }
                                        history={this.state.history}
                                        gender={this.props.gender}
                                        handleExaminationChange={(
                                          examinationString
                                        ) => {
                                          this.setState(
                                            {
                                              history: isEmpty(
                                                this.state.history
                                              )
                                                ? examinationString
                                                : this.state.history +
                                                  "\n" +
                                                  examinationString,
                                              diagnosisData: null,
                                            },
                                            () => {
                                              this.scrollToBottomForTextArea(
                                                "history_textarea"
                                              );
                                              let {onSetDynamicInput} =
                                                this.props.dynamicTabAction;
                                              let {dynamicInputs} = this.props;

                                              if (
                                                typeof onSetDynamicInput ===
                                                "function"
                                              ) {
                                                let payload = {
                                                  ...dynamicInputs,
                                                  history: this.state.history,
                                                  // reason_text: this.state.reason_text,
                                                  // reason_text_string: "",
                                                  symptomsData:
                                                    this.state.symptomsData,
                                                  diagnosisData: null,
                                                  selectedCodesArray:
                                                    this.state
                                                      .selectedCodesArray,
                                                  selectedSnomedCodesArray:
                                                    this.state
                                                      .selectedCodesArray,
                                                };
                                                onSetDynamicInput(payload);
                                              }
                                            }
                                          );
                                        }}
                                        updateReasonText={(reason) => {
                                          this.setState({
                                            symptomsData: [
                                              // ...this.state.searchSymptom,
                                              reason,
                                            ],
                                            diagnosisData: null,
                                            reason_text: [
                                              // ...this.state.reason_text,
                                              reason,
                                            ],
                                            recentTravel: false,
                                            region_id: 1,
                                          });
                                        }}
                                        updateSnomedCodes={(
                                          found,
                                          removeOldSnomedCodeArray,
                                          removeOldSymptomSnomedCode
                                        ) => {
                                          this.setState(
                                            {
                                              selectedCodesArray:
                                                removeOldSnomedCodeArray
                                                  .concat({
                                                    id: found.snomed_code_id,
                                                    code: found.snomed_code,
                                                    description:
                                                      found.snomed_code_title,
                                                  })
                                                  .filter((item1) => {
                                                    return !this.state.symptompSnomedCode.some(
                                                      (item2) =>
                                                        +item2.id === +item1.id
                                                    );
                                                  }),
                                              symptompSnomedCode: [],
                                              // removeOldSymptomSnomedCode.concat({
                                              //   id: found.snomed_code_id,
                                              //   code: found.snomed_code,
                                              //   description:
                                              //     found.snomed_code_title,
                                              // }),
                                              diagnosisData: null,
                                              recentTravel: false,
                                              region_id: 1,
                                            },
                                            () => {
                                              store.dispatch({
                                                type: "SET_SYMPTOMP_FETCHED_LINKS",
                                                payload: null,
                                              });
                                              let {onSetDynamicInput} =
                                                this.props.dynamicTabAction;
                                              let {dynamicInputs} = this.props;

                                              if (
                                                typeof onSetDynamicInput ===
                                                "function"
                                              ) {
                                                let payload = {
                                                  ...dynamicInputs,
                                                  history: this.state.history,
                                                  reason_text:
                                                    this.state.reason_text,
                                                  recentTravel:
                                                    this.state.recentTravel,
                                                  region_id:
                                                    this.state.region_id,
                                                  reason_text_string: "",
                                                  symptomsData:
                                                    this.state.symptomsData,
                                                  diagnosisData: null,
                                                  selectedCodesArray:
                                                    this.state
                                                      .selectedCodesArray,
                                                  selectedSnomedCodesArray:
                                                    this.state
                                                      .selectedCodesArray,
                                                  symptompSnomedCode:
                                                    this.state
                                                      .symptompSnomedCode,
                                                };
                                                onSetDynamicInput(payload);
                                              }
                                            }
                                          );
                                        }}
                                      />
                                    }
                                    <div className="consultant_review push-10-b">
                                      <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                        History
                                      </p>
                                      {/* //ON GOING TASK START */}

                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {consultationDetail !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.history",
                                              "-"
                                            )
                                              ? verifyObject(
                                                  consultationDetail,
                                                  "consultation_log.history",
                                                  "-"
                                                )
                                              : "-"}
                                          </p>
                                        )}
                                        {verifyObject(
                                          consultationDetail,
                                          "consultation_log.edited_history",
                                          []
                                        ).map((k, index) => {
                                          if (k.history !== null) {
                                            return (
                                              <div
                                                className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                                key={index}
                                              >
                                                {" "}
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                  {k.history}
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {k.edited_by} On{" "}
                                                  {moment(k.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>

                                      {/* //ON GOING TASK END */}

                                      {
                                        <AutoTextArea
                                          autoSize={true}
                                          type="text"
                                          className={`form-control-textarea input-bg-f9f9f9 form-control push-10-t ${
                                            errors &&
                                            errors.history &&
                                            "danger-border"
                                          } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                                          name="history"
                                          value={history}
                                          onChange={this._handleTextChange}
                                          placeholder="Write patient history here"
                                          rows="2"
                                          id="history_textarea"
                                          scrollToBottomForTextArea={() =>
                                            this.scrollToBottomForTextArea(
                                              "history_textarea"
                                            )
                                          }
                                        />
                                      }
                                      {errors && (
                                        <span className="validate-danger font-14">
                                          {errors.history}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  {
                                    <div className="col-md-5 consultantion-info-table-wrapper">
                                      {/* {this.props.fromOngoingConsultation && ( */}

                                      <div className="form-group">
                                        <div className="form-row">
                                          <div className="col-md-12 religon_drop">
                                            <div className="walkingquestions">
                                              <div className="hideshowcheckbox">
                                                <Checkbox
                                                  checked={
                                                    this.state.recentTravel
                                                  }
                                                  onChange={
                                                    this.onquestionChange
                                                  }
                                                >
                                                  <label htmlFor="sel1 text-high-dark-blue-imp">
                                                    Any recent foreign travel?
                                                  </label>
                                                </Checkbox>
                                              </div>
                                            </div>
                                            {/* {this.state.recentTravel && ( */}
                                            <Select
                                              // showSearch
                                              getPopupContainer={() => document.querySelector('.consultantion-info-table-wrapper')}
                                              disabled={
                                                !this.state.recentTravel ||
                                                this.state.fetchingRegion
                                              }
                                              loading={
                                                this.state.fetchingRegion
                                              }
                                              className="form-control margin-0 form-control-textarea input-bg-f9f9f9 full-width custom-input font-12 weight-400 placeholder-10"
                                              placeholder="Select region"
                                              optionFilterProp="children"
                                              value={
                                                !this.state.recentTravel
                                                  ? this.state.fetchingRegion
                                                    ? null
                                                    : 1
                                                  : this.state.fetchingRegion
                                                  ? null
                                                  : this.state.region_id
                                              }
                                              onChange={(value) =>
                                                this.setState(
                                                  {
                                                    region_id: value,
                                                  },
                                                  async () => {
                                                    let {onSetDynamicInput} =
                                                      this.props
                                                        .dynamicTabAction;
                                                    let {dynamicInputs} =
                                                      this.props;

                                                    if (
                                                      typeof onSetDynamicInput ===
                                                      "function"
                                                    ) {
                                                      let payload = {
                                                        ...dynamicInputs,
                                                        region_id:
                                                          this.state.region_id,
                                                      };
                                                      onSetDynamicInput(
                                                        payload
                                                      );
                                                    }
                                                    // TODO:417
                                                    if (
                                                      this.props.gender !==
                                                      "female"
                                                    ) {
                                                      await this.handleGetDiagnosis();
                                                    } else {
                                                      await this.handleGetDiagnosisFemale();
                                                    }
                                                  }
                                                )
                                              }
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                            >
                                              {this.state.regionsData.length >
                                                0 &&
                                                this.state.regionsData.map(
                                                  (region) => {
                                                    return (
                                                      <Select.Option
                                                        value={region.nhs_id}
                                                      >{`${region.name} `}</Select.Option>
                                                    );
                                                  }
                                                )}
                                            </Select>
                                            {/* )} */}
                                          </div>
                                        </div>
                                      </div>
                                      {/* )} */}
                                      {
                                        // this.props.fromOngoingConsultation &&
                                        this.state.diagnosisData !== null && (
                                          <div className="">
                                            {this.state.diagnosisData &&
                                              !isEmpty(
                                                this.state.diagnosisData
                                              ) && (
                                                // this.state.diagnosisData.length > 0 &&
                                                <div className="tabledata push-10-b">
                                                  {this.state
                                                    .searchingDiagnose ? (
                                                    <span className="d-flex justify-content-center align-items-center">
                                                      <Spin />
                                                    </span>
                                                  ) : (
                                                    <SymmtomDiagnosisTabs
                                                      gender={this.props.gender}
                                                      diagnosisData={
                                                        this.state.diagnosisData
                                                      }
                                                      loading={
                                                        this.state
                                                          .searchingDiagnose
                                                      }
                                                      patient_id={
                                                        this.props.patientId ||
                                                        this.props.patient_id
                                                      }
                                                      updateSnomedCodes={(
                                                        found
                                                      ) => {
                                                        this.setState(
                                                          {
                                                            selectedCodesArray:
                                                              uniqBy(
                                                                [
                                                                  ...selectedCodesArray,
                                                                  {
                                                                    id: found.id,
                                                                    code: found.code,
                                                                    description:
                                                                      found.description,
                                                                  },
                                                                ],
                                                                "code"
                                                              ).filter(
                                                                (item) => {
                                                                  return !this.state.symptompSnomedCode.some(
                                                                    (item2) =>
                                                                      +item2.id ===
                                                                      item.id
                                                                  );
                                                                }
                                                              ),
                                                            newselectedCodesArray:
                                                              uniqBy(
                                                                [
                                                                  ...(newselectedCodesArray ||
                                                                    []),
                                                                  {
                                                                    id: found.id,
                                                                    code: found.code,
                                                                    description:
                                                                      found.description,
                                                                  },
                                                                ],
                                                                "code"
                                                              ).filter(
                                                                (item) => {
                                                                  return !this.state.symptompSnomedCode.some(
                                                                    (item2) =>
                                                                      +item2.id ===
                                                                      item.id
                                                                  );
                                                                }
                                                              ),
                                                            symptompSnomedCode:
                                                              [
                                                                // ...this.state
                                                                //   .symptompSnomedCode,
                                                                {
                                                                  id: found.id,
                                                                  code: found.code,
                                                                  description:
                                                                    found.description,
                                                                },
                                                              ],
                                                          },
                                                          () => {
                                                            let {
                                                              onSetDynamicInput,
                                                            } =
                                                              this.props
                                                                .dynamicTabAction;
                                                            let {
                                                              dynamicInputs,
                                                            } = this.props;

                                                            if (
                                                              typeof onSetDynamicInput ===
                                                              "function"
                                                            ) {
                                                              let payload = {
                                                                ...dynamicInputs,
                                                                history:
                                                                  this.state
                                                                    .history,
                                                                reason_text:
                                                                  this.state
                                                                    .reason_text,
                                                                reason_text_string:
                                                                  "",
                                                                symptomsData:
                                                                  this.state
                                                                    .symptomsData,
                                                                diagnosisData:
                                                                  this.state
                                                                    .diagnosisData,
                                                                selectedCodesArray:
                                                                  this.state
                                                                    .selectedCodesArray,
                                                                selectedSnomedCodesArray:
                                                                  this.state
                                                                    .selectedCodesArray,
                                                                symptompSnomedCode:
                                                                  this.state
                                                                    .symptompSnomedCode,
                                                                newselectedCodesArray:
                                                                  this.state
                                                                    .newselectedCodesArray,
                                                              };
                                                              onSetDynamicInput(
                                                                payload
                                                              );
                                                            }
                                                          }
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              )}
                                          </div>
                                        )
                                      }
                                    </div>
                                  }
                                </div>

                                <div className="form-row">
                                  <div className="col-md-7">
                                    <div className="push-10-b">
                                      <div className="consultant_review">
                                        <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                                          Examination
                                        </p>
                                        {/* EXAMINATION START */}
                                        <div className="div-border consultant_data input-bg-f9f9f9">
                                          {consultationDetail !== null && (
                                            <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                              {verifyObject(
                                                consultationDetail,
                                                "consultation_log.examination",
                                                "-"
                                              )
                                                ? verifyObject(
                                                    consultationDetail,
                                                    "consultation_log.examination",
                                                    "-"
                                                  )
                                                : "-"}
                                            </p>
                                          )}
                                          {verifyObject(
                                            consultationDetail,
                                            "consultation_log.edited_examination",
                                            []
                                          ).map((k, index) => {
                                            if (k.examination !== null) {
                                              return (
                                                <div
                                                  className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                                  key={index}
                                                >
                                                  {" "}
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                    {k.examination}
                                                  </p>
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                    Edited by {k.edited_by} On{" "}
                                                    {moment(k.date).format(
                                                      DATE_FORMAT_24_HR
                                                    )}
                                                  </p>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                        </div>
                                        {/* EXAMINATION END */}
                                        {
                                          <AutoTextArea
                                            autoSize={true}
                                            type="text"
                                            className={`form-control-textarea input-bg-f9f9f9 form-control mt-3 ${
                                              errors &&
                                              errors.examination &&
                                              "danger-border"
                                            } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                                            name="examination"
                                            value={examination}
                                            onChange={this._handleTextChange}
                                            placeholder="Write examination detail here"
                                            rows="2"
                                            id="examination_textarea"
                                            scrollToBottomForTextArea={() =>
                                              this.scrollToBottomForTextArea(
                                                "examination_textarea"
                                              )
                                            }
                                          />
                                        }
                                        {errors && (
                                          <span className="validate-danger font-14">
                                            {errors.examination}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    {
                                      <div className={`checkboxgroup`}>
                                        <div className="form-row m-0">
                                          {checkboxStateArray.map(
                                            (item, index) => {
                                              return (
                                                <div
                                                  className={`col p-0 ${
                                                    item.short_text ===
                                                    "Pregnancy"
                                                      ? "preg_input"
                                                      : item.short_text ===
                                                        "Stroke"
                                                      ? "highlighted-stroke"
                                                      : item.short_text ===
                                                        "Sepsis"
                                                      ? "highlighted-sepsis"
                                                      : item.short_text ===
                                                        "PHQ-9"
                                                      ? "highlighted-phq9"
                                                      : item.short_text ===
                                                        "GAD-7"
                                                      ? "highlighted-gad7"
                                                      : item.short_text ===
                                                        "Centor throat"
                                                      ? "highlighted_throat"
                                                      : item.short_text ===
                                                        "Wells DVT"
                                                      ? "highlighted_dvt"
                                                      : ""
                                                  }`}
                                                  key={item.snomed_code}
                                                >
                                                  <div className="inputbox highlightedtext d-flex align-items-center">
                                                    <a
                                                      href={item.link}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {item.short_text}
                                                    </a>
                                                    <div className="numberinput">
                                                      {item.short_text !==
                                                        "Pregnancy" && (
                                                        <InputNumber
                                                          className="custom-input-number"
                                                          formatter={
                                                            this.handleFormater
                                                          }
                                                          parser={(value) =>
                                                            this.handleParser(
                                                              value,
                                                              item.allowNegativeValue
                                                            )
                                                          }
                                                          onChange={(e) =>
                                                            this.setState(
                                                              (state) => {
                                                                let stateItems =
                                                                  state.checkboxStateArray;
                                                                stateItems[
                                                                  index
                                                                ]["value"] = e;
                                                                return {
                                                                  checkboxStateArray:
                                                                    stateItems,
                                                                };
                                                              }
                                                            )
                                                          }
                                                          value={
                                                            item.value || ""
                                                          }
                                                          type="number"
                                                          onBlur={() =>
                                                            this.handleBlur(
                                                              item,
                                                              index
                                                            )
                                                          }
                                                          onKeyPress={(e) =>
                                                            this.handleKeyPress(
                                                              e,
                                                              item,
                                                              index
                                                            )
                                                          }
                                                          precision={2}
                                                          onKeyUp={
                                                            this.handleKeyUp
                                                          }
                                                        />
                                                      )}
                                                      {item.short_text ===
                                                        "Pregnancy" && (
                                                        <div>
                                                          <InputMask
                                                            mask={"99/99/9999"}
                                                            placeholder="DD/MM/YYYY"
                                                            // className="custom-input-number"
                                                            {...this.props}
                                                            onChange={(e) => {
                                                              this.setState(
                                                                (state) => {
                                                                  let stateItems =
                                                                    state.checkboxStateArray;
                                                                  stateItems[
                                                                    index
                                                                  ]["value"] =
                                                                    e.target.value;
                                                                  return {
                                                                    checkboxStateArray:
                                                                      stateItems,
                                                                  };
                                                                }
                                                              );
                                                            }}
                                                            value={item.value}
                                                            onBlur={() =>
                                                              this.handleBlur(
                                                                item,
                                                                index,
                                                                true
                                                              )
                                                            }
                                                            onKeyDown={(e) =>
                                                              this.handleKeyPress(
                                                                e,
                                                                item,
                                                                index,
                                                                true
                                                              )
                                                            }
                                                            onKeyUp={() =>
                                                              this.handleKeyUp(
                                                                true
                                                              )
                                                            }
                                                          >
                                                            {(inputProps) => (
                                                              <input
                                                                {...inputProps}
                                                                type="text"
                                                                className="custom-input-number"
                                                                name={
                                                                  this.props
                                                                    .name
                                                                }
                                                              />
                                                            )}
                                                          </InputMask>
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    }
                                    <div className="push-10-b diagnosisdropwn">
                                      <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize diagnosisdrop">
                                        Diagnosis / Clinical Impressions
                                      </p>
                                      <div>
                                        {/* {selectedSnomedCodesArray &&
                                        isArray(selectedSnomedCodesArray) &&
                                        selectedSnomedCodesArray.map(
                                          (code, index) => {
                                            return (
                                              <div
                                                className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1"
                                                key={index}
                                              >
                                                <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                                                  <span className="ng-binding ng-scope">
                                                    {verifyObject(
                                                      code,
                                                      "code",
                                                      null
                                                    ) !== null
                                                      ? code.code
                                                      : "--"}
                                                  </span>
                                                </p>
                                                <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                                                  {code.description}
                                                </p>
                                              </div>
                                            );
                                          }
                                        )} */}
                                      </div>
                                      <div className="consultaiondetail-main">
                                        {/* //commented poup flow, extarct to main screen */}

                                        <div className="in-block d-flex">
                                          {/* //commented poup flow, extarct to main screen */}

                                          {
                                            <form className="createtableform">
                                              <div className="form-group push-10-b">
                                                <div className="form-row">
                                                  <div className="col-md-12">
                                                    <CreatableSelect
                                                      // isMulti
                                                      // className={`form-control margin-0 ${
                                                      //   errors && errors.snomedCodeId && "danger-border"
                                                      // } indication-dropdown`}

                                                      placeholder="Search and select..."
                                                      className="disselectfield"
                                                      classNamePrefix="react-select"
                                                      isClearable
                                                      onChange={(value) =>
                                                        this._handleDropDownChange(
                                                          value,
                                                          "snomedCodeId"
                                                        )
                                                      }
                                                      components={{
                                                        DropdownIndicator: null,
                                                      }}
                                                      onInputChange={(
                                                        value
                                                      ) => {
                                                        this.setState({
                                                          snomedCodeId: value,
                                                        });
                                                        this.searchUpdate(
                                                          value
                                                        );
                                                      }}
                                                      onCreateOption={
                                                        this
                                                          ._handleCustomCodeAdd
                                                      }
                                                      value={
                                                        this.state.snomedCodeId
                                                          ? this.state
                                                              .snomedCodeId
                                                          : ""
                                                      }
                                                      inputValue={
                                                        this.state.snomedCodeId
                                                          ? this.state
                                                              .snomedCodeId
                                                          : ""
                                                      }
                                                      name="snomedCodeId"
                                                      options={
                                                        snomedCodes.length > 0
                                                          ? snomedCodes
                                                          : []
                                                      }
                                                      isLoading={searching}
                                                      loadingMessage={() => (
                                                        <span>
                                                          Fetching Snomed ...
                                                        </span>
                                                      )}
                                                      onMenuScrollToBottom={
                                                        this.handleScroll
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-xs-12 bg-white coding_modal_table push-10-t">
                                                <table className="table live-talk2-table">
                                                  <thead>
                                                    <tr>
                                                      <th className="font-12 weight-500 text-black">
                                                        Code
                                                      </th>
                                                      <th className="font-12 weight-500 text-black">
                                                        Description
                                                      </th>
                                                      <th className="font-12 weight-500 text-black"></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {selectedCodesArray
                                                      ? selectedCodesArray.length ===
                                                          0 && (
                                                          <tr className="ng-hide">
                                                            <td
                                                              colSpan="3"
                                                              className="text-center text-dark-gray font-12 weight-400 default-padding"
                                                            >
                                                              No codes selected
                                                            </td>
                                                          </tr>
                                                        )
                                                      : null}
                                                    {selectedCodesArray
                                                      ? selectedCodesArray.length >
                                                          0 &&
                                                        isArray(
                                                          selectedCodesArray
                                                        ) &&
                                                        this.removeDuplicates(
                                                          selectedCodesArray
                                                        ).map((code) => {
                                                          let ids = map(
                                                            this.state
                                                              .newselectedCodesArray ??
                                                              [],
                                                            "id"
                                                          );

                                                          let disabledClass =
                                                            ids.includes(
                                                              code?.id
                                                            )
                                                              ? ""
                                                              : "disabled-view";
                                                          return (
                                                            <tr>
                                                              <td className="font-12 weight-400 default-padding text-light-black">
                                                                {verifyObject(
                                                                  code,
                                                                  "code",
                                                                  ""
                                                                )}
                                                              </td>
                                                              <td className="font-12 weight-400 default-padding text-light-black">
                                                                {verifyObject(
                                                                  code,
                                                                  "description",
                                                                  ""
                                                                )}
                                                              </td>
                                                              <td
                                                                className={`font-12 weight-400 default-padding text-light-black text-right relative ${disabledClass}`}
                                                              >
                                                                {" "}
                                                                <span
                                                                  onClick={() =>
                                                                    this._removeCode(
                                                                      code
                                                                    )
                                                                  }
                                                                  className="text-right text-dark-blue weight-400 hide_outline"
                                                                  ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                                                  role="button"
                                                                  tabIndex="0"
                                                                >
                                                                  <img
                                                                    src={
                                                                      deleteImage
                                                                    }
                                                                    alt="Add Coding"
                                                                    height="12"
                                                                    width="12"
                                                                  />
                                                                </span>
                                                              </td>
                                                            </tr>
                                                          );
                                                        })
                                                      : null}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </form>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-5 consultantion-info-table-wrapper p-t-0">
                                    <div className="table-wrapper maintable">
                                      <div className="border">
                                        <div className="bg-gray tablehead">
                                          <div className="d-flex w-50">
                                            <div className="padding-5 w-40">
                                              <b>Key</b>
                                            </div>
                                            <div className="padding-5 w-20">
                                              <b>Value</b>
                                            </div>
                                            <div className="padding-5 flex-1"></div>
                                          </div>
                                        </div>
                                        <div className="tablebodymain">
                                          <div className="tablerow d-flex">
                                            <div className="highlightedtext d-flex">
                                              <div className="keytd">Pulse</div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state.pulseObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .pulseObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state.pulseObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state.pulseObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    // className="custom-input-number"
                                                    className={this.renderInputColor(
                                                      this.state
                                                        .pulse_per_minute,
                                                      "pulse_per_minute"
                                                    )}
                                                    min={30}
                                                    max={200}
                                                    type="number"
                                                    value={
                                                      this.state
                                                        .pulse_per_minute
                                                    }
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[0][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "pulse_per_minute"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "pulse_per_minute"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 0);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "pulse_per_minute"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        0
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {errors.pulse_per_minute}
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    bpm
                                                  </span>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="highlightedtext d-flex">
                                              <div className="keytd">SpO2</div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state
                                                        .blood_oxygen_saturationObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .blood_oxygen_saturationObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state
                                                      .blood_oxygen_saturationObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state
                                                          .blood_oxygen_saturationObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    // className="custom-input-number"
                                                    className={this.renderInputColor(
                                                      this.state
                                                        .blood_oxygen_saturation,
                                                      "blood_oxygen_saturation"
                                                    )}
                                                    min={50}
                                                    max={100}
                                                    type="number"
                                                    value={
                                                      this.state
                                                        .blood_oxygen_saturation
                                                    }
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[3][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "blood_oxygen_saturation"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "blood_oxygen_saturation"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 3);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "blood_oxygen_saturation"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        3
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {
                                                        errors.blood_oxygen_saturation
                                                      }
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    %
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="tablerow d-flex">
                                            <div className="highlightedtext d-flex">
                                              <div className="keytd">
                                                Height{" "}
                                              </div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state.heightObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .heightObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state.heightObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state.heightObject,
                                                        "value",
                                                        ""
                                                      ) * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    className="custom-input-number"
                                                    min={100}
                                                    max={250}
                                                    type="number"
                                                    value={this.state.height}
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[5][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "height"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key === "height"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 5);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key === "height"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        5
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {errors.height}
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    cm
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="highlightedtext d-flex bigfields">
                                              <div className="keytd">
                                                Weight
                                              </div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state.weightObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .weightObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state.weightObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state.weightObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    className="custom-input-number"
                                                    min={25}
                                                    max={250}
                                                    type="number"
                                                    value={this.state.weight}
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[6][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "weight"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key === "weight"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 6);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key === "weight"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        6
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {errors.weight}
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    kg
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* BMI CHANGES START */}
                                          <div className="tablerow d-flex">
                                            <div className="highlightedtext d-flex">
                                              <div className="keytd">BMI </div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state.bmiObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .bmiObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state.bmiObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state.bmiObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : // * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                                      "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    className="custom-input-number"
                                                    // min={100}
                                                    // max={250}
                                                    type="number"
                                                    readOnly
                                                    value={this.state.bmi}
                                                    // onChange={(e) =>
                                                    //   this.setState(
                                                    //     (state) => {
                                                    //       let stateItems =
                                                    //         state.healthStateArray;
                                                    //       stateItems[5]["value"] = e;
                                                    //       return {
                                                    //         healthStateArray:
                                                    //           stateItems,
                                                    //       };
                                                    //     },
                                                    //     () => {
                                                    //       this.handleHealthTextChange(
                                                    //         e,
                                                    //         "height"
                                                    //       );
                                                    //     }
                                                    //   )
                                                    // }
                                                    // onBlur={() => {
                                                    //   let obj =
                                                    //     this.state.healthStateArray.find(
                                                    //       (i, index) => {
                                                    //         if (i.key === "height") {
                                                    //           return i;
                                                    //         }
                                                    //       }
                                                    //     );

                                                    //   this.handleBlurHA(obj, 5);
                                                    // }}
                                                    // onKeyPress={(e) => {
                                                    //   let obj =
                                                    //     this.state.healthStateArray.find(
                                                    //       (i, index) => {
                                                    //         if (i.key === "height") {
                                                    //           return i;
                                                    //         }
                                                    //       }
                                                    //     );
                                                    //   this.handleKeyPressHA(
                                                    //     e,
                                                    //     obj,
                                                    //     5
                                                    //   );
                                                    // }}
                                                    // onKeyUp={this.handleKeyUp}
                                                  />
                                                  {/* {errors && (
                                                  <span className="validate-danger font-14">
                                                    {errors.bmi}
                                                  </span>
                                                )} */}

                                                  {/* <span className="ml-1">cm</span> */}
                                                  <span className="ml-1"></span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="highlightedtext d-flex bigfields">
                                              <div className="keytd">Temp</div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state
                                                        .body_temperatureObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .body_temperatureObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state
                                                      .body_temperatureObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state
                                                          .body_temperatureObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    // className="custom-input-number"
                                                    className={this.renderInputColor(
                                                      this.state
                                                        .body_temperature,
                                                      "body_temperature"
                                                    )}
                                                    min={25}
                                                    max={60}
                                                    type="number"
                                                    value={
                                                      this.state
                                                        .body_temperature
                                                    }
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[4][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "body_temperature"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "body_temperature"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 4);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "body_temperature"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        4
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {errors.body_temperature}
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    °C
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* BMI CHANGES END */}

                                          <div className="tablerow d-flex">
                                            <div className="highlightedtext d-flex bpfields bigfields">
                                              <div className="keytd">
                                                BP (systolic) /(diastolic)
                                              </div>
                                              <div className="valuetd">
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state
                                                        .systolic_bpObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .systolic_bpObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state
                                                      .systolic_bpObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? `${verifyObject(
                                                        this.state
                                                          .systolic_bpObject,
                                                        "value",
                                                        ""
                                                      )} /`
                                                    : "-"}
                                                </Tooltip>
                                                <br />
                                                <Tooltip
                                                  placement="top"
                                                  title={` ${
                                                    verifyObject(
                                                      this.state
                                                        .diastolic_bpObject,
                                                      "created_at",
                                                      ""
                                                    ) !== ""
                                                      ? moment(
                                                          verifyObject(
                                                            this.state
                                                              .diastolic_bpObject,
                                                            "created_at",
                                                            ""
                                                          )
                                                        ).format(
                                                          DATE_FORMAT_24_HR
                                                        )
                                                      : ""
                                                  }`}
                                                >
                                                  {verifyObject(
                                                    this.state
                                                      .diastolic_bpObject,
                                                    "value",
                                                    ""
                                                  ) !== ""
                                                    ? verifyObject(
                                                        this.state
                                                          .diastolic_bpObject,
                                                        "value",
                                                        ""
                                                      )
                                                    : "-"}
                                                </Tooltip>
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center two_input">
                                                  <div className="input-wrapper">
                                                    <InputNumber
                                                      // className="custom-input-number"
                                                      className={this.renderInputColor(
                                                        this.state.systolic_bp,
                                                        "systolic_bp"
                                                      )}
                                                      min={40}
                                                      max={300}
                                                      type="number"
                                                      value={
                                                        this.state.systolic_bp
                                                      }
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (state) => {
                                                            let stateItems =
                                                              state.healthStateArray;
                                                            stateItems[2][
                                                              "value"
                                                            ] = e;
                                                            return {
                                                              healthStateArray:
                                                                stateItems,
                                                            };
                                                          },
                                                          () => {
                                                            this.handleHealthTextChange(
                                                              e,
                                                              "systolic_bp"
                                                            );
                                                          }
                                                        )
                                                      }
                                                      onBlur={() => {
                                                        let obj =
                                                          this.state.healthStateArray.find(
                                                            (i, index) => {
                                                              if (
                                                                i.key ===
                                                                "systolic_bp"
                                                              ) {
                                                                return i;
                                                              }
                                                            }
                                                          );

                                                        this.handleBlurHA(
                                                          obj,
                                                          2
                                                        );
                                                      }}
                                                      onKeyPress={(e) => {
                                                        let obj =
                                                          this.state.healthStateArray.find(
                                                            (i, index) => {
                                                              if (
                                                                i.key ===
                                                                "systolic_bp"
                                                              ) {
                                                                return i;
                                                              }
                                                            }
                                                          );
                                                        this.handleKeyPressHA(
                                                          e,
                                                          obj,
                                                          2
                                                        );
                                                      }}
                                                      onKeyUp={this.handleKeyUp}
                                                    />
                                                    {errors && (
                                                      <span className="validate-danger font-14">
                                                        {errors.systolic_bp}
                                                      </span>
                                                    )}

                                                    <span>/</span>
                                                    <InputNumber
                                                      // className="custom-input-number"
                                                      className={this.renderInputColor(
                                                        this.state.diastolic_bp,
                                                        "diastolic_bp"
                                                      )}
                                                      min={20}
                                                      max={200}
                                                      type="number"
                                                      value={
                                                        this.state.diastolic_bp
                                                      }
                                                      onChange={(e) =>
                                                        this.setState(
                                                          (state) => {
                                                            let stateItems =
                                                              state.healthStateArray;
                                                            stateItems[1][
                                                              "value"
                                                            ] = e;
                                                            return {
                                                              healthStateArray:
                                                                stateItems,
                                                            };
                                                          },
                                                          () => {
                                                            this.handleHealthTextChange(
                                                              e,
                                                              "diastolic_bp"
                                                            );
                                                          }
                                                        )
                                                      }
                                                      onBlur={() => {
                                                        let obj =
                                                          this.state.healthStateArray.find(
                                                            (i, index) => {
                                                              if (
                                                                i.key ===
                                                                "diastolic_bp"
                                                              ) {
                                                                return i;
                                                              }
                                                            }
                                                          );

                                                        this.handleBlurHA(
                                                          obj,
                                                          1
                                                        );
                                                      }}
                                                      onKeyPress={(e) => {
                                                        let obj =
                                                          this.state.healthStateArray.find(
                                                            (i, index) => {
                                                              if (
                                                                i.key ===
                                                                "diastolic_bp"
                                                              ) {
                                                                return i;
                                                              }
                                                            }
                                                          );
                                                        this.handleKeyPressHA(
                                                          e,
                                                          obj,
                                                          1
                                                        );
                                                      }}
                                                      onKeyUp={this.handleKeyUp}
                                                    />
                                                    {errors && (
                                                      <span className="validate-danger font-14">
                                                        {errors.diastolic_bp}
                                                      </span>
                                                    )}
                                                  </div>
                                                  <span className="ml-1">
                                                    mmHg
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="tablerow d-flex">
                                            <div className="highlightedtext d-flex">
                                              <div className="keytd w-60">
                                                Respiratory rate
                                              </div>
                                              <div className="inputtd">
                                                <div className="d-flex align-items-center">
                                                  <InputNumber
                                                    className="custom-input-number"
                                                    min={10}
                                                    max={60}
                                                    type="number"
                                                    value={
                                                      this.state
                                                        .respiratory_rate
                                                    }
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (state) => {
                                                          let stateItems =
                                                            state.healthStateArray;
                                                          stateItems[7][
                                                            "value"
                                                          ] = e;
                                                          return {
                                                            healthStateArray:
                                                              stateItems,
                                                          };
                                                        },
                                                        () => {
                                                          this.handleHealthTextChange(
                                                            e,
                                                            "respiratory_rate"
                                                          );
                                                        }
                                                      )
                                                    }
                                                    onBlur={() => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "respiratory_rate"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );

                                                      this.handleBlurHA(obj, 7);
                                                    }}
                                                    onKeyPress={(e) => {
                                                      let obj =
                                                        this.state.healthStateArray.find(
                                                          (i, index) => {
                                                            if (
                                                              i.key ===
                                                              "respiratory_rate"
                                                            ) {
                                                              return i;
                                                            }
                                                          }
                                                        );
                                                      this.handleKeyPressHA(
                                                        e,
                                                        obj,
                                                        7
                                                      );
                                                    }}
                                                    onKeyUp={this.handleKeyUp}
                                                  />
                                                  {errors && (
                                                    <span className="validate-danger font-14">
                                                      {errors.respiratory_rate}
                                                    </span>
                                                  )}

                                                  <span className="ml-1">
                                                    bpm
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-row push-10-b">
                                <div className="col-md-12 consultant_review">
                                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-high-dark-blue-imp">
                                    Management Plan
                                  </p>
                                  {/* MGMT START */}
                                  <div className="div-border consultant_data input-bg-f9f9f9">
                                    {consultationDetail !== null && (
                                      <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                        {verifyObject(
                                          consultationDetail,
                                          "consultation_log.management_plan",
                                          "-"
                                        )
                                          ? verifyObject(
                                              consultationDetail,
                                              "consultation_log.management_plan",
                                              "-"
                                            )
                                          : "-"}
                                      </p>
                                    )}
                                    {verifyObject(
                                      consultationDetail,
                                      "consultation_log.edited_managment_plan",
                                      []
                                    ).map((k, index) => {
                                      if (k.management_plan !== null) {
                                        return (
                                          <div
                                            className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                            key={index}
                                          >
                                            {" "}
                                            <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70">
                                              {k.management_plan}
                                            </p>
                                            <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                              Edited by {k.edited_by} On{" "}
                                              {moment(k.date).format(
                                                DATE_FORMAT_24_HR
                                              )}
                                            </p>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                  {/* MGMT END */}
                                  {
                                    <AutoTextArea
                                      autoSize={true}
                                      type="text"
                                      className={`form-control-textarea input-bg-f9f9f9 form-control push-10-t ${
                                        errors &&
                                        errors.managementPlan &&
                                        "danger-border"
                                      } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                      name="managementPlan"
                                      value={managementPlan}
                                      onChange={this._handleTextChange}
                                      placeholder="Add your Management Plan"
                                      ref={this.scollRef}
                                      id="management_textarea"
                                      scrollToBottomForTextArea={() =>
                                        this.scrollToBottomForTextArea(
                                          "management_textarea"
                                        )
                                      }
                                    />
                                  }
                                  {errors && (
                                    <span className="validate-danger font-14">
                                      {errors.managementPlan}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {/* )} */}
                              {/* {presriptionPlan && presriptionPlan !== "" && ( */}
                              <div
                                className="form-row push-10-b"
                                // ref={this.scollRef}
                              >
                                <div className="col-md-12 consultant_review">
                                  {(((this.props.prev_appt_tab_key ===
                                    "finished" ||
                                    this.props.prev_appt_tab_key ===
                                      "reviewed") &&
                                    consultationDetail !== null) ||
                                    this.state.presriptionPlan !== "" ||
                                    this.state.presriptionPlan !== null) && (
                                    <p className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                                      Prescription Plan{" "}
                                      {verifyObject(
                                        consultationDetail,
                                        "prescription_code",
                                        null
                                      )
                                        ? `(${verifyObject(
                                            consultationDetail,
                                            "prescription_code",
                                            null
                                          )})`
                                        : ""}
                                    </p>
                                  )}
                                  {/* PRISCRIPTION START */}
                                  <div className="div-border consultant_data input-bg-f9f9f9">
                                    {/* {this.state.presriptionPlan !== "" ||
                                  this.state.presriptionPlan !== null ? (
                                    <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                      {this.state.presriptionPlan
                                        ? this.state.presriptionPlan
                                        : "-"}
                                    </p>
                                  ) : null} */}
                                    {consultationDetail !== null && (
                                      <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                        {prescription_plan
                                          ? prescription_plan
                                          : verifyObject(
                                              this.props?.dynamicInputs,
                                              "presriptionPlan",
                                              null
                                            )
                                          ? verifyObject(
                                              this.props?.dynamicInputs,
                                              "presriptionPlan",
                                              null
                                            )
                                          : verifyObject(
                                              consultationDetail,
                                              "consultation_log.prescription_plan",
                                              "-"
                                            )
                                          ? verifyObject(
                                              consultationDetail,
                                              "consultation_log.prescription_plan",
                                              "-"
                                            )
                                          : "-"}
                                      </p>
                                    )}
                                    {verifyObject(
                                      consultationDetail,
                                      "consultation_log.edited_prescription_plan",
                                      []
                                    ).map((k, index) => {
                                      if (k.prescription_plan !== null) {
                                        return (
                                          <div
                                            className="highlightedtext push-5-t normal-lineheight padding-5-t"
                                            key={index}
                                          >
                                            {" "}
                                            <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                              {k.prescription_plan}
                                            </p>
                                            <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                              Edited by {k.edited_by} On{" "}
                                              {moment(k.date).format(
                                                DATE_FORMAT_24_HR
                                              )}
                                            </p>
                                          </div>
                                        );
                                      }
                                      return null;
                                    })}
                                  </div>
                                  {/* PRISCRIPTION END */}
                                  {/* <AutoTextArea
                                      disabled={
                                        !this.state.isFinshedEdit &&
                                        this.props.prev_appt_tab_key ===
                                          "finished"
                                      }
                                      autoSize={true}
                                      type="text"
                                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                                        errors &&
                                        errors.managementPlan &&
                                        "danger-border"
                                      } custom-input font-12 weight-400 placeholder-10 min-height-26-imp no-focus`}
                                      name="presriptionPlan"
                                      value={presriptionPlan}
                                      // onChange={this._handleTextChange}
                                      // disabled
                                      placeholder="Add your Prescription Plan"
                                      id="prescription_textarea"
                                      scrollToBottomForTextArea={() =>
                                        this.scrollToBottomForTextArea(
                                          "prescription_textarea"
                                        )
                                      }
                                    /> */}
                                  {errors && (
                                    <span className="validate-danger font-14">
                                      {errors.presriptionPlan}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {/* )} */}
                              {consultationDetail !== null &&
                                (this.props.prev_appt_tab_key === "finished" ||
                                  this.props.prev_appt_tab_key ===
                                    "reviewed") && (
                                  <div className="form-group push-10-b relative">
                                    <div className="form-row">
                                      <div className="col-md-12 consultant_review">
                                        <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                          Informational Outcomes
                                        </p>
                                        {
                                          <a
                                            onClick={() =>
                                              this._handleEdit("editOutcome")
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        }
                                        <div className="div-border consultant_data input-bg-f9f9f9">
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                            Treatment :{" "}
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.outcome.treatment",
                                              "-"
                                            )}
                                          </p>
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                            Follow Up :{" "}
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.outcome.follow_up",
                                              "-"
                                            )}
                                          </p>
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                            Referral :{" "}
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.outcome.referral",
                                              "-"
                                            )}
                                          </p>
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                            Comment :{" "}
                                            {verifyObject(
                                              consultationDetail,
                                              "consultation_log.outcome.comment",
                                              "-"
                                            )}
                                          </p>
                                        </div>

                                        <div className="div-border consultant_data push-10-t input-bg-f9f9f9">
                                          {verifyObject(
                                            consultationDetail,
                                            "consultation_log.edited_outcome",
                                            []
                                          ).map((k, index) => {
                                            if (
                                              Object.keys(k.outcome).length !==
                                              0
                                            ) {
                                              return (
                                                <div
                                                  className="edited-outcome"
                                                  key={index}
                                                >
                                                  {" "}
                                                  <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                    Treatment :{" "}
                                                    {verifyObject(
                                                      k,
                                                      "outcome.treatment",
                                                      "-"
                                                    )}
                                                  </p>
                                                  <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                    Follow Up :{" "}
                                                    {verifyObject(
                                                      k,
                                                      "outcome.follow_up",
                                                      "-"
                                                    )}
                                                  </p>
                                                  <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                    Referral :{" "}
                                                    {verifyObject(
                                                      k,
                                                      "outcome.referral",
                                                      "-"
                                                    )}
                                                  </p>
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 margin-top--5-imp">
                                                    Comment :{" "}
                                                    {verifyObject(
                                                      k,
                                                      "outcome.comment",
                                                      "-"
                                                    )}
                                                  </p>
                                                  <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5 margin-top--5-imp">
                                                    Edited by {k.edited_by} On{" "}
                                                    {moment(k.date).format(
                                                      DATE_FORMAT_24_HR
                                                    )}
                                                  </p>
                                                </div>
                                              );
                                            }
                                            return null;
                                          })}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="push-10-t d-flex align-items-center">
                                {/* {(this.props
                                      .show_prescriotion_modal_from_notes ||
                                      this.props
                                        .show_prescriotion_modal_from_notes ===
                                        null) && ( */}
                                {this.props.userRole !==
                                  ROLE_SUPERINTENDENT_PHARMACIST &&
                                (!prescription_plan ||
                                  prescription_plan === "") &&
                                (verifyObject(
                                  consultationDetail,
                                  "consultation_log.prescription_plan",
                                  null
                                ) === "" ||
                                  verifyObject(
                                    consultationDetail,
                                    "consultation_log.prescription_plan",
                                    null
                                  ) === null) ? (
                                  <button
                                    onClick={this.handleAddPrescription}
                                    disabled={
                                      this.state.updatingConsultationDetail ||
                                      this.state.isReviewing
                                    }
                                    className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 mr-1"
                                  >
                                    Add Prescription
                                  </button>
                                ) : null}
                                {/* )} */}
                                {/* {(this.props.userRole === ROLE_DOCTOR ||
                                this.props.userRole ===
                                  ROLE_CLINICAL_PHARMACIST) &&
                                (this.props.prev_appt_tab_key ===
                                  "unfinished" ||
                                  (this.props.prev_appt_tab_key ===
                                    "finished" &&
                                    consultationDetail === null)) && (
                                  // (isShowSubmit || this.props.show_submit_from_notes) &&
                                  <div className="push-60-b margin-top-15">
                                    <button
                                      onClick={this._handleSubmit}
                                      className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-255px"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                )} */}
                                {/* {(this.props.userRole === ROLE_DOCTOR ||
                                this.props.userRole ===
                                  ROLE_CLINICAL_PHARMACIST) &&
                                this.props.prev_appt_tab_key === "finished" &&
                                !this.state.isFinshedEdit && (
                                  // (isShowSubmit || this.props.show_submit_from_notes) &&
                                  <div className="push-60-b margin-top-15">
                                    <Button
                                      onClick={this.handleUpdateConsultation}
                                      className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-255px"
                                      disabled={
                                        this.state.updatingConsultationDetail
                                      }
                                      loading={
                                        this.state.updatingConsultationDetail
                                      }
                                    >
                                      Update Consultation
                                    </Button>
                                  </div>
                                )} */}
                                {/* {(this.props.userRole === ROLE_DOCTOR ||
                                this.props.userRole ===
                                  ROLE_CLINICAL_PHARMACIST) && (
                                // (isShowSubmit || this.props.show_submit_from_notes) &&
                                <div className="push-60-b margin-top-15">
                                  <button
                                    onClick={() => this._handleSubmit(true)}
                                    className="btn btn-custom btn-blue font-12 weight-500 submitBtn width-100px height-30 p-0 ml-1"
                                  >
                                    Submit For Review
                                  </button>
                                </div>
                              )} */}
                                {isReview === "true" && (
                                  <div className="text-left">
                                    <button
                                      onClick={() => this._markAsReviewed(true)}
                                      disabled={this.state.isReviewing}
                                      className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-255px height-30 p-0 mr-1"
                                    >
                                      Mark As Reviewed
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <InformationalOutcomeModal
                            _handleSubmit={this._handleModalSubmission}
                            _hideModal={this._hideModal}
                            visible={visible}
                            changing={addingDetail}
                            submitting={this.state.addingDetail}
                            _handleTextChange={this._handleTextChange}
                            _handleUpdateoutcome={this._handleUpdateoutcome}
                            {...this.state}
                          />
                        </div>
                      </PerfectScrollbar>
                    </Pane>
                    <Pane initialSize={splitSize ? "53px" : "495px"}>
                      <div
                        className={`sectionscroll walkingappointmentsecscroll ${secondPanelClassName}`}
                      >
                        <div className="dragbtn">
                          <a className="d-flex btn-updown" onClick={this.handleButtonClick}>
                            <span className="uprrow">
                              <CaretUpOutlined />
                            </span>
                            <span className="downarrow">
                              <CaretDownOutlined />
                            </span>
                          </a>
                        </div>
                        <div className="padding-left-10 padding-right-10">
                          <Fragment>
                            <Tabs
                              onChange={this.handleTabChange}
                              defaultActiveKey="finished"
                              activeKey={this.state.tabKey}
                              destroyInactiveTabPane={true}
                            >
                              <Tabs.TabPane
                                tab={
                                  <div>
                                    <span className="icons_wrapper">
                                      <span className="icons_wrapper_img_box"></span>
                                      <span className="icons_wrapper_title">
                                        Prev.Encounters
                                        {this.state.tabKey ===
                                          "preencounters" && (
                                          <span className="in-block">
                                            {isLoadingPreEncounter
                                              ? ""
                                              : `(${verifyObject(
                                                  paginationPreEncounter,
                                                  "total",
                                                  ""
                                                )})`}
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                }
                                key={"preencounters"}
                                disabled={isLoading}
                              >
                                <PreEncountersContainer
                                  _getPatientConultationDetails={
                                    this._getPatientConultationDetails
                                  }
                                />
                              </Tabs.TabPane>
                              <Tabs.TabPane
                                tab={
                                  <div>
                                    <span className="icons_wrapper">
                                      <span className="icons_wrapper_img_box"></span>
                                      <span className="icons_wrapper_title">
                                        Prescriptions{" "}
                                        {this.state.tabKey ===
                                          "prescriptions" && (
                                          <span className="in-block">
                                            {isLoadingPrescription
                                              ? ""
                                              : `(${verifyObject(
                                                  paginationPrescription,
                                                  "total",
                                                  ""
                                                )})`}
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                }
                                key={"prescriptions"}
                                disabled={isLoading}
                              >
                                <PrescriptionsContainer
                                  triggeredFromPast={this.state.triggered}
                                  prev_appt_tab_key={
                                    this.props.prev_appt_tab_key
                                  }
                                  consultation_obj={this.props.consultation_obj}
                                />
                              </Tabs.TabPane>{" "}
                              <Tabs.TabPane
                                tab={
                                  <div>
                                    <span className="icons_wrapper">
                                      <span className="icons_wrapper_img_box"></span>
                                      <span className="icons_wrapper_title">
                                        Sick Note{" "}
                                        {this.state.tabKey === "sicknote" && (
                                          <span className="in-block">
                                            {isLoadingPrescription
                                              ? ""
                                              : `(${verifyObject(
                                                  paginationPrescription,
                                                  "total",
                                                  ""
                                                )})`}
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                }
                                key={"sicknote"}
                                disabled={isLoading}
                              >
                                <SickNotesContainer />
                              </Tabs.TabPane>{" "}
                              <Tabs.TabPane
                                tab={
                                  <div>
                                    <span className="icons_wrapper">
                                      <span className="icons_wrapper_img_box"></span>
                                      <span className="icons_wrapper_title">
                                        Referral Note{" "}
                                        {this.state.tabKey ===
                                          "referralnote" && (
                                          <span className="in-block">
                                            {isLoadingPrescription
                                              ? ""
                                              : `(${verifyObject(
                                                  paginationPrescription,
                                                  "total",
                                                  ""
                                                )})`}
                                          </span>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                }
                                key={"referralnote"}
                                disabled={isLoading}
                              >
                                <ReferralNotesContainer />
                              </Tabs.TabPane>
                              <Tabs.TabPane
                                tab={
                                  <div>
                                    <span className="icons_wrapper">
                                      <span className="icons_wrapper_img_box"></span>
                                      <span className="icons_wrapper_title">
                                        Screeenshots
                                      </span>
                                    </span>
                                  </div>
                                }
                                key={"screenshots"}
                                disabled={isLoading}
                              >
                                <CallScreenShotContainer
                                  fromConsultation={true}
                                  // requestFirst={
                                  //   dynamicInputs === null && history === ""
                                  //     ? true
                                  //     : false
                                  // }
                                />
                              </Tabs.TabPane>
                            </Tabs>
                          </Fragment>
                          <InformationalOutcomeModal
                            _handleSubmit={this._handleModalSubmission}
                            _hideModal={this._hideModal}
                            visible={visible}
                            changing={addingDetail}
                            submitting={this.state.addingDetail}
                            _handleTextChange={this._handleTextChange}
                            _handleUpdateoutcome={this._handleUpdateoutcome}
                            {...this.state}
                          />
                          {/*NOTE: commented add code as it's taken on screen, if requir in popup again need to uncomment popup and need some code revert for on dropdown selection of snomed code save button click functionality performed directly on selction */}
                          {/* <ModalPopUp
              title={"Code Selection"}
              handleCancel={this._hideCodeModal}
              visible={showCodeModal}
              footer={true}
              closable={false}
              handleOk={() => {
                if (consultationDetail === null) {
                  this.setState({
                    showCodeModal: false,
                    selectedSnomedCodesArray: uniq([
                      ...selectedSnomedCodesArray,
                      ...selectedCodesArray,
                    ]),
                  });
                } else {
                  this._handleSnomedCodeUpdate();
                }
              }}
              okText={"Save"}
              okButtonProps={{disabled: selectedCodesArray.length === 0}}
              loading={changing}
              cancelBtnclassName="custom_cancel_btn"
              maskClosable={false}
            >
              <form>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">Indication</label>
                      <Select
                        className={`form-control margin-0 ${
                          errors && errors.snomedCodeId && "danger-border"
                        } indication-dropdown`}
                        name="snomedCodeId"
                        onPopupScroll={this.handleScroll}
                        allowClear={searching ? false : true}
                        value={this.state.snomedCodeId}
                        showSearch={true}
                        onChange={(value) =>
                          this._handleDropDownChange(value, "snomedCodeId")
                        }
                        onSearch={(value) => {
                          let searchValue = value;
                          this.searchUpdate(searchValue);
                        }}
                        suffixIcon={
                          !isLoadmore &&
                          searching && (
                            <Spin
                              className="custom-dropdown-spinner"
                              size="small"
                            />
                          )
                        }
                        notFoundContent={
                          !searching &&
                          snomedCodes.length === 0 && (
                            <span>No codes available</span>
                          )
                        }
                        filterOption={false}
                        dropdownRender={(menus) => {
                          return (
                            <div ref={this.scrollDiv}>
                              {menus}
                              {isLoadmore && searching && (
                                <div style={{textAlign: "center", padding: 10}}>
                                  <Spin size="small" />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      >
                        {snomedCodes.map((k) => {
                          return (
                            <Option value={k.id}>
                              <Highlighter
                                highlightClassName="search-highlighter"
                                searchWords={[this.state.search]}
                                autoEscape={true}
                                textToHighlight={
                                  verifyObject(k, "full_code", null) !== null
                                    ? `${k.full_code}`
                                    : `-`
                                }
                              />
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 bg-white push-10-b coding_modal_table push-10-t">
                  <table className="table live-talk2-table">
                    <thead>
                      <tr>
                        <th className="font-10 weight-500 text-black">Code</th>
                        <th className="font-10 weight-500 text-black">
                          Description
                        </th>
                        <th className="font-10 weight-500 text-black"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCodesArray.length === 0 && (
                        <tr className="ng-hide">
                          <td
                            colSpan="3"
                            className="text-center text-dark-gray font-10 weight-400 default-padding"
                          >
                            No codes selected
                          </td>
                        </tr>
                      )}
                      {selectedCodesArray.map((code) => (
                        <tr>
                          <td className="font-10 weight-400 default-padding text-light-black">
                            {code.code}
                          </td>
                          <td className="font-10 weight-400 default-padding text-light-black">
                            {code.description}
                          </td>
                          <td className="font-10 weight-400 default-padding text-light-black text-right relative">
                            {" "}
                            <span
                              onClick={() => this._removeCode(code)}
                              className="text-right text-dark-blue weight-400 hide_outline"
                              ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                              role="button"
                              tabIndex="0"
                            >
                              <img
                                src={deleteImage}
                                alt="Add Coding"
                                height="12"
                                width="12"
                              />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1" className="">
                        Feel free to freetext your Clinical Impressions
                      </label>
                      <Input
                        className={`form-control placeholder-10 addcomment margin-0 ${
                          errors && errors.snomedCodeId && "danger-border"
                        }`}
                        name="snomedCodeText"
                        placeholder="Add custom code"
                        value={snomedCodeText}
                        onChange={this._handleTextChange}
                        suffix={
                          !isEmpty(snomedCodeText) && (
                            <img
                              width={12}
                              className="cursor-pointer"
                              src={addImage}
                              alt="Add"
                              onClick={this._handleCustomCodeAdd}
                            />
                          )
                        }
                      />
                      {this.state.errors && (
                        <span className="validate-danger font-14">
                          {this.state.errors.snomedCodeId}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </ModalPopUp> */}
                        </div>
                      </div>
                    </Pane>
                  </SplitPane>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    preEnccounter: verifyObject(state, "preEnccounter", []),
    prescriptions: verifyObject(state, "referralNotes", []),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", false),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    selectedSidebarKey: verifyObject(state, "dynamicTab.sidebar_key", ["1"]),

    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    isPrescriptionAdded: verifyObject(
      state.localStore,
      "is_prescription_added.is_presription_added",
      null
    ),
    isOwnPharamcy: verifyObject(
      state.localStore,
      "is_prescription_added.is_own_pharamcy",
      false
    ),
    prescriptionCode: verifyObject(
      state.localStore,
      "is_prescription_added.prescription_code",
      null
    ),
    patientDOB: verifyObject(state.localStore, "patient_detail_obj", null),

    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    userRole: verifyObject(state, "localStore.user.role", null),
    userId: verifyObject(state, "localStore.user.id", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    prescriptionAbbreviation: verifyObject(
      state,
      "prescriptionAbbreviation",
      []
    ),
    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),

    systolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.systolic_bp",
      ""
    ),
    diastolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.diastolic_bp",
      ""
    ),
    pulse_per_minute: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.pulse_per_minute",
      ""
    ),
    respiratory_rate: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.respiratory_rate",
      ""
    ),
    blood_oxygen_saturation: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.blood_oxygen_saturation",
      ""
    ),
    body_temperature: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.body_temperature",
      ""
    ),
    height: verifyObject(state.dynamicTab, "dynamicHealthInputs.height", ""),
    weight: verifyObject(state.dynamicTab, "dynamicHealthInputs.weight", ""),
    bmi: verifyObject(state.dynamicTab, "dynamicHealthInputs.bmi", ""),

    patient_id: verifyObject(
      state.localStore,
      "patient_detail_obj.user.id",
      ""
    ),

    reason_text: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text",
      ""
    ),
    reason_text_string: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text_string",
      ""
    ),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    consultation_obj: verifyObject(state, "localStore.consultation_obj", null),
    gender: verifyObject(state.localStore, "patient_detail_obj.gender", ""),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onRequestSetComponentID,
        onSetDynamicInput,
        onSetSidebarKey,
        onSetDynamicHealthInput,
      },
      dispatch
    ),

    preEncountersActions: bindActionCreators(preEncountersActions, dispatch),
    prescriptionAbbreviationActions: bindActionCreators(
      prescriptionAbbreviationActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientConsultationContainerForInReview);

export default withRouter(ConnectedComponent); //ConnectedComponent;
